<template>
  <!-- Conteúdo -->
  <div class="container main">
      <div class="row">
          <div class="col-12">
              <div class="bg-white d-block mb-20">
                  <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Esqueci minha Senha</h3>
                  <div class="bg-white p-15">
                    <input v-model="oAccount.email" type="text" class="form-control mt-10 br-0" placeholder="Digite seu email" />
                  </div>
                  <div class="p-15 text-center d-block">
                      <button @click="sendEmail" class="btn btn-primary text-uppercase text-center mx-auto">
                        Enviar
                      </button>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!-- FIM Conteúdo -->
</template>
<script>
import apiAccount from '@/api/account'
import router from '@/router'
import { showAlert } from '@/plugins/alerts'
export default {
  data () {
    return {
      oAccount: {
        email: '',
        redirect_url: 'https://' + location.host + '/front/nova-senha'
      }
    }
  },

  mounted () {
    if (this.$route.query.email) {
      this.oAccount.email = this.$route.query.email
    }
  },

  methods: {
    sendEmail: function () {
      if (this.oAccount.email === '') {
        showAlert('warning', 'Preencha o email!')
        return false
      }
      apiAccount.SendEmailPasswordForgotten(this.oAccount).then(function (response) {
        let aData = response.data
        let bSuccess = aData.success
        if (!bSuccess) {
          showAlert('error', aData.errors[0])
        } else {
          showAlert('success', 'Sucesso! Verifique sua conta de email!')
          router.push({ name: 'homeCatalog' })
        }
      })
    }
  }
}
</script>
