<template>
    <div class="container main">
        <div class="row">
            <div class="col-12">
                <div class="bg-white d-block mb-15">
                    <form id="survey-answer-form">
                        <ul class="pb-15 list-unstyled">
                            <li class="title title-1 text-uppercase">
                                <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Pesquisa de satisfação</h3>
                            </li>
                            <template v-if="error">
                                <div class="text-center my-30 ">
                                    <h1 class="fw-700 fs-m">{{error}}</h1>
                                </div>
                            </template>
                            <template v-if="!error && !success">
                                <li class="text-center">
                                    <div v-for="(question, idx) of survey" v-bind:key="idx" class="my-30">
                                        <h3 class="fw-700 fs-m">{{question.question}}</h3>
                                        <div class="star-survey mt-15" v-if="parseInt(question.note_type) === 1">
                                            <fieldset class="">
                                                <input type="radio" v-model="question.answer_note" v-bind:id="'survey_5_'+idx" value="5" /><label v-bind:for="'survey_5_'+idx"></label>
                                                <input type="radio" v-model="question.answer_note" v-bind:id="'survey_4_'+idx" value="4" /><label v-bind:for="'survey_4_'+idx"></label>
                                                <input type="radio" v-model="question.answer_note" v-bind:id="'survey_3_'+idx" value="3" /><label v-bind:for="'survey_3_'+idx"></label>
                                                <input type="radio" v-model="question.answer_note" v-bind:id="'survey_2_'+idx" value="2" /><label v-bind:for="'survey_2_'+idx"></label>
                                                <input type="radio" v-model="question.answer_note" v-bind:id="'survey_1_'+idx" value="1" /><label v-bind:for="'survey_1_'+idx"></label>
                                            </fieldset>
                                        </div>
                                        <div v-else-if="parseInt(question.note_type) === 2">
                                            <textarea v-model="question.answer_note_text" data-counter="2"></textarea>
                                        </div>
                                        <div v-else>
                                            <input type="radio" v-model="question.answer_note" value="1"> Ruim
                                            <input type="radio" v-model="question.answer_note" value="2"> Boa
                                            <input type="radio" v-model="question.answer_note" value="3"> Ótima
                                            <br>
                                            <textarea v-model="question.answer_note_text" data-counter="25"></textarea>
                                        </div>
                                    </div>
                                    <div class="my-30">
                                       <h3 class="fw-700 fs-m mb-1">Observações</h3>
                                       <div class="star-survey">
                                       <textarea class="ft-12" v-model="comment" rows="5" cols="30"></textarea>
                                       </div>
                                    </div>
                                </li>
                                <li class="text-center">
                                    <input class="btn btn-primary text-uppercase my-30" id="send-survey-answer" value="Enviar" @click="postSurvey()" />
                                </li>
                            </template>
                            <template v-if="success">
                                <ul class="text-center my-30">
                                    <li class="fw-700 fs-m">Respostas enviadas com sucesso! </li>
                                </ul>
                                <div class="box box-2 color-open bg-2 text-center relative mt-15">
                                    <i class="far fa-thumbs-up fs-14 mt-15 mb-15" aria-hidden="true"></i>
                                </div>
                                <div class="box box-2 bg-2 text-center my-30 ">
                                    <p class="fw-700 fs-m mb-30">Agradecemos por ter participado da nossa pesquisa e esperamos tê-lo em breve em nosso estabelecimento novamente :)</p>
                                </div>
                            </template>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import apiSurvey from '@/api/survey'
export default {
  name: 'vtto-survey',
  created () {
    this.hash = this.$route.params.hash
  },
  data: () => ({
    hash: null,
    survey: {},
    error: false,
    success: false,
    comment: ''

  }),
  mounted () {
    this.getSurvey()
  },
  methods: {
    postSurvey () {
      let data = {
        survey_token: this.hash,
        notes: []
      }
      let note = {}
      for (var i in this.survey) {
        note = {
          question_id: this.survey[i].question_id,
          numeric_note: typeof this.survey[i].answer_note !== 'undefined' ? this.survey[i].answer_note : 0,
          string_note: typeof this.survey[i].answer_note_text !== 'undefined' ? this.survey[i].answer_note_text : '',
          comment: this.comment
        }
        data.notes.push(note)
      }
      let self = this
      apiSurvey.PostSurvey(data).then(function (response) {
        if (!response || !response.data || response.data.success === false) {
          self.error = response.data.errors[0]
          return false
        } else {
          self.success = true
        }
      })
    },

    getSurvey () {
      let surveyToken = {
        survey_token: this.hash
      }
      let self = this
      apiSurvey.GetSurvey(surveyToken).then(function (response) {
        if (!response || !response.data || response.data.success === false) {
          self.error = response.data.errors[0]
          return false
        } else {
          let aData = response.data
          self.survey = aData.data
        }
      })
    }
  }
}
</script>
