import vttoService from '@/api/catalog'

const state = {
  catalog: [],
  ingredientsextra: [],
  buttonSteps: false,
  buttonStepsLabel: 'Próximo',
  buttonStepsMode: 1,
  filter: '',
  edges: []
}

const aAllCategories = {}
const getProducts = (products, category, isCombo, isDivision, aEdges = []) => {
  for (const i in products) {
    const product = products[i]
    let iCategoryId = 0
    let categoryEdges = []
    let categoryExtras = []
    if (isCombo) {
      /* Categoria que o produto está atribuído */
      iCategoryId = product.category_parent_id ? product.category_parent_id : product.category_id
      category = getCategory(aAllCategories[iCategoryId])
      isDivision = category && category.categorDivisionAllowed // Em caso de combo, atribui a regra de meio a meio para a categoria do produto que está no combo.
      categoryEdges = category && category.edges ? category.edges : []
      if (categoryEdges.length == 0) {
        categoryEdges = getEdges(iCategoryId, aEdges)
      }
      /* A categoria que ele está atribuído é uma subcategoria? (USado para atribuir os ingredientes extras ao produto) */
      let subcategory = category
      if (product.category_parent_id && aAllCategories[iCategoryId]) {
        subcategory = getSubcategory(aAllCategories[iCategoryId].category_subcategories, product.category_id)
      }

      if (subcategory) {
        categoryExtras = isDivision && subcategory.extras && subcategory.extras[product.category_id] ? (subcategory.extras[product.category_id]) : (subcategory.extras ? subcategory.extras : [])
      }
    } else {
      categoryEdges = category.edges ? category.edges : []
      categoryExtras = isDivision && category.extras && category.extras[product.category_id] ? (category.extras[product.category_id]) : (category.extras ? category.extras : [])
    }

    for (const y in product.product_sizes) {
      const iSizeId = product.product_sizes[y].size_id
      product.product_sizes[y].size_edges = categoryEdges[iSizeId] ? categoryEdges[iSizeId] : []
      product.product_sizes[y].ingredients_extra = categoryExtras[iSizeId] ? categoryExtras[iSizeId] : []
    }

    // Produto é um combo
    if (product.product_steps) {
      for (const j in product.product_steps) {
        const comboproducts = product.product_steps[j].step_products
        if (comboproducts) {
          getProducts(comboproducts, category, true, '', category.edges)
        }
      }
    }
  }
  return products
}

const getSizes = (products, catalogItem) => {
  products = getProducts(products, catalogItem, false, true)
  const sizes = {}
  products.map(a => {
    if (a.product_sizes) {
      a.product_sizes.map(b => {
        if (!sizes[b.size_name]) {
          sizes[b.size_name] = {
            size_max_products: 0,
            size_id: 0,
            products: []
          }
        }
        sizes[b.size_name].size_max_products = b.size_max_products
        sizes[b.size_name].size_id = b.size_id
        sizes[b.size_name].products.push(a)
      })
    }
  })
  return sizes
}

const getSubcategory = (aSubcategories, iSubcategoryId) => {
  for (const i in aSubcategories) {
    const subcategory = aSubcategories[i]
    if (subcategory && parseInt(subcategory.category_id) === parseInt(iSubcategoryId)) {
      return {
        categoryName: subcategory.category_name ? subcategory.category_name : '',
        categoryType: subcategory.category_type,
        categoryId: subcategory.category_id,
        categorDivisionAllowed: subcategory.category_division_allowed,
        products: [],
        extras: subcategory.category_ingredientextras,
        edges: subcategory.category_edges
      }
    }
  }
  return false
}

const getEdges = (idCategory, aEdges) => {
  Object.keys(aEdges).forEach(function (key) {
    aEdges[key].forEach(e => {
      if (e.edge_category_id == idCategory) {
        state.edges.push(aEdges[key])
      }
    })
  })

  return state.edges
}

const getCategory = (a) => {
  if (a) {
    return {
      categoryName: a.category_name ? a.category_name : '',
      categoryType: a.category_type,
      categoryId: a.category_id,
      categorDivisionAllowed: a.category_division_allowed,
      products: [],
      extras: a.category_ingredientextras,
      edges: a.category_edges
    }
  }
}

const getters = {
  getCatalogFormated (state) {
    const originCatalog = Object.assign({}, state.catalog)
    const catalog = []
    for (const i in originCatalog) {
      aAllCategories[originCatalog[i].category_id] = originCatalog[i]
      if (originCatalog[i].category_products && originCatalog[i].category_products.length === 0) {
        delete originCatalog[i]
      }

      if (originCatalog[i] && originCatalog[i].category_subcategories) {
        for (const y in originCatalog[i].category_subcategories) {
          if (originCatalog[i].category_subcategories[y].category_products && originCatalog[i].category_subcategories[y].category_products.length === 0) {
            delete originCatalog[i].category_subcategories[y]
          }
        }

        if (originCatalog[i].category_subcategories.length === 0) {
          delete originCatalog[i]
        }
      }
    }

    Object.entries(originCatalog).map(a => {
      const catalogItem = getCategory(a[1])
      if (!a[1].category_subcategories) {
        catalogItem.products = []
        catalogItem.enabledDeliveryConsumer = a[1].category_enabled_delivery_consumer
        if (!a[1].category_division_allowed) {
          catalogItem.products = getProducts(a[1].category_products, catalogItem)
          return catalog.push(catalogItem)
        }

        catalogItem.sizes = getSizes(a[1].category_products, catalogItem)
        return catalog.push(catalogItem)
      }

      a[1].category_subcategories.map(b => {
        const catalogItem = Object.assign({}, getCategory(b))
        catalogItem.products = []
        catalogItem.categoryName = a[1].category_name ? a[1].category_name : ''
        catalogItem.subCategoryName = b && b.category_name ? b.category_name : ''
        catalogItem.edges = a[1].category_edges
        catalogItem.enabledDeliveryConsumer = b.category_enabled_delivery_consumer
        if (!a[1].category_division_allowed) {
          catalogItem.products = getProducts(b.category_products, catalogItem)
          return catalog.push(catalogItem)
        }

        catalogItem.sizes = getSizes(b.category_products, catalogItem)
        return catalog.push(catalogItem)
      })
    })
    return catalog
  }
}

const actions = {
  getCatalog ({ commit }) {
    const session = JSON.parse(sessionStorage.getItem('vtto.catalog.catalog'))
    const sessionIngredientsExtra = JSON.parse(sessionStorage.getItem('vtto.catalog.ingredientsextra'))

    if (session) {
      commit('setIngredientsextra', sessionIngredientsExtra)
      commit('setCatalog', session)
      return
    }

    vttoService.catalogCatalog().then(res => {
      if (res && res.data && res.data.success) {
        sessionStorage.setItem('vtto.catalog.catalog', JSON.stringify(res.data.data.categories))
        commit('setCatalog', res.data.data.categories)
        sessionStorage.setItem('vtto.catalog.ingredientsextra', JSON.stringify(res.data.data.categories_ingredients_extra))
        commit('setIngredientsextra', res.data.data.categories_ingredients_extra)
      }
    })
  },

  searchCatalog ({ commit }, term) {
    commit('setFilter', term)
  },

  setBtnMode ({ commit }, mode) {
    commit('setButtonStepsMode', mode)
  },

  buttonSteps ({ commit }, value) {
    commit('switchButtonSteps', value)
  }
}

const mutations = {
  setCatalog (state, catalog) {
    state.catalog = catalog
  },
  setIngredientsextra (state, ingredientsextra) {
    state.ingredientsextra = ingredientsextra
  },
  setFilter (state, term) {
    state.filter = term
  },
  setButtonStepsMode (state, mode) {
    state.buttonStepsMode = mode
    if (mode === 1) {
      state.buttonStepsLabel = 'Próximo'
    } else {
      state.buttonStepsLabel = 'Adicionar ao Pedido'
    }
  },
  setButtonStepsLabel (state, label) {
    state.buttonStepsLabel = label
  },
  switchButtonSteps (state, value) {
    state.buttonSteps = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
