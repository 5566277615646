<template>
    <div>
        <!-- Mapa -->
        <div class="row" v-if="aDeliveryAreas.delivery_type == 'maps'">
            <div class="col-12">
                <div class="bg-white d-block mb-20" v-if="marker">
                    <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Regiões de Entrega</h3>
                    <l-map style="height: 450px; position:relative" :zoom="zoom" :center="marker">
                        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                        <l-marker :lat-lng="marker"></l-marker>
                        <l-polygon :fillRule="'nonzero'" :lat-lngs="oPolygon" v-for="(oPolygon, iPolygon) in aPolygonPoints" :key="iPolygon"></l-polygon>
                    </l-map>
                </div>
            </div>
        </div>
        <!-- FIM Mapa -->
        <!-- Regiões separadas por bairro -->
        <div class="row" v-if="aDeliveryAreas.delivery_type == 'regions'">
            <div class="col-12">
                <div class="bg-white d-block mb-20">
                    <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Regiões de Entrega</h3>
                    <ul class="list-unstyled">
                        <div class="bg-white w-100 float-left text-uppercase">
                            <div class="fw-700 d-block mt-15 mb-15 ml-15">
                                <input type="checkbox" class="form-group-md-checkbox" id="showCities" v-model="showCities" />
                                <label class="form-group-md-label_checkbox fw-700" for="showCities">
                                    Exibir nome das cidades
                                </label>
                            </div>
                            <li v-for="(oDeliveryArea, iDeliveryArea) in aDeliveryAreas.delivery_areas" :key="iDeliveryArea"
                                class="fw-400 p-20 fs-m bb-1-gray limit-text w-33 float-left bg-white list-region">
                                {{showCities ? oDeliveryArea.city_name+' - ' : '' }}{{oDeliveryArea.district_name}}
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
        <!-- FIM Regiões separadas por bairro -->
    </div>
</template>

<script>
/* TODO: Exibir CSS no Style */
import store from '@/store'
import { mapState } from 'vuex'
import 'leaflet/dist/leaflet.css'
import { LMap, LTileLayer, LMarker, LPolygon } from 'vue2-leaflet'
import L from 'leaflet'

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
  name: 'vtto-about-delivery',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolygon
  },
  mounted () {
    store.dispatch('store/getDeliveryAreas')
  },

  methods: {
    /* Reorganiza os pontos para desenhar no mapa */
    getPolygons: function (aPolygons) {
      let aPointsPoly = []
      for (var iPolygon in aPolygons) {
        let oPolygon = aPolygons[iPolygon]
        let aPoints = []
        let aNewPolygon = []
        for (var iGeolocation in oPolygon.geolocation) {
          var aGeolocation = oPolygon.geolocation[iGeolocation]
          for (var iPoint in aGeolocation) {
            var oPoint = aGeolocation[iPoint]
            let iNewPointLat = oPoint.lat
            let iNewPointLng = oPoint.lng
            let aNewPoint = [iNewPointLat, iNewPointLng]
            aNewPolygon.push(aNewPoint)
          }
          aPoints.push(aNewPolygon)
        }
        aPointsPoly.push(aPoints)
      }
      this.aPolygonPoints.push(aPointsPoly)
    }
  },
  data () {
    return {
      showCities: false,
      zoom: 12,
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      aPolygonPoints: []
    }
  },
  computed: {
    ...mapState({
      aDeliveryAreas: state => state.store.deliveryAreas,
      marker: state => state.store.info.store_geolocation
    })
  },
  watch: {
    marker: function () {
      if (this.aMarker) {
        return L.LatLng(this.aMarker.lat, this.aMarker.lng)
      }
    },
    aDeliveryAreas: function () {
      if (this.aDeliveryAreas) {
        this.getPolygons(this.aDeliveryAreas.delivery_areas)
      }
    }
  }
}
</script>
