<template>
    <div>
      <button @click="auth" class="btn-google"> <img :src="iconGoogle"> Login com Google</button>
    </div>
</template>
<script>
import iconGoogle from './google.png'
import apiLogin from '@/api/account'
import { ConfigGoogleAuth } from './helpers'

export default {
  name: 'google-login',
  data () {
    return {
      iconGoogle,
      profile: {
        email: '',
        google_id: ''
      }
    }
  },
  methods: {
    async auth () {
      try {
        const oGoogleUser = await this.$gAuth.signIn()
        const oUserData = oGoogleUser.getBasicProfile()
        await this.organizeUSerData(oUserData)
        if (this.profile.email) {
          apiLogin.GoLogin(this.profile).then(response => {
            this.$emit('google-auth', response, this.profile)
          })
        }
      } catch (error) {
        if (error.error != 'popup_closed_by_user') {
          console.log('key error')
        }
      }
    },
    async organizeUSerData (oUserData) {
      Object.values(oUserData).map(k => {
        if (/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(k)) {
          this.profile.email = k
        }
        if (!isNaN(k)) {
          this.profile.google_id = k
        }
      })
    }
  },
  props: {
    googleKey: {
      type: String,
      required: true
    }
  },
  mounted () {
    ConfigGoogleAuth(this.googleKey)
  }
}
</script>

<style scoped>
  button {
  position: relative;
  padding: 0 15px 0px 46px;
  border: none;
  line-height: 34px;
  font-size: 16px;
  color: #FFF;
  min-width: 225px;
  background: #c0392b;
}

.spinner {
  box-sizing: border-box;
  width: 30px;
  height: 90%;
  border-radius: 50%;
  border: 5px solid #f3f3f3;
  animation: spin 2s linear infinite;
  position: absolute;
  left: 5px;
}

img {
  position: absolute;
  top: 3px;
  left: 10px;
  width: 30px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
