<template>
    <div v-if="ingredients.length > 0" >
        <div class="bg-white p-15 mt-15 bb-1-gray">
            <span v-if="!extra" class="text-uppercase fs-s fw-900">Ingredientes</span>
            <span v-else class="text-uppercase fs-s fw-900">Ingredientes Adicionais</span>
        </div>
        <template v-if="!extra && typeof currentItem !== 'undefined' && !isEnabledIngredient()">
          <ul class="list-unstyled bg-white">
            <li class="p-15 bb-1-gray">
              {{groupIngredients()}}
            </li>
          </ul>
        </template>
        <template v-else>
          <ul class="list-unstyled bg-white">
              <li class="p-15 bb-1-gray" v-for="(ingredient, index) in ingredients" :key="index">
                  <div class="row">
                      <div class="form-group-md-checkbox_group col-9">
                        <div v-if="!extra">
                          <input ref="item" @click="removeAddIngredients" type="checkbox"
                                :id="`option-${ingredient.ingredient_id}-${extra}`" name="aOptions[]"
                                class="form-group-md-checkbox" :checked="!extra" :value="`${ingredient.ingredient_id}`"/>
                          <label class="form-group-md-label_checkbox fw-700 fs-m"
                                :for="`option-${ingredient.ingredient_id}-${extra}`">{{ingredient.ingredient_name}}</label>
                        </div>
                        <div v-else class="media flex-center justify-start">
                          <div class="btn-numbers d-flex justify-content-between flex-center fs-s">
                            <vtto-btn-qty min="0" :elem="ingredient" init-qty="0" v-model="qty" @quantity-click="removeAddIngredients"/>
                          </div>
                          <span class="fs-m ml-15">{{ingredient.ingredient_name}} {{ ingredientPrice(ingredient) }}</span>
                        </div>
                      </div>
                  </div>
              </li>
          </ul>
        </template>

    </div>
</template>

<script>

import VttoBtnQty from '@/components/VttoBtnQty'

export default {
  components: {
    VttoBtnQty
  },
  data () {
    return {
      qty: 0,
      addIngredients: []
    }
  },
  props: {
    ingredients: {},
    currentItem: {},
    extra: {
      default: false
    },
    step2: {}
  },
  watch: {
    step2: function () {
      for (let i in this.$refs.item) {
        this.$refs.item[i].checked = true
      }
    }
  },
  methods: {
    groupIngredients () {
      let arrIngr = []
      for (let i in this.ingredients) {
        arrIngr.push(this.ingredients[i].ingredient_name)
      }
      return arrIngr.join(', ')
    },
    isEnabledIngredient () {
      let type = typeof this.currentItem.enabled_ingredient
      if (type !== 'undefined') {
        if (type === 'string') {
          return this.currentItem.enabled_ingredient === '1'
        } else if (type === 'number') {
          return this.currentItem.enabled_ingredient === 1
        } else {
          return true
        }
      } else {
        return true
      }
    },
    ingredientPrice (ingredient) {
      if (ingredient.ingredient_amount && ingredient.ingredient_amount > 0) {
        return `(R$ ${parseFloat(ingredient.ingredient_amount).toFixed(2).replace('.', ',')})`
      }
      return ''
    },
    removeAddIngredients (qty) {
      if (!this.extra) {
        let removeIngredients = []
        for (let i in this.$refs.item) {
          if (!this.$refs.item[i].checked) {
            removeIngredients[this.$refs.item[i].value] = {
              ingredient_id: this.ingredients[i].ingredient_id,
              ingredient_name: this.ingredients[i].ingredient_name
            }
          }
        }

        let res = []
        for (let i in removeIngredients) {
          res.push(removeIngredients[i])
        }
        this.$emit('remove-ingredients', res)
        return
      }

      this.addIngredients[qty.elem.ingredient_id] = {
        ingredient_dir: qty.dir,
        ingredient_quantity: qty.qty,
        ingredient_id: qty.elem.ingredient_id,
        ingredient_name: qty.elem.ingredient_name,
        ingredient_amount: qty.elem.ingredient_amount
      }

      let res = []
      for (let i in this.addIngredients) {
        if (this.addIngredients[i].ingredient_quantity > 0) {
          res.push(this.addIngredients[i])
        }
      }

      this.$emit('add-ingredients', res)
    }
  }
}
</script>
