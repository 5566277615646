import Api from './api'

export default {
  storeInfo () {
    return Api('store', 'info', 'get', {}, {})
  },
  storeDeliveryAreas () {
    return Api('store', 'delivery-areas', 'get', {}, {})
  },
  storeIsOpen () {
    return Api('store', 'is-open', 'get', {}, {}, false)
  },
  AddressList () {
    return Api('address', 'list', 'get', {}, {})
  }
}
