var VueScrollTo = require('vue-scrollto')

export function scrollToMe (box, offset) {
  if (window.innerWidth < 991) {
    var options = {
      container: box,
      offset: offset
    }

    VueScrollTo.scrollTo(event.target, 400, options)
  }
}
