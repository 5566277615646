<template>
    <div>
        <!-- Informações pontos -->
        <div class="row">
            <div class="col-12">
                <div class="bg-white mb-20">
                    <div class="d-lg-flex justify-content-lg-between bb-1-gray">
                        <span class="fw-900 text-uppercase p-15 fs-s d-block">Seus pontos</span>
                        <span class="fw-900 text-uppercase d-block p-15 fs-s color-main">Total: {{iPoints}} ponto<span
                                v-if="iPoints != 1">s</span></span>
                    </div>
                    <!-- Pedido -->
                    <paginate name="aPoints" :list="aPoints" class="paginate-list" :per="10">
                        <div class="pedido bb-1-gray" v-for="(oLoyaltyPoint, iLoyaltyPointKey) in paginated('aPoints')"
                             :key="iLoyaltyPointKey">
                            <div class="d-lg-flex justify-content-lg-between">
                                <div class="p-15 mt-10 fs-s d-block">
                                    <template v-if="oLoyaltyPoint.order_id > 0">
                                        Pedido {{oLoyaltyPoint.order_id}} - {{oLoyaltyPoint.sCreated_at}}
                                        <router-link
                                                :to="{ name: 'order-detail', params: { iOrderId: oLoyaltyPoint.order_id}}"
                                                class="color-main">+ detalhes
                                        </router-link>
                                    </template>
                                </div>
                                <div class="price p-15 pt-xs-0 text-lg-right">
                                    <span class="color-main fs-1-4">{{oLoyaltyPoint.points}} pts</span><span
                                        v-if="oLoyaltyPoint.points_in_expiration !== null"> (val. {{oLoyaltyPoint.loyalty.sExpirated_at}})</span>
                                </div>
                            </div>
                        </div>
                    </paginate>
                    <!-- FIM Pedido -->

                    <paginate-links v-if="aPoints.length > 10"
                                    for="aPoints"
                                    :simple="{
                    next: 'Próximo',
                    prev: 'Anterior'
                  }"
                                    :classes="{
                    'ul': ['d-flex', 'align-center','justify-center','list-unstyled'],
                    'ul *': ['py-15','px-10'],
                    '.next > a': ['btn', 'btn-secondary','fs-1-2'],
                    '.prev > a': ['btn', 'btn-secondary','fs-1-2']
                  }"
                    ></paginate-links>
                </div>
            </div>
        </div>
        <!-- FIM Informações pontos -->

        <div class="row" v-if="hasLoyalty">
            <div class="col-12">
                <div class="bg-white p-15 d-block text-center">
                    <router-link :to="{ name: 'loyalty-rules'}"
                                 class="btn btn-secondary text-uppercase text-center mx-auto">
                        Regulamento
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import apiAccount from '@/api/account'
import { empty } from '@/utils'
import { showAlert } from '@/plugins/alerts'

export default {
  data: function () {
    return {
      aPoints: [],
      paginate: ['aPoints']
    }
  },
  mounted () {
    this.aLoyaltyPoints()
  },
  computed: mapState({
    iPoints: state => state.account.oUser.loyaltybalance,
    hasLoyalty: state => !empty(state.store.info.loyalty)
  }),
  methods: {
    clickPagination (iNumber) {
      this.aLoyaltyPoints(iNumber)
    },
    aLoyaltyPoints (iPageNumber) {
      let self = this
      var aReturn = []

      if (!iPageNumber) {
        iPageNumber = 1
      }

      let oPageConfig = {}
      apiAccount.ListLoyaltyReport(oPageConfig).then(function (response) {
        if (!response || !response.data || response.data.success === false) {
          showAlert('error', response.data.errors[0])
          return aReturn
        } else {
          let aData = response.data
          self.aPoints = aData.data
        }
      })
    }
  }
}
</script>
