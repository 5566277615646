<template>
  <div>
    <div class="p-15 bg-white">
        <p class="fw-700 text-uppercase fs-s">Pagante <span class="number">{{iPayer}}</span></p>
        <money name="input-cupom" class="form-control border-0 fs-m p-0 mt-5 number" v-model="oPayer.payment_amount"></money>
    </div>
    <!-- Formas de pagamento -->
    <div id="accordionPayment" class="box-content py-xs-10 mb-xs-15">
      <!-- Forma de pagamento -->
      <div class="card bg-none px-15 pb-10 pt-10"  v-for="(oPaymentType, iPaymentType) in aPaymentTypes" :key="iPaymentType">
        <div v-if="showPaymentType(oPaymentType.type_id)" >
        <div :id="'heading'+iPayer+oPaymentType.type_id">
          <label class="flex-start form-group-radio_group text-uppercase fw-700" :for="'pagto'+iPayer+oPaymentType.type_id" data-toggle="collapse" :data-target="'#collapse'+iPayer+oPaymentType.type_id" aria-expanded="true" :aria-controls="'collapse'+iPayer+oPaymentType.type_id">
            <input data-type="desk" :id="'pagto'+iPayer+oPaymentType.type_id" type="radio" :name="'pagtoForm'+iPayer" class="delivery-type form-group-radiobox" :value="oPaymentType.type_id" v-model="oPayer.payment_type_id"/>
            <span class="form-group-check_radio"></span>
                {{oPaymentType.type_name}}
          </label>
           </div>
        </div>
        <!-- Bandeiras -->
        <div :id="'collapse'+iPayer+oPaymentType.type_id" class="collapse" :aria-labelledby="'heading'+iPayer+oPaymentType.type_id" data-parent="#accordionPayment" >
          <ul class="card-body list-inline m-0 px-0 d-flex flex-column">
            <li class="list-inline-item my-5" v-for="(oPaymentMethod, iPaymentMethod) in oPaymentType.methods" :key="iPaymentMethod">
              <label class="form-group-radio_group text-uppercase fw-700" :for="'flag'+iPayer+oPaymentMethod.method_id">
                <input :id="'flag'+iPayer+oPaymentMethod.method_id" type="radio" :name="'flag'+iPayer+oPaymentMethod.method_id" class="delivery-type form-group-radiobox" :value="oPaymentMethod.method_id"  v-model="oPayer.payment_method_id"/>
                <span class="form-group-check_radio"></span>
                <img loading="lazy" width="30" height="20" data-toggle="tooltip" class="forma-pagamento" :data-id="iPayer+oPaymentMethod.method_id" :alt="oPaymentMethod.method_name" :title="oPaymentMethod.method_name" :src="methodCardImage(oPaymentMethod.method_image)" />
                {{oPaymentMethod.method_name}}
              </label>
              <!-- Somente dinheiro -->
              <div class="p-15" v-if="oPayer.payment_type_id === 3 && oPaymentType.type_id == oPayer.payment_type_id">
                <p class="fw-700 text-uppercase fs-s">Troco Para:</p>
                <money @blur.native="verifyChange(oPayer)" class="form-control border-0 fs-m px-0" v-model="oPayer.change_amount"></money>
              </div>
              <!-- FIM Somente dinheiro -->
            </li>
          <!-- FIM Bandeiras -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { showAlert } from '@/plugins/alerts'
export default {
  name: 'vtto-checkout-payments-payer',
  props: {
    iPayer: {
      default: 0
    },
    aPaymentTypes: {},
    oPayer: {},
    giftPayments: {}
  },
  data: function () {
    return {
      allowedPaymentTypes: {
        CREDIT: 1,
        DEBIT: 2,
        MONEY: 3,
        VOUCHER: 7,
        CHECK: 9,
        PIX: 17
      }
    }
  },
  mounted () {
  },
  computed: {
    iPaymentTypeId: function () {
      return this.oPayer.payment_type_id
    },
    iPaymentMethodId: function () {
      return this.oPayer.payment_method_id
    },
    fAmount: function () {
      return this.oPayer.payment_amount
    },
    fChangeAmount: function () {
      return this.oPayer.change_amount
    }
  },
  methods: {
    verifyChange: function (oPayer) {
      if (oPayer.change_amount <= oPayer.payment_amount && oPayer.change_amount != 0) {
        showAlert('error', 'O valor do troco deve ser maior que o valor do pagamento!')
        this.$eventHub.$emit('btn-enabled', true)
        return
      }
      this.$eventHub.$emit('btn-enabled', false)
    },
    showPaymentType: function (iPaymentTypeId) {
      if (this.giftPayments) {
        const enabled = this.giftPayments.filter(a => {
          if (parseInt(a) === parseInt(iPaymentTypeId)) {
            return true
          }
        })

        if (iPaymentTypeId <= 9 && enabled.length > 0) {
          return true
        }
      }

      const allowedPaymentTypes = Object.values(this.allowedPaymentTypes)
      return allowedPaymentTypes.includes(iPaymentTypeId)
    },
    methodCardImage: function (sImage) {
      if (sImage === null || sImage === '' || sImage === undefined) {
        sImage = 'card-default.png'
      }
      return 'https://vittocdn.com.br/assets/images/admin/cards/' + sImage
    },
    emitPayerChanged: function () {
      this.oPayer.id = this.iPayer - 1
      this.$emit('paymentChanged', this.oPayer)
    },
    findPaymentTypeId: function (iPaymentTypeId) {
      for (let idx in this.aPaymentTypes) {
        if (iPaymentTypeId === this.aPaymentTypes[idx].type_id) {
          return idx
        }
      }
      return false
    }
  },
  watch: {
    iPaymentTypeId: function (val) {
      if (val !== 3) {
        this.$eventHub.$emit('btn-enabled', false)
      }

      let idx = this.findPaymentTypeId(this.oPayer.payment_type_id)

      /* Seleciona sempre o primeiro método de pagamento ao clicar em um tipo de pagamento */
      if (typeof this.aPaymentTypes[idx] !== 'undefined' && this.aPaymentTypes[idx].methods) {
        this.oPayer.payment_method_id = this.aPaymentTypes[idx].methods[0].method_id
        this.oPayer.onlinepayment = false
      }

      this.emitPayerChanged()
    },
    iPaymentMethodId: function (val) {
      this.emitPayerChanged()
    },
    fAmount: function (val) {
      this.emitPayerChanged()
    },
    fChangeAmount: function (val) {
      this.emitPayerChanged()
    }
  }
}
</script>
