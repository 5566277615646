<template>
<div class="bg-white p-15 mb-20">
  <span v-if="!isMobile" class="text-uppercase fs-s fw-900">Observações</span>
  <div v-else>
    <div class="bb-1-gray pb-15 d-flex flex-center justify-between">
        <h3 class="fw-900 text-uppercase d-block fs-s">Observações</h3>
        <i @click="add" class="fas fa-times fs-1-6 pointer"></i>
    </div>
  </div>

  <input @keydown.enter.prevent maxlength="250" ref="textarea" name="input-obs" type="text" class="form-control mt-10 br-0 mt-xs-20" placeholder="Digite aqui suas observações" v-model="sOrderObs"/>

  <div class="p-15 text-center d-block d-sm-none">
    <button @click="add" class="btn btn-primary text-uppercase fs-1-2-xs mt-10">Salvar</button>
  </div>
</div>
</template>
<script>
import { setOrderInfo } from '@/plugins/orders'

export default {
  name: 'vtto-checkout-obs',
  data: function () {
    return {
      sOrderObs: ''
    }
  },
  watch: {
    sOrderObs: function (value) {
      setOrderInfo('order_comments', this.sOrderObs)
    }
  },
  methods: {
    add () {
      let content = this.$refs.textarea.value

      if (content.length > 0) {
        this.$emit('have-obs', content)
      } else {
        this.$emit('have-obs', false)
      }
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.store.isMobile
    }
  }
}
</script>
