<template>
    <!-- Conteúdo -->
    <div class="container main">
        <div class="row">
            <div class="col-12">
                <!-- Menu superior página -->
                <ul class="nav-tabs list-table w-100 bg-white mt-45 mb-20 text-center">
                    <li class="list-table-item relative">
                        <div class="fw-900 fs-s text-uppercase p-15 d-block">
                            <span v-if="iStatusId === 1">Enviando pedido...</span>
                            <span v-if="iStatusId === 5">Pedido cancelado =/</span>
                            <span v-if="iStatusId >= 2 && iStatusId <= 4">Pedido aceito! =D</span>
                            <span v-if="iStatusId === 0">Pedido enviado</span>
                             <span v-if="iStatusId === 7">Aguardando Pagamento...</span>
                        </div>
                    </li>
                </ul>
                <!-- FIM Menu superior página -->
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <vtto-waiting v-if="iStatusId === 1" :formatTime="sIntervalTime"></vtto-waiting>
                <vtto-canceled v-if="iStatusId === 5" :iOrderId="iOrderId"></vtto-canceled>
                <vtto-confirmed v-if="iStatusId >= 2 && iStatusId <= 4" :iOrderId="iOrderId"
                                :sOrderOrigin="sOrderOrigin"></vtto-confirmed>
                <vtto-sent v-if="iStatusId === 0" :iOrderId="iOrderId"></vtto-sent>
                <vtto-payment v-if="iStatusId === 7" :image="pixQrCode" :url="pixQrCodeUrl"></vtto-payment>
            </div>
        </div>
    </div>
    <!-- FIM Conteúdo -->
</template>
<script>
import VttoCanceled from '@/components/Order/Status/VttoCanceled'
import VttoConfirmed from '@/components/Order/Status/VttoConfirmed'
import VttoSent from '@/components/Order/Status/VttoSent'
import VttoWaiting from '@/components/Order/Status/VttoWaiting'
import VttoPayment from '@/components/Order/Status/VttoAwating'
import apiOrder from '@/api/order'
import router from '@/router'
import { empty } from '@/utils'
import { mapState } from 'vuex'
import { showAlert } from '@/plugins/alerts'

export default {
  name: 'vtto-order-status',
  components: {
    VttoCanceled,
    VttoConfirmed,
    VttoSent,
    VttoWaiting,
    VttoPayment
  },
  data: function () {
    return {
      iOrderId: this.$store.state.order.oOrder.order_id,
      iOrderWithoutRegistration: this.$store.state.order.oOrder.order_without_registration,
      sOrderStatus: 'waiting',
      iLastingTime: 60 * 10,
      iIntervalTime: 1000,
      iStatusId: 1,
      iGetApiIntervalTime: 10
    }
  },
  mounted () {
    this.getTimer()
    if (!empty(this.pixQrCode)) {
      this.iStatusId = 7
    }
  },
  computed: {
    ...mapState({
      sOrderOrigin: state => state.order.oOrder.delivery_address.origin
    }),
    sIntervalTime: function () {
      return this.getSIntervalTime()
    }
  },
  methods: {
    /* Converte o tempo em segundos para o padrão mm:ss */
    getSIntervalTime () {
      let iTime = this.iLastingTime
      let iMinutes = parseInt(iTime / 60, 10)
      let iSeconds = parseInt(iTime % 60, 10)
      let sMinutes = iMinutes < 10 ? '0' + iMinutes : iMinutes
      let sSeconds = iSeconds < 10 ? '0' + iSeconds : iSeconds
      return sMinutes + ':' + sSeconds
    },
    /* Varre todos os status que o pedido já passou e devolve o atual */
    getCurrentStatus (aStatuses) {
      for (let iStatusKey in aStatuses) {
        let oStatus = aStatuses[iStatusKey]
        if (oStatus.current) {
          return parseInt(oStatus.status_id)
        }
      }
      /* Faz o contador decrescer e a cada iGetApiIntervalTime segundos verifica se o pedido está pronto */
    },
    getTimer: function () {
      let self = this

      if (empty(self.iOrderId)) {
        // showAlert('error', 'Pedido não encontrado!')
        router.push({ name: 'homeCatalog' })
      }

      let oOrder = {
        order_id: self.iOrderId,
        order_without_registration: self.iOrderWithoutRegistration
      }

      let refreshInterval = setInterval(function () {
        self.iLastingTime--
        /* Se o tempo acabou */
        if (self.iLastingTime <= 0) {
          /* Pedido enviado */
          self.iStatusId = 0
          clearInterval(refreshInterval)
          return false
        }

        /* Somente busca o status na api a cada iGetApiIntervalTime segundos */
        if (self.iLastingTime % self.iGetApiIntervalTime === 0) {
          apiOrder.GetOrderStatus(oOrder).then(function (response) {
            if (response && response.data) {
              if (response.data.success === false) {
                showAlert('error', response.data.errors[0])
                return false
              } else {
                let aStatuses = response.data.data
                self.iStatusId = self.getCurrentStatus(aStatuses)
              }
            }
          })
        }
      }, self.iIntervalTime)
    }
  },
  props: {
    item: {},
    pixQrCode: {
      type: [String],
      default: ''
    },
    pixQrCodeUrl: {
      type: [String],
      default: ''
    }
  }
}
</script>
