import { render, staticRenderFns } from "./VttoSocial.vue?vue&type=template&id=e9263958&"
import script from "./VttoSocial.vue?vue&type=script&lang=js&"
export * from "./VttoSocial.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports