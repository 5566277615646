import Vue from 'vue'
import Vuex from 'vuex'
import store from './modules/store'
import catalog from './modules/catalog'
import cart from './modules/cart'
import account from './modules/account'
import order from './modules/order'
import messaging from './modules/messaging'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    store,
    catalog,
    cart,
    account,
    order,
    messaging
  }
})
