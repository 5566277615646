<template>
  <div>
    <div class="container main">
      <div class="row">
        <div class="col-12">
          <div class="bg-white d-block mb-15">
            <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s bg-white">Seu pedido</h3>
            <!-- Produtos -->
            <vtto-products @reload-price="reloadPrice" @updateSubtotal="getSubTotal" :items="aCart" class="box-content"></vtto-products>
            <!-- Fim Produtos -->
          </div>

          <!-- Mobile Options -->
          <vtto-checkout-mobile-options v-if="isMobile" @slide-item="openBox" :customer="oCustomer" :obs="obsContent" :gift="giftMsg" :addrText="addrText" :addrPrice="addrPrice"></vtto-checkout-mobile-options>
          <!-- Fim Moble Options -->

          <!-- Observações -->
          <div ref="obs" class="slide-target z-3000">
            <vtto-checkout-obs @have-obs="updateObs"></vtto-checkout-obs>
          </div>
          <!-- Fim Observações -->

          <!-- INFORMAÇÕES DO USUARIO PEDIDO SEM CADASTRO  SETA INFORAMAÇÕES PARA CUSTOMER-->
          <div ref="customer" class="slide-target z-3000">
            <vtto-checkout-customer @set-customer="setCustomer"></vtto-checkout-customer>
          </div>
          <!-- FIM -->

          <!-- Entrega -->
          <div ref="delivery" class="slide-target z-3000">
            <vtto-checkout-delivery @delivery_address="updateAddr"></vtto-checkout-delivery>
          </div>
          <!-- Fim Entrega -->

          <!-- Cupom de desconto -->
          <div ref="gift" class="slide-target z-3000">
            <vtto-checkout-gift @gift-payments="setGiftPayments" @updateTotalDiscount="getTotalDiscount" @have-gift="updateGift" :fSubtotal="fSubtotal" :fTotalDelivery="fTotalDelivery"></vtto-checkout-gift>
          </div>
          <!-- Fim Cupom de desconto -->

          <!-- Valores -->
          <vtto-checkout-amounts :fTotalDelivery="fTotalDelivery" :fTotalDiscount="fTotalDiscount" :fSubtotal="fSubtotal" :addrMinOrderPrice="addrMinOrderPrice"></vtto-checkout-amounts>
          <!-- Fim Valores -->

          <!-- Formas de pagamento -->
          <div ref="payments" class="slide-target">
            <vtto-checkout-payments v-if="enableBtnFinish" :giftPayments="giftPayments" :aCards="cards" :fTotalDelivery="fTotalDelivery" :fTotalDiscount="fTotalDiscount" :fSubtotal="fSubtotal"></vtto-checkout-payments>
          </div>
          <!-- Fim Formas de pagamento -->
        </div>
      </div>
    </div>
    <!-- Footer fixed -->
    <footer class="fixed bottom-0 w-100 d-block bg-white z-2000">
      <!-- <vtto-checkout-finish v-if="enableBtnFinish && !isMobile" :enable="btnEnabled"></vtto-checkout-finish> -->

      <div v-if="enableBtnFinish">
        <div v-if="!paymentOpen && isMobile" class="p-15 text-center d-block">
          <button @click="slide(true, $refs.payments); paymentOpen = true" class="btn btn-secondary text-uppercase text-center fs-m">Formas de pagamento</button>
        </div>
        <vtto-checkout-finish v-else :enable="btnEnabled" :address="addrText" :id="addrId" @change-addr="changeAddr"></vtto-checkout-finish>
      </div>
    </footer>
    <!-- FIM Footer fixed -->
  </div>
</template>
<script>
import VttoCheckoutAmounts from '@/components/Checkout/VttoCheckoutAmounts'
import VttoCheckoutDelivery from '@/components/Checkout/VttoCheckoutDelivery'
import VttoCheckoutCustomer from '@/components/Checkout/VttoCheckoutCustomer'
import VttoCheckoutGift from '@/components/Checkout/VttoCheckoutGift'
import VttoCheckoutObs from '@/components/Checkout/VttoCheckoutObs'
import VttoCheckoutPayments from '@/components/Checkout/VttoCheckoutPayments'
import VttoCheckoutFinish from '@/components/Checkout/VttoCheckoutFinish'
import VttoCheckoutMobileOptions from '@/components/Checkout/VttoCheckoutMobileOptions'
import VttoProducts from '@/components/VttoProducts'
import { mapState, mapGetters } from 'vuex'
import { setOrderInfo } from '@/plugins/orders'
import { slide } from '@/utils/index'
import apiCheckout from '@/api/checkout'
import router from '@/router'
export default {
  data: function () {
    return {
      fTotalDelivery: 0,
      fTotalDiscount: 0,
      fSubtotal: 0,
      btnEnabled: false,
      enableBtnFinish: false,
      addrText: '',
      addrId: 0,
      addrPrice: '',
      addrMinOrderPrice: 0,
      obsContent: '',
      giftMsg: '',
      cards: [],
      paymentOpen: false,
      giftPayments: null,
      oCustomer: {}
    }
  },

  created () {
    this.$scrollTo('#app')
    this.updateCart()
    this.getCreditCards()
    if (this.aCart.length === 0) {
      router.push({ name: 'homeCatalog' })
    }
  },

  methods: {
    updateCart: function () {
      setOrderInfo('items', this.aCart)
      if (this.info.price_rule) {
        this.fSubtotal = this.totalCart(this.info.price_rule)
        this.enableBtnFinish = true
      } else {
        setTimeout(() => {
          this.fSubtotal = this.totalCart(this.info.price_rule)
          this.enableBtnFinish = true
        }, 3000)
      }
    },
    getTotalDiscount: function (fTotalDiscount) {
      this.fTotalDiscount = fTotalDiscount
    },
    getSubTotal: function (fSubtotal) {
      this.fSubtotal = fSubtotal
    },
    reloadPrice () {
      this.updateCart()
    },

    /* Mobile Options Methods */
    slide: function (toggle, element) {
      slide(toggle, element)
    },
    openBox (box) {
      if (box === 'obs') {
        slide(true, this.$refs.obs)
      }

      if (box === 'gift') {
        slide(true, this.$refs.gift)
      }

      if (box === 'delivery') {
        slide(true, this.$refs.delivery)
      }

      if (box === 'customer') {
        slide(true, this.$refs.customer)
      }
    },
    updateObs (res) {
      slide(false, this.$refs.obs)

      this.obsContent = res
    },
    updateGift (res) {
      slide(false, this.$refs.gift)

      if (res) {
        this.giftMsg = res
      }
    },
    setCustomer (oCustomer) {
      slide(false, this.$refs.customer)
      this.oCustomer = oCustomer
    },
    updateAddr (addr, bSlide = true) {
      this.fTotalDelivery = addr.total_delivery
      if (bSlide) {
        slide(false, this.$refs.delivery)
      }
      this.addrText = addr.address
      this.addrId = parseInt(addr.address_id)
      this.addrPrice = this.fTotalDelivery
      this.addrMinOrderPrice = addr.min_order_price
    },
    getCreditCards () {
      let that = this
      apiCheckout.getCustomerCards().then(function (response) {
        if (response && response.data && response.data.data) {
          that.cards = response.data.data
        }
      })
    },
    setGiftPayments (data) {
      this.giftPayments = data
    },
    changeAddr () {
      if (this.isMobile) {
        this.slide(false, this.$refs.payments)
        this.paymentOpen = false
        // this.slide(false, this.$refs.delivery)

        let self = this
        setTimeout(() => {
          self.slide(true, this.$refs.delivery)
        }, 500)
      }
    }
  },
  computed: {
    ...mapGetters('cart', [
      'totalCart'
    ]),
    ...mapState({
      hiddenDelivery: state => state.store.info.hidden_delivery,
      hiddenStore: state => state.store.info.hidden_store,
      aCart: state => state.cart.cart,
      info: state => state.store.info
    }),
    isMobile () {
      return this.$store.state.store.isMobile
    }
  },
  components: {
    VttoCheckoutAmounts,
    VttoCheckoutDelivery,
    VttoCheckoutGift,
    VttoCheckoutObs,
    VttoCheckoutPayments,
    VttoCheckoutFinish,
    VttoCheckoutMobileOptions,
    VttoProducts,
    VttoCheckoutCustomer
  }
}
</script>
