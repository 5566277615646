<template>
  <div>
    <div v-if="type==='zoom'">
      <div class="modal-main" v-if="visible">
        <div class="modal-bg" @click="hide"></div>
        <div class="modal-alert">
          <p class="text-uppercase fw-900 fs-12 p-15">{{ title }}</p>
          <img loading="lazy" class="img-fluid" :src="imageUrl" alt="">
          <p class="align-center d-flex justify-center p-15">
            <button @click="select" class="btn btn-default btn-primary text-uppercase ">selecionar produto</button>
          </p>
        </div>
      </div>
    </div>
    <div v-if="type==='confirm'">
      <div class="modal-main" v-if="visible">
        <div class="modal-bg"></div>
        <div class="modal-alert p-30">
          <p class="fs-1-4 text-uppercase text-center" v-html="text"></p>
          <p class="align-center d-flex justify-center mt-15">
            <button @click="hide" class="btn btn-default btn-secondary text-uppercase mr-15">não</button>
            <button @click="confirm" class="btn btn-default btn-primary text-uppercase">sim</button>
          </p>
        </div>
      </div>
    </div>
    <div v-if="type==='banner'">
      <div class="modal-main flex-center" v-if="visible">
        <div class="modal-bg" @click="hideBanner"></div>
        <div class="banner modal-alert-2">
          <div class="d-flex justify-between align-center px-15 py-15">
            <p class="text-uppercase fw-900 fs-12 ">{{ title }}</p>
            <button @click="hideBanner" class="bg-none border-0 pointer ">
              <i class="fa fa-times color-4"></i>
            </button>
          </div>
          <div class="text-center">
            <img loading="lazy" class="img-fluid" :src="imageUrl" alt="">
          </div>
        </div>
      </div>
    </div>
    <div v-if="type==='bannerHome'">
      <div class="modal-main flex-center" v-if="visible">
        <div class="modal-bg" @click="hideBannerHome"></div>
        <div class="banner modal-alert-2">
          <div class="d-flex justify-between align-center px-15 py-15">
            <p class="text-uppercase fw-900 fs-12 ">{{ title }}</p>
            <button @click="hideBannerHome" class="bg-none border-0 pointer ">
              <i class="fa fa-times color-4"></i>
            </button>
          </div>
          <div class="text-center">
            <img loading="lazy" class="img-fluid" :src="imageUrl" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/plugins/modal'
export default {
  name: 'vtto-modal',
  data () {
    return {
      type: '',
      visible: false,
      title: '',
      text: '',
      imageUrl: '',
      onConfirm: {},
      onSelectProduct: {}
    }
  },
  methods: {
    hide () {
      // method for closing modal
      this.visible = false
    },
    select () {
      if (typeof this.onSelectProduct === 'function') {
        this.onSelectProduct()
        this.hide()
      } else {
        this.hide()
      }
    },
    confirm () {
      if (typeof this.onConfirm === 'function') {
        this.onConfirm()
        this.hide()
      } else {
        this.hide()
      }
    },
    show (params) {
      this.visible = true

      this.type = params.type
      this.title = params.title
      this.text = params.text
      this.imageUrl = params.imageUrl

      this.onConfirm = params.onConfirm
      this.onSelectProduct = params.onSelectProduct
    },
    hideBanner () {
      sessionStorage.setItem('vtto.store.hideBanner', true)
      this.visible = false
    },
    hideBannerHome () {
      sessionStorage.setItem('vtto.store.hideBannerHome', true)
      this.visible = false
    }
  },
  beforeMount () {
    Modal.EventBus.$on('show', (params) => {
      this.show(params)
    })
  }
}
</script>
