<template>
  <div class="container main">
    <div class="row">
      <div class="col-12">
        <div class="bg-white d-block mt-15 mb-15">
          <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Regulamento</h3>
          <div class="p-15 fs-s">
            <span v-html="sRegulation"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      sRegulation: ''
    }
  },
  created () {
    if (this.info.loyalty) {
      this.sRegulation = this.info.loyalty.loyalty_regulation
    }
  },
  watch: {
    'info.loyalty': function (loyalty) {
      this.sRegulation = loyalty.loyalty_regulation
    }
  },
  computed: mapState({
    info: state => state.store.info
  })
}
</script>
