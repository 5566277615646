<template>
    <div>
        <div class="bg-white p-15 mt-15 bb-1-gray">
            <span class="text-uppercase fs-s fw-900">Mínimo: {{ step.step_min_items }} / Máximo: {{ step.step_max_items }}</span>
            - <span class="text-uppercase fs-s fw-700">Selecionados: {{ sumQtySelected }}</span>
        </div>
        <ul class="list-unstyled bg-white">
            <li class="p-15 bb-1-gray media m-0 flex-center" v-for="(ingredient, index) in step.step_ingredients"
                :key="index">
                <div class="btn-numbers d-flex justify-content-between flex-center fs-s">
                    <vtto-btn-qty min="0" :max="max" init-qty="0" v-model="qty"
                                  @click="addIngredient(ingredient, qty)"/>
                </div>
                <div class="media-body ml-15 fs-m">
                    {{ ingredient.ingredient_name }}
                </div>
            </li>
        </ul>

        <div v-if="step.step_ingredients_extra">
            <div class="bg-white p-15 mt-15 bb-1-gray">
                <span class="text-uppercase fs-s fw-900">Ingredientes Extras</span>
            </div>
            <ul class="list-unstyled bg-white">
                <li class="p-15 bb-1-gray media m-0 flex-center"
                    v-for="(ingredient, index) in step.step_ingredients_extra" :key="index">
                    <div class="btn-numbers d-flex justify-content-between flex-center fs-s">
                        <vtto-btn-qty min="0" init-qty="0" v-model="qtyExtra"
                                      @click="addIngredient(ingredient, qty, true)"/>
                    </div>
                    <div class="media-body ml-15 fs-m">
                        {{ ingredient.ingredient_name }}
                    </div>
                </li>
            </ul>
        </div>
        <!-- <vtto-catalog-item /> -->
    </div>
</template>

<script>
import VttoBtnQty from '@/components/VttoBtnQty'

export default {
  data () {
    return {
      qty: 0,
      qtyExtra: 0,
      sumQtySelected: 0,
      ingredientsSelected: {},
      ingredientsSelectedExtra: {},
      max: 0
    }
  },
  components: {
    VttoBtnQty
  },
  props: {
    id: {},
    step: {},
    total: {},
    type: {}
  },
  methods: {
    addIngredient (ingredient, qty, extra = false) {
      let item = { product_steps: [] }

      if (this.$store.state.cart.currentItem.product_steps) {
        item.product_steps = this.$store.state.cart.currentItem.product_steps
      }

      if (!item.product_steps[this.id]) {
        item.product_steps[this.id] = {
          step_id: this.step.step_id,
          step_name: this.step.step_name
        }
      }

      if (!extra) {
        item.product_steps[this.id].step_ingredients = []

        this.ingredientsSelected[ingredient.ingredient_id] = {
          qty: qty,
          amount: ingredient.ingredient_amount,
          name: ingredient.ingredient_name
        }
        this.sumIngredientsSelected()

        Object.entries(this.ingredientsSelected).map(a => {
          item.product_steps[this.id].step_ingredients.push({
            ingredient_id: a[0],
            ingredient_quantity: a[1].qty,
            ingredient_amount: a[1].amount,
            ingredient_name: a[1].name
          })
        })
      }

      if (extra) {
        item.product_steps[this.id].step_ingredients_extra = []

        this.ingredientsSelectedExtra[ingredient.ingredient_id] = {
          qty: qty,
          amount: ingredient.ingredient_amount,
          name: ingredient.ingredient_name
        }
        Object.entries(this.ingredientsSelectedExtra).map(a => {
          item.product_steps[this.id].step_ingredients_extra.push({
            ingredient_id: a[0],
            ingredient_quantity: a[1].qty,
            ingredient_amount: a[1].amount,
            ingredient_name: a[1].name
          })
        })
      }

      this.$store.dispatch('cart/setProduct', item)
    },
    sumIngredientsSelected () {
      let sum = 0
      Object.entries(this.ingredientsSelected).map(e => {
        sum = sum + parseInt(e[1].qty)
      })
      this.sumQtySelected = sum
    }
  },
  watch: {
    sumQtySelected: function (a) {
      if (a === this.step.step_max_items) {
        this.$store.dispatch('catalog/buttonSteps', true)
        this.max = 0
      }
      this.$store.dispatch('catalog/buttonSteps', false)
      this.max = this.step.step_max_items
    },
    id: function () {
      this.qty = 0
      this.qtyExtra = 0
      this.ingredientsSelected = {}
      this.ingredientsSelectedExtra = {}
      this.sumQtySelected = 0
    },
    step: function () {
    }
  },
  created () {
    this.max = this.step.step_max_items
    this.$store.dispatch('catalog/buttonSteps', false)
  }
}
</script>
