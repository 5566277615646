<template>
    <div class="container main">
        <vtto-account-menu></vtto-account-menu>
        <div class="row">
            <div class="col-12">
                <div class="bg-white d-block mb-20">
                    <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Dados de Acesso</h3>
                    <div class="bg-white p-15">
                        <p class="fw-700 d-block text-uppercase">Email</p>
                        <input @focus="scrollToMe()" focus @keyup.enter="sendLogin" type="email" name="email" id="id" class="form-control mt-10 br-0 fs-s"
                               placeholder="digite seu email" v-model="oLogin.email"/>
                        <p class="fw-700 d-block text-uppercase mt-15">Senha</p>
                        <input @focus="scrollToMe()" focus @keyup.enter="sendLogin" type="password" v-model="oLogin.password"
                               placeholder="digite sua senha" class="form-control mt-10 br-0 fs-s"/>
                    </div>
                    <div class="p-15 text-center d-block">
                        <button @click="sendLogin" class="btn btn-primary text-uppercase text-center mx-auto">Entrar
                        </button>
                        <span v-if="this.mobile" class="d-block my-10">ou</span>
                          <facebook-login v-if="aFacebook && aFacebook.enable_login === true && this.mobile" class="pointer button"
                                        :appId="aFacebook.app_id"
                                        @login="onLogin"
                                        @get-initial-status="onLoad"
                                        loginLabel="Login com Facebook"
                                        version="v10.0"
                                        :loginOptions="{scope:'public_profile, email', return_scopes: true, fields: 'name, email' }"
                        >
                        </facebook-login>
                        <span v-if="this.isGoogleKey && this.mobile" class="d-block my-10">ou</span>
                        <google-login v-if="this.isGoogleKey && this.mobile" @google-auth="googleAuth" :googleKey="this.keyGoogle"></google-login>
                        <!-- <v-facebook-login app-id="2223980211186306"></v-facebook-login> -->
                        <router-link :to="{ name: 'forgot-password' }" class="d-block my-10 fw-700">Esqueceu sua
                            senha?
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import apiLogin from '@/api/account'
import googleLogin from '@/components/Google/google-login'
import VttoAccountMenu from '@/components/Account/VttoMenu'
import { setXUserKey } from '@/plugins/account'
import router from '@/router'
import facebookLogin from '@/components/Facebook/facebook-login'
import { mapState } from 'vuex'
import { empty } from '@/utils'
import { showAlert } from '@/plugins/alerts'
import { scrollToMe } from '@/plugins/scroll'
export default {
  data () {
    return {
      oLogin: {
        password: '',
        email: ''
      },
      FbApi: {},
      mobile: true,
      keyGoogle: ''
    }
  },
  computed: {
    ...mapState({
      aFacebook: state => state.store.info.facebook,
      oGoogle: state => state.store.info.google_key_auth,
      isReactNative () {
        return window.ReactNativeWebView
      }
    }),
    isGoogleKey () {
      if (typeof this.oGoogle !== 'undefined') {
        if ('key' in this.oGoogle) {
          if (this.oGoogle.key != '') {
            this.keyGoogle = this.oGoogle.key
            return true
          }
        }
      }

      return false
    }
  },
  mounted () {
    this.isMobile()
  },
  methods: {
    sendLogin () {
      let self = this
      apiLogin.Login(self.oLogin).then(function (response) {
        if (response && response.data) {
          if (!response.data.errors || response.data.errors.length == 0) {
            let aData = response.data
            localStorage.setItem('addsCheck', '0')
            setXUserKey(aData.data['x-user-key'])
            router.push({ name: 'homeCatalog' })
          } else {
            showAlert('error', response.data.errors[0])
            return false
          }
        }
      })
    },
    fbGetData () {
      if (window && window.FB) {
        window.FB.api('/me', { locale: 'en_US', fields: 'email, id' },
          function (response) {
            let email = response.email
            if (!empty(response.error)) {
              showAlert('error', response.error)
              return false
            } else {
              response.fb_id = response.id
              apiLogin.FbLogin(response).then(function (response) {
                let aData = response.data
                let bSuccess = aData.success
                localStorage.setItem('addsCheck', '0')
                if (!bSuccess) {
                  router.push({ name: 'AccountCreate', params: { fbEmail: email } })
                } else {
                  setXUserKey(aData.data['x-user-key'])
                  router.push({ name: 'homeCatalog' })
                }
              })
            }
          }
        )
      }
    },
    /* Método chamado ao clicar no botão de login */
    onLogin (oData) {
      if (oData.response.status === 'connected') {
        this.fbGetData()
        setTimeout(function () { oData.FB.logout() }, 3000)
      }
    },
    /* Método chamado ao carregar a página de login */
    onLoad (oData) {
      if (this.isReactNative) {
        this.fbReact()
      }
      if (oData.status && oData.status == 'connected') {
        this.fbGetData()
      }
    },
    fbReact () {
      if ($('.container.pointer.button button').get(0)) {
        let parentElem = $($('.container.pointer.button').get(0).outerHTML).addClass('app-fb-login')
        let imgElm = $('<img>').attr('src', 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpmZmYyZjIyZi04ZDdlLTQzNjEtYjM2Zi02NGFiYmI2Nzg3ZDUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTREMzBERkE2NjFBMTFFNDk5OEZFQ0REMkU5OTk0QTQiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTREMzBERjk2NjFBMTFFNDk5OEZFQ0REMkU5OTk0QTQiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDozNGZlZTRmMS0wMGNjLTRlZTEtYTY5MS00MjJmOGUxY2VmYjciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6ZmZmMmYyMmYtOGQ3ZS00MzYxLWIzNmYtNjRhYmJiNjc4N2Q1Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+F7pueQAAAKpJREFUeNpi+P//PwM2jA0AxZWBeA4QP/2PCl5j6CfWYKCYJRB//o8boOhnxOU6RkZGZENZgNQNIFZmwA0YkTksDMQBOyyGvgfic0D8BZsGYg3WQeP/AGItoK9eIPkKRQETkQYLofHvIxuKDRBrMDr4RUgBuQYTBDhTBSjYSDDnGhBr08LFd2kVFPdoZfBdbFmVYJYG8hvQsu8FQubQLFWMGjxq8HAwGCDAAM8dwEI+7fo0AAAAAElFTkSuQmCC')
        $(imgElm).css({
          width: '25px',
          height: '80%',
          position: 'absolute',
          top: '10%',
          left: '10px'
        })
        $('.spinner', parentElem).replaceWith(imgElm)

        $('.container.pointer.button.app-fb-login').remove()
        $('.container.pointer.button:not(.app-fb-login)').hide()
        $(parentElem).insertBefore($('.container.pointer.button:not(.app-fb-login)'))

        $('.container.pointer.button.app-fb-login button').get(0).onclick = (e) => {
          e.stopPropagation()
          window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'fbLogin' }))
          return false
        }
      }
    },
    scrollToMe: function () {
      scrollToMe('body', -80)
    },
    googleAuth (oData, oUser) {
      if (oData.data.success) {
        setXUserKey(oData.data.data['x-user-key'])
        router.push({ name: 'homeCatalog' })
      } else {
        router.push({ name: 'AccountCreate', params: { googleEmail: oUser.email } })
      }
    },
    isMobile () {
      if (navigator.userAgent.includes('Vitto-app-android', 'Vitto-app-ios')) {
        this.mobile = false
      }
    }
  },
  components: {
    VttoAccountMenu,
    facebookLogin,
    googleLogin
  }
}
</script>
