const config = {
  url_api: '',
  url_images: 'https://dmmzi9kl6wusl.cloudfront.net/homol/uploads/',
  mobile: {
    menu: [
      {
        link: '/',
        name: 'homeCatalog',
        display: 'Cardápio',
        icon: 'icon-food',
        class: ''
      },
      {
        link: '/login',
        name: 'login',
        display: 'Login',
        icon: 'icon-login',
        class: ''
      },
      {
        link: '/cart',
        name: 'checkout',
        display: 'Carrinho',
        icon: 'icon-cart',
        class: ''
      },
      {
        link: '/front/sobre',
        name: 'about-index',
        display: 'Mais Info',
        icon: 'icon-info',
        class: ''
      }
    ]
  },
  watchOptions: {
    poll: true
  }
}

export default config
