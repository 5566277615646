<template>
  <div class="nav-2 nav-desktop text-right d-none d-lg-block">
      <ul class="list-inline">
          <li class="list-inline-item" v-if="menuFilters(item)" v-for="(item, index) in items" :key="index">
            <router-link click="item.method" class="bg-white" :class="item.class" :to="{name: item.name}"><i class="mr-6" v-if="item.icon" :class="item.icon" aria-hidden="true"></i> {{ item.display }}</router-link>
          </li>
          <li class="list-inline-item" v-if="deadlineDelivery">
            <router-link class="bg-white" :to="{name:'about-delivery'}"><i class="fa fa-clock-o" aria-hidden="true"></i>Entrega: {{ deadlineDelivery }}</router-link>
          </li>
          <li class="list-inline-item" v-if="deadlineDesk">
            <router-link :to="{name:'about-delivery'}" class="bg-white"><i class="fa fa-clock-o" aria-hidden="true"></i>Balcão: {{ deadlineDesk }}</router-link>
          </li>
          <li class="list-inline-item" v-if="withoutRegistration">
            <router-link :to="{name:'find-order'}" class="bg-white"><i class="fa fa-clock-o" aria-hidden="true"></i>Buscar Pedido</router-link>
          </li>
          <li class="list-inline-item">
            <a @click="logout" v-if="isLogged" class="bg-white pointer"><i class="fas fa-sign-out-alt" aria-hidden="true"></i>Sair</a>
          </li>
      </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { setXUserKey } from '@/plugins/account'
import { empty } from '@/utils'
export default {
  name: 'vtto-menu-top',
  props: {
    isOpen: {},
    isLogged: {},
    oUser: {},
    items: {},
    deadlineDelivery: {},
    deadlineDesk: {},
    withoutRegistration: {}
  },
  computed: mapState({
    hasLoyalty: state => !empty(state.store.info.loyalty)
  }),
  methods: {
    logout () {
      setXUserKey()
      this.$store.dispatch('account/cleanUser')
      this.$router.push({ name: 'login' })
    },
    menuFilters (item) {
      if (this.isLogged === true && item.link.indexOf('/meus-dados') >= 0) {
        if (!empty(this.oUser)) {
          let sDisplay = this.oUser.firstname
          if (this.hasLoyalty === true) {
            sDisplay += ' - ' + this.oUser.loyaltybalance + ' pts'
          }
          item.display = sDisplay
        }
      }

      if (item.class.indexOf('open') >= 0) {
        if (this.isOpen) {
          return true
        }
        return false
      }

      if (item.class.indexOf('close') >= 0) {
        if (!this.isOpen) {
          return true
        }
        return false
      }

      if (item.logged !== false && empty(item.logged)) {
        return true
      }

      if (this.isLogged === true && item.logged === true) {
        return true
      }

      if (this.isLogged === false && item.logged === false) {
        return true
      }
      return false
    }
  }
}
</script>
