<template>
    <div id="confirm">
        <div class="row">
            <div class="col-lg-12">
                <div class="bg-white p-15 mt-15 m-xs-0 bb-1-gray">
                    <span v-if="this.currentItem.product_id" class="text-uppercase fs-s fw-900">Escolha o tamanho</span>
                    <span v-else class="text-uppercase fs-s fw-900">Escolha a quantidade de sabores</span>
                </div>
                <div class="bg-white bb-1-gray" v-for="(size, index) in list" :key="index">
                    <vtto-catalog-item-size :size-position="index" :current-item="currentItem" :index="index"
                                            :product="size"
                                            :type="params.type" :confirm="true"/>
                </div>
            </div>
        </div>
        <vtto-catalog-confirm-steps-button :closed="closed()"/>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import VttoCatalogItemSize from '@/components/Catalog/VttoCategoryItemSize'
import VttoCatalogConfirmStepsButton from '@/components/Catalog/Steps/VttoConfirmStepsButton'

export default {
  name: 'vitto-home',
  components: {
    VttoCatalogItemSize,
    VttoCatalogConfirmStepsButton
  },
  data () {
    return {}
  },
  created () {
    this.$store.dispatch('catalog/buttonSteps', false)
  },
  beforeRouteEnter (to, from, next) {
    if (!to.params.product) {
      next({ path: '/' })
    }
    next()
  },
  methods: {
    closed () {
      if (!this.info.working_time.is_open && Boolean(this.currentItem.product_id)) {
        return true
      }
      return false
    }
  },
  computed: {
    list: function () {
      if (this.currentItem.product_sizes) {
        return this.currentItem.product_sizes
      }

      if (this.params && this.params.product && this.params.product.products) {
        let flavors = []
        for (let i = 1; i <= this.params.product.size_max_products; i++) {
          let label = i === 1 ? `${i} sabor` : `${i} sabores`
          flavors.push({ label_qty_products: label, qty: i })
        }
        return flavors
      }
      // return this.params.product.products
      return []
    },
    ...mapState({
      info: state => state.store.info,
      isLogged: state => state.store.isLogged,
      catalog: state => state.catalog.catalog,
      params: state => state.route.params,
      buttonSteps: state => state.catalog.buttonSteps,
      currentItem: state => state.cart.currentItem
    })
  }
}
</script>
