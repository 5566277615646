<template>
    <div>
      <div :id="`category-${item.categoryId}`">

          <div v-if="collapse">
              <div class="category-collapsed bg-white py-15 px-20  bb-1-gray pointer" data-toggle="collapse"
                  :href="`#collapseProduct${item.categoryId}`"
                  role="button" aria-expanded="false" aria-controls="collapseProduct1">
                  <span class="text-uppercase fs-s fw-900 o-80">{{ item.categoryName }}</span>
                  <span v-if="item.subCategoryName"
                        class="text-uppercase fs-s fw-900 ml-5">// {{ item.subCategoryName }}</span>
                  <i class="fas fa-plus float-right mt-4"></i>
              </div>
              <div class="collapse" :id="`collapseProduct${item.categoryId}`" v-for="(product, index) in defineData(item)"
                  :key="index"
                  v-if="(product.products && product.products.length >0) || (product.product_sizes && product.product_sizes.length >0)">
                  <div class="card card-body br-0">
                      <vtto-catalog-category-item :size-id="product.size_id" :images-mobile="imagesMobile"
                        :category="category(item)" :category-type="item.categoryType" :category-id="item.categoryId"
                        :index="index"
                        :product="product" />
                  </div>
              </div>
          </div>
          <div v-else>
              <div class="bg-white py-15 px-20 mt-15 bb-1-gray">
                  <span class="text-uppercase fs-s fw-900 o-80">{{ item.categoryName }}</span>
                  <span v-if="item.subCategoryName"
                        class="text-uppercase fs-s fw-900 ml-5">// {{ item.subCategoryName }}</span>
              </div>
              <div class="bg-white p-20 bb-1-gray" v-for="(product, index) in defineData(item)" :key="index"
                  v-if="(product.products && product.products.length >0) || (product.product_sizes && product.product_sizes.length >0)">
                  <vtto-catalog-category-item :size-id="product.size_id" :images-mobile="imagesMobile"
                    :category="category(item)" :item="item"
                    :category-type="item.categoryType" :category-id="item.categoryId" :index="index" :product="product" />
              </div>
          </div>
      </div>

    </div>

</template>

<script>
import VttoCatalogCategoryItem from '@/components/Catalog/VttoCategoryItem'

export default {
  name: 'vtto-catalog-category',
  components: {
    VttoCatalogCategoryItem
  },
  methods: {
    category (item) {
      const subcategory = item.subCategoryName ? item.subCategoryName : ''
      return {
        category: item.categoryName,
        subcategory: subcategory
      }
    },
    defineData (item) {
      if (item.sizes) {
        return item.sizes
      }
      return item.products
    }
  },
  created () {
  },
  props: {
    item: {},
    collapse: {},
    imagesMobile: {}
  }
}
</script>
