<template>
  <div v-if="!isMobile">
    <vtto-cart />
    <vtto-payments :payments="payments" />
    <vtto-loyalty :loyalty="loyalty" />
    <vtto-apps :apps="apps" />
    <vtto-social :social="social" v-if="isEmptySocial"/>
  </div>
</template>

<script>
import VttoCart from '@/components/VttoCart'
import VttoPayments from '@/components/VttoPayments'
import VttoLoyalty from '@/components/VttoLoyalty'
import VttoApps from '@/components/VttoApps'
import VttoSocial from '@/components/VttoSocial'

export default {
  name: 'vtto-sidebar',
  components: {
    VttoCart,
    VttoPayments,
    VttoLoyalty,
    VttoApps,
    VttoSocial
  },
  props: {
    apps: {},
    social: {},
    payments: {},
    loyalty: {}
  },
  computed: {
    isMobile: function () {
      return this.$store.state.store.isMobile
    },
    isEmptySocial: function () {
      if (this.$store.state.store.info.social) {
        return Object.values(this.$store.state.store.info.social).find(element => element != '')
      }
      return true
    }
  }
}
</script>
