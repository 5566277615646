<template>
  <footer id="teste" class="nav-bottom text-center color-white bg-main w-100" v-if="footerVisible()">

    <!-- Footer Desktop -->
    <div v-if="!isMobile" class="container text-left fs-1-2 footer-desktop d-none d-lg-block mt-60">
      <div class="row">
        <div class="col-4 mt-30 mb-30" v-if="this.isEmptyContact()">
          <p class="text-uppercase mb-15 fs-1-2">Entre em contato</p>
          <p class="mb-15" v-if="isPhoneEmpty"><i class="fa fa-phone flip-h mr-10"></i>{{ phones | tel }}</p>
          <p v-if="email" class="mb-15"><a :href="`mailto:${email}`" class="color-white"><i
                class="far fa-envelope mr-10"></i>{{ email }}</a></p>
          <p v-if="!hiddenAddress"><i class="fas fa-location-arrow mr-10"></i>{{ address.address }}, {{ address.number
            }} - {{ address.region }} - {{ address.city }} - {{ address.state }}</p>
          <!-- <p><i class="fas fa-location-arrow mr-10"></i>{{  }}</p> -->
        </div>

        <div class="col-4 mt-30 mb-30">
          <p class="text-uppercase mb-15 fs-1-2">Informações</p>
          <p class="mb-15">
            <router-link :to="{name: 'about-time'}" class="color-white"><i class="fas fa-clock mr-10"></i>Horários de
              funcionamento</router-link>
          </p>
          <p class="mb-15">
            <router-link :to="{name: 'about-delivery'}" class="color-white"><i
                class="fas fa-map-marker-alt mr-10"></i>Regiões de entrega</router-link>
          </p>
          <p><a href="https://sistemavitto.com.br/privacidade_sistema.html" target="_blank" class="color-white"><i
                class="far fa-file-alt mr-10"></i>Política de Privacidade</a></p>
        </div>

        <div class="col-4 mt-30 mb-30 text-right">
          <p class="text-uppercase mb-15 fs-1-2">{{ company_name }}</p>
          <p>{{ about }}</p>
        </div>
      </div>
    </div>
    <!-- FIM Footer Desktop -->

    <!-- Copyright -->
    <div v-if="!isMobile" class="text-right p-15 o-50 copyright d-none d-lg-block">
      desenvolvido por
      <a href="https://www.sistemavitto.com.br" target="_blank" class="color-main">
        <i class="marca-vitto color-white fs-2-5"></i>
      </a>
    </div>
    <!-- FIM Copyright item.name != 'checkout' || item.name != 'about-index') -->

    <!-- Footer Mobile -->
    <div v-if="!inputFocus && isMobile"
      class="container-fluid p-0 footer-mobile bg-main fixed bottom-0 w-100 d-block d-lg-none">
      <div class="row no-gutters">
        <div class="col-3" v-for="(item, index) in $config.mobile.menu" :key="index">
          <router-link v-if="!['checkout', 'about-index'].includes(item.name)" :class="{ 'active': $store.state.route.name === linkTo(item) }"
            class="btn-app text-lowercase" :to="{name: linkTo(item)}">
            <span :class="[item.icon]" class="icon-app fs-2"></span>
            <span v-if="item.name == 'checkout' && cart.length > 0" class="cart-counter btn btn-orange number"><span>{{
                cart.length }}</span></span>
            <br />
            {{ item.name == 'checkout' && cart.length > 0 ? 'Finalizar pedido' : logged(item.display) }}
          </router-link>

          <div v-if="item.name == 'checkout' && (info.order_without_registration != 1 || isLogged)" class="btn-app text-lowercase" @click="checkout()">
            <span :class="[item.icon]" class="icon-app fs-2"></span>
            <span v-if="item.name == 'checkout' && cart.length > 0" class="cart-counter btn btn-orange number"><span>{{
                cart.length }}</span></span>
            <br />
            {{ item.name == 'checkout' && cart.length > 0 ? 'Finalizar pedido' : logged(item.display)}}
          </div>

          <router-link v-if="item.name == 'about-index' && (info.order_without_registration != 1 || isLogged)" class="btn-app text-lowercase" @click="checkout()" :class="{ 'active': $store.state.route.name === linkTo(item) }" :to="{name: linkTo(item)}">
            <span :class="[item.icon]" class="icon-app fs-2"></span>
            <br />
            {{logged(item.display)}}
          </router-link>

          <div v-if="item.name == 'about-index' && (info.order_without_registration == 1 && !isLogged)" class="btn-app text-lowercase" @click="showOptionsInfo()">
              <div style="position: absolute; bottom:50px; width: 100%;">
                <div class="btn-app text-lowercase bg-main" @click="toRoute('find-order')" :class="{'d-none': visibleInfo == false}">
                  <span class="icon-app"></span>
                  <i class="fas fs-2 fa-search"></i>
                  <br />
                  Buscar Pedido
                </div>

                <div v-if="!isLogged" class="btn-app text-lowercase bg-main" @click="toRoute('about-index')" :class="{'d-none': visibleInfo == false}">
                  <span :class="[item.icon]" class="icon-app fs-2"></span>
                  <br />
                  Sobre
                </div>
            </div>
            <span :class="[item.icon]" class="icon-app fs-2"></span>
            <br />
            Mais Info
          </div>

          <div v-if="item.name == 'checkout' && info.order_without_registration == 1 && !isLogged" @click="showOptions()" class="btn-app text-lowercase">
            <div style="position: absolute; bottom:50px;">
              <div class="btn-app text-lowercase bg-main" @click="checkout()" :class="{'d-none': visible == false}">
                <span :class="[item.icon]" class="icon-app fs-2"></span>
                <br />
                Finalizar pedido
              </div>

              <div v-if="!isLogged" class="btn-app text-lowercase bg-main" @click="checkout(1)" :class="{'d-none': visible == false}">
                <span :class="[item.icon]" class="icon-app fs-2"></span>
                <br />
                Fechar Pedido sem cadastro
              </div>
            </div>

            <span :class="[item.icon]" class="icon-app fs-2"></span>
            <span v-if="item.name == 'checkout' && cart.length > 0" class="cart-counter btn btn-orange number"><span>{{
                cart.length }}</span></span>
            <br />
            {{ item.name == 'checkout' && cart.length > 0 ? 'Finalizar pedido' : logged(item.display)}}
          </div>
        </div>
      </div>
    </div>
    <!-- FIM Footer Mobile -->

  </footer>
</template>

<script>
import { mapState } from 'vuex'
import { empty } from './../utils'
export default {
  name: 'vtto-footer',
  data () {
    return {
      visible: false,
      visibleInfo: false
    }
  },
  created () {
  },
  filters: {
    tel (value) {
      let tel = ''
      for (let i in value) {
        if (value[i]) {
          if (i !== 'phone_1') {
            tel += ' / '
          }
          tel += value[i]
        }
      }
      return tel
    }
  },
  props: {
    phones: {},
    about: {},
    email: {},
    company_name: {
      type: String,
      default: ''
    },
    address: {
      default: function () {
        return {
          address: '',
          number: '',
          region: '',
          city: '',
          state: ''
        }
      }
    }
  },
  methods: {
    checkout: function (oOrderType = 0) {
      this.$store.state.order.oOrder.order_without_registration = oOrderType
      if (oOrderType == 0) {
        this.$router.push({ name: 'checkout' })
        return
      }
      this.$router.push({ name: 'checkout-not-register' })
    },
    toRoute: function (sRoute) {
      this.$router.push({ name: sRoute })
    },
    showOptions () {
      let self = this
      self.visible = !self.visible
    },
    showOptionsInfo () {
      let self = this
      self.visibleInfo = !self.visibleInfo
    },
    footerVisible () {
      const route = this.$store.state.route.name
      if (route === 'checkout' || route === 'catalogConfirm' || route === 'detail' || route === 'select') {
        this.$store.state.store.footerVisible = false
        return false
      }

      this.$store.state.store.footerVisible = true
      return true
    },
    logged (item) {
      if (item === 'Login' && this.$store.state.account.isLogged) {
        return 'Meus Dados'
      }
      return item
    },
    isEmptyContact () {
      const info = this.$store.state.store.info
      if (info) {
        if (info.email || !empty(info.phones) || (!empty(info.address) && !info.hidden_address)) {
          return true
        }
        return false
      }
    },
    linkTo (item) {
      if (item && item.name === 'login' && this.$store.state.account.isLogged) {
        return 'account-edit'
      }
      if (this.$store.state.route && this.$store.state.route.name && item && item.name) {
        if (this.$store.state.route.name.search('about') >= 0 && item.name.search('about') >= 0) {
          return this.$store.state.route.name
        }

        if (this.$store.state.route.name.search('account') >= 0 && item.name.search('account') >= 0) {
          return this.$store.state.route.name
        }
      }
      return item.name
    }
  },
  computed: {
    ...mapState('cart', ['cart']),
    ...mapState('store', ['info']),
    inputFocus () {
      return this.$store.state.store.inputFocus
    },
    isLogged () {
      return this.$store.state.account.isLogged
    },
    hiddenAddress () {
      return this.$store.state.store.info.hidden_address
    },
    isMobile () {
      return this.$store.state.store.isMobile
    },
    isPhoneEmpty () {
      return !empty(this.$store.state.store.info.phones)
    }
  }
}

</script>
