<template>
    <li class="media m-0 d-flex justify-between" :class="{'p-20': confirm, 'bg-light-gray': item === `id-${uuid}`}">
      <div class="form-group-radio_group link-product w-100" @click="internalSteps(); selectItem(`id-${uuid}`)">
        <span class="fw-500 fs-m number" :class="item === `id-${uuid}` ? 'color-main fw-600' : ''">{{ sizeFlavorName }}</span>
        <p v-if="!confirm" class="fw-300 fs-s">até {{ product.size_max_products }} {{ product.size_max_products > 1 ? 'sabores' : 'sabor' }}</p>
        <vtto-catalog-item-price v-if="!this.product.label_qty_products" :prices="prices"/>
      </div>
      <a v-if="product.product_image && imagesMobile" class="img-catalog ml-15 mt-5">
        <img loading="lazy" class="img-fluid br-5" :src="product.product_image" @click="openImage">
      </a>
    </li>
</template>

<script>
import VttoCatalogItemPrice from '@/components/Catalog/VttoCategoryItemPrice'
import uuid from '@/plugins/uuid'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      name: '',
      item: '',
      productPrices: {}
    }
  },
  props: {
    product: {},
    index: {},
    confirm: {
      default: false
    },
    type: {},
    test: {},
    currentItem: {},
    steps: {},
    sizeId: {},
    combo: {
      default: false
    },
    sizePosition: {}
  },
  created () {
  },
  components: {
    VttoCatalogItemPrice
  },
  watch: {
    item: function (a, b) {
      this.$store.dispatch('catalog/buttonSteps', true)
    }
  },
  computed: {
    uuid: function () {
      return uuid()
    },
    sizeFlavorName: function () {
      if (this.product.size_name) {
        return this.product.size_name
      }

      if (this.product.label_qty_products) {
        return this.product.label_qty_products
      }

      return this.index
    },
    prices: function () {
      try {
        if (!this.confirm) {
          let size = this.product.products
          let minorPrice = 0
          let minorLoyalty = 0
          let promo = 0
          if (typeof size !== 'undefined') {
            size.map(a => {
              a.product_sizes.map(b => {
                if (b.size_id === this.sizeId) {
                  let amount = parseFloat(b.size_amount)
                  if (amount < minorPrice || minorPrice === 0) {
                    minorPrice = amount
                  }

                  let promo = parseFloat(b.size_amount_promo)
                  if (promo < minorPrice && promo != 0) {
                    minorPrice = promo
                  }

                  if (parseFloat(b.size_amount_loyalty) < minorLoyalty || minorLoyalty === 0) {
                    minorLoyalty = parseFloat(b.size_amount_loyalty)
                  }
                }
              })
            })
          }
          return {
            price: minorPrice,
            loyalty: minorLoyalty,
            promo: promo,
            minor: true
          }
        }

        return {
          price: this.product.size_amount,
          loyalty: this.product.size_amount_loyalty,
          promo: this.product.size_amount_promo
        }
      } catch (error) {
        console.log(error)
        return error
      }
    },
    params: function () {
      return Object.assign({}, this.$store.state.route.params)
    },
    newPrices: function () {
      return this.$store.state.cart.currentItemPrices
    },
    ...mapGetters('catalog', [
      'getCatalogFormated'
    ])
  },
  methods: {
    selectItem (id) {
      this.item = id
      this.$eventHub.$emit('categoryItemSize', id)
      this.$eventHub.$on('categoryItemSize', categoryItemSize => {
        this.item = categoryItemSize
      })
    },
    internalSteps () {
      if (this.combo) {
        this.$emit('set-flavors', this.product.qty)
        return
      }
      /** add size data in currentitem */
      if (this.confirm) {
        /** meio a meio */
        if (!this.currentItem.product_id) {
          this.currentItem.qty_flavors = this.product.qty
          this.currentItem.type = 2
          this.$store.dispatch('cart/setProduct', this.currentItem)
          return
        }

        this.currentItem.size_position = this.sizePosition
        this.currentItem.size_id = this.product.size_id
        this.currentItem.size_name = this.product.size_name
        this.currentItem.allow_loyalty_payment = this.product.allow_loyalty_payment
        // Remover após atualização do rogério
        this.currentItem.size_amount = this.product.size_amount
        // this.currentItem.size_amount_promo = this.product.size_amount_promo
        this.currentItem.size_amount_loyalty = this.product.size_amount_loyalty
        this.currentItem.product_prices = {
          simple: {
            amount: this.product.size_amount,
            amount_promo: this.product.size_amount_promo,
            amount_loyalty: this.product.size_amount_loyalty
          }
        }

        let newPrices = this.newPrices
        if (!newPrices.products) {
          newPrices = { products: [] }
        }

        newPrices.products[0] = {
          price: this.product.size_amount,
          promo: this.product.size_amount_promo,
          loyalty: this.product.size_amount_loyalty
        }

        this.$store.dispatch('cart/setPrices', newPrices)

        this.currentItem.size_edge_allowed = this.product.size_edge_allowed

        if (this.currentItem.size_edge_allowed) {
          if (this.currentItem.product_sizes) {
            this.currentItem.size_edges = this.currentItem.product_sizes[0].size_edges
          }
        }

        if (this.product.ingredients_extra) {
          this.currentItem.ingredients_extra_choice = this.product.ingredients_extra
        }

        if (this.product.info_extra) {
          this.currentItem.info_extra = this.product.info_extra
        }

        delete this.currentItem.product_sizes
        this.$store.dispatch('cart/setProduct', this.currentItem)

        if (this.currentItem.type === 3) {
          this.$store.dispatch('cart/setSteps', [{ step: 1, end: this.params.product.product_steps.length }])
        }

        return
      }

      this.steps(2, Object.assign({}, this.product))
    },
    defineName () {
      if (this.confirm) {
        if (this.type === 1) {
          this.name = this.product.size_name
        }

        if (this.type === 2) {
          this.name = this.product.label_qty_products
        }
      }
      this.name = this.index
      return this.name
    },
    minorPriceSize (size) {
      let minorPrice = 0
      let minorLoyalty = 0
      let promo = 0
      size.map(a => {
        a.product_sizes.map(b => {
          let amount = parseFloat(b.size_amount)
          if (amount < minorPrice || minorPrice === 0) {
            minorPrice = amount
            promo = parseFloat(b.size_amount_promo)
          }

          if (parseFloat(b.size_amount_loyalty) < minorLoyalty || minorLoyalty === 0) {
            minorLoyalty = parseFloat(b.size_amount_loyalty)
          }
        })
      })
      return {
        minorPrice: minorPrice,
        minorLoyalty: minorLoyalty,
        promo: promo
      }
    }
  }
}
</script>
