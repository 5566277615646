<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'vitto-front',
  computed: mapState({
    info: state => state.store.info
  }),

  beforeCreate () {
    this.$store.dispatch('cart/cleanCurrentItem')
    this.$store.dispatch('catalog/setBtnMode', 1)
    this.$store.dispatch('cart/setSteps', [])
  },
  computed: {
    ...mapState({
      info: state => state.store.info
    })
  },
  watch: {
    info: function (n, o) {
      this.verifyBlocked(n)
    }
  },
  methods: {
    verifyBlocked: function (infos) {
      let self = this
      if (infos.status) {
        if (infos.status === 'blocked' || infos.status === 'canceled') {
          self.$router.push({ name: 'unavailable' })
        }
      }
    }
  }
}
</script>
