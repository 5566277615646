export function convertMoney (value, prefix = 'R$', withSpace = true) {
  value = parseFloat(value).toFixed(2).replace('.', ',')
  return withSpace ? `${prefix} ${value}` : `${prefix}${value}`
}

export function empty (mixedVar) {
  // discuss at: http://phpjs.org/functions/empty
  var key
  if (mixedVar === '' || mixedVar === 0 || mixedVar === '0' || mixedVar === null || mixedVar === false || mixedVar === undefined) {
    return true
  }
  if (typeof mixedVar === 'object') {
    for (key in mixedVar) {
      return false
    }
    return true
  }
  return false
}

export function sPrice (fPrice) {
  return 'R$' + parseFloat(fPrice).toFixed(2).toString().replace('.', ',')
}

export function getDayOfWeek (iDay) {
  switch (iDay) {
    case 0:
    case 7:
      return 'Domingo'
    case 1:
      return 'Segunda-feira'
    case 2:
      return 'Terça-feira'
    case 3:
      return 'Quarta-feira'
    case 4:
      return 'Quinta-feira'
    case 5:
      return 'Sexta-feira'
    case 6:
      return 'Sábado'
  }
}

export function getUnityPrice (currentItem) {
  let sum = 0
  if (currentItem) {
    Object.entries(currentItem.product_prices).map(a => {
      if (a[1].amount_promo === 0) {
        sum += a[1].amount
      } else {
        sum += a[1].amount_promo
      }
    })

    sum += getSteps(currentItem)
  }

  // sum += getSteps(currentItem)
  // this.total = this.qty * sum
  return sum
}

function getSteps (currentItem) {
  let sum = 0
  if (currentItem.product_steps) {
    for (const i in currentItem.product_steps) {
      if (currentItem.product_steps[i].step_ingredients) {
        for (const y in currentItem.product_steps[i].step_ingredients) {
          sum += currentItem.product_steps[i].step_ingredients[y].ingredient_quantity * this.currentItem.product_steps[i].step_ingredients[y].ingredient_amount
        }
      }
    }
  }
  return sum
}

export function slide (toggle, element) {
  if (toggle) {
    element.classList.add('opened')
  } else if (!toggle) {
    element.classList.remove('opened')
  } else {
    console.log('Valor de slide inválido!')
  }
}

export function onlyNumbers (string) {
  return string.replace(/([^0-9])/g, '')
}

export function verifyPhone (string) {
  if (string === false) {
    return false
  }

  const phone = string.replace(/([^0-9])/g, '')
  if (phone.length === 11) {
    if (parseInt(phone[2]) === 9) {
      return true
    }
  }

  if (phone.length === 10) {
    if (phone[2] < 7) {
      return true
    }
  }

  return false
}

export function diffDays (date) {
  let now = new Date()
  let data = new Date(date)
  let diff = Math.abs(now.getTime() - data.getTime())
  return Math.ceil(diff / (1000 * 60 * 60 * 24))
}

export function diffHours (date) {
  return Math.abs((new Date()).getHours() - (new Date(date)).getHours())
}

export function getItemLocalStorage (key) {
  return JSON.parse(localStorage.getItem(key))
}

export function validateAddress (oAddress, hideZipCode = false) {
  if (empty(oAddress)) return false
  delete oAddress.extra
  delete oAddress.zipcode
  return !Object.values(oAddress).includes('')
}
