<template>
  <div class="bg-white d-block mb-20" v-if="oOrder.order_without_registration">
    <div class="bb-1-gray p-15 d-flex flex-center justify-between">
      <h3 class="fw-900 text-uppercase d-block fs-s">Dados Pessoais</h3>
      <i @click="close()" class="fas fa-times fs-1-6 pointer d-block d-sm-none"></i>
    </div>

    <div class="bg-white d-block mb-20">
      <div class="p-3">
        <p class="fw-700 d-block text-uppercase">Email <small>(Opcional)</small></p>
        <input type="email" name="email" id="email" class="form-control mt-10 br-0 fs-s" placeholder="digite seu email"
          v-model="oCustomer.email" />
        <p class="fw-700 d-block text-uppercase mt-15">Nome</p>
        <input type="text" class="form-control mt-10 br-0 fs-s" placeholder="digite o nome" required
          v-model="oCustomer.firtsname" />
        <p class="fw-700 d-block text-uppercase mt-15">Telefone</p>
        <input type="tel" class="form-control mt-10 br-0 fs-s" v-mask="'(##) #####-####'" v-model="oCustomer.phone"
          placeholder="(__) ____-____" />
      </div>
    </div>
    <p class="p-15 text-center" v-if="isMobile" >
        <button @click="close" class="btn btn-primary text-uppercase fs-1-2-xs">Salvar</button>
      </p>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { setOrderInfo } from '@/plugins/orders'
export default {
  name: 'vtto-checkout-customer',
  data: function () {
    return {
      oCustomer: {
        phone: '',
        firtsname: '',
        email: ''
      }
    }
  },
  methods: {
    close: function () {
      let self = this
      this.$emit('set-customer', self.oCustomer)
    }
  },
  computed: {
    ...mapState({
      oOrder: state => state.order.oOrder
    }),
    isMobile () {
      return this.$store.state.store.isMobile
    }
  },
  watch: {
    'oCustomer.firtsname': function (sFirtsName) {
      setOrderInfo('firstname', sFirtsName)
    },
    'oCustomer.email': function (sEmail) {
      setOrderInfo('email', sEmail)
    },
    'oCustomer.phone': function (sPhone) {
      setOrderInfo('phone', sPhone)
    }
  }
}
</script>
