<template>
    <div class="fixed bottom-0 w-100 d-block bg-white" style="left: 0; z-index:1;">
        <div class="p-15 text-center d-block">
            <button v-if="!closed" class="btn btn-primary text-uppercase text-center mx-auto" :disabled="!enable"
                    @click="step" ref="next-button">Próximo
            </button>
            <button v-else class="btn btn-primary text-uppercase text-center mx-auto">Loja Fechada</button>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    enable: {
      default: false
    },
    currentStep: {},
    stage: {},
    closed: {},
    autoNext: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    step () {
      this.$emit('next')
      this.$eventHub.$emit('carousel')
    }
  },
  mounted () {
    if (this.autoNext) {
      this.step()
    }
  }
}
</script>
