<template>
  <div class="container main">
    <div class="row">
        <div class="col-12">
            <!-- Menu superior página -->
            <ul class="nav-tabs list-table w-100 bg-white mb-15 text-center">
                <li v-for="(item, index) in accountMenu" class="list-table-item relative" :key="index" :class="{'active': item.link === $route.name}">
                    <router-link :to="{name: item.link}" class="fw-900 fs-s text-uppercase p-15 d-block">{{ item.display }}</router-link>
                </li>
            </ul>
            <!-- FIM Menu superior página -->
        </div>
    </div>
    <router-view/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { empty } from '@/utils'
export default {
  name: 'vtto-about',
  computed: mapState({
    accountMenu: state => state.store.accountMenu,
    hasLoyalty: state => !empty(state.store.info.loyalty)
  }),
  methods: {
    menuFilters (item) {
      if (!this.hasLoyalty && item.name === 'loyalty') {
        return false
      }
      return true
    }
  }
}
</script>
