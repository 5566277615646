<template>
    <div>
        <div class="bg-white p-15 mt-15 bb-1-gray">
            <span class="text-uppercase fs-s fw-900">Bordas</span>
        </div>

        <ul class="list-unstyled bg-white">
            <div class="card card-body br-0">
                <li class="media m-0 relative">
                    <div class="media-body mr-10">
                        <label class="form-group-radio_group link-product" for="edge-0">
                            <input id="edge-0" type="radio" name="edge" class="form-group-radiobox" value="" checked
                                   @click="setEdge()">
                            <span class="form-group-check_radio"></span>
                            <span class="fw-700 fs-m">Sem Borda</span>
                        </label>
                    </div>
                </li>
            </div>

            <div class="card card-body br-0" v-for="(e, index) in currentItem.size_edges" :key="index">
                <li class="media m-0 relative">
                    <div class="media-body mr-10">
                        <label class="form-group-radio_group link-product" :for="`edge-${index+1}`">
                            <input :id="`edge-${index+1}`" type="radio" name="edge" class="form-group-radiobox" value=""
                                   @click="setEdge(e)">
                            <span class="form-group-check_radio"></span>
                            <span class="fw-700 fs-m">{{ e.edge_name }}</span>
                        </label>
                    </div>
                    <div class="ml-10 text-right relative">
                        <p class="price fs-m color-main w-80">
                            {{ e.edge_amount | money }}
                        </p>
                    </div>
                </li>
            </div>
        </ul>
    </div>
</template>

<script>
export default {
  props: {
    currentItem: {}
  },
  created () {
  },
  methods: {
    setEdge (e = null) {
      if (e) {
        this.currentItem.edge_id = e.edge_id
        this.currentItem.edge_amount = e.edge_amount
        this.currentItem.edge_name = e.edge_name
        this.currentItem.product_prices.edge = {
          amount: e.edge_amount
        }
      } else {
        delete this.currentItem.edge_id
        delete this.currentItem.edge_amount
        delete this.currentItem.edge_name
        this.currentItem.product_prices.edge = {
          amount: 0
        }
      }
      this.$emit('set-edge', this.currentItem)
      // this.$store.dispatch('cart/setProduct', this.currentItem)
    }
  }
}
</script>
