<template>
    <div :class="{newaddress: bNew}" class="col-12 mb-15 bg-white">
        <div class="bg-white d-block">
            <div v-if="!bNew" class="bb-1-gray p-15 d-flex flex-center justify-between">
                <h3 class="fw-900 text-uppercase d-block fs-s">Endereço</h3>

                <button v-if="!isOpened && !bNew" @click="slide(true)"
                        class="btn btn-secondary btn-s text-uppercase text-center fs-1-9 px-15 btn-s">
                    <i class="fas fa-edit mr-5"></i>
                    Editar
                </button>
                <i v-else @click="slide(false)" class="fas fa-times fs-1-6 pointer"></i>
            </div>

            <div v-if="((!isOpened && isHidden) || isMobile) && !bNew"
                 class="editable p-15 px-30 fs-m  bg-gray color-dark-gray">
                <p class="fw-700 color-black">
                    {{ oAddress.title.toLowerCase().trim() }}
                </p>
                <p class="fw-700 number" v-if="showZipCode()">
                    <i class="fas fa-map-marker-alt mr-10"></i>
                    {{ oAddress.zipcode }}
                </p>
                <p class="fw-500 number text-capitalize">
                    <i class="fas fa-road mr-10"></i>
                    {{ oAddress.address.toLowerCase().trim() }}<span
                        v-if="oAddress.number">, {{ oAddress.number }}</span><span
                        v-if="oAddress.extra"> ({{ oAddress.extra.toLowerCase().trim() }})</span>
                </p>
                <p class="fw-500 number mb-5">
                    <i class="fas fa-globe mr-10"></i>
                    {{ oAddress.city_name }}, {{ oAddress.district_name }} - {{ oAddress.state }}
                </p>
                <div v-if="oAddress.outdated === 1"  class="alert alert-danger usual-text mt-15 mb-0 fs-0-8" style="top: 0!important;" role="alert">
                  <h4 class="fs-1-4 pb-10">Seu bairro precisa ser atualizado</h4>
                  <p class="fs-1-2">Atualize-o para prosseguir</p>
                </div>
            </div>

            <div class="bg-white of-x-hide">
                <div class="slide-box bg-white" :class="[{ opened: isOpened }, { hidden: isHidden }]">
                    <div class="p-15 pt-0">
                        <div class="edit-title-mobile">
                            <div class="bb-1-gray p-15 d-flex flex-center justify-between">
                                <div v-if="bNew">
                                    <h3 class="fw-900 text-uppercase d-block fs-s">Novo Endereço</h3>
                                </div>
                                <div v-else>
                                    <h3 class="fw-900 text-uppercase d-block fs-s">Edite seu endereço</h3>
                                </div>

                                <i @click="close" class="fas fa-times fs-1-6 pointer"></i>
                            </div>
                        </div>

                        <p class="fw-700 d-block text-uppercase mt-15">Apelido (opcional)</p>
                        <input @focus="scrollToMe('.opened')" type="text" class="form-control mt-10 br-0 fs-s"
                               placeholder="Ex: (Minha Casa)" v-model="oAddress.title"/>
                        <template v-if="!hideZipcode">
                            <p class="fw-700 d-block text-uppercase mt-15">CEP</p>
                            <input @focus="scrollToMe('.opened')" type="text" class="form-control mt-10 br-0 fs-s"
                                   placeholder="digite o CEP" v-mask="'#####-###'" v-model="oAddress.zipcode"/>
                        </template>
                        <p class="fw-700 d-block text-uppercase mt-15">Estado</p>
                        <div class="form-group select-default">
                            <select @focus="scrollToMe('.opened')" id="uf" name="uf"
                                    class="text-uppercase relative pr-30"
                                    v-model="oAddress.state">
                                <option value="0"> Selecione</option>
                                <option v-for="(uf, stateKey) in states" :key="stateKey" :value="uf">{{uf}}</option>
                            </select>
                        </div>
                        <p class="fw-700 d-block text-uppercase mt-15">Cidade</p>
                        <div v-if="cities && cities[oAddress.state]" class="form-group select-default">
                            <select @focus="scrollToMe('.opened')" id="cidade" name="cidade"
                                    class="text-uppercase relative pr-30"
                                    v-model="oAddress.city_id">
                                <option value="0"> Selecione</option>
                                <option v-for="(city, cityKey) in cities[oAddress.state]" :key="cityKey"
                                        :value="cityKey">{{city}}
                                </option>
                            </select>
                        </div>
                        <p class="fw-700 d-block text-uppercase mt-15">Bairro</p>
                        <div v-if="districts && districts[oAddress.city_id]" class="form-group select-default">
                            <select @focus="scrollToMe('.opened')" id="bairro" name="bairro"
                                    class="text-uppercase relative pr-30"
                                    v-model="oAddress.district_id">
                                <option value="0"> Selecione</option>
                                <option v-for="(district) in districts[oAddress.city_id]"
                                        :key="district.district_id"
                                        :value="district.district_id">{{district.district_name}}
                                </option>
                            </select>
                        </div>
                        <p class="fw-700 d-block text-uppercase mt-15">Endereço</p>
                        <input @focus="scrollToMe('.opened')" type="text" class="form-control mt-10 br-0 fs-s"
                               placeholder="digite o endereço" v-model="oAddress.address"/>
                        <div class="row">
                            <div class="col-md-3">
                                <p class="fw-700 d-block text-uppercase mt-15">Número</p>
                                <input @focus="scrollToMe('.opened')" type="text" class="form-control mt-10 br-0 fs-s"
                                       placeholder="digite o número" v-model="oAddress.number"/>
                            </div>
                            <div class="col-md-9">
                                <p class="fw-700 d-block text-uppercase mt-15">Complemento (opcional)</p>
                                <input @focus="scrollToMe('.opened')" type="text" class="form-control mt-10 br-0 fs-s"
                                       placeholder="digite o complemento" v-model="oAddress.extra"/>
                            </div>
                        </div>
                    </div>
                    <div class="p-20 text-center mbslidexs">
                        <button v-if="bNew" @click="saveAddress"
                                class="btn btn-primary text-uppercase text-center fs-s mb-xs-20">
                            Salvar Endereço
                        </button>
                        <button v-if="!bNew" @click="updateAddress"
                                class="btn btn-primary text-uppercase text-center fs-s">
                            Atualizar Endereço
                        </button>
                        <button v-if="!bNew && iCountAddresses > 0" @click="removeAddress(oAddress.address_id)"
                                class="btn btn-primary text-uppercase text-center fs-s ml-10 mt-xs-15 ml-xs-auto mb-xs-20">
                            Excluir endereço
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import apiAccount from '@/api/account'
import { showAlert } from '@/plugins/alerts'
import { scrollToMe } from '@/plugins/scroll'

const axios = require('axios')
export default {
  data () {
    return {
      isOpened: false,
      isHidden: true
    }
  },
  created () {
    if (this.bNew) {
      this.slide(true)
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.store.isMobile
    },

    states () {
      return this.$store.state.account.addressInfoList.uf
    },

    cities () {
      return this.$store.state.account.addressInfoList.cities
    },

    districts () {
      return this.$store.state.account.addressInfoList.districts
    }
  },
  watch: {
    'oAddress.zipcode': function (value) {
      let self = this
      if (value.length === 9) {
        let sUrl = `${process.env.VUE_APP_API}/location/find-by-zipcode?zipcode=` + value.replace('-', '')

        return axios({
          url: sUrl,
          method: 'get'
        }).then(function (response) {
          if (response.data) {
            let address = response.data.data.address
            if (address) {
              self.oAddress.address = address.type + ' ' + address.name_min
              self.oAddress.state = address.uf
              self.oAddress.city_id = address.city_id
              self.oAddress.district_id = address.district_id
            }
          }
        })
      }
    }
  },
  methods: {
    showZipCode () {
      if (this.oAddress.zipcode == 0) return false
      return true
    },

    removeAddress: function (iAddressId) {
      this.slide(false)

      const that = this
      const params = {
        type: 'confirm',
        title: '',
        text: 'Deseja realmente</br><strong>remover este endereço?</strong>',
        onConfirm: () => {
          apiAccount.RemoveAddresses(iAddressId).then(function (response) {
            if (!response || !response.data || response.data.success === false) {
              showAlert('error', response.data.errors[0])
              return false
            } else {
              showAlert('success', 'Endereço removido com sucesso!')
              that.$store.dispatch('account/getUserAddresses', true)
            }
          })
        }
      }
      this.$modal.show(params)
    },
    saveAddress: function () {
      const that = this
      apiAccount.CreateAddresses(this.oAddress).then(function (response) {
        if (!response || !response.data || response.data.success === false) {
          showAlert('error', response.data.errors[0])
          return false
        } else {
          showAlert('success', 'Endereço adicionado com sucesso!')
          that.$store.dispatch('account/getUserAddresses', true)
          that.close()
        }
      })
    },
    updateAddress: function () {
      const that = this
      apiAccount.UpdateAddresses(this.oAddress).then(function (response) {
        if (!response || !response.data || response.data.success === false) {
          showAlert('error', response.data.errors[0])
          return false
        } else {
          showAlert('success', 'Endereço atualizado com sucesso!')
          that.$store.dispatch('account/getUserAddresses', true)
          that.slide(false)
        }
      })
    },
    editData: function (el) {
      // editData(el)
    },
    slide: function (toggle) {
      if (toggle) {
        this.isHidden = !this.isHidden
        setTimeout(() => {
          this.isOpened = !this.isOpened
        }, 30)
      } else if (!toggle) {
        this.isOpened = !this.isOpened
        setTimeout(() => {
          this.isHidden = !this.isHidden
        }, 450)
      } else {
        console.log('Valor de slide inválido!')
      }
    },
    close () {
      this.slide(false)

      if (this.bNew) {
        setTimeout(() => {
          this.$parent.showNewAddress = false
        }, 250)
      }
    },
    scrollToMe: function (box) {
      scrollToMe(box, -40)
    }
  },
  name: 'vtto-account-address',
  props: {
    hideZipcode: {
      type: Boolean,
      default: false
    },
    bNew: {
      type: Boolean,
      default: false
    },
    iAddressId: {
      type: Number,
      default: 0
    },
    iCountAddresses: {},
    oAddress: {}
  }
}
</script>
