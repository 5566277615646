function ipify () {
  const url = 'https://api.ipify.org'
  return new Promise((resolve, reject) => {
    fetch(url).then(function (response) {
      return response.text()
    }).then(function (text) {
      if (text) {
        resolve({
          api: 'ipify',
          ip: text
        })
      } else {
        reject(new Error('Falha na resposta da API'))
      }
    }).catch(function (err) {
      reject(err)
    })
  })
}

function cloudflare () {
  return new Promise((resolve, reject) => {
    const url = 'https://www.cloudflare.com/cdn-cgi/trace'
    fetch(url).then(function (response) {
      return response.text()
    }).then(function (text) {
      const regex = /^(?:ip)=(.*)$/gm
      let ip = text.match(regex)

      if (ip.length > 0) {
        resolve({
          api: 'cloudflare',
          ip: ip[0].replace('ip=', '')
        })
      } else {
        reject(new Error('Falha na resposta da API'))
      }
    }).catch(function (err) {
      reject(err)
    })
  })
}

export default function () {
  return new Promise(function (resolve, reject) {
    cloudflare().then(function (data) {
      resolve(data)
    }).catch(function () {
      ipify().then(function (data) {
        resolve(data)
      }).catch(function () {
        reject(new Error('Ocorreu um erro ao tentar capturar o IP do usuário'))
      })
    })
  })
}
