<template>
  <div>
    <div class="row" v-if="info.address && !info.hidden_address">
      <div class="col-12">
        <div class="bg-white d-block mb-15">
            <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Endereço</h3>
            <span class="fw-400 text-uppercase p-15 d-block fs-s">{{ info.address.address }}, {{ info.address.number }} - {{ info.address.region }} - {{ info.address.city }} - {{ info.address.state }}</span>
        </div>
       </div>
    </div>
    <div class="row" v-if="info.phones">
        <div class="col-12">
            <div class="bg-white d-block mb-15">
                <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Telefones</h3>
                <span v-if="oPhone" v-for="(oPhone, iPhoneKey) in info.phones" :key="iPhoneKey" class="fw-400 text-uppercase p-15 d-block fs-s">{{ oPhone }}</span>
            </div>
        </div>
    </div>
    <div class="row" v-if="info.email">
        <div class="col-12">
             <div class="bg-white d-block mb-15">
                <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Email</h3>
                <span class="fw-400 text-uppercase p-15 d-block fs-s">{{ info.email }}</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="bg-white d-block mb-15">
                <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Outros</h3>
                <router-link v-if="info && info.loyalty && info.loyalty.allow_loyalty" :to="{name: 'loyalty-rules'}" class="fw-400 text-uppercase p-15 d-block fs-s bb-1-gray">Regulamento programa de fidelidade</router-link>
                <a href="#" class="fw-400 text-uppercase p-15 d-block fs-s">Política de Privacidade</a>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
             <div class="bg-white d-block mb-15">
                <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Desenvolvido por</h3>
                <span class="fw-400 text-uppercase p-15 d-block fs-s">Sistema Vitto - Gestão Operacional e Delivery Para Restaurantes</span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'vtto-about-index',
  computed: mapState({
    info: state => state.store.info
  })
}
</script>
