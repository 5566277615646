import Vue from 'vue'
import Router from 'vue-router'
import Home from './pages/Home.vue'
import Coming from './pages/Coming.vue'
import Init from './pages/Init.vue'
import Front from './pages/Front.vue'
import FrontCatalog from './pages/FrontCatalog.vue'
import About from './pages/About.vue'
import AboutIndex from './pages/About/Index.vue'
import aboutDelivery from './pages/About/Delivery.vue'
import aboutTime from './pages/About/Time.vue'
import Login from './pages/Account/Login.vue'
import HomeCatalog from './pages/Catalog'
import Survey from './pages/Survey'
import CatalogConfirm2 from './pages/Catalog/Confirm2'
import Detail from './pages/Detail'
import CatalogSelect from './pages/Catalog/Select'
import CatalogCombo from './pages/Catalog/Combo'
import Account from './pages/Account.vue'
import AccountOrders from './pages/Account/Orders.vue'
import AccountLoyalty from './pages/Account/Loyalty.vue'
import AccountEdit from './pages/Account/Edit.vue'
import AccountCreate from './pages/Account/Create.vue'
import OrderDetail from './pages/Order/Detail.vue'
import OrderStatus from './pages/Order/Status.vue'
import LoyaltyRules from './pages/Loyalty/Rules.vue'
import NewPassword from './pages/Account/NewPassword.vue'
import FindOrder from './pages/Order/FindOrder.vue'
import ForgotPassword from './pages/Account/ForgotPassword.vue'
import EmailConfirmation from './pages/Account/EmailConfirmation.vue'
import Checkout from './pages/Checkout/Checkout.vue'
import NotFound from './pages/NotFound/NotFound.vue'
import Unavailable from './pages/Unavailable/Unavailable.vue'

/* Proteção para erro de múltiplos cliques na mesma url quando já está na página destino (principalmente front/login) */
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Init,
      name: 'init'
    },
    {
      path: '/catalog',
      name: 'catalog',
      redirect: '/front'
    },
    {
      path: '/front/api/fblogin',
      redirect: '/front/login'
    },
    {
      path: '/front',
      component: Front,
      meta: { needsAuth: false },
      children: [
        {
          path: '/',
          component: FrontCatalog,
          meta: { needsAuth: false },
          children: [
            {
              path: '',
              name: 'homeCatalog',
              component: HomeCatalog,
              meta: { needsAuth: false }
            },
            {
              path: '/front/confirma',
              name: 'catalogConfirm',
              component: CatalogConfirm2,
              meta: { needsAuth: false }
            },
            {
              path: '/front/combo',
              name: 'catalogCombo',
              component: CatalogCombo,
              meta: { needsAuth: false }
            },
            {
              path: '/front/selecionar',
              name: 'select',
              component: CatalogSelect,
              meta: { needsAuth: false }
            },
            {
              path: '/front/detalhes',
              name: 'detail',
              component: Detail,
              meta: { needsAuth: false }
            }
          ]
        },
        {
          path: '/front/sobre',
          name: '',
          component: About,
          meta: { needsAuth: false },
          children: [
            {
              path: '',
              name: 'about-index',
              component: AboutIndex,
              meta: { needsAuth: false }
            },
            {
              path: 'entrega',
              name: 'about-delivery',
              component: aboutDelivery,
              meta: { needsAuth: false }
            },
            {
              path: 'horario',
              name: 'about-time',
              component: aboutTime,
              meta: { needsAuth: false }
            }
          ]
        },
        {
          path: '/front/login',
          name: 'login',
          component: Login,
          meta: { needsAuth: false }
        },
        {
          path: '/front/logout',
          name: 'logout',
          component: {},
          meta: { needsAuth: true }
        },
        {
          path: '/front/novo-usuario',
          name: 'AccountCreate',
          component: AccountCreate,
          meta: { needsAuth: false }
        },
        {
          path: '/front/nova-senha',
          name: 'new-password',
          component: NewPassword,
          meta: { needsAuth: false }
        },
        {
          path: '/front/buscar-pedido',
          name: 'find-order',
          component: FindOrder,
          meta: { needsAuth: false },
          props: true
        },
        {
          path: '/front/esqueci-senha',
          name: 'forgot-password',
          component: ForgotPassword,
          meta: { needsAuth: false }
        },
        {
          path: '/front/confirmacao-email',
          name: 'ConfirmEmail',
          component: EmailConfirmation,
          meta: { needsAuth: false }
        },
        {
          path: '/front/meus-dados',
          name: 'account',
          component: Account,
          meta: { needsAuth: true },
          children: [
            {
              path: 'pontos',
              name: 'account-loyalty',
              component: AccountLoyalty,
              meta: { needsAuth: true }
            },
            {
              path: 'pedidos',
              name: 'account-orders',
              component: AccountOrders,
              meta: { needsAuth: true }
            },
            {
              path: '',
              name: 'account-edit',
              component: AccountEdit,
              meta: { needsAuth: true }
            }
          ]
        },
        {
          path: '/front/regulamento-fidelidade',
          name: 'loyalty-rules',
          component: LoyaltyRules,
          meta: { needsAuth: false }
        },
        {
          path: '/front/fidelidade/regulamento',
          // name: 'loyalty-rules',
          name: 'rules-loyalty',
          component: LoyaltyRules,
          meta: { needsAuth: false }
        },
        {
          path: '/front/pedido/status',
          name: 'order-status',
          component: OrderStatus,
          meta: { needsAuth: true },
          props: true
        },
        {
          path: '/front/pedido/:iOrderId',
          name: 'order-detail',
          component: OrderDetail,
          meta: { needsAuth: true }
        },
        {
          path: '/front/fechamento',
          name: 'checkout',
          component: Checkout,
          meta: { needsAuth: true }
        },
        {
          path: '/front/fechamento/',
          name: 'checkout-not-register',
          component: Checkout,
          meta: { needsAuth: false }
        },
        {
          path: '/front/pesquisa/:hash',
          name: 'survey',
          component: Survey,
          meta: { needsAuth: false }
        },
        {
          path: '/front/em-breve',
          name: 'coming',
          component: Coming,
          meta: {
            needsAuth: false,
            bHasNoTemplate: true
          }
        },
        {
          path: '/front/pagina-nao-encontrada',
          name: 'not-found',
          component: NotFound
        }
      ]
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      params: {
        template: false
      },
      meta: {
        needsAuth: false,
        bHasTemplateBody: false
      }
    },
    {
      path: '/indisponivel',
      name: 'unavailable',
      component: Unavailable,
      meta: {
        needsAuth: false,
        bHasNoTemplate: true
      }
    },
    {
      path: '*',
      name: 'default',
      redirect: '/front/pagina-nao-encontrada'
    }
  ]
})
