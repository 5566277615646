<!-- PRODUTO TIPO 3 = COMBO -->
<template>
    <div>
        <div class="bg-white mt-15 m-xs-0">
            <vtto-carousel-steps :current-step="currentStep" :steps="steps"/>
        </div>
        <div class="bg-white p-15 fs-s bb-1-gray">
        <span class="text-uppercase fw-900">{{ steps[currentStep-1].name }}</span>
        </div>

        <!-- LISTA DE PRODUTOS DE UM COMBO -->
        <vtto-steps-items v-if="currentStage === 0 && type !== 2" :step="steps[currentStep-1].combo" :size-id="productSteps[currentStep-1].step_size_id" :id="currentStep" @enable-btn="enableBtn" @ingredient-click="addProduct"/>
        <!-- ESCOLHA DE QUANTIDADE DE SABORES PARA PRODUTOS MEIO-A-MEIO -->
        <div v-if="currentStage === 0 && type === 2">
            <div class="bg-white p-15 mt-15 bb-1-gray">
                <span class="text-uppercase fs-s fw-900">Escolha a quantidade de sabores</span>
            </div>
            <div class="bg-white bb-1-gray" v-for="(size, index) in list()" :key="index">
                <vtto-catalog-item-size :index="index" :product="size" :type="2" :confirm="true" :combo="true" @set-flavors="setQtyFlavors"/>
            </div>
        </div>
        <!-- ESCOLHA DE QUANTIDADE DE SABORES PARA PRODUTOS MEIO-A-MEIO -->
        <div v-if="currentStage === 0 && type === 4">
            <div class="bg-white p-15 mt-15 bb-1-gray">
                <span class="text-uppercase fs-s fw-900">Escolha a quantidade de sabores</span>
            </div>
            <div class="bg-white bb-1-gray" v-for="(size, index) in list()" :key="index">
                <vtto-catalog-item-size :index="index" :product="size" :type="2" :confirm="true" :combo="true" @set-flavors="setQtyFlavors"/>
            </div>
        </div>

        <!-- EXTRAS E MONTAGEM  -->
        <div v-if="currentStage > 0">

            <div v-if="type === 1">
                <!-- LISTA DE INGREDIENTES -->
                <vtto-catalog-detail-ingredients-new
                        v-if="comboResult[currentStep-1].step_products[0].product_ingredients"
                        :ingredients="comboResult[currentStep-1].step_products[0].product_ingredients"
                        @remove-ingredients="simpleRemoveIngredients" :step2="currentStep2"/>

                <vtto-catalog-detail-ingredients-new
                        v-if="comboResult[currentStep-1].step_products[0].product_ingredients_extra"
                        :ingredients="comboResult[currentStep-1].step_products[0].product_ingredients_extra"
                        :extra="true" @add-ingredients="simpleAddIngredients" :step2="currentStep2"/>

                <!-- LISTA DE INGREDIENTES ADICIONAIS -->
                <vtto-catalog-detail-ingredients-new
                        v-if="comboResult[currentStep-1].step_products[0].product_sizes[0].ingredients_extra"
                        :ingredients="comboResult[currentStep-1].step_products[0].product_sizes[0].ingredients_extra"
                        :extra="true" @add-ingredients="simpleAddIngredientsExtra" :step2="currentStep2"/>

                <vtto-step-info-extra
                        v-if="comboResult[currentStep-1].step_products[0].product_sizes[0].info_extra"
                        @set-info-extra="setInfoExtra"
                        :info-extra="comboResult[currentStep-1].step_products[0].product_sizes[0].info_extra"
                        @enable-btn="enableBtn"
                        />

            </div>
            <div v-if="type === 2 || type === 10">
                <vtto-step-parts v-if="productSteps[currentStep-1].step_products.length > 0"
                                 @set-products="setParts" @set-edge="setEdge" @next="nextStepParts"
                                 :edge="productSteps[currentStep-1].step_products[0].product_sizes[getIndexStepId()].size_edge_allowed"
                                 :qty-flavors="qtyFlavors" :flavors="productSteps[currentStep-1].step_products"
                                 :size-id="steps[currentStep - 1].combo.step_size_id"
                                 :is-combo="isCombo"
                                 :is-special-combo="isSpecialCombo"/>
            </div>
            <div v-if="type === 4">
               <vtto-step-combo v-if="productSteps[currentStep-1].step_products.length > 0"
                                 @set-products="setParts" @set-edge="setEdge" @next="nextStepParts"
                                 :edge="productSteps[currentStep-1].step_products[0].product_sizes[getIndexStepId()].size_edge_allowed"
                                 :qty-flavors="qtyFlavors" :flavors="productSteps[currentStep-1].step_products"
                                 :size-id="steps[currentStep - 1].combo.step_size_id"
                                 :is-combo="isCombo"/>
            </div>
            <div v-if='comboResult[currentStep-1] && type !== 1'>
            <vtto-step-info-extra
                        v-if="comboResult[currentStep-1].step_products[0].product_sizes[0].info_extra"
                        @set-info-extra="setInfoExtra"
                        :info-extra="comboResult[currentStep-1].step_products[0].product_sizes[0].info_extra"/>
            </div>
            <div v-if="type === 3">
                <vtto-catalog-mounting
                  :parent="false"
                  :size-id="productSteps[currentStep-1].step_size_id"
                  :product-steps="montingSteps"
                  :combo-step="currentStep"
                  @next="nextStepMonting"
                  @step-ingredients="resultStepsMonting"/>
            </div>
        </div>
        <vtto-btn-steps v-if="!end" @next="nextStep" :auto-next="autoNext" :enable="btnEnable"/>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import VttoCarouselSteps from '@/components/VttoCarouselSteps'
import VttoStepsItems from '@/components/Catalog/new/VttoMountingItems'
import VttoBtnSteps from '@/components/Catalog/new/VttoBtnSteps'
import VttoCatalogMounting from '@/components/Catalog/VttoCatalogMounting'
import VttoCatalogDetailIngredientsNew from '@/components/Catalog/new/VttoIngredients'
import VttoStepInfoExtra from '@/components/Catalog/new/VttoStepInfoExtra'
import VttoCatalogItemSize from '@/components/Catalog/VttoCategoryItemSize'
import VttoStepParts from '@/components/Catalog/VttoStepParts'
import VttoStepCombo from '@/components/Catalog/VttoStepCombo'

export default {
  props: {
    productSteps: {}
  },
  data () {
    return {
      isCombo: true,
      isSpecialCombo: false,
      currentStep: 1,
      currentStep2: 0,
      currentProduct: 0,
      currentProduct2: 0,
      currentStage: 0,
      end: false,
      btnEnable: false,
      comboResult: [],
      type: 1,
      montingSteps: [],
      maxProducts: 1,
      qtyFlavors: 1,
      autoNext: false,
      lastStepDone: false
    }
  },
  components: {
    VttoCarouselSteps,
    VttoStepsItems,
    VttoBtnSteps,
    VttoCatalogMounting,
    VttoCatalogDetailIngredientsNew,
    VttoStepInfoExtra,
    VttoCatalogItemSize,
    VttoStepParts,
    VttoStepCombo
  },
  updated () {
    this.getType()
  },
  created () {
    this.getType()
  },
  methods: {
    /** se for algo relacionado a sabores de pizza procure em vttoStepParts */

    /** quantidades de sabor de pizza */
    getType () {
      let maxProducts = 1

      if (typeof this.productSteps[this.currentStep - 1].step_products !== 'undefined') {
        for (let j in this.productSteps[this.currentStep - 1].step_products[0].product_sizes) {
          let maxProductsItem = this.productSteps[this.currentStep - 1].step_products[0].product_sizes[j].size_max_products
          if (maxProductsItem > maxProducts) {
            maxProducts = maxProductsItem
          }

          // se tiver borda, tipo vira 2 também
          if (this.productSteps[this.currentStep - 1].step_products[0].product_sizes[j].size_edge_allowed) {
            this.type = 2
          }
        }
      }

      if (maxProducts > 1) {
        this.type = 2
      }

      if (this.step_products) {
        if (this.productSteps[this.currentStep - 1].step_max_items > 1 && this.productSteps[this.currentStep - 1].step_max_items <= 5 && this.step_products.length < 5) {
          this.type = 4
        }
      }

      let comboWithSpecialStep = { minProductsStep: 1, maxProductsStep: 5 }
      let storeInfo = this.$store.state.store.info
      if (storeInfo.combo_with_special_step) {
        comboWithSpecialStep = Object.assign(comboWithSpecialStep, storeInfo.combo_with_special_step)
      }

      if (this.productSteps[this.currentStep - 1]) {
        if (this.productSteps[this.currentStep - 1].step_max_items) {
          if (this.productSteps[this.currentStep - 1].step_max_items > comboWithSpecialStep.minProductsStep && this.productSteps[this.currentStep - 1].step_max_items <= comboWithSpecialStep.maxProductsStep && this.productSteps.length < 10) {
            this.type = 2
            this.isSpecialCombo = true
          }
        }
      }
    },
    getIndexStepId: function () {
      let productSizes = this.productSteps[this.currentStep - 1].step_products[0].product_sizes
      for (let i in productSizes) {
        if (productSizes[i].size_id === this.productSteps[this.currentStep - 1].step_size_id) {
          return i
        }
      }
    },
    list () {
      let flavors = []
      let sizeId = 0
      let maxProducts = 1
      if (this.productSteps[this.currentStep - 1].step_products.length > 0) {
        if (this.steps[this.currentStep - 1].combo.step_size_id) {
          sizeId = this.steps[this.currentStep - 1].combo.step_size_id

          for (let j in this.productSteps[this.currentStep - 1].step_products[0].product_sizes) {
            if (this.productSteps[this.currentStep - 1].step_products[0].product_sizes[j].size_id === sizeId) {
              maxProducts = this.productSteps[this.currentStep - 1].step_products[0].product_sizes[j].size_max_products
            }
          }
        } else {
          maxProducts = this.productSteps[this.currentStep - 1].step_products[0].product_sizes[0].size_max_products
        }
      }

      for (let i = 1; i <= maxProducts; i++) {
        let label = i === 1 ? `${i} sabor` : `${i} sabores`
        flavors.push({ label_qty_products: label, qty: i })
      }

      if (flavors.length === 1) {
        this.qtyFlavors = 1
        this.autoNext = true
      }

      if (flavors.length === 1 && this.type === 4) {
        this.qtyFlavors = parseInt(this.productSteps[this.currentStep - 1].step_max_items)
        this.autoNext = true
      }

      if (flavors.length < 2 && this.type === 2) {
        this.qtyFlavors = parseInt(this.productSteps[this.currentStep - 1].step_max_items)
        this.autoNext = true
      }

      if (this.isSpecialCombo === true && this.currentStage === 0 && this.type === 2) {
        this.currentStage = 1
        this.autoNext = true
        this.btnEnable = false
        this.end = true
        this.lastStepDone = false
      }

      return flavors
    },
    nextStepParts (step) {
      if (this.currentStep === 1 && this.type === 2) {
        this.autoNext = true
      }

      if (this.currentStep === this.productSteps.length) {
        this.end = true
        this.$emit('set-step', 'end')
        return
      }

      if (step === 'end') {
        this.currentStage = 0
        this.end = false
        this.btnEnable = false
        this.type = 0
        this.qtyFlavors = 1
      }
    },
    nextStep () {
      if (this.currentStep > this.productSteps.length) {
        this.end = true
        this.$emit('set-step', 'end')
        return
      }

      if (this.qtyFlavors === 1 && this.type === 2) {
        this.currentStage = 1
        this.btnEnable = false
        this.end = true
        return
      }

      /** multiplos sabores de pizza */
      if (this.qtyFlavors > 1) {
        this.currentStage = 1
        this.btnEnable = false
        this.end = true
        return
      }

      if (this.currentStage === 1 && this.currentStep2 > 0) {
        if (this.comboResult[this.currentStep - 1].step_products.length > this.currentStep2) {
          this.currentStep2 = this.currentStep2 + 1
          return
        }
      }

      if (this.currentStage === 1 && this.currentStep < this.productSteps.length) {
        this.currentStep = this.currentStep + 1
        this.currentStage = 0
        this.btnEnable = false
        this.currentStep2 = 0
        return
      }

      if (this.comboResultVerify('product_steps')) {
        /** montagem */
        this.type = 3
        this.currentStage = 1
        this.end = true
        let x = 0
        let y = 0

        for (x in this.comboResult[this.currentStep - 1].step_products) {
          for (y in this.comboResult[this.currentStep - 1].step_products[x].product_steps) {
            if (this.comboResult[this.currentStep - 1].step_products[x].product_steps.length > 1) {
              this.montingSteps[y] = this.comboResult[this.currentStep - 1].step_products[x].product_steps[y]
            } else {
              this.montingSteps[x] = this.comboResult[this.currentStep - 1].step_products[x].product_steps[0]
            }
          }
        }
        return
      }

      /** simples */
      if (this.comboResultVerify('product_ingredients') && this.currentStage === 0) {
        this.currentStage = 1
        this.end = false
        return
      }

      if (this.currentStep >= this.productSteps.length && this.lastStepDone) {
        this.end = true
        this.$emit('set-step', 'end')
        return
      }

      if (this.currentStep === this.productSteps.length) {
        this.lastStepDone = true
        return
      }

      // executa ultimo step(SEM USAR ERROS)
      if (this.currentStep < this.productSteps.length) {
        this.currentStep += 1
        this.lastStepDone = true
      }
    },
    comboResultVerify (type) {
      if (!this.comboResult[this.currentStep - 1]) return false
      if (!this.comboResult[this.currentStep - 1].step_products) return false
      if (type === 'product_steps' && this.comboResult[this.currentStep - 1].step_products[0].product_steps) return true
      if (type === 'product_ingredients' && this.comboResult[this.currentStep - 1].step_products[0].product_ingredients) return true
      return false
    },
    enableBtn (value) {
      this.btnEnable = value
    },
    addProduct (product) {
      if (typeof product.step_products !== 'undefined' && product.step_products.length > 1) {
        this.currentStep2 = 1
        this.end = false
      } else {
        this.currentStep2 = 0
        this.comboResult[this.currentStep - 1] = product

        this.$emit('set-combo', this.comboResult)
      }
    },
    nextStepMonting (next) {
      if (next === 'end') {
        this.end = !(this.currentStep < this.productSteps.length)
        this.currentStage = 0
        this.currentStep = this.currentStep + 1
        this.enableBtn(false)
        if (this.end) {
          this.$emit('set-step', 'end')
        }
      }
    },
    resultStepsMonting (monting) {
      let x = 0
      let y = 0

      for (x in this.comboResult[this.currentStep - 1].step_products) {
        for (y in this.comboResult[this.currentStep - 1].step_products[x].product_steps) {
          if (typeof this.comboResult[this.currentStep - 1].step_products[x] === 'undefined') {
            if (typeof this.comboResult[this.currentStep - 1].step_products[x].product_steps === 'undefined') {
              this.end = true
              this.$emit('set-step', 'end')
            }
          } else {
            if (this.comboResult[this.currentStep - 1].step_products[x].product_steps.length > 1) {
              this.comboResult[this.currentStep - 1].step_products[x].product_steps[y] = monting[y]
            } else {
              this.comboResult[this.currentStep - 1].step_products[x].product_steps[0] = monting[x]
            }
          }
          this.$emit('set-combo', this.comboResult)
        }
      }
    },
    simpleRemoveIngredients (ingredients) {
      let step2 = 0
      if (this.currentStep2 > 0) {
        step2 = this.currentStep2 - 1
      }
      this.comboResult[this.currentStep - 1].step_products[step2].remove_ingredients = ingredients
      if (ingredients.length === 0) {
        delete this.comboResult[this.currentStep - 1].step_products[step2].remove_ingredients
      }
      this.$emit('set-combo', this.comboResult)
    },
    simpleAddIngredientsExtra (ingredients) {
      let step2 = 0
      if (this.currentStep2 > 0) {
        step2 = this.currentStep2 - 1
      }

      let sumExtras = 0
      for (let i in ingredients) {
        let quantity = 1
        if (typeof ingredients[i].ingredient_quantity !== 'undefined' && ingredients[i].ingredient_quantity > 0) {
          quantity = ingredients[i].ingredient_quantity
        }
        sumExtras += parseFloat(ingredients[i].ingredient_amount) * quantity
      }

      this.comboResult[this.currentStep - 1].step_products[step2].ingredients_extra = ingredients
      this.comboResult[this.currentStep - 1].step_products[step2].ingredients_amount = sumExtras

      this.$emit('set-combo', this.comboResult)
    },
    setInfoExtra (infoExtraOptionId) {
      let step2 = 0
      let infoExtraAmount = 0
      let infoExtraName = ''
      if (this.currentStep2 > 0) {
        step2 = this.currentStep2 - 1
      }

      this.comboResult[this.currentStep - 1].step_products[step2].info_extra = this.comboResult[this.currentStep - 1].step_products[step2].product_sizes[0].info_extra
      this.comboResult[this.currentStep - 1].step_products[step2].info_extra_option_id = infoExtraOptionId

      for (let x in this.comboResult[this.currentStep - 1].step_products[step2].product_sizes[0].info_extra.info_extra_options) {
        let iId = this.comboResult[this.currentStep - 1].step_products[step2].product_sizes[0].info_extra.info_extra_options[x].option_id
        if (iId === infoExtraOptionId) {
          infoExtraAmount = this.comboResult[this.currentStep - 1].step_products[step2].product_sizes[0].info_extra.info_extra_options[x].option_amount
          infoExtraName = this.comboResult[this.currentStep - 1].step_products[step2].product_sizes[0].info_extra.info_extra_options[x].option_name
        }
      }

      this.comboResult[this.currentStep - 1].step_products[step2].info_extra_option_name = infoExtraName
      this.comboResult[this.currentStep - 1].step_products[step2].info_extra_option_amount = parseFloat(infoExtraAmount)
      this.$emit('set-combo', this.comboResult)
    },
    setQtyFlavors (qty) {
      this.qtyFlavors = qty
      this.btnEnable = true
    },
    setParts (parts) {
      this.comboResult[this.currentStep - 1] = this.steps[this.currentStep - 1].combo
      delete this.comboResult[this.currentStep - 1].step_products
      delete this.comboResult[this.currentStep - 1].step_products_id

      this.comboResult[this.currentStep - 1].step_products = []
      const newParts = parts.products ? parts.products : parts
      this.comboResult[this.currentStep - 1].step_products.push({
        product_quantity: 1,
        products: newParts
      })
      this.$emit('set-combo', this.comboResult)
    },
    setEdge (edge) {
      if (edge === 0) {
        delete this.comboResult[this.currentStep - 1].step_products[0].edge_id
        delete this.comboResult[this.currentStep - 1].step_products[0].edge_name
        delete this.comboResult[this.currentStep - 1].step_products[0].edge_amount
      } else {
        this.comboResult[this.currentStep - 1].step_products[0].edge_id = edge.edge_id
        this.comboResult[this.currentStep - 1].step_products[0].edge_name = edge.edge_name
        this.comboResult[this.currentStep - 1].step_products[0].edge_amount = edge.edge_amount
      }
      this.$emit('set-combo', this.comboResult)
    }
  },
  computed: {
    ...mapState('store', ['info']),
    steps: function () {
      let res = []
      for (let i in this.productSteps) {
        res.push({
          name: this.productSteps[i].step_name,
          combo: this.productSteps[i]
        })
      }
      return res
    }
  }
}
</script>
