<template>
    <div id="app" ref="app">
        <div v-if="bHasNoTemplate === true">
            <router-view/>
        </div>
        <div v-else :class="colors" class="home bg-gray h-min-100" :style="getBackground()">
            <vtto-alerts></vtto-alerts>
            <div v-if="bHasTemplateBody === false">
                <router-view/>
            </div>
            <div v-else>
                <vtto-loading :show="loading"/>
                <vtto-header :is-open="isOpen" :menu-data="mainMenu" :title="info.name" :brand="info.brand" :oUser="oUser" :isLogged="isLogged" :deadline="info.deadline"/>
                <router-view/>
                <vtto-footer :phones="info.phones" :about="info.about" :company_name="info.name" :email="info.email" :address="info.address"/>
            </div>
            <span @click="scrollToTop" :class="{'o-0': scrolled < 1}" class="backtop bg-main color-white pointer bounce z-1">
              <i class="fas fa-angle-up"></i>
            </span>
        </div>
        <app-modal/>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { empty, diffDays, diffHours, getItemLocalStorage } from './utils'
import VttoHeader from '@/components/VttoHeader'
import VttoLoading from '@/components/VttoLoading'
import VttoFooter from '@/components/VttoFooter'
import VttoAlerts from '@/components/VttoAlerts'
import router from '@/router'

export default {
  timers: {
    showBannerTimer: { time: 1000, autostart: false, repeat: true },
    showBannerHomeTimer: { time: 1000, autostart: false, repeat: true }
  },
  components: {
    VttoHeader,
    VttoLoading,
    VttoFooter,
    VttoAlerts
  },
  data () {
    return {
      bTimeToShowBanner: 1800, /* 30min */
      bCountBanner: null,
      bTimeToShowBannerHome: 1800, /* 30min */
      bCountBannerHome: null,
      isOpen: false,
      colors: '',
      background: '',
      bMkt: false,
      sColorFront: '',
      google_auth_key: '',
      order_without_registration: '',
      HOUR_CLEAN_STORAGE: 3,
      LIMIT_DAY_CLEAN_STORAGE: 1
    }
  },
  methods: {
    scrollToTop () {
      var VueScrollTo = require('vue-scrollto')
      VueScrollTo.scrollTo(this.$refs.app, 400)
    },

    setCssMain (sCss) {
      document.querySelector('#color').textContent = sCss
    },

    mountCssMain (sColorOne, sColortwo) {
      return `
          .main-color .btn-app.active {
              color: ${sColorOne} !important;
              border-top:3px solid #C33332 !important;
          }
          .main-color .color-main, .main-color-main a.color-main {
              color: ${sColorOne} !important;
          }
          .main-color .bg-main{
              background: ${sColorOne} !important;
          }
          .main-color .nav-tabs > li > a::after {
              content: "";
              background: ${sColorOne} !important;
          }
          .btn-color .btn.btn-primary {
              background: ${sColortwo} !important;
          }
          .btn-color .btn.btn-secondary {
              border:1px solid ${sColortwo} !important;
              color: ${sColortwo} !important;
          }
          .main-color .pulse {
              display: block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: ${sColortwo} ;
              cursor: pointer;
              -webkit-animation: pulsered 1s infinite;
              animation: pulsered 1s infinite;
              position: absolute;
              left: 45%;
          }
           @-webkit-keyframes pulsegray {
              0% {
                  -webkit-box-shadow: 0 0 0 0 ${sColorOne};
              }
              70% {
                  -webkit-box-shadow: 0 0 0 100px ${sColorOne};
              }
              100% {
                  -webkit-box-shadow: 0 0 0 0 ${sColorOne};
              }
            }
          @keyframes pulsegray {
              0% {
                  -moz-box-shadow: 0 0 0 0 ${sColorOne};
                  box-shadow: 0 0 0 0 ${sColorOne};
              }
              70% {
                  -moz-box-shadow: 0 0 0 100px ${sColorOne};
                  box-shadow: 0 0 0 100px ${sColorOne};
              }
              100% {
                  -moz-box-shadow: 0 0 0 0 ${sColorOne};
                  box-shadow: 0 0 0 0 ${sColorOne};
              }
          }`
    },

    showBannerTimer () {
      let self = this
      self.bCountBanner++
      if (this.bCountBanner >= self.bTimeToShowBanner) {
        this.showBanner(this.info.banner)
        this.bCountBanner = 0
      }
    },

    showBannerHomeTimer () {
      let self = this
      self.bCountBannerHome++
      if (this.bCountBannerHome >= self.bTimeToShowBannerHome) {
        this.showBannerHome(this.info.bannerHome)
        this.bCountBannerHome = 0
      }
    },

    /* Antes de verificar o banner, verifica se precisa redirecionar para https ou não */
    verifyHttps (infos) {
      let host = window.location.host
      let protocol = window.location.protocol
      let pathName = window.location.pathname
      let port = window.location.port
      let apiEnv = this.$store.state.store.env

      if (host.search('localhost') < 0 && port === '') {
        if (protocol === 'http:') {
          /* Domínio próprio em https */
          if (infos.own_ssl) {
            window.location.href = `https://${host}${pathName}`
          } else {
            /* Redirecionamento convencional em https */
            window.location.href = `https://${apiEnv}.vtto.com.br${pathName}`
          }
        }
      }
    },

    verifyAndShowBanner (infos) {
      if (typeof infos.banner !== 'undefined' && typeof infos.banner.image !== 'undefined') {
        if (this.bCountBanner === null) {
          this.bCountBanner = 0
          if (this.$timer) {
            this.$timer.start('showBannerTimer')
            this.showBanner(infos.banner)
          }
        }
      }
    },

    verifyAndShowBannerHome (infos) {
      if (typeof infos.bannerHome !== 'undefined' && typeof infos.bannerHome.image !== 'undefined') {
        if (this.bCountBannerHome === null) {
          this.bCountBannerHome = 0
          if (this.$timer) {
            this.$timer.start('showBannerHomeTimer')
            this.showBannerHome(infos.bannerHome)
          }
        }
      }
    },
    showBanner (banner) {
      if (router.currentRoute.name === 'init' || router.currentRoute.name === 'home' || router.currentRoute.name === 'survey' || router.currentRoute.name === 'coming') {
        return false
      } else {
        const params = {
          type: 'banner',
          title: typeof banner.text !== 'undefined' ? banner.text : '',
          imageUrl: banner.image
        }

        this.$modal.show(params)
      }
    },

    showBannerHome (bannerHome) {
      if (router.currentRoute.name === 'home' || router.currentRoute.name === 'init') {
        const params = {
          type: 'bannerHome',
          title: typeof bannerHome.text !== 'undefined' ? bannerHome.text : '',
          imageUrl: bannerHome.image
        }

        this.$modal.show(params)
      }
    },
    updateScroll () {
      this.$store.dispatch('store/setScroll')
    },

    addMarketing (info) {
      let mkt = info.marketing
      if (mkt && !this.bMkt) {
        this.bMkt = true
        if (info.favicon) {
          this.setFavIcon(info.favicon)
        }

        if (info.name) {
          this.seoMetaTag(info.name, info.description, info.brand)
        }

        if (mkt.analytics_id) {
          this.analytics(mkt.analytics_id)
        }

        if (mkt.fbpixel_id) {
          this.fbPixel(mkt.fbpixel_id)
        }

        if (mkt.tag_manager) {
          this.tagManager(mkt.tag_manager)
        }

        if (mkt.smart_look_front) {
          this.smartLook(mkt.smart_look_front)
        }
      }
    },
    setScript: function (parentTag, tag, src = null, text = null, attributeType = null, attributeValue = null, content = null) {
      let t = document.getElementsByTagName(parentTag)[0]
      let tscript = document.createElement(tag)
      if (src) {
        tscript.src = src
      }

      if (text) {
        tscript.text = text
      }
      if (attributeValue) {
        tscript.setAttribute(attributeType, attributeValue)
      }

      if (content) {
        tscript.content = content
      }
      t.insertBefore(tscript, t.firstChild)
    },

    setFavIcon: function (favicon) {
      if (typeof favicon !== 'undefined') {
        let t = document.getElementsByTagName('head')[0]
        let tfavicon = document.createElement('link')
        tfavicon.setAttribute('rel', 'shortcut icon')
        tfavicon.setAttribute('type', 'image/x-icon')
        tfavicon.setAttribute('href', favicon)
        t.insertBefore(tfavicon, t.firstChild)
      }
    },

    analytics: function (id) {
      const script = `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
      
        ga('create', '${id}', 'auto');
        ga('send', 'pageview');
      `
      this.setScript('head', 'script', null, script)
    },

    tagManager: function (id) {
      const script1 = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${id}');
      `
      this.setScript('head', 'script', null, script1)
    },

    smartLook: function (id) {
      const script = `
        window.smartlook || (function (d) {
          var o = smartlook = function () {
              o.api.push(arguments)
          }, h = d.getElementsByTagName('head')[0];
          var c = d.createElement('script');
          o.api = new Array();
          c.async = true;
          c.type = 'text/javascript';
          c.charset = 'utf-8';
          c.src = '//rec.smartlook.com/recorder.js';
          h.appendChild(c);
        })(document);
        smartlook('init', '${id}');
      `
      this.setScript('head', 'script', null, script)
    },

    fbPixel: function (id) {
      const script = `
        /* <![CDATA[ */
          var fbpixel_id = '${id}';
          /* ]]> */
          !function (f, b, e, v, n, t, s) {
              if (f.fbq)
                  return;n = f.fbq = function () {
                  n.callMethod ?
                          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
              };
              if (!f._fbq)
                  f._fbq = n;
              n.push = n;
              n.loaded = !0;
              n.version = '2.0';
              n.queue = [];
              t = b.createElement(e);
              t.async = !0;
              t.src = v;
              s = b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t, s)
          }(window,
                  document, 'script', '//connect.facebook.net/en_US/all.js');

          fbq('init', fbpixel_id);
          fbq('track', "PageView");
      `
      this.setScript('head', 'script', null, script)

      const script2 = `
        <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id='${id}'&ev=PageView&noscript=1"
        />
      `
      this.setScript('body', 'noscript', null, script2)
    },

    seoMetaTag: function (title, description, image) {
      /* Facebook */
      this.setScript('head', 'meta', null, null, 'property', 'og:title', title)
      this.setScript('head', 'meta', null, null, 'property', 'og:description', description)
      this.setScript('head', 'meta', null, null, 'property', 'og:image', image)

      /* Geral */
      this.setScript('head', 'title', null, title)
      this.setScript('head', 'meta', null, null, 'name', 'description', description)
    },

    getBackground: function () {
      // -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover
      if (this.info.background && !this.$store.state.store.isTablet && !this.$store.state.store.isMobile) {
        return 'background: url(' + this.info.background.image + ') no-repeat center center fixed #e6e6e6; background-size: cover'
      } else {
        if (this.$store.state.route.name === 'home') {
          if (this.info.background) {
            if (this.info.background_mobile && this.info.background_mobile.image) {
              return 'background: url(' + this.info.background_mobile.image + ') no-repeat center center fixed #e6e6e6; background-size: cover'
            } else {
              return 'background: url(' + this.info.background.image + ') no-repeat center center fixed #e6e6e6; background-size: cover'
            }
          }
        }
      }
      return ''
    },
    verifyLocalStorage () {
      let self = this
      let oCard = getItemLocalStorage('cart')
      if (oCard !== null) {
        if (!empty(oCard)) {
          oCard.forEach(element => {
            if ('added' in element) {
              // LIMPAR O LOCAL STORAGE CASO TENHA SE PASSADO MAIS DE 3 HORAS OU 1 DIA
              if (diffHours(element.added) >= self.HOUR_CLEAN_STORAGE || diffDays(element.added) > self.LIMIT_DAY_CLEAN_STORAGE) {
                self.emptyCart()
              }
            }
          })
        }
      }
    },
    emptyCart () {
      localStorage.setItem('cart', [])
    }
  },

  computed: {
    ...mapState({
      info: state => state.store.info,
      mainMenu: state => state.store.mainMenu,
      isLogged: state => state.account.isLogged,
      loading: state => state.store.loading,
      oUser: state => state.account.oUser,
      bHasNoTemplate: state => state.route.meta.bHasNoTemplate,
      bHasTemplateBody: state => state.route.meta.bHasTemplateBody,
      scrolled: state => state.store.scrolled
    })
  },
  created () {
    this.$store.dispatch('cart/removeEmptyItemCart')
    this.verifyLocalStorage()
    if (typeof mktplace !== 'undefined') {
      this.colors = 'main-color btn-color'
    }
    if (navigator.userAgent.match(/instagram/i)) {
      this.$store.state.store.isMobile = true
    }
    if (window.innerWidth < 768) {
      this.$store.state.store.isMobile = true
    }
    if (window.innerWidth > 768 && window.innerWidth < 992) {
      this.$store.state.store.isTablet = true
    }
  },
  mounted () {
    window.addEventListener('scroll', this.updateScroll)
  },
  watch: {
    info: function (n, o) {
      this.colors = 'main-color btn-color'
      this.sColorFront = this.mountCssMain(n.colors.front_1, n.colors.front_2)
      this.google_auth_key = n.google_auth_key
      this.order_without_registration = n.order_without_registration
      this.isOpen = n.working_time.is_open
      this.verifyAndShowBanner(n)
      this.verifyAndShowBannerHome(n)
      this.verifyHttps(n)
      this.addMarketing(n)
      this.setCssMain(this.sColorFront)
    },

    // Ações na troca de rota
    $route (to, from) {
      if (to.name === 'homeCatalog') {
        this.bCountBanner = 599
      }
      if (to.name === 'home') {
        this.bCountBannerHome = 599
      }
    }
  }
}
</script>

<style>
    @import 'assets/css/external/bootstrap.min.css';
    @import 'assets/css/external/fontawesome-all.css';
    @import 'assets/css/vitto/help.css';
    @import 'assets/css/vitto/layout.css';
    @import 'assets/css/vitto/forms.css';
    @import 'assets/css/vitto/style.css';

    #js-header {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        transition: height 0.3s;
    }

    #js-header.smaller {
        height: 30px;
    }

    #js-header.smaller.bg-restaurant {
        background: '#000 !important'
    }

</style>
