<template>
  <!-- Conteúdo -->
  <div>
    <div class="h-min-100 d-flex align-items-center text-center ">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <h1 class=" fs-4 mb-30">Realizando a confirmação de email...</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- FIM Conteúdo -->
</template>
<script>
import apiAccount from '@/api/account'
import router from '@/router'
import { showAlert } from '@/plugins/alerts'
import { setXUserKey } from '@/plugins/account'
export default {
  data () {
    return {
      oAccount: {
        code: ''
      }
    }
  },

  mounted () {
    this.oAccount.code = this.$route.query.code
    this.confirm()
  },

  methods: {

    confirm: function () {
      if (this.oAccount.code === '') {
        showAlert('error', 'URL inválida! Não foi possível localizar o código de confirmação', 5000)
        return false
      }
      apiAccount.ConfirmEmail(this.oAccount).then(function (response) {
        let aData = response.data

        const isError = !aData.success
        const messageType = isError ? 'error' : 'success'
        const messageTime = messageType === 'success' ? 5000 : 10000
        let message = ''

        if (isError) {
          const defaultMessage = 'Não foi possível realizar a confirmação de e-mail'
          message = (aData.errors && aData.errors.length > 0) ? aData.errors[0] : defaultMessage
        } else {
          if (aData.data && aData.data['x-user-key']) {
            setXUserKey(aData.data['x-user-key'])
          }
          message = aData.data.message
        }
        showAlert(messageType, message, messageTime)

        router.push({ name: 'homeCatalog' })
      })
    }
  }
}
</script>

<style scoped>

h1 {
  text-transform: uppercase;
  font-weight: 900;
}
</style>
