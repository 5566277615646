<template>
  <div class="container main" @keyup.f2.prevent="clearCart">
    <div class="row">
      <div class="col-lg-8">
        <vtto-main-menu />
        <router-view />
      </div>
      <div class="col-lg-4 d-none d-lg-block">
        <vtto-sidebar :apps="info.apps" :social="info.social" :payments="info.payments" :loyalty="info.loyalty" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VttoMainMenu from '@/components/VttoMainMenu'
import VttoSidebar from '@/components/VttoSidebar'

export default {
  created () {
    this.$scrollTo('#app')
  },
  components: {
    VttoMainMenu,
    VttoSidebar
  },
  methods: {
    clearCart () {
      this.$store.dispatch('cart/cleanCart')
    }
  },
  beforeCreate () {
    this.$store.dispatch('cart/cleanCurrentItem')
    this.$store.dispatch('catalog/setBtnMode', 1)
    this.$store.dispatch('cart/setSteps', [])
    this.$store.dispatch('order/cleanCoupon')
  },
  computed: mapState({
    info: state => state.store.info,
    isLogged: state => state.store.isLogged,
    catalog: state => state.catalog.catalog
  })
}
</script>
