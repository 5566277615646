<template>
  <div>
    <div id="error" :class="{'top-0': sError, 'alert-closed': !sError}" class="bg-danger color-white text-center text-uppercase fixed w-100 fw-900 fs-m py-15 z-10000 alert">{{sError}}</div>
    <div id="success" :class="{'top-0': sSuccess, 'alert-closed': !sSuccess}" class="bg-sucess color-white text-center text-uppercase fixed w-100 fw-900 fs-m py-15 z-10000 alert">{{sSuccess}}</div>
    <div id="warning" :class="{'top-0': sWarning, 'alert-closed': !sWarning}" class="bg-alert color-white text-center text-uppercase fixed w-100 fw-900 fs-m py-15 z-10000 alert">{{sWarning}}</div>
    <div id="fixed" :class="{'top-0': sFixed, 'alert-closed': !sFixed}" class="alert-static bg-danger color-white text-center text-uppercase relative w-100 fw-900 fs-m py-15 z-10000 alert">{{sFixed}}</div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'vtto-alerts',
  computed: mapState({
    sSuccess: state => state.messaging.sSuccess,
    sError: state => state.messaging.sError,
    sWarning: state => state.messaging.sWarning,
    sFixed: state => state.messaging.sFixed
  })
}
</script>
