<template>
  <div>
    <vtto-catalog-item-product :steps="nextStep" :images-mobile="imagesMobile" :product="productData"
                              v-if="product.product_name"/>
    <!-- Somente meio a meio -->
    <vtto-catalog-item-size :size-id="sizeId" :images-mobile="imagesMobile" :steps="nextStep" v-else :index="index"
                            :product="product"/>
  </div>
</template>

<script>
import VttoCatalogItemSize from '@/components/Catalog/VttoCategoryItemSize'
import VttoCatalogItemProduct from '@/components/Catalog/VttoCategoryItemProduct'

export default {
  name: 'vtto-catalog-item',
  components: {
    VttoCatalogItemSize,
    VttoCatalogItemProduct
  },
  props: {
    product: {},
    index: {},
    sizeId: {},
    categoryType: {},
    categoryId: {
      type: Number,
      default: 0
    },
    category: {},
    confirm: {
      default: () => {
        return false
      }
    },
    imagesMobile: {},
    item: {}
  },
  created () {
  },
  computed: {
    productData: function () {
      if (this.product.product_name) {
        const data = {
          id: this.index,
          category: this.category,
          category_type: this.categoryType,
          category_id: this.categoryId,
          product_id: this.product.product_id,
          product_name: this.product.product_name,
          product_image: this.product.product_image,
          product_description: this.product.product_description,
          product_ingredients: this.product.product_ingredients,
          product_sizes: this.product.product_sizes,
          product_steps: this.product.product_steps,
          enabled_ingredient: this.product.enabled_ingredient,
          prices: this.minorPrice(this.product.product_sizes)
        }
        return data
      }

      return this.product
    },
    newPrices: function () {
      return this.$store.state.cart.currentItemPrices
    }
  },
  methods: {
    nextStep (type, product) {
      /**
       * type: 1 - product
       * type: 2 - size (sempre meio a meio)
       */
      let currentItem = {}
      this.$store.dispatch('catalog/setBtnMode', 1)
      if (type === 1) {
        currentItem.category_name = this.category.category
        currentItem.subcategory_name = this.category.subcategory
        currentItem.category_id = this.categoryId
        currentItem.category_type = this.categoryType
        currentItem.product_id = product.product_id
        currentItem.product_name = product.product_name
        currentItem.product_image = product.product_image
        currentItem.product_description = product.product_description
        currentItem.product_sizes = product.product_sizes
        currentItem.product_quantity = 1
        currentItem.enabled_ingredient = product.enabled_ingredient

        let nextPage = 'catalogConfirm'

        /** para pular tela de tamanho */
        if (product.product_sizes && product.product_sizes.length === 1) {
          nextPage = 'detail'
          currentItem.size_id = product.product_sizes[0].size_id
          currentItem.size_name = product.product_sizes[0].size_name
          currentItem.size_amount = product.product_sizes[0].size_amount
          currentItem.size_amount_promo = product.product_sizes[0].size_amount_promo
          currentItem.size_amount_loyalty = product.product_sizes[0].size_amount_loyalty
          currentItem.size_edge_allowed = product.product_sizes[0].size_edge_allowed
          currentItem.allow_loyalty_payment = product.product_sizes[0].allow_loyalty_payment

          currentItem.product_prices = {
            simple: {
              amount: product.product_sizes[0].size_amount,
              amount_promo: product.product_sizes[0].size_amount_promo,
              amount_loyalty: product.product_sizes[0].size_amount_loyalty
            }
          }

          let newPrices = this.newPrices
          if (!newPrices.products) {
            newPrices = { products: [] }
          }

          newPrices.products[0] = {
            price: product.product_sizes[0].size_amount,
            promo: product.product_sizes[0].size_amount_promo,
            loyalty: product.product_sizes[0].size_amount_loyalty
          }

          // this.$emit('set-prices', newPrices)
          this.$store.dispatch('cart/setPrices', newPrices)

          if (product.product_sizes[0].info_extra) {
            currentItem.info_extra = product.product_sizes[0].info_extra
          }

          if (currentItem.size_edge_allowed) {
            currentItem.size_edges = product.product_sizes[0].size_edges
          }

          if (product.product_sizes[0].ingredients_extra) {
            currentItem.ingredients_extra_choice = product.product_sizes[0].ingredients_extra
          }

          delete currentItem.product_sizes
        }

        if (product.category_type === 3) {
          // currentItem.product_steps = product.product_steps
          currentItem.type = 3
          this.$store.dispatch('cart/setProduct', currentItem)
          return this.$router.push({ name: 'select', params: { product: product } })
          // return
        }

        if (product.product_steps && product.product_sizes) {
          currentItem.type = 2
          if (product.product_sizes.length === 1) {
            nextPage = 'select'
          }

          currentItem.product_steps = null
          currentItem.product_ingredients = product.product_ingredients

          if (product.product_sizes[0].size_edge_allowed && product.product_sizes.length === 1) {
            currentItem.size_edge_allowed = product.product_sizes[0].size_edge_allowed
            nextPage = 'detail'
          }

          this.$store.dispatch('cart/setProduct', currentItem)
          return this.$router.push({ name: nextPage, params: { product: product } })
        }

        if (product.product_ingredients) {
          currentItem.product_ingredients = product.product_ingredients
        }

        /** save temporary object cart and go to next page sending original object to params */
        this.$store.dispatch('cart/setProduct', currentItem)
        return this.$router.push({ name: nextPage, params: { product: { ...product } } })
      }

      if (type === 2) {
        let nextPage = 'catalogConfirm'
        currentItem.category_name = this.category.category
        currentItem.subcategory_name = this.category.subcategory
        currentItem.category_id = this.categoryId
        currentItem.category_type = this.categoryType
        currentItem.product_id = product.product_id
        currentItem.product_name = product.product_name
        currentItem.product_quantity = 1
        currentItem.size_id = product.size_id
        currentItem.size_edge_allowed = false
        currentItem.enabled_ingredient = product.enabled_ingredient
        if (product.size_max_products === 1) {
          currentItem.qty_flavors = 1
          currentItem.type = 2
          nextPage = 'select'
        }

        for (let i in product.products) {
          for (let y in product.products[i].product_sizes) {
            if (product.products[i].product_sizes[y].size_id === product.size_id) {
              currentItem.size_edge_allowed = product.products[i].product_sizes[y].size_edge_allowed
            }
          }
        }

        // descobre o nome do tamanho selecionado
        for (let i in product.products[0].product_sizes) {
          if (parseInt(product.products[0].product_sizes[i].size_id) === product.size_id) {
            currentItem.size_name = product.products[0].product_sizes[i].size_name
          }
        }

        this.$store.dispatch('cart/setProduct', currentItem)
        return this.$router.push({ name: nextPage, params: { product: { ...product }, category: { ...this.item } } })
      }
    },
    goNextStep (routeName, params) {
      return this.$router.push({ name: routeName, params: params.params })
    },
    minorPrice (sizes) {
      let minorPrice = 0
      let minorLoyalty = 0
      let promo = 0
      if (sizes) {
        Object.entries(sizes).forEach(([index, value]) => {
          let amount = parseFloat(value.size_amount)

          if (amount < minorPrice || minorPrice === 0) {
            minorPrice = amount
            promo = parseFloat(value.size_amount_promo)
          }

          if (parseFloat(value.size_amount_loyalty) < minorLoyalty || minorLoyalty === 0) {
            minorLoyalty = parseFloat(value.size_amount_loyalty)
          }
        })
      }
      return {
        minorPrice: minorPrice,
        minorLoyalty: minorLoyalty,
        promo: promo
      }
    },
    minorPriceSize (size) {
      let minorPrice = 0
      let minorLoyalty = 0
      let promo = 0
      size.map(a => {
        a.product_sizes.map(b => {
          let amount = parseFloat(b.size_amount)
          if (amount < minorPrice || minorPrice === 0) {
            minorPrice = amount
            promo = parseFloat(b.size_amount_promo)
          }

          if (parseFloat(b.size_amount_loyalty) < minorLoyalty || minorLoyalty === 0) {
            minorLoyalty = parseFloat(b.size_amount_loyalty)
          }
        })
      })
      return {
        minorPrice: minorPrice,
        minorLoyalty: minorLoyalty,
        promo: promo
      }
    }
  }
}
</script>
