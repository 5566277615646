<template>
    <li class="media m-0 d-flex justify-between p-20" :class="item === `item-${product.id}-${product.product_name}` ? 'bg-light-gray' : ''">
      <div class="form-group-radio_group link-produc w-100" @click="internalSteps(); selectItem(`item-${product.id}-${product.product_name}`)">
        <span class="fw-500 fs-m number" :class="item === `item-${product.id}-${product.product_name}` ? 'fw-700 color-main' : ''">{{ product.product_name }}</span>
        <p class="fw-300 fs-1-2 mt-5">{{ capitalizeFirstLetter(productDescription) }}</p>
        <vtto-catalog-item-price v-if="product.prices && !isCombo" :prices="product.prices"/>
      </div>
      <a v-if="product.product_image && imagesMobile" class="img-catalog ml-15 mt-5" data-type="product-image" :class="classImage()">
        <img loading="lazy" class="img-fluid br-5" :src="product.product_image" @click="openImage">
      </a>
    </li>
</template>

<script>
import VttoCatalogItemPrice from '@/components/Catalog/VttoCategoryItemPrice'

export default {
  components: {
    VttoCatalogItemPrice
  },
  created () {
    /* Este cara está destruindo o tempo de resposta na tela de produtos de meio a meio. */
    /* Estou comentando para ter certeza que não afeta outras áreas do front. */
    // this.$store.dispatch('catalog/buttonSteps', false)
  },
  props: {
    product: {},
    prices: {},
    index: {},
    step: {},
    end: {},
    imagesMobile: {},
    isCombo: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      description: '',
      isChecked: false,
      item: ''
    }
  },
  computed: {
    productDescription: function () {
      if (this.product.product_description === '') {
        let ingredients = []
        for (let i in this.product.product_ingredients) {
          ingredients.push(this.product.product_ingredients[i].ingredient_name)
        }
        return ingredients.join(', ')
      } else {
        return this.product.product_description
      }
    }
  },
  methods: {
    openImage () {
      const params = {
        type: 'zoom',
        title: this.product.product_name,
        imageUrl: this.product.product_image,
        onSelectProduct: () => {
          this.internalSteps()
        }
      }
      this.$modal.show(params)
    },
    classImage () {
      if (this.imagesMobile) {
        return 'img-catalog mr-15'
      }
      return 'img-catalog mr-15 d-none d-lg-block'
    },
    internalSteps () {
      this.isChecked = 'checked'
      this.$emit('select-product', this.product)
    },
    selectItem (id) {
      this.item = id
      this.$eventHub.$emit('categoryItemSize', id)
      this.$eventHub.$on('categoryItemSize', categoryItemSize => {
        this.item = categoryItemSize
      })
      this.$store.dispatch('catalog/buttonSteps', true)
    },
    capitalizeFirstLetter (string) {
      string = string.toLowerCase()
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  },
  watch: {
    step: function () {
      this.item = {}
    }
  }
}
</script>
