<template>
    <div class="d-flex align-center justify-between mt-10">
      <div>
        <p v-if="!prices.minor && prices.promo"><span class="text-line">de {{ prices.price | money }}</span> por</p>
        <p v-if="prices.minor ">a partir de</p>
        <p class="price fs-m color-main">
          <span v-if="prices.promo">{{ prices.promo | money }}</span>
          <span v-if="!prices.promo">{{ prices.price | money }}</span>
          <span v-if="printLoyalty()" class="loyalty color-black o-80 fs-0-7 ml-5">ou {{ prices.loyalty }} pts</span>
        </p>
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'vtto-category-item-price',
  props: {
    prices: {}
  },

  created () {
  },

  data () {
    return {
      // prices: {
      //   price: 10,
      //   promo: 5,
      //   loyalty: 0,
      //   minor: true
      // }
    }
  },

  methods: {
    printLoyalty () {
      if (this.prices.loyalty <= 0) return false
      var hide = 0
      if (this.loyalty) {
        hide = parseInt(this.loyalty.hide_loyalty_front_products) || 0
        if (hide === 1 && !this.loyalty.allow_use_today) return false
      }
      return true
    }
  },

  computed: mapState({
    loyalty: state => state.store.info.loyalty
  })
}
</script>
