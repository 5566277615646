<template>
    <div>
      <vtto-search v-if="step.step_products" :catalog="step.step_products" @search="setFilteredProducts"></vtto-search>

        <div class="bg-white p-15 bb-1-gray mt-15" :class="{'m-0': step.step_products}">
            <span class="text-uppercase fs-s fw-900">Mínimo: {{ step.step_min_items }} / Máximo: {{
                step.step_max_items }}</span>
            - <span class="text-uppercase fs-s fw-700">Selecionados: {{ sumQtySelected }}</span>
        </div>
        <ul class="list-unstyled bg-white" v-if="step.step_ingredients">
            <li class="p-15 bb-1-gray media m-0 flex-center" v-for="(ingredient, index) in
            orderBy(step.step_ingredients, 'ingredient_name')"
                :key="index">
                <div class="btn-numbers d-flex justify-content-between flex-center fs-s">
                    <vtto-btn-qty min="0" :id="id" :max="max" :elem="ingredient" init-qty="0" v-model="qty"
                                  @quantity-click="addIngredient"/>
                </div>
                <div class="media-body ml-15 fs-m">
                    {{ ingredient.ingredient_name }} {{ ingredientPrice(ingredient) }}
                </div>
            </li>
        </ul>

        <!-- PRODUTOS -->
        <div v-if="getProducts.length > 0">
            <ul class="list-unstyled bg-white">
                <li class="p-15 bb-1-gray media m-0 flex-center" v-for="(product, index) in getProducts"
                    :key="index">
                    <div class="btn-numbers d-flex justify-content-between flex-center fs-s">
                        <vtto-btn-qty min="0" init-qty="0" :max="max" :elem="product" v-model="qty"
                                      @quantity-click="addProduct"/>
                    </div>
                    <div class="media-body ml-15 fs-m">
                        {{ product.product_name }}
                        <p class="fs-0-8 mt-10 fw-300">{{ description(product) }}</p>
                    </div>
                </li>
            </ul>
        </div>
        <div v-else>
          <div v-if="step.step_products" class="bg-white p-20 fs-1-2">
            <p>Nenhum produto foi encontrado!</p>
          </div>
        </div>

        <!-- INGREDIENTES EXTRAS DA ETAPA DE MONTAGEM -->
        <div v-if="step.ingredients_extra" class="relative" :class="{'o-30': !sumQtySelected}">
          <div v-if="!sumQtySelected" class="absolute top-0 left-0 w-100 h-100" style="z-index:1"></div>
            <div class="bg-white p-15 mt-15 bb-1-gray">
                <span class="text-uppercase fs-s fw-900">Ingredientes Extras</span>
            </div>
            <ul class="list-unstyled bg-white">
                <li class="p-15 bb-1-gray media m-0 flex-center" v-for="(ingredient, index) in step.ingredients_extra"
                    :key="index">
                    <div class="btn-numbers d-flex justify-content-between flex-center fs-s">
                        <vtto-btn-qty min="0" init-qty="0" :elem="ingredient" :name="ingredient.ingredient_name"
                                      v-model="qtyExtra"
                                      @quantity-click="addIngredientExtra"/>
                    </div>
                    <div class="media-body ml-15 fs-m">
                        {{ ingredient.ingredient_name }} {{ ingredientPrice(ingredient) }}
                    </div>
                </li>
            </ul>
        </div>
        <!-- INFORMAÇÃO EXTRA DA ETAPA DE MONTAGEM -->
        <div v-if="step.info_extra" class="relative" :class="{'o-30': !sumQtySelected}">
          <div v-if="!sumQtySelected" class="absolute top-0 left-0 w-100 h-100" style="z-index:1"></div>
            <vtto-step-info-extra-mounting @set-info-extra="setInfoExtraMounting"
                                  :info-extra="step.info_extra[0]"
                                  :size-id="sizeId"/>
        </div>

    </div>
</template>

<script>
import VttoStepInfoExtraMounting from '@/components/Catalog/new/VttoStepInfoExtra'
import VttoBtnQty from '@/components/VttoBtnQty'
import VttoSearch from '@/components/VttoSearch'
import Vue2Filters from 'vue2-filters'

export default {
  mixins: [Vue2Filters.mixin],
  components: {
    VttoStepInfoExtraMounting,
    VttoBtnQty,
    VttoSearch
  },
  props: {
    id: {},
    step: {},
    sizePosition: {},
    productIngredients: {},
    sizeId: {}
  },
  data () {
    return {
      qty: 0,
      qtyExtra: 0,
      max: 0,
      selectedItems: {},
      selectedItemsExtra: [],
      sumQtySelected: 0,
      stepResult: {},
      minIngredients: 0,
      getProducts: [],
      ingredientOrProduct: null
    }
  },
  created () {
    this.processAmountIngredient()
    this.max = this.step.step_max_items
    this.minIngredients = this.step.step_min_items
    this.stepResult = { ...this.step }
    delete this.stepResult.step_ingredients
    delete this.stepResult.ingredients_extra
    this.$emit('ingredient-click', this.stepResult)
  },
  watch: {
    step: function () {
      this.processAmountIngredient()
      this.sumQtySelected = 0
      this.selectedItems = {}
      this.selectedItemsExtra = []
      this.minIngredients = this.step.step_min_items
      this.stepResult = { ...this.step }
      this.max = this.step.step_max_items
      this.qtyExtra = 0
      delete this.stepResult.step_ingredients
      delete this.stepResult.ingredients_extra
      this.$emit('ingredient-click', this.stepResult)
    },
    sumQtySelected: function (sumQty) {
      if (sumQty === this.step.step_max_items) {
        this.max = 0
      } else {
        this.max = this.step.step_max_items
      }
    }
  },
  updated () {
    if (this.ingredientOrProduct === 'product') {
      this.calculateEnableByProduct()
    }
    if (this.ingredientOrProduct === 'ingredient') {
      this.calculateEnableByIngredient()
    }
  },
  methods: {
    calculateEnableByProduct () {
      let sum = 0

      this.stepResult.step_products = []
      for (let i in this.selectedItems) {
        sum += this.selectedItems[i].product_quantity
        this.stepResult.step_products.push(this.selectedItems[i])
      }

      this.sumQtySelected = sum
      let btnEnable = false

      if (this.sumQtySelected >= this.minIngredients) {
        btnEnable = true
      }

      this.$emit('enable-btn', btnEnable)
    },
    calculateEnableByIngredient () {
      let sum = 0

      this.stepResult.step_ingredients = {}
      for (let i in this.selectedItems) {
        sum += this.selectedItems[i].ingredient_quantity

        if (typeof this.selectedItems[i].ingredient_amount !== 'undefined') {
          if (typeof this.selectedItems[i].ingredient_amount[Object.keys(this.selectedItems[i].ingredient_amount)[0]] !== 'undefined') {
            this.selectedItems[i].ingredient_amount = this.selectedItems[i].ingredient_quantity *
              parseFloat(this.selectedItems[i].ingredient_amount[Object.keys(this.selectedItems[i].ingredient_amount)[0]])
          }
        }
        this.stepResult.step_ingredients[this.selectedItems[i].ingredient_id] = this.selectedItems[i]
      }

      this.sumQtySelected = sum
      let btnEnable = false
      if (this.sumQtySelected >= this.minIngredients) {
        /* VERIFICA SE O PRODUTO TEM INFORMAÇÃO EXTRA E SE SETA O BOTÃO PRÓXIMO
        PARA DISABLED (VAI ESPERAR O CLIENTE SELECIONAR UMA OPÇÃO) */
        if (this.stepResult.info_extra && this.sumQtySelected > 0) {
          if (typeof this.stepResult.info_extra_option_id !== 'undefined') {
            btnEnable = true
          } else {
            btnEnable = false
          }
        } else {
          btnEnable = true
        }
      }

      this.$emit('enable-btn', btnEnable)
    },
    processAmountIngredient () {
      for (let i in this.step.step_ingredients) {
        let amount = 0
        if (this.step.step_ingredients[i].ingredient_amount.length > 1) {
          amount = this.step.step_ingredients[i].ingredient_amount[this.sizePosition]
        } else {
          if (this.step.step_ingredients[i].ingredient_amount[0]) {
            amount = this.step.step_ingredients[i].ingredient_amount[0]
          } else {
            amount = this.step.step_ingredients[i].ingredient_amount
          }
        }

        this.step.step_ingredients[i].ingredient_amount = amount
      }
    },
    description (product) {
      if (product.product_description) {
        return product.product_description
      }
      let ingredients = []
      for (let i in product.product_ingredients) {
        ingredients[i] = product.product_ingredients[i].ingredient_name
      }

      return ingredients.join(', ')
    },
    money (value, prefix = 'R$') {
      value = parseFloat(value).toFixed(2).replace('.', ',')
      return `${prefix} ${value}`
    },
    ingredientPrice (ingredient) {
      let amount = ''
      if (typeof ingredient.ingredient_amount === 'object') {
        if (typeof ingredient.ingredient_amount[this.sizeId] !== 'undefined' &&
        parseFloat(ingredient.ingredient_amount[this.sizeId]) > 0) {
          amount = ` (+ ${this.money(ingredient.ingredient_amount[this.sizeId])})`
        } else if (typeof ingredient.ingredient_amount[Object.keys(ingredient.ingredient_amount)] !== 'undefined' &&
        parseFloat(ingredient.ingredient_amount[Object.keys(ingredient.ingredient_amount)]) > 0) {
          amount = ` (+ ${this.money(ingredient.ingredient_amount[Object.keys(ingredient.ingredient_amount)])})`
        }
      }
      return amount
    },
    addIngredient (ingredient) {
      this.ingredientOrProduct = 'ingredient'
      const qty = { ingredient_quantity: ingredient.qty }
      const priceBySize = { ingredient_amount: ingredient.elem.ingredient_amount[this.sizeId] }
      if (ingredient.qty === 0) {
        if (this.selectedItems[ingredient.elem.ingredient_id]) {
          delete this.selectedItems[ingredient.elem.ingredient_id]
        }
      } else {
        this.selectedItems[ingredient.elem.ingredient_id] = { ...qty, ...ingredient.elem, ...priceBySize }
      }

      this.calculateEnableByIngredient()
      this.$emit('ingredient-click', this.stepResult)
    },
    addIngredientExtra (ingredient) {
      const qty = { ingredient_quantity: ingredient.qty }
      if (ingredient.qty === 0) {
        if (this.selectedItemsExtra[ingredient.elem.ingredient_id]) {
          this.selectedItemsExtra.splice(ingredient.elem.ingredient_id, 1)
        }
      } else {
        this.selectedItemsExtra[ingredient.elem.ingredient_id] = { ...qty, ...ingredient.elem }
        this.selectedItemsExtra[ingredient.elem.ingredient_id].ingredient_amount = ingredient.elem.ingredient_amount[this.sizeId]
      }

      this.stepResult.ingredients_extra = []
      for (let i in this.selectedItemsExtra) {
        if (typeof this.selectedItemsExtra[i].ingredient_amount[Object.keys(this.selectedItemsExtra[i].ingredient_amount)[0]] === 'number') {
          this.selectedItemsExtra[i].ingredient_amount = this.selectedItemsExtra[i].ingredient_amount[Object.keys(this.selectedItemsExtra[i].ingredient_amount)[0]]
        }
        this.stepResult.ingredients_extra.push(this.selectedItemsExtra[i])
      }

      this.$emit('ingredient-click', this.stepResult)
    },
    addProduct (product) {
      this.ingredientOrProduct = 'product'
      const qty = { product_quantity: product.qty }

      if (product.qty === 0) {
        if (this.selectedItems[product.elem.product_id]) {
          delete this.selectedItems[product.elem.product_id]
        }
      } else {
        this.selectedItems[product.elem.product_id] = { ...qty, ...product.elem }
      }

      this.calculateEnableByProduct()
      this.$emit('ingredient-click', this.stepResult)
    },
    setFilteredProducts (products) {
      this.getProducts = products
    },
    setInfoExtraMounting (info) {
      this.stepResult.info_extra_option_id = info
      this.stepResult.size_id = this.sizeId
      this.stepResult.info_extra_option_amount = 0

      // PEGA O VALOR DA INFO_EXTRA SELECIONADA E ENVIA JUNTO
      let infoExtra = this.stepResult.info_extra[0]
      for (let i in infoExtra.info_extra_options) {
        if (infoExtra.info_extra_options[i].option_id === info) {
          this.stepResult.info_extra_option_amount = parseFloat(infoExtra.info_extra_options[i].option_amount[this.stepResult.size_id])
        }
      }
      this.$emit('ingredient-click', this.stepResult)
      this.$emit('enable-btn', true)
    }
  }
}
</script>
