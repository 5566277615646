<template>
    <div v-if="apps.ios || apps.android">
        <div class="row">
            <div class="col-12">
                <div class="bg-white d-block mb-20">
                    <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Baixe nosso Aplicativo</h3>
                    <a v-if="apps.ios" :href="apps.ios" target="_blank" class="media p-15 bb-1-gray">
                        <i class="fab fa-apple fs-3 mr-10"></i>
                        <div class="media-body lh-1-4">
                            <i class="fs-s fw-700"> Disponível na</i><br/>
                            <span class="fw-900 fs-l">App Store</span>
                        </div>
                    </a>
                    <a v-if="apps.android" :href="apps.android" target="_blank" class="media p-15 bb-1-gray">
                        <i class="fab fa-android fs-3 mr-10"></i>
                        <div class="media-body lh-1-4">
                            <i class="fs-s fw-700"> Disponível no</i><br/>
                            <span class="fw-900 fs-l">Google Play</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'vtto-apps',
  props: {
    apps: {
      type: Object,
      default: function () {
        return {
          android: '',
          ios: ''
        }
      }
    }
  }
}
</script>
