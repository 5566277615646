<template>
  <div>
    <div class="row">
        <div class="col-12">
            <div class="bg-white d-block mb-20">
                <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Formas de Pagamento</h3>
                <ul class="list-inline p-15 bb-1-gray d-block" v-for="(payment, index) in payments" :key="index">
                    <h4 class="fw-900 text-uppercase pb-15 d-block fs-s">{{ payment.type_name == 16 ? 'Pagamento Online' : payment.type_name }}</h4>
                    <li class="list-inline-item" v-for="(method, index) in payment.methods" :key="index">
                        <img loading="lazy" width="30" height="20" data-toggle="tooltip" class="forma-pagamento" :data-id="method.method_id" :alt="method.method_name" :title="method.method_name" :src="getUrl(method.method_image)" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'vtto-payments',
  props: {
    payments: {}
  },
  methods: {
    getUrl (sImage) {
      if (sImage === null || sImage === '' || sImage === undefined) {
        sImage = 'card-default.png'
      }

      return `https://vittocdn.com.br/assets/images/admin/cards/${sImage}`
    }
  }
}
</script>
