<template>
<div></div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  mounted: function () {
    this.verifyBlocked(this.info)
  },
  name: 'vitto-home',
  methods: {
    verifyBlocked: function (infos) {
      let self = this

      if (infos.status) {
        if (infos.status === 'blocked' || infos.status === 'canceled') {
          self.$router.push({ name: 'coming' })
        } else {
          if (infos.units_count) {
            switch (infos.units_count) {
              case 'none':
                self.$router.push({ name: 'homeCatalog' })
                break
              default:
                self.$router.push({ name: 'home' })
                break
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapState({
      info: state => state.store.info
    })
  },
  watch: {
    info: function (n, o) {
      this.verifyBlocked(n)
    }
  }
}
</script>
