<template>
  <div class="col-12 p-15 bg-light-gray mt-15 bb-1-gray py-xs-10 d-flex align-center justify-between">
      <input v-focus @keyup="init()" v-model="term" name="input-search" type="text" class="input-search form-control border-0 fs-s p-xs-0 bg-light-gray px-xs-5 fs-1-4" placeholder="Procure por um produto ou ingrediente." />
      <!-- DESKTOP -->
      <button v-if="term.length == 0" class="btn btn-primary text-center text-uppercase d-none d-lg-block"><i class="fas fa-search" aria-hidden="true"></i></button>
      <button v-else @click="term = ''; init(true)"  class="btn btn-primary text-center text-uppercase d-none d-lg-block"><i class="fas fa-times" aria-hidden="true"></i></button>
      <!-- MOBILE -->
      <a v-if="term.length == 0" class="btn-search text-center text-uppercase d-block d-lg-none bg-light-gray color-main fs-1-6"><i class="fas fa-search" aria-hidden="true"></i></a>
      <a v-else @click="term = ''; init(true)" class="btn-search text-center text-uppercase d-block d-lg-none bg-light-gray color-main fs-1-6"><i class="fas fa-times" aria-hidden="true"></i></a>
  </div>
</template>

<script>
export default {
  name: 'vtto-search',
  timers: {
    search: { time: 700, autostart: false }
  },
  props: {
    catalog: {
      default: ''
    }
  },
  data () {
    return {
      term: ''
    }
  },
  watch: {
    catalog: function () {
      if (typeof this.catalog === 'object') {
        this.catalogFilter(this.catalog)
      }
    }
  },
  mounted () {
    this.catalogFilter(this.catalog)
    // this.$refs.search.focus()
  },
  methods: {
    updateProduct (product, category) {
      let categoryEdges = category.edges ? category.edges : []
      let categoryExtras = category.extras ? category.extras : []
      for (let y in product.product_sizes) {
        let iSizeId = product.product_sizes[y].size_id
        product.product_sizes[y].size_edges = categoryEdges[iSizeId] ? categoryEdges[iSizeId] : []
        product.product_sizes[y].ingredients_extra = categoryExtras[iSizeId] ? categoryExtras[iSizeId] : []
      }

      return product
    },
    init (isbutton) {
      if (!isbutton) {
        this.$timer.stop('search')
        this.$timer.start('search')
        return
      }
      this.catalogFilter(this.catalog)
    },
    search () {
      this.catalogFilter(this.catalog)
    },
    catalogFilter (catalog) {
      let res = []
      const term = this.term.toLowerCase()
      let getCatalogFormated = JSON.parse(JSON.stringify(catalog))

      if (term !== '') {
        if (typeof getCatalogFormated[0] !== 'undefined' && getCatalogFormated[0].categoryName) {
          for (let i in getCatalogFormated) {
            let add = false
            // category name
            const categoryName = getCatalogFormated[i].categoryName.toLowerCase()
            if (categoryName.search(term) >= 0) {
              add = true
            }

            if (getCatalogFormated[i].subCategoryName) {
              const subCategoryName = getCatalogFormated[i].subCategoryName.toLowerCase()
              if (subCategoryName.search(term) >= 0) {
                add = true
              }
            }

            /** products */
            let products = []
            for (let y in getCatalogFormated[i].products) {
              let addProduct = false
              /** product name */
              const productName = getCatalogFormated[i].products[y].product_name.toLowerCase()
              if (productName.search(term) >= 0) {
                addProduct = true
              }
              /** ingredients */
              if (addProduct === false) {
                for (let z in getCatalogFormated[i].products[y].product_ingredients) {
                  const ingredientName = getCatalogFormated[i].products[y].product_ingredients[z].ingredient_name.toLowerCase()
                  if (ingredientName.search(term) >= 0) {
                    addProduct = true
                  }
                }
              }

              if (addProduct) {
                products.push(getCatalogFormated[i].products[y])
              }
            }

            if (products.length > 0) {
              getCatalogFormated[i].products = products
              add = true
            }

            /** */
            if (add) {
              res.push(getCatalogFormated[i])
            }
          }
        } else {
          for (let i in getCatalogFormated) {
            let addProduct = false
            /** product name */
            const productName = getCatalogFormated[i].product_name.toLowerCase()
            if (productName.search(term) >= 0) {
              addProduct = true
            }
            /** ingredients */
            if (addProduct === false) {
              for (let z in getCatalogFormated[i].product_ingredients) {
                const ingredientName = getCatalogFormated[i].product_ingredients[z].ingredient_name.toLowerCase()
                if (ingredientName.search(term) >= 0) {
                  addProduct = true
                }
              }
            }

            if (addProduct) {
              res.push(getCatalogFormated[i])
            }
          }
        }

        this.$emit('search', res)
        return
      }

      this.$emit('search', catalog)
    }
  }
}
</script>
