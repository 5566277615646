<template>
  <div>
    <div class="row" v-if="sTimeInfo">
        <div class="col-12">
            <div class="bg-white d-block mb-20">
                <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Informação</h3>
                <div class="p-15 fs-s">
                    {{sTimeInfo}}
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="bg-white d-block mb-20">
                <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Horários de funcionamento</h3>
                <div v-for="time in workingTime" :key="JSON.stringify(time)" >
                  <span class="fw-400 text-uppercase p-15 d-block fs-s bb-1-gray">{{time.dow}} - {{time.times}}</span>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { getDayOfWeek } from '@/utils'
export default {
  name: 'vtto-about-time',
  data: function () {
    return {
      workingTime: []
    }
  },
  methods: {
    iDay2sDay (iDay) {
      return getDayOfWeek(iDay)
    },
    processWorkingTime () {
      for (let i in this.aDays) {
        let dow = getDayOfWeek(parseInt(i))
        let aTimes = []
        for (let j in this.aDays[i]) {
          let times = this.aDays[i][j]
          if (parseInt(times.active) === 1 && times.open && times.close) {
            let str = 'das ' + times.open + ' às ' + times.close
            aTimes.push(str)
          }
        }

        if (aTimes.length) {
          this.workingTime.push({
            dow: dow,
            times: aTimes.join(' e ')
          })
        }
      }
    }
  },
  mounted () {
    if (!this.workingTime.length) {
      this.processWorkingTime()
    }
  },
  watch: {
    aDays (v) {
      this.processWorkingTime()
    }
  },
  computed: mapState({
    aDays: state => state.store.info.working_time.days,
    sTimeInfo: state => state.store.info.working_time.description
  })
}
</script>
