import vttoService from '@/api/account'
import { formatCities } from '@/plugins/cities'
import { getXUserKey } from '@/plugins/account'
import customStorage from '@/plugins/customStorage'
import AppBridge from '@/plugins/app'

const state = {
  addressInfoList: [],
  isLogged: false,
  oUser: {},
  oUserAddresses: []
}

const getters = {
  states () {
    return state => state.addressInfoList['uf']
  },

  cities () {
    return state => state.addressInfoList['cities']
  },
  districts () {
    return state => state.addressInfoList['districts']
  }
}

const actions = {
  getListAddressInfo ({ commit }) {
    const session = sessionStorage.getItem('vtto.account.addressInfoList')

    if (session) {
      return commit('setAddressInfoList', JSON.parse(session))
    }

    vttoService.ListAddressInfo().then(res => {
      if (res && res.data && res.data.data) {
        let aInfos = res.data.data
        aInfos = formatCities(aInfos)
        sessionStorage.setItem('vtto.account.addressInfoList', JSON.stringify(aInfos))
        commit('setAddressInfoList', aInfos)
      }
    })
  },

  getUser ({ commit }, bReset) {
    /*  Somente se já tiver o token */
    let sToken = getXUserKey()
    if (!sToken) {
      AppBridge.emit('clearCustomerID', null)
      return false
    }

    if (bReset === true) {
      localStorage.removeItem('vtto.account.oUser')
      AppBridge.emit('clearCustomerID', null)
    }

    const session = customStorage().get.item('vtto.account.oUser')
    if (session) {
      commit('setUser', session)
    }

    vttoService.GetCustomer().then(res => {
      if (res && res.data && res.data.success === true) {
        let aInfos = res.data.data
        if (typeof aInfos !== 'undefined') {
          aInfos.phone = JSON.parse(aInfos.phone)
          customStorage().set('vtto.account.oUser', aInfos, true)
          AppBridge.emit('setCustomerID', aInfos.customer_id)
          commit('setUser', aInfos)
        }
      }
    })
  },

  getUserAddresses ({ commit }, bReset) {
    /*  Somente se já tiver o token */
    let sToken = getXUserKey()
    if (!sToken) {
      return false
    }

    if (bReset) {
      sessionStorage.removeItem('vtto.account.oUserAddresses')
    }

    const session = sessionStorage.getItem('vtto.account.oUserAddresses')
    if (session) {
      return commit('setUserAddresses', JSON.parse(session))
    }

    let origin = null
    if (localStorage.getItem('token')) {
      origin = 'mktplc'
    }

    vttoService.ListAddresses(origin).then(res => {
      if (res && res.data && res.data.success === true) {
        let aInfos = res.data.data
        if (typeof aInfos !== 'undefined') {
          sessionStorage.setItem('vtto.account.oUserAddresses', JSON.stringify(Object.values(aInfos), true))
          commit('setUserAddresses', aInfos)
        }
      }
    })
  },

  loyaltybalance ({ commit }, loyaltybalance) {
    commit('setLoyaltyBalance', loyaltybalance)
  },
  cleanUser ({ commit }) {
    commit('setUserAddresses', {})
    sessionStorage.removeItem('vtto.account.oUserAddresses')
    commit('setUser', {})
  },
  getIsLogged ({ commit }) {
    // const session = localStorage.getItem('vtto.account.x-user-key')
    const session = customStorage().get.item('vtto.account.x-user-key')
    if (session) {
      return commit('setIsLogged', true)
    } else {
      return commit('setIsLogged', false)
    }
  }
}

const mutations = {
  setUser (state, oUser) {
    state.oUser = oUser
  },
  setLoyaltyBalance (state, loyaltybalance) {
    let user = customStorage().get.item('vtto.account.oUser')
    user.loyaltybalance = loyaltybalance
    customStorage().set('vtto.account.oUser', user, true)
    state.oUser = user
  },
  setUserAddresses (state, oUserAddresses) {
    state.oUserAddresses = oUserAddresses
  },
  setIsLogged (state, isLogged) {
    state.isLogged = isLogged
  },
  setAddressInfoList (state, districtList) {
    state.addressInfoList = districtList
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
