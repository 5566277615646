export function formatCities (aCities) {
  let aInfosRes = aCities
  let aInfos = {
    uf: {},
    cities: {},
    districts: {}
  }

  for (let cityKey in aInfosRes) {
    let oInfo = aInfosRes[cityKey]

    /* Formatar Estados */
    aInfos.uf[oInfo.city_uf] = oInfo.city_uf

    let oTempCity = {}

    oTempCity[oInfo.city_id] = oInfo.city_name

    if (!aInfos.cities || !aInfos.cities[oInfo.city_uf]) {
      aInfos.cities[oInfo.city_uf] = {}
    }

    /* Formatar Cidades */
    Object.assign(aInfos.cities[oInfo.city_uf], oTempCity)

    /* Formatar Bairros */
    let sortedDistrictsTemp = []
    for (let districtKey in oInfo.districts) {
      sortedDistrictsTemp[districtKey] = oInfo.districts[districtKey]
    }
    sortedDistrictsTemp.sort(function (a, b) {
      return (a.district_name < b.district_name) ? -1 : (a.district_name > b.district_name) ? 1 : 0
    })
    let sortedDistricts = []
    for (let districtKey in sortedDistrictsTemp) {
      let districtInfo = sortedDistrictsTemp[districtKey]
      sortedDistricts[districtKey] = {}
      sortedDistricts[districtKey]['district_id'] = (districtInfo.district_id) ? districtInfo.district_id : ''
      sortedDistricts[districtKey]['district_name'] = (districtInfo.district_name) ? districtInfo.district_name : ''
    }
    aInfos.districts[oInfo.city_id] = sortedDistricts
  }
  return aInfos
}
