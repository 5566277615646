<template>
    <div class="bg-white d-block p-20 text-center relative">
        <div class="pb-30 color-green">
            <span class="fs-14"><i class="fas fa-paper-plane"></i></span>
            <p class="fw-900 fs-m text-uppercase mt-20">Seu pedido ainda não está confirmado</p>
            <p class="fw-400 fs-s py-10">
                Mas já foi recebido pelo nosso estabelecimento. <br>
                Enviaremos a confirmação ou cancelamento dele para o seu email,<br>
                <strong>se precisar entre em contato conosco {{sPhone}}</strong>
            </p>
            <p class="fw-400 fs-m text-uppercase">e informe o código do pedido: {{iOrderId}}</p>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'vtto-order-status-sent',
  props: {
    iOrderId: {
      type: Number,
      default: 0
    }
  },
  computed: mapState({
    sPhone: state => state.store.info.phones.phone_1
  })
}
</script>
