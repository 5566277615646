<template>
    <div class="flex-center">
        <button @click="opQty(-1)" class="qntminus color-main">-</button>
        <span class="price number">{{ qty }}</span>
        <button @click="opQty(1)" class="qntplus color-main">+</button>
    </div>
</template>

<script>
export default {
  data () {
    return {
      qty: 1
    }
  },
  model: {
    event: 'click'
  },
  props: {
    initQty: {
      default: 1
    },
    min: {
      default: null
    },
    max: {
      default: null
    },
    elem: {},
    id: {},
    name: null
  },
  created () {
    this.qty = 0
    if (this.min) {
      if (parseInt(this.min) > parseInt(this.initQty)) {
        this.qty = parseInt(this.min)
      }
    }
  },
  watch: {
    elem: function () {
      this.qty = 0
    }
  },
  methods: {
    opQty (dir) {
      let newValue = parseInt(this.qty) + dir
      if (newValue < parseInt(this.min)) {
        return
      }

      if (newValue > parseInt(this.max)) {
        if (dir > 0) {
          return
        }
      }

      if (newValue > parseInt(this.elem.ingredient_max)) {
        return
      }

      this.qty = parseInt(this.qty) + dir
      let qty = this.qty
      if (this.elem) {
        qty = { qty: this.qty, elem: this.elem, dir: dir }
      }
      this.$emit('quantity-click', qty)
    }
  }
}
</script>
