import apiCheckout from '@/api/checkout'
import router from '@/router'
import store from '@/store'
import { showAlert } from '@/plugins/alerts'

export function getOrderStatusName (iOrderStatus) {
  switch (iOrderStatus) {
    case '1':
      return 'Aberto'
    case '2':
      return 'Em produção'
    case '3':
      return 'Enviado para entrega'
    case '4':
      return 'Finalizado'
    case '5':
      return 'Cancelado'
  }
}

// validate cart foi modificado, então não vai mais funcionar
export function repeatOrder (oOrder) {
  let oCart = {
    items: oOrder.items
  }
  apiCheckout.ValidateCart(oCart).then(function (response) {
    if (!response || !response.data || response.data.success === false) {
      showAlert('error', response.data.errors[0])
      return false
    } else {
      let aData = response.data
      if (aData.data.have_errors) {
        showAlert('error', 'Atenção: não é possível repetir este pedido')
        return false
      } else {
        for (let i in aData.data.items) {
          aData.data.items[i].product_prices = {
            products: [
              {
                loyalty: 0,
                price: aData.data.items[i].product_amount,
                promo: 0
              }
            ]
          }
        }
        store.dispatch('cart/addItemsCart', aData.data.items)
        router.push({ name: 'homeCatalog' })
      }
    }
  })
}

export function setOrderInfo (sProperty, oValue) {
  let oOrderInfo = {
    sProperty: sProperty,
    oInfo: oValue
  }
  store.dispatch('order/setOrderInfo', oOrderInfo)
}
