import vttoService from '@/api/store'

const state = {
  env: '',
  isMobile: false,
  isTablet: false,
  inputFocus: false,
  footerVisible: true,
  scrolled: 0,
  carousel: 0,
  info: {
    working_time: {
      is_open: false
    }
  },
  loading: false,
  deliveryAreas: {},
  mainMenu: [
    {
      link: '/sobre/horario',
      name: 'about-time',
      class: 'color-close fw-900 fs-s text-uppercase',
      display: 'Fechado agora',
      icon: ''
    },
    {
      link: '/sobre/horario',
      name: 'about-time',
      class: 'color-open fw-900 fs-s text-uppercase',
      display: 'Aberto agora',
      icon: ''
    },
    {
      link: '/',
      name: 'homeCatalog',
      class: '',
      display: 'Home',
      icon: 'fa fa-home'
    },
    {
      link: '/sobre',
      name: 'about-index',
      class: '',
      display: 'Info',
      icon: 'icon-app icon-info'
    },
    {
      link: '/login',
      name: 'login',
      class: '',
      display: 'Login',
      icon: 'icon-app icon-login',
      logged: false
    },
    {
      link: '/meus-dados',
      name: 'account-edit',
      class: '',
      display: 'Meus dados',
      icon: 'icon-app icon-login',
      logged: true
    }
  ],
  aboutMenu: [
    {
      name: 'sobre',
      display: 'Sobre',
      link: 'about-index'
    },
    {
      name: 'entrega',
      display: 'Entrega',
      link: 'about-delivery'
    },
    {
      name: 'horário',
      display: 'Horário',
      link: 'about-time'
    }
  ],
  accountMenu: [
    {
      name: 'edit',
      display: 'Meus dados',
      link: 'account-edit'
    },
    {
      name: 'orders',
      display: 'Meus pedidos',
      link: 'account-orders'
    },
    {
      name: 'loyalty',
      display: 'Meus pontos',
      link: 'account-loyalty'
    }
  ]
}

const getters = {
  isOpen: state => {
    return state.info.working_time.is_open
  },
  info: state => {
    return state
  }
}

const actions = {
  getInfo ({ commit }) {
    const session = JSON.parse(sessionStorage.getItem('vtto.store.info'))

    if (session) {
      commit('setLoading', false)
      commit('setInfo', session)
      return session
    }

    vttoService.storeInfo().then(res => {
      if (res && res.data && res.data.success) {
        sessionStorage.setItem('vtto.store.info', JSON.stringify(res.data.data))
        commit('setLoading', false)
        commit('setInfo', res.data.data)
        return res.data.data
      } else {
        console.log(res)
      }
    })
  },
  getDeliveryAreas ({ commit }) {
    const session = JSON.parse(sessionStorage.getItem('vtto.store.deliveryAreas'))
    if (session) {
      commit('setLoading', false)
      return commit('setDeliveryAreas', session)
    }

    vttoService.storeDeliveryAreas().then(res => {
      if (res && res.data && res.data.data) {
        sessionStorage.setItem('vtto.store.deliveryAreas', JSON.stringify(res.data.data))
        commit('setLoading', false)
        return commit('setDeliveryAreas', res.data.data)
      }
    })
  },
  getIsOpen ({ commit }) {
    vttoService.storeIsOpen().then(res => {
      if (res && res.data && res.data.data && res.data.data.is_open !== getters.isOpen) {
        commit('setIsOpen', res.data.data.is_open)
      }
    })
  },
  setScroll ({ commit }) {
    commit('setScroll')
  },
  setCarousel ({ commit }, info) {
    commit('setCarousel', info)
  }
}

const mutations = {
  setIsOpen (state, info) {
    state.info.working_time.is_open = info
  },
  setInfo (state, info) {
    state.info = info
  },
  setLoading (state, show) {
    state.loading = show
  },
  setEnvName (state, env) {
    state.env = env
  },
  setDeliveryAreas (state, info) {
    state.deliveryAreas = info
  },
  setScroll (state) {
    state.scrolled = window.pageYOffset
  },
  setCarousel (state, info) {
    if (info) {
      state.carousel += info
      return state.carousel
    }
    state.carousel = 0
    return state.carousel
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
