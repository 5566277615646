<template>
  <div :class="[{'bg-gray': isMobile}, {'bg-white': !isMobile}]" class="d-block mb-20">
    <div class="bb-1-gray p-15 d-flex flex-center justify-between" :class="[{'bg-main': isMobile}, {'color-white': isMobile}]">
    <h3 class="fw-900 text-uppercase d-block fs-s">Formas de Pagamento</h3>
      <i @click="slide(false, $parent.$refs.payments); $parent.paymentOpen = false" class="fas fa-times fs-1-6 pointer d-block d-sm-none"></i>
  </div>

  <ul v-if="letBothPaymentTypes()" class="d-flex align-center w-100 bg-white nav-tabs list-table w-100 text-center fw-900 fs-s text-uppercase d-block">
    <li v-if="oInfo.gateway && oInfo.gateway.active" class="list-table-item relative pointer" :class="{'active': this.type === 0}" ><a @click="type = 0" class="p-15 d-block " :class="{'active': this.type === 0}">Pagamento Online</a></li>
    <li class="list-table-item relative pointer" :class="{'active': this.type === 1}"><a @click="type = 1" class="p-15 d-block" :class="{'active': this.type === 1}">Pagamento na entrega</a></li>
  </ul>

  <vtto-checkout-payments-online :aCards="aCards" :bPix="oInfo.gateway.pix" :bDisableCard="oInfo.gateway.disable_card" @cardToken="setCardToken" v-if="type === 0 && giftOnlinePayment" @setCard="setCard" @setPaymentPix="setPaymentPix" />

  <div v-if="type === 1 || !giftOnlinePayment" class="mb-xs-150">
    <vtto-checkout-payments-payer v-for="(oPayer, iPayer) in aPayments" :key="iPayer" :giftPayments="giftPayments" :iPayer="iPayer+1" :aPaymentTypes="aPaymentTypes" :oPayer="oPayer" v-on:paymentChanged="setPayerChanged"></vtto-checkout-payments-payer>
    <div class="bg-white d-block p-15 d-block pb-xs-20 mt-xs--15 text-center-xs">
        <button v-if="aPayments.length > 1" @click="removePayer" class="btn btn-secondary text-uppercase text-center mr-10 fs-s mx-xs-5 fs-1-xs mt-5">Remover pagante</button>
        <button @click="addPayer" class="btn btn-secondary text-uppercase text-center fs-s mx-xs-5 fs-1-xs mt-5">Adicionar pagante</button>
    </div>
  </div>
</div>
</template>
<script>
import VttoCheckoutPaymentsOnline from '@/components/Checkout/VttoCheckoutPaymentsOnline'
import VttoCheckoutPaymentsPayer from '@/components/Checkout/VttoCheckoutPaymentsPayer'
import { mapState } from 'vuex'
import { slide } from '@/utils/index'
import { setOrderInfo } from '@/plugins/orders'
import { showAlert } from '@/plugins/alerts'
import Checkout from '@/api/checkout'

export default {
  props: {
    fTotalDelivery: {
      type: Number,
      default: 0
    },
    fTotalDiscount: {
      type: [Number, String],
      default: 0
    },
    fSubtotal: {
      type: Number,
      default: 0
    },
    aCards: {},
    giftPayments: {}
  },
  components: {
    VttoCheckoutPaymentsOnline,
    VttoCheckoutPaymentsPayer
  },
  name: 'vtto-checkout-payments',
  data: function () {
    return {
      oPayment: this.clearPayment(),
      aPayments: [],
      type: 1,
      onlinepayment: 0,
      cards: [],
      sent: false
    }
  },
  mounted () {
    this.addPayer()

    if (this.oInfo && this.oInfo.gateway && this.oInfo.gateway.active) {
      this.onlinepayment = 1
      this.type = 0
      if (process.env.VUE_APP_AMBIENT_TYPE !== 'HOMOL') {
        Iugu.setAccountID(this.oInfo.gateway.token_public)
      } else {
        Iugu.setAccountID(this.oInfo.gateway.token_public)
        Iugu.setTestMode(true)
      }
    } else {
      this.checkout = null
    }
  },
  watch: {
    fTotal: function () {
      this.resetPayments()
    }
  },
  computed: {
    ...mapState({
      oOrder: state => state.order.oOrder,
      oInfo: state => state.store.info
    }),
    aPaymentTypes: function () {
      return this.$store.state.store.info.payments
    },
    fTotal: function () {
      return this.fSubtotal + this.fTotalDelivery - this.fTotalDiscount
    },
    isMobile () {
      return this.$store.state.store.isMobile
    },
    giftOnlinePayment () {
      if (!this.giftPayments) {
        return true
      }
      for (let i in this.giftPayments) {
        if (parseInt(this.giftPayments[i]) === 16) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    letBothPaymentTypes () {
      if (this.onlinepayment && this.giftOnlinePayment) {
        if (typeof Iugu !== 'undefined') {
          return true
        }
        this.type = 1
        if (!this.sent) {
          // manda IP do cliente para o slack relatando o problema
          $.get('https://api.ipify.org', function (data) {
            var info = {
              IP: data,
              window: window.location.href
            }
            Checkout.sendErrors(info)
          })
          this.sent = true
        }
      }
      return false
    },

    clearPayment: function () {
      return {
        payment_amount: 0,
        payment_type_id: 0,
        payment_method_id: 0,
        change_amount: 0,
        online_payment: {
          card_number: '',
          cvv: 0,
          cpf: ''
        }
      }
    },
    resetPayments: function () {
      let oNewPayment = this.aPayments[0]
      if (this.aPayments[0].payment_type_id !== 16) {
        oNewPayment = this.clearPayment()
      }
      oNewPayment.payment_amount = this.fTotal
      this.aPayments = [oNewPayment]
    },
    splitPayments: function () {
      let fTotal = this.fTotal
      let iCountPayments = this.aPayments.length
      let fPartialValue = 0
      let a = 0
      let b = 0
      let aux = 0
      let info = 0

      if (iCountPayments > 0) {
        fPartialValue = parseFloat(this.fTotal / iCountPayments).toFixed(2)
      }

      aux = fTotal
      fTotal = fPartialValue

      if (iCountPayments === 1) {
        this.aPayments[0].payment_amount = fTotal
      } else {
        for (let iPaymentKey in this.aPayments) {
          if (fTotal <= fPartialValue) {
            this.aPayments[iPaymentKey].payment_amount = fTotal
            a = aux * 100
            b = fPartialValue * 100
            info = iCountPayments - 1
            fTotal = (a - (b * info)) / 100
            info = parseFloat(fTotal % 2).toFixed(2)
          } else {
            if (fTotal - fPartialValue < fPartialValue) {
              this.aPayments[iPaymentKey].payment_amount = this.fTotalDelivery
              if (info >= 1) {
                info = info * 100
                fTotal = (a - info) / 100
              }
            } else {
              this.aPayments[iPaymentKey].payment_amount = fPartialValue
            }
          }
        }
      }
      setOrderInfo('payments', this.aPayments)
    },

    setPayerChanged: function (oPayer) {
      this.aPayments[oPayer.id] = oPayer
      setOrderInfo('payments', this.aPayments)
    },
    addPayer: function () {
      /* Atribui novo pagante */
      this.oPayment = this.clearPayment()
      let newPayment = Object.assign({}, this.oPayment)
      this.aPayments.push(newPayment)

      /* Redistribui valores */
      this.splitPayments()
    },
    removePayer: function () {
      this.aPayments.splice(-1, 1)
      setOrderInfo('payments', this.aPayments)

      /* Redistribui valores */
      this.splitPayments()
    },
    slide (toggle, element) {
      slide(toggle, element)
    },
    getPaymetMethod (typeId, method) {
      for (let i in this.aPaymentTypes) {
        if (this.aPaymentTypes[i].type_id === typeId) {
          for (let y in this.aPaymentTypes[i].methods) {
            let methodName = this.aPaymentTypes[i].methods[y].method_name.replace(' ', '').toLowerCase()
            if (methodName === method.toLowerCase()) {
              return this.aPaymentTypes[i].methods[y]
            }
          }
        }
      }
    },
    setCardToken (card) {
      let paymentType = this.aPaymentTypes.filter(a => {
        if (a.type_id === 16) {
          return a
        }
      })
      this.aPayments[0].payment_type_id = paymentType[0].type_id
      this.aPayments[0].payment_method_id = card.brand_id
      this.aPayments[0].onlinepayment = true
      this.aPayments[0].online_payment.card_token = card.card_token
      this.aPayments[0].online_payment.cpf = card.cpf
      this.aPayments[0].online_payment.holder_name = card.holder
      this.aPayments[0].online_payment.savedCard = true
      this.aPayments[0].isPix = false
      setOrderInfo('payments', this.aPayments)
    },
    setCard (card) {
      this.aPayments[0].payment_type_id = 0
      this.aPayments[0].payment_method_id = 0
      this.aPayments[0].isPix = false
      if (card.card_number) {
        if (!Iugu.utils.validateCreditCardNumber(card.card_number)) {
          showAlert('error', 'Número de cartão inválido! Verifique e tente novamente.')
          return
        }
        let cardtype = Iugu.utils.getBrandByCreditCardNumber(card.card_number)
        let method = this.getPaymetMethod(16, cardtype)
        if (typeof method === 'undefined') {
          showAlert('error', 'Método de pagamento ' + cardtype + ' não aceito')
        }
        this.aPayments[0].payment_type_id = 16
        this.aPayments[0].payment_method_id = method.method_id
      }
      this.aPayments[0].online_payment = card
      this.aPayments[0].onlinepayment = true
      setOrderInfo('payments', this.aPayments)
    },
    setPaymentPix () {
      this.aPayments[0].payment_type_id = 16
      let method = this.getPaymetMethod(16, 'PixOnline')
      this.aPayments[0].isPix = true
      this.aPayments[0].payment_method_id = method.method_id
      this.aPayments[0].onlinepayment = true
      setOrderInfo('payments', this.aPayments)
    }
  }
}
</script>
