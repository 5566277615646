<template>
  <div class="p-15 text-center d-block">
      <button :disabled="enable" @click="confirm = true" class="btn btn-primary text-uppercase text-center mx-auto">
          Fechar pedido
      </button>

      <!-- Confirm Address Drawer -->
      <div>
        <div class="fixed w-100 h-100 top-0 left-0 bg-black o-70" :class="{'d-none': !confirm}"></div>
        <div class="confirmation fixed w-100 left-0 bg-white p-30 fs-1-4" :class="{'open': confirm}">
          <p class="mb-10 fw-600">
            <span v-if="id != 0">Você receberá em</span>
            <span v-else>Retirar no balcão</span>
          </p>
          <p class="text-uppercase">
            <span v-if="id != 0">{{ address }}</span>
            <span v-else>{{ info.address.address }}, {{ info.address.number }} - {{ info.address.region }} - {{ info.address.city }} - {{ info.address.state }}</span>
          </p>

          <div class="mt-30 flex-center">
            <button @click="confirm = false; $emit('change-addr')" class="btn btn-secondary btn-s text-uppercase text-center mr-10">Trocar</button>
            <button @click="finishOrder(); confirm = false" class="btn btn-primary btn-s text-uppercase text-center">Confirmar</button>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import apiCheckout from '@/api/checkout'
import { setOrderInfo } from '@/plugins/orders'
import { empty, validateAddress, verifyPhone } from '../../utils'
import { showAlert } from '@/plugins/alerts'
import { setXUserKeyOrder } from '@/plugins/account'
import router from '@/router'
import { mapState, mapGetters } from 'vuex'
import store from '@/store'
import Bugsnag from '@bugsnag/js'

export default {
  name: 'vtto-checkout-finish',
  data () {
    return {
      enable: false,
      confirm: false,
      getAddress: ''
    }
  },
  props: {
    address: String,
    id: Number
  },
  created () {
    this.$eventHub.$on('btn-enabled', enable => {
      this.enable = enable
    })
  },
  computed: {
    ...mapGetters('cart', [
      'totalCart'
    ]),
    ...mapState({
      oOrder: state => state.order.oOrder,
      info: state => state.store.info
    }),
    hideZipcode: function () {
      return this.$store.state.store.info.hide_zipcode
    },
    currentDate: function () {
      const dDate = new Date()
      const month = ('0' + (dDate.getMonth() + 1)).slice(-2)
      const date = ('0' + dDate.getDate()).slice(-2)
      const year = dDate.getFullYear()
      return `${year}-${month}-${date} 00:00:00`
    },
    isMobile: function () {
      return this.$store.state.store.isMobile
    }
  },
  methods: {
    finishOrder: function () {
      var self = this
      const oOrder = this.$store.state.order.oOrder
      // fazendo uma cópia do oOrder já que modificar o original quebra o reenvio do pedido
      var oOrderCopy = JSON.parse(JSON.stringify(oOrder))
      if (oOrder.payments[0].payment_method_id === 0 || oOrder.payments[0].payment_type_id === 0) {
        showAlert('error', 'Necessario marcar a forma de pagamento!')
        return false
      }

      if (oOrder.order_without_registration == false) {
        let addresses = sessionStorage.getItem('vtto.account.oUserAddresses')
        if (addresses == null || addresses.length <= 2) {
          showAlert('error', 'Nenhum endereço cadastrado! Por favor registre ao menos 1 endereço antes de finalizar a compra.')
          return false
        }
      }
      // VALIDAÇÕES DO PEDIDO SEM CADASTRO
      if (oOrder.order_without_registration) {
        if (oOrder.payments[0].payment_type_id == 16 && empty(oOrder.email)) {
          showAlert('error', 'Para realizar pagamento online e necessário informar o email no pedido sem cadastro')
          return
        }

        if (oOrder.delivery_address.origin == 'desk' && oOrder.payments[0].payment_type_id == 16) {
          showAlert('error', 'Não e possivel realizar o pedido sem cadastro com retirada no balcão com pagamento online')
          return
        }

        let copyAddress = JSON.parse(JSON.stringify(oOrder))
        if (oOrder.delivery_address.address_id == -1 && !validateAddress(copyAddress.address, self.hideZipcode)) {
          showAlert('error', 'Não e possivel realizar o pedido sem cadastro sem informações do seu endereço')
          return
        }

        if (empty(oOrder.firstname)) {
          showAlert('error', 'Não e possivel realizar o pedido sem cadastro sem informar seu nome')
          return
        }

        if (empty(oOrder.phone)) {
          showAlert('error', 'Não e possivel realizar o pedido sem cadastro sem seu número de telefone')
          return
        }

        if (!verifyPhone(oOrder.phone)) {
          showAlert('error', 'Informe um telefone válido.')
          return
        }
      }

      oOrder.payments.forEach(pay => {
        if (pay.payment_type_id !== 3) {
          pay.change_amount = 0
        }
      })
      if (oOrder.payments[0].onlinepayment && !oOrder.payments[0].isPix) {
        if (('savedCard' in oOrderCopy.payments[0].online_payment) == false) {
          let cardData = {
            number: oOrderCopy.payments[0].online_payment.card_number.replace(/ /g, ''),
            verification_value: oOrderCopy.payments[0].online_payment.cvv,
            expirationMonth: oOrderCopy.payments[0].online_payment.validate.split('/')[0],
            expirationYear: oOrderCopy.payments[0].online_payment.validate.split('/')[1],
            first_name: oOrderCopy.payments[0].online_payment.holder_name.split(' ')[0],
            last_name: oOrderCopy.payments[0].online_payment.holder_name.split(' ')[1]
          }
          if (process.env.VUE_APP_AMBIENT_TYPE !== 'HOMOL') {
            Iugu.setAccountID(JSON.parse(sessionStorage.getItem('vtto.store.info'))['gateway']['token_public'])
          } else {
            Iugu.setAccountID(JSON.parse(sessionStorage.getItem('vtto.store.info'))['gateway']['token_public'])
            Iugu.setTestMode(true)
          }
          delete oOrderCopy.payments[0].online_payment.card_number
          delete oOrderCopy.payments[0].online_payment.cvv

          let oCard = Iugu.CreditCard(cardData.number, cardData.expirationMonth, cardData.expirationYear, cardData.first_name, cardData.last_name, cardData.verification_value)
          Iugu.createPaymentToken(oCard, function (response) {
            if (response.errors) {
              showAlert('error', 'Informações de pagamento incorretas. Por favor, verifique as informações e tente novamente')
              console.log(response.errors)
              Bugsnag.notify(response.errors)
            } else {
              oOrderCopy.payments[0].online_payment.card_token = response.id
              self.sendOrder(oOrderCopy)
            }
          })
        } else {
          self.sendOrder(oOrderCopy)
        }
      } else {
        this.sendOrder(oOrder)
      }
    },

    sendOrder (oOrder) {
      Bugsnag.leaveBreadcrumb('oOrder', oOrder)
      try {
        let memos = JSON.parse(sessionStorage.getItem('vtto.store.info'))['collect_memos']
        let hasMemo = localStorage.getItem('has_memos')
        if (memos == 1 && hasMemo == 1) {
          throw new Error('pedidos com memos aqui')
        }
      } catch (error) {
        Bugsnag.notify(error)
      }

      Bugsnag.leaveBreadcrumb('orderState', this.$store.state.order)
      Bugsnag.leaveBreadcrumb('cartState', this.$store.state.cart)
      apiCheckout.SendOrder(oOrder).then(function (response, self) {
        if (oOrder.payments[0].payment_amount == 0) {
          showAlert('error', 'Pedido não enviado :( Revise a forma de pagamento e tente novamente.')
        }
        if (response && response.data) {
          if (!response.data.errors || response.data.errors.length == 0) {
            let aData = response.data
            if (localStorage.getItem('token')) {
              let data = {
                order_id: aData.data.order_id,
                user_email: (that.$store.state.account.oUser.email ? that.$store.state.account.oUser.email : ''),
                schema: localStorage.getItem('schema'),
                created_at: Date(),
                amount: oOrder.payments[0].payment_amount,
                status: 1
              }
              store.dispatch('order/mktplcUpdateOrder', data)
            }
            if (oOrder.order_without_registration) {
              setXUserKeyOrder(aData.data['x-user-key'])
            }
            setOrderInfo('order_id', aData.data.order_id)
            setOrderInfo('coupon', '')
            showAlert('success', 'Pedido realizado com sucesso!')
            store.dispatch('cart/cleanCart')
            if (oOrder.order_without_registration == false) {
              store.dispatch('account/loyaltybalance', aData.data.loyalty_balance.points_valid)
              router.push({ name: 'order-status', params: { pixQrCode: aData.data.pix_qr_code, pixQrCodeUrl: aData.data.pix_link_payment } })
              return
            }
            router.push({ name: 'find-order', params: { order_id: aData.data.order_id, sOrderOrigin: oOrder.delivery_address.origin, pixQrCode: aData.data.pix_qr_code, sPixQrCodeUrl: aData.data.pix_link_payment } })
          } else {
            showAlert('error', response.data.errors[0])
          }
        }
      })
    }
  }
}
</script>
