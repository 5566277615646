<template>
    <p class="fw-400 fs-m">
    <b>
    A previsão de
    <span v-if="sOrderOrigin == 'delivery'">entrega</span>
    <span v-if="sOrderOrigin == 'desk'">retirada</span>
    é de
    <span v-if="aDeadlineDelivery && sOrderOrigin == 'delivery'">{{aDeadlineDelivery[0]}}</span>
    <span v-if="aDeadlineDesk && sOrderOrigin == 'desk'">{{aDeadlineDesk[0]}}</span>
    <span v-if="aDeadlineDelivery && sOrderOrigin == 'delivery' && aDeadlineDelivery[1]"> a {{aDeadlineDelivery[1]}}</span>
    <span v-if="aDeadlineDesk && sOrderOrigin == 'desk' && aDeadlineDesk[1]"> a {{aDeadlineDesk[1]}}</span>
        min
     </b>
    </p>
</template>

<script>

export default {
  name: 'vtto-dead-line',
  props: {
    aDeadlineDesk: {
      type: Array,
      default: () => {}
    },
    aDeadlineDelivery: {
      type: Array,
      default: () => {}
    },
    sOrderOrigin: String
  }
}
</script>
