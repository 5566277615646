<template>
    <div>
        <div class="bg-white p-15 mt-15 bb-1-gray">
            <span class="text-uppercase fs-s fw-900">Valores</span>
        </div>
        <div class="bg-white p-15 bb-1-gray d-flex justify-content-between">

            <div class="fs-s">
                valor unitário
                <p class="price fs-m color-main">
                    {{ unitPrice2() | money }}
                </p>
            </div>

            <div class="btn-numbers d-flex justify-content-between flex-center fs-s">
                <button @click="opQty(-1)" class="qntminus color-main">-</button>
                <span class="price fs-m">{{ qty }}</span>
                <button @click="opQty(1)" class="qntplus color-main">+</button>
            </div>

        </div>
        <div class="bg-white p-15 bb-1-gray">
            <div class="fs-s text-lg-right">
                valor total
                <p class="price fs-l color-main">
                    {{ qty * unitPrice2() | money }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    item: {}
  },
  data () {
    return {
      qty: 1,
      total: 0,
      unitPrice: 0
    }
  },
  watch: {
    prices: function () {
    }
  },
  created () {
    this.unitPrice = this.unitPrice2()

    this.$eventHub.$on('prices', prices => {
      this.unitPrice = this.unitPrice2()
    })
  },
  methods: {
    unitPrice2 () {
      let saldo = 0

      if (typeof this.newPrices.edge !== 'undefined') {
        saldo += this.newPrices.edge
      }

      for (let i in this.newPrices.products) {
        if (this.newPrices.products.length === 1) {
          saldo += this.newPrices.products[i].promo > 0 ? parseFloat(this.newPrices.products[i].promo) : parseFloat(this.newPrices.products[i].price)
        }
        if (this.newPrices.products[i].ingredientsExtra) {
          saldo += parseFloat(this.newPrices.products[i].ingredientsExtra)
        }

        if (this.newPrices.products[i].ingredients) {
          saldo += parseFloat(this.newPrices.products[i].ingredients)
        }

        if (this.newPrices.products[i].infoExtra) {
          saldo += parseFloat(this.newPrices.products[i].infoExtra)
        }

        if (this.newPrices.products[i].extra) {
          saldo += parseFloat(this.newPrices.products[i].extra)
        }

        /** edge */
        if (this.newPrices.products[i].edge) {
          saldo += parseFloat(this.newPrices.products[i].edge)
        }
      }

      if (this.newPrices.products.length !== 1) {
        saldo += this.calculatePriceProportional()
      }

      return saldo
    },
    /**
     * Verifica o tipo de divisão e calcula o valor do produto
     */
    calculatePriceProportional () {
      switch (this.info.price_rule.type) {
        case 'M': // Maior valor
          return this.calculateMaximumValue()
        case 'W': // Média ponderada
          return this.calculateWeightedValue()
        case 'A': // Média simples
          return this.calculateAltValue()
      }
    },
    calculateMaximumValue () {
      let max = 0
      for (let i in this.currentItem.products) {
        let itemPrices = this.currentItem.products[i].prices
        let price = parseFloat(itemPrices.promo) > 0 ? parseFloat(itemPrices.promo) : parseFloat(itemPrices.price)
        if (price > max) {
          max = price
        }
      }
      return max
    },
    calculateWeightedValue () {
      let weight = 0
      for (let i in this.currentItem.products) {
        let quantity = String(this.currentItem.products[i].product_quantity)
        let itemPrices = this.currentItem.products[i].prices
        let price = parseFloat(itemPrices.promo) > 0 ? parseFloat(itemPrices.promo) : parseFloat(itemPrices.price)
        let math = quantity.split('/')
        let divide = parseInt(math[1])
        let multiply = parseInt(math[0])
        weight += (price / divide) * multiply
      }
      return weight
    },
    calculateAltValue () {
      let alt = 0
      for (let i in this.currentItem.products) {
        let itemPrices = this.currentItem.products[i].prices
        let price = parseFloat(itemPrices.promo) > 0 ? parseFloat(itemPrices.promo) : parseFloat(itemPrices.price)
        alt += price
      }
      return (alt / this.currentItem.length)
    },
    getSteps () {
      let sum = 0
      if (this.currentItem.product_steps) {
        for (let i in this.currentItem.product_steps) {
          if (this.currentItem.product_steps[i].step_ingredients) {
            for (let y in this.currentItem.product_steps[i].step_ingredients) {
              sum += this.currentItem.product_steps[i].step_ingredients[y].ingredient_quantity * this.currentItem.product_steps[i].step_ingredients[y].ingredient_amount
            }
          }
        }
      }
      return sum
    },
    getUnitPrice () {
      let sum = 0

      Object.entries(this.currentItem.product_prices).map(a => {
        if (a[1].amount_promo && a[1].amount_promo > 0) {
          sum += a[1].amount_promo
        } else {
          sum += a[1].amount
        }
      })

      this.total = this.qty * sum
      this.currentItem.subtotal = this.total
      this.$store.dispatch('cart/setProduct', this.currentItem)
      return sum
    },
    opQty (dir) {
      if (this.qty + dir > 0) {
        this.qty = this.qty + dir
        let currentItem = Object.assign({}, this.$store.state.cart.currentItem)
        currentItem.product_quantity = this.qty
        currentItem.subtotal = 0

        for (let i in currentItem.product_prices) {
          currentItem.subtotal += this.qty * parseFloat(currentItem.product_prices[i].amount)
        }

        this.$store.dispatch('cart/setProduct', currentItem)
      }
    },
    getPricesStepParts () {
      let sum = 0
      const parts = this.currentItem.products.length
      for (let a in this.currentItem.products) {
        const sizeId = this.currentItem.size_id
        const productId = this.currentItem.products[a].product_id
        for (let b in this.params.product.products) {
          if (this.params.product.products[b].product_id === productId) {
            for (let c in this.params.product.products[b].product_sizes) {
              if (this.params.product.products[b].product_sizes[c].size_id === sizeId) {
                let amount = 0
                if (this.params.product.products[b].product_sizes[c].size_amount_promo > 0) {
                  amount = this.params.product.products[b].product_sizes[c].size_amount_promo
                } else {
                  amount = this.params.product.products[b].product_sizes[c].size_amount
                }

                sum += amount / parts
              }
            }
          }
        }
      }
      return sum
    }
  },
  computed: {
    unit: function () {
      return this.currentItem
    },
    ...mapState({
      currentItem: state => state.cart.currentItem,
      params: state => state.route.params,
      newPrices: state => state.cart.currentItemPrices,
      info: state => state.store.info
    }),
    ...mapGetters('cart', [
      'productPrice'
    ])
  }
}
</script>
