<template>
 <div class="container main">
      <div class="row" v-if="!order_id">
          <div class="col-12">
              <div class="bg-white d-block mb-20">
                  <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Buscar Pedido</h3>
                  <div class="bg-white p-15">
                    <input v-model="oOrder.order_id" type="mumber" class="form-control mt-10 br-0" placeholder="Número do pedido" required />
                  </div>
                  <div class="p-15 text-center d-block">
                      <button @click="getStatusOrder" class="btn btn-primary text-uppercase text-center mx-auto">
                        Buscar
                      </button>
                  </div>

              </div>
          </div>
      </div>

      <div class="row" v-if="isStatusEmpty()">
            <div class="col-12">
                <!-- Menu superior página -->
                <ul class="nav-tabs list-table w-100 bg-white mt-45 mb-20 text-center">
                    <li class="list-table-item relative">
                        <div class="fw-900 fs-s text-uppercase p-15 d-block">
                            <span v-if="iStatusId === 1">Enviando pedido N° {{order_id || oOrder.order_id}}</span>
                            <span v-if="iStatusId === 5">Pedido cancelado =/ N° {{order_id || oOrder.order_id}}</span>
                            <span v-if="iStatusId >= 2 && iStatusId <= 4">Pedido aceito! =D N° {{order_id || oOrder.order_id}}</span>
                            <span v-if="iStatusId === 0">Pedido enviado N° {{order_id || oOrder.order_id}} {{iStatusId}}</span>
                            <span v-if="iStatusId === 7">Aguardando Pagamento Pedido N° {{order_id || oOrder.order_id}}</span>
                        </div>
                    </li>
                </ul>
                <!-- FIM Menu superior página -->
            </div>
        </div>

      <div class="row" v-if="isStatusEmpty()">
          <div class="col-12">
            <vtto-waiting v-if="iStatusId === 1" :formatTime="sIntervalTime"></vtto-waiting>
            <vtto-canceled v-if="iStatusId === 5" :iOrderId="oOrder.order_id"></vtto-canceled>
            <vtto-confirmed v-if="iStatusId >= 2 && iStatusId <= 4" :iOrderId="oOrder.order_id" :hideButton="0" :sOrderOrigin="sOrderOrigin"></vtto-confirmed>
            <vtto-payment v-if="iStatusId === 7" :image="sPixQrCode" :url="sPixQrCodeUrl"></vtto-payment>
          </div>
      </div>
  </div>
</template>

<script>
import VttoCanceled from '@/components/Order/Status/VttoCanceled'
import VttoConfirmed from '@/components/Order/Status/VttoConfirmed'
import VttoWaiting from '@/components/Order/Status/VttoWaiting'
import VttoPayment from '@/components/Order/Status/VttoAwating'
import { empty } from '@/utils'
import { showAlert } from '@/plugins/alerts'
import order from '@/api/order'
export default {
  props: ['order_id', 'sOrderOrigin', 'pixQrCode', 'pixQrCodeUrl', 'ignore'],
  components: {
    VttoCanceled,
    VttoConfirmed,
    VttoWaiting,
    VttoPayment
  },
  data: function () {
    return {
      oOrder: {
        order_id: '',
        ignore: false
      },
      iStatusId: '',
      Interval: '',
      aStatusIdNotRequest: [2, 3, 4, 5],
      iLastingTime: 60 * 10,
      iIntervalTime: 196000,
      sPixQrCode: '',
      sPixQrCodeUrl: ''
    }
  },

  mounted: function () {
    if (this.ignore) {
      this.oOrder.ignore = this.ignore
    }

    if (!empty(this.order_id)) {
      this.oOrder.order_id = this.order_id
      this.getStatusOrder()
    }

    if (!empty(this.pixQrCode)) {
      this.sPixQrCode = this.pixQrCode
      this.sPixQrCodeUrl = this.pixQrCodeUrl
    }

    this.getTimer()
  },
  destroyed: function () {
    let self = this
    clearInterval(self.Interval)
  },
  computed: {
    isMobile () {
      return this.$store.state.store.isMobile
    },
    sIntervalTime: function () {
      return this.getSIntervalTime()
    }
  },

  methods: {
    getStatusOrder: function () {
      let self = this
      if (empty(self.oOrder.order_id)) {
        showAlert('error', 'Informe o número do pedido')
        return
      }

      if (!empty(self.Interval)) {
        clearInterval(self.Interval)
        self.Interval = ''
      }
      self.iStatusId = ''
      self.updateOrderStatus()
    },
    getTimer: function () {
      let self = this

      let refreshInterval = setInterval(function () {
        self.iLastingTime--
        /* Se o tempo acabou */
        if (self.iLastingTime <= 0) {
          /* Pedido enviado */
          self.iStatusId = 0
          clearInterval(refreshInterval)
          return false
        }
      }, 1000)
    },
    getSIntervalTime () {
      let iTime = this.iLastingTime
      let iMinutes = parseInt(iTime / 60, 10)
      let iSeconds = parseInt(iTime % 60, 10)
      let sMinutes = iMinutes < 10 ? '0' + iMinutes : iMinutes
      let sSeconds = iSeconds < 10 ? '0' + iSeconds : iSeconds
      return sMinutes + ':' + sSeconds
    },
    updateOrderStatus: function () {
      let self = this
      self.setStatusOrder()
      self.Interval = setInterval(function () {
        // OTIMIZAÇÃO PARA CASO O PEDIDO FOR FINALIADO OU CANCELADO, ACEITO, ENVIADO
        if (self.aStatusIdNotRequest.includes(self.iStatusId)) {
          clearInterval(self.Interval)
        }
        if (!empty(self.iStatusId)) {
          self.setStatusOrder()
        }
      }, self.iIntervalTime)
    },
    setStatusOrder: function () {
      let self = this
      order.getOrderStatusWithoutRegister(self.oOrder).then(function (response) {
        if (!response.data.data.errors) {
          self.iStatusId = parseInt(response.data.data.order_status)
          self.sPixQrCode = response.data.data.pix_qr_code
          self.sPixQrCodeUrl = response.data.data.pix_link_payment
        } else {
          showAlert('error', response.data.data.errors)
          self.iStatusId = ''
          return false
        }
      })
    },
    isStatusEmpty: function () {
      let self = this
      return !empty(self.iStatusId) || self.iStatusId === 0
    },
    scrollToMe: function (box) {
      scrollToMe(box, -40)
    }
  }
}
</script>
