<template>
  <div>
    <!-- <pre>{{ oOrder }}</pre> -->
    <div class="bg-white d-block mb-15 m-xs-0">
      <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Valores</h3>
      <div class="box-content p-15">
        <div v-show="addrMinOrderPrice > 0" class="p-15 bg-vitto color-white mb-15 flex-center br-5" >
          <i class="fas fa-info-circle fs-1-8"></i>
          <div class="fs-m ml-15 fw-700">
            Este pedido deve atingir <span class="price">{{addrMinOrderPrice | money}}</span>, sem contar a taxa de entrega!
          </div>
        </div>
        <div class="text-lg-right relative" >
          <template v-if="parseFloat(fTotalDelivery) > 0 || parseFloat(fTotalDiscount) > 0">
            <p class="fs-m">Subtotal: <span class="price fw-700">{{ sSubtotal }}</span></p>
            <template v-if="parseFloat(fTotalDelivery) > 0">
              <p class="fs-m">Entrega: <span class="price fw-700">{{fTotalDelivery | money}}</span></p>
            </template>
            <template v-else>
              <p class="fs-m">Entrega: <span class="price fw-700">Grátis</span></p>
            </template>
            <template v-if="parseFloat(fTotalDiscount) > 0">
              <p class="fs-m">Desconto: <span class="price fw-700">{{fTotalDiscount | money}}</span></p>
            </template>
            <!--
              TODO: Exibir quando o pagamento online for implementado
              <p class="fs-m">Desconto Pagamento online: <span class="price fw-700">R$10,00</span></p>
            -->
          </template>
            <div class="text-lg-right relative py-15 fs-m">
              Valor Total:
                <p class="price fs-m color-main">
                    {{sTotal}}
                </p>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bugsnag from '@bugsnag/js'
import { convertMoney } from '@/utils'

export default {
  props: {
    fTotalDelivery: {
      type: Number,
      default: 0
    },
    fTotalDiscount: {
      type: [Number, String],
      default: 0
    },
    fSubtotal: {
      type: Number,
      default: 0
    },
    addrMinOrderPrice: {
      type: Number,
      default: 0
    }
  },
  name: 'vtto-checkout-amounts',
  computed: {
    oOrder: function () {
      return this.$store.state.order.oOrder
    },
    sTotal: function () {
      const fTotal = parseFloat(this.fSubtotal) + (parseFloat(this.fTotalDelivery) - parseFloat(this.fTotalDiscount))
      if (isNaN(fTotal)) {
        Bugsnag.leaveBreadcrumb('valores do checkout', { fSubtotal: this.fSubtotal, fTotalDelivery: this.fTotalDelivery, fTotalDiscount: this.fTotalDiscount, fTotal: fTotal })
        Bugsnag.leaveBreadcrumb('valores do pedido', { order: this.$store.state.order, cart: this.$store.state.cart })
        Bugsnag.notify(new Error('NaN encontrado ao calcular o total do checkout'))
      }

      return convertMoney(fTotal, 'R$', false)
    },
    isMobile: function () {
      return this.$store.state.store.isMobile
    },
    sSubtotal: function () {
      return convertMoney(this.fSubtotal, 'R$', false)
    }
  }
}
</script>
