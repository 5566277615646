<template>
  <div class="mb-xs-150">
    <div v-if="aCards && aCards.length > 0 && newCard === false && payment_type == 2 && !bDisableCard">
      <ul class="d-block  list-unstyled fs-s">
        <li class="p-15 bb-1-gray" v-for="(card, index) in aCards" :key="index">
          <label :for="`creditcards-${index}`" class="flex-start form-group-radio_group text-uppercase fw-500" @click="selectCard(card)">
            <input :id="`creditcards-${index}`"  type="radio" name="creditcards" class="delivery-type form-group-radiobox" :value="card.card_token">
            <span class="form-group-check_radio"></span><div>
            <p class="fw-700 number">{{ card.brand }} {{ card.card_number }}</p></div>
          </label>
        </li>
        <li class="text-left p-15 ">
          <a @click="newCard = true" class="btn btn-secondary text-uppercase fs-1-xs">
          Novo Cartao
          </a>
        </li>
      </ul>
    </div>

    <fieldset v-else class="px-15 pb-15">
      <div v-if="bPix">
        <div class="card bg-none px-15 pb-10 pt-10">
          <div>
          <div>
            <label class="flex-start form-group-radio_group text-uppercase fw-700" data-toggle="collapse" aria-expanded="true" :aria-controls="'collapse'">
              <input data-type="desk"  type="radio" class="delivery-type form-group-radiobox" v-model="payment_type" :value='1' @click="setPix"/>
              <span class="form-group-check_radio"></span>
                  Pix
            </label>
            </div>
          </div>
        </div>

        <div class="card bg-none px-15 pb-10 pt-10" v-if="!bDisableCard">
          <div>
          <div>
            <label class="flex-start form-group-radio_group text-uppercase fw-700" data-toggle="collapse" aria-expanded="true" :aria-controls="'collapse'">
              <input data-type="desk"  type="radio" class="delivery-type form-group-radiobox" v-model="payment_type" :value='2'/>
              <span class="form-group-check_radio"></span>
                  Cartão de Credito
            </label>
            </div>
          </div>
        </div>
      </div>
      <div v-if="(payment_type == 2 || bPix == false) && !bDisableCard">
        <div class="form-group">
          <label for="nome" class="fw-700 d-block text-uppercase mt-15">Nome  (igual o cartão)</label>
          <input type="text" class="form-control" id="nome" name="nome" v-model="oCard.holder_name" @change="setCard">
        </div>

        <div class="form-group">
          <label for="cpf" class="fw-700 d-block text-uppercase mt-15">Cpf</label>
          <input type="text" v-mask="'###.###.###-##'" class="form-control" id="cpf" name="cpf" v-model="oCard.cpf" @change="setCard">
        </div>
        <div class="form-group">
          <label for="numero-cartao" class="fw-700 d-block text-uppercase mt-15">Número</label>
          <input type="text" v-mask="'#### #### #### ####'" class="form-control" id="numero-cartao" name="numero-cartao" v-model="oCard.card_number" @change="setCard">
        </div>
        <div class="row">
            <div class="col-md-8">
              <div class="form-group" >
                <label for="validade-cartao" class="fw-700 d-block text-uppercase mt-15">Validade</label>
                <input type="text" v-mask="'##/##'" class="form-control" id="validade-cartao" name="validade-cartao" v-model="oCard.validate" @change="setCard">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="cvv" class="fw-700 d-block text-uppercase mt-15">CVV</label>
                <input type="text" v-mask="['###?#']" class="form-control" id="cvv" name="cvv" v-model="oCard.cvv" @change="setCard">
              </div>
            </div>
        </div>

        <div class="form-group-sm-checkbox_group" v-if="oOrder.order_without_registration != 1">
          <input type="checkbox"  class="form-group-md-checkbox mr-10" id="card-po" v-model="oCard.credit_card_store" @change="setCard">
          <label for="card-po" class="form-group-md-label_checkbox fs-s">
            <span class="mt-15">Salvar cartao</span>
          </label>
        </div>
      </div>

      <p v-if="newCard === true" class="text-left py-30">
        <a @click="newCard = false" class="btn btn-secondary text-uppercase fs-1-xs">
        Cancelar
        </a>
      </p>
    </fieldset>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'vtto-checkout-payments-online',
  props: {
    aCards: {},
    bPix: Boolean,
    bDisableCard: Boolean
  },
  data: function () {
    return {
      newCard: false,
      oCard: {},
      payment_type: ''
    }
  },
  methods: {
    selectCard (card) {
      this.$emit('cardToken', card)
    },
    setCard () {
      this.$emit('setCard', this.oCard)
    },
    setPix () {
      this.$emit('setPaymentPix')
    }
  },
  computed: {
    ...mapState({
      oOrder: state => state.order.oOrder
    })
  }
}

</script>
