<template>
    <li class="media m-0 d-flex justify-between">
      <div class="form-group-radio_group link-produc w-100" @click="internalSteps">
        <span class="fw-500 fs-m number">{{ product.product_name }}</span>
        <p class="fw-300 fs-1-2 mt-5">{{ description(product) }}</p>
        <vtto-catalog-item-price :prices="prices()"/>
      </div>
      <a v-if="product.product_image && imagesMobile" class="img-catalog ml-15 mt-5">
        <img loading="lazy" class="img-fluid br-5" :src="product.product_image" @click="openImage">
      </a>
    </li>
</template>

<script>
import VttoCatalogItemPrice from '@/components/Catalog/VttoCategoryItemPrice'

export default {
  props: {
    product: {},
    steps: {},
    half: {},
    imagesMobile: {}
  },
  components: {
    VttoCatalogItemPrice
  },
  created () {
  },
  methods: {
    openImage () {
      const params = {
        type: 'zoom',
        title: this.product.product_name,
        imageUrl: this.product.product_image,
        onSelectProduct: () => {
          this.internalSteps()
        }
      }

      this.$modal.show(params)
    },
    capitalizeFirstLetter (string) {
      string = string.toLowerCase()
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    description (product) {
      if (product.product_description) {
        return this.capitalizeFirstLetter(product.product_description)
      }
      let ingredients = []
      for (let i in product.product_ingredients) {
        ingredients[i] = product.product_ingredients[i].ingredient_name
      }

      return this.capitalizeFirstLetter(ingredients.join(', '))
    },
    prices () {
      let prices = this.minorPrice(this.product.product_sizes)
      if (this.product.product_sizes) {
        if (this.product.product_sizes.length === 1) {
          /** só possui 1 tamanho, seta preço do tamanho */
          prices.price = this.product.product_sizes[0].size_amount
          prices.promo = this.product.product_sizes[0].size_amount_promo
          prices.loyalty = this.product.product_sizes[0].size_amount_loyalty
          prices.minor = false
        }
      }
      return prices
    },
    internalSteps () {
      if (!this.half) {
        return this.steps(1, this.product)
      }

      const currentItem = this.$store.state.cart.currentItem
      if (!currentItem.products) {
        currentItem.products = []
      }
      currentItem.products.push(this.product)
      this.$store.dispatch('cart/setProduct', currentItem)
      if (this.product.product_ingredients) {
        this.$store.dispatch('cart/setStage', 1)
      }
    },
    minorPrice (sizes) {
      let minorPrice = 0
      let minorLoyalty = 0
      let promo = 0
      if (sizes) {
        Object.entries(sizes).forEach(([index, value]) => {
          let amount = parseFloat(value.size_amount)

          if (amount < minorPrice || minorPrice === 0) {
            minorPrice = amount
            promo = parseFloat(value.size_amount_promo)
          }

          if (parseFloat(value.size_amount_loyalty) < minorLoyalty || minorLoyalty === 0) {
            minorLoyalty = parseFloat(value.size_amount_loyalty)
          }
        })
      }
      return {
        price: minorPrice,
        loyalty: minorLoyalty,
        promo: promo,
        minor: true
      }
    }
  }
}
</script>
