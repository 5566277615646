<template>
    <div class="container main">
        <!-- Tipo do Pedido -->
        <div class="row">
            <div class="col-12 bg-white mb-20">
                <h3 v-if="aOrders.length === 0" class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Você ainda não realizou nenhum pedido :(</h3>
                <!-- <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">{{getStatusName(iStatus)}}</h3> -->
                <!-- Pedido -->
                <paginate name="aOrders" :list="aOrders" class="paginate-list" :per="10">
                    <div class="pedido bb-1-gray" v-for="(oOrder, oOrderKey) in paginated('aOrders')" :key="oOrderKey">
                        <div class="d-lg-flex justify-content-lg-between">
                            <div class="p-15 mt-10 fs-s d-block">
                                {{oOrder.order_id}} - {{oOrder.sOrigin}}
                                - {{ getStatusName(oOrder.order_status) }} - {{oOrder.sCreated_at}}
                                <router-link :to="{ name: 'order-detail', params: { iOrderId: oOrder.order_id}}"
                                             class="color-main">+ detalhes
                                </router-link>
                            </div>
                            <div class="price p-15 pt-xs-0 text-lg-right">
                                valor <br>
                                <span class="color-main fs-1-4">{{oOrder.sPrice}}</span>
                            </div>
                        </div>
                    </div>
                </paginate>
                <paginate-links v-if="aOrders.length !== 0" for="aOrders"
                                :simple="{next: 'Próximo »', prev: '« Anterior'}"
                                :classes="{
                          'ul': ['d-flex', 'align-center','justify-center','list-unstyled'],
                          'ul *': ['py-15','px-10'],
                          '.next > a': ['btn', 'btn-secondary','fs-1-2'],
                          '.prev > a': ['btn', 'btn-secondary','fs-1-2']
                        }"
                ></paginate-links>
                <!-- FIM Pedido -->
            </div>
        </div>
        <!-- FIM Tipo do Pedido -->
    </div>
</template>
<script>
import apiOrder from '@/api/order'
import { empty } from '@/utils'
import { getOrderStatusName, repeatOrder } from '@/plugins/orders'
import { showAlert } from '@/plugins/alerts'

export default {
  data: function () {
    return {
      aOrders: [],
      paginate: ['aOrders']
    }
  },
  mounted () {
    this.aOrderlist()
  },

  methods: {
    localRepeatOrder (iOrderid) {
      return repeatOrder(iOrderid)
    },
    getStatusName (iStatusId) {
      return getOrderStatusName(iStatusId)
    },
    aOrdersFormatted (aOrders) {
      let aReturn = []
      aOrders.sort((a, b) => {
        return b.order_id - a.order_id
      })

      for (var oOrderId in aOrders) {
        let oOrder = aOrders[oOrderId]
        if (empty(aReturn[oOrder.order_status])) {
          aReturn[oOrder.order_status] = []
        }
        aReturn[oOrder.order_status].push(oOrder)
      }
      return aReturn
    },
    aOrderlist () {
      let self = this
      let oPageConfig = { }
      apiOrder.ListOrderReport(oPageConfig).then(function (response) {
        if (!response || !response.data || response.data.success === false) {
          showAlert('error', response.data.errors[0])
          return false
        } else {
          let aData = response.data
          self.aOrders = aData.data.report.records
        }
      })
    }
  }
}
</script>
