import VttoModal from '@/components/VttoModal'

const Modal = {
  install (Vue, options) {
    this.EventBus = new Vue()
    Vue.component('app-modal', VttoModal)

    Vue.prototype.$modal = {
      show (params) {
        Modal.EventBus.$emit('show', params)
      }
    }
  }
}

export default Modal
