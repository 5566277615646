<template>

  <section id="main">
    <div class="text-center py-60">
      <div v-if="sBrand">
        <img loading="lazy" :src="sBrand" class="brand-img brand-img-lg">
      </div>
    </div>
    <div class="container">
      <div class="row  justify-content-around" v-if="iUnits == 'multiple'">
        <div class="col-md-5 mb-30" v-for="(oUnit, iUnit) in aUnits" :key="iUnit">
          <div class="bg-white p-30 br-5 text-center">
            <h2 class="fs-2  text-uppercase fw-900 number">{{oUnit.name}}</h2>
            <a class="bg-main color-white btn-block text-uppercase p-15 my-15 fs-1-4 br-5" :href="oUnit.url">
              Faça seu pedido Online
            </a>
            <div v-if="aPhones">
              <ul class="list-inline fs-1-4">
                <li class="number list-inline-item" v-if="oUnit.phone">
                  <span>Tel.:</span>{{oUnit.phone}}
                </li>
                <li class="number list-inline-item" v-if="aPhones.phone2">
                  <span>Tel.:</span>{{oUnit.phone2}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="fixed-lg-bottom">
      <div class="container">
        <ul class="row d-flex list-unstyled justify-content-around">
          <li v-if="!iUnits || iUnits == 'simple'" class="col-md-4">
            <router-link class="bg-main  my-15 fs-1-4 br-5 py-10 btn-block text-center color-white flex-center br-20"
                         :to="{name: 'homeCatalog'}">
              <i class="fas fa-laptop mr-15 fs-3-3"></i>
              <div class="text-left ml-15">
                <p class="fs-0-9"><i>Peça</i></p>
                <p class="fs-1-2 mt--5">Online</p>
              </div>
            </router-link>
          </li>
          <li v-if="aApps && aApps.ios" class="col-md-4">
            <a class="bg-main  my-15 fs-1-4 br-5 py-10 btn-block text-center color-white flex-center br-20"
               :href="aApps.ios" target="_blank">
              <i class="fab fa-apple mr-15 fs-3-3"></i>
              <div class="text-left ml-15">
                <p class="fs-0-9"><i>Baixe na</i></p>
                <p class="fs-1-2 mt--5">App Store</p>
              </div>
            </a>
          </li>
          <li v-if="aApps && aApps.android" class="col-md-4">
            <a class="bg-main  my-15 fs-1-4 br-5 py-10 btn-block text-center color-white flex-center br-20"
               :href="aApps.android" target="_blank">
              <i class="fab fa-android mr-15 fs-3-3"></i>
              <div class="text-left ml-15">
                <p class="fs-0-9"><i>Baixe na</i></p>
                <p class="fs-1-2 mt--5">Play Store</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  </section>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'vtto-about',
  computed: {
    ...mapState({
      sBrand: state => state.store.info.brand,
      aApps: state => state.store.info.apps,
      aPhones: state => state.store.info.phones,
      aUnits: state => state.store.info.units,
      iUnits: state => state.store.info.units_count
    })
  }
}
</script>
