<template>
  <router-link :to="{name : 'homeCatalog'}">
    <img loading="lazy" v-if="image" class="brand-img brand-img-l d-none d-lg-block mt-15" :src="image" :alt="name" />
    <!-- Oculto porque a logo do vitto fica aparecendo no site até a logo do cliente ser carregada. Uma solução seria aplicar uma logo do CVitto menor ou até deixar sem logo mesmo.-->
    <!-- <img v-else class="brand-img brand-img-l d-none d-lg-block mt-15" :src="require('../assets/img/logo-front-default.png')" :alt="name" /> -->
  </router-link>
</template>

<script>
export default {
  name: 'vtto-header-brand',
  props: {
    link: {
      default: '/'
    },
    name: {
      default: ''
    },
    image: {
    }
  }
}
</script>
