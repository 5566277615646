<template>
    <div>
        <div :class="[{'px-0': isChild}, {'pb-0': isChild}, {'bb-1-gray': !isChild}, {'pt-5': isCombo}]"
             class="m-0 relative p-20" v-for="(oItem, iItem) in items" :key="iItem">

            <a v-if="isCombo" @click="ghostIphone" data-toggle="collapse" :data-target="`#id_${cart ? 'cart_' : ''}${indice}_${iItem}`"
               aria-expanded="false" :aria-controls="`id_${cart ? 'cart_' : ''}${indice}_${iItem}`">
                <div class="collapse-title text-uppercase number fs-1-2 fw-600 flex-center justify-between">
                    <span>{{qty(oItem.product_quantity, oItem.products, oItem.category_type)}} {{oItem.product_name}}</span>
                    <span v-if="hasCollapse(oItem)"><i class="fas fa-angle-down ml-15"></i></span>
                </div>
            </a>

            <div class="media-body">
                <div v-if="isCombo != true" class="d-flex justify-between">

                    <!-- [01] Nomes -->
                    <div :class="{'d-flex flex-end': bPizza}">
                        <p class="fw-700 fs-1-6">
                            <span :class="{'mr-5': oItem.product_quantity.length > 0}" class="number"
                                  v-if="oItem.product_quantity">{{qty(oItem.product_quantity, oItem.products, oItem.category_type)}} </span>
                            <span class="text-uppercase">{{oItem.category_name}}&nbsp;</span>
                            <span v-if="oItem.category_name && oItem.subcategory_name"> // </span>
                            <span v-if="oItem.subcategory_name" class="text-uppercase">{{oItem.subcategory_name}}</span>
                        </p>
                        <p class="fw-500 fs-m text-uppercase">
                            <span class="mb-10" v-if="oItem.size_name">{{oItem.size_name}}</span>
                            <span v-if="oItem.size_name && oItem.product_name"> - </span>
                            <span v-if="oItem.product_name"
                                  :class="[{'ml--5': oItem.size_name}, {'fs-0-9 fw-600 color-gray2': (hasCollapse(oItem) != true) && isCombo}]">  {{oItem.product_name}}</span>
                        </p>
                    </div>
                    <!-- fim [01] -->

                    <!-- [02] Preços -->
                    <div v-if="!isChild && oItem.product_prices.products" class="ml-25">
                        <div class="d-flex flex-center flex-end">
                            <p class="price fs-1-6 color-main w-80">
                              <template v-if="oItem.loyalty_payment">
                                {{ calculatePriceWithoutLoyalty(oItem, info) * oItem.product_quantity | money }}
                              </template>
                              <template v-else>
                                {{oItem.product_quantity * itemPrice(oItem.product_prices, info.price_rule, 1) | money}}
                              </template>
                            </p>

                            <button @click="cancelItem(iItem)" class="bg-none border-0 pointer p-0 ml-10"
                                    style="margin-top:2px;">
                                <i class="fas fa-times color-4 fs-1-4" aria-hidden="true"></i>
                            </button>
                        </div>

                        <!-- Fidelidade -->
                        <div v-if="cart !== true && calculateLoyalty(oItem, info.price_rule)">
                            <div class="form-group-sm-checkbox_group flex-center" v-if="(enableLoyalty(oItem.products) &&
                            loyaltyBalance >= oItem.product_prices.products[0].loyalty) || oItem.loyalty_payment">
                                <input @change="changePoints(iItem, oItem)"
                                       v-model="oItem.loyalty_payment"
                                       type="checkbox" :id="'option-'+ iItem" name="aOptions[]"
                                       class="form-group-sm-checkbox bg-white" />
                                <label v-if="oItem.allow_loyalty_payment"
                                       class="form-group-sm-label_checkbox form-group-sm-label_checkbox-loyalty"
                                       :for="'option-'+ iItem">
                                    <div class="color-main m-xs-0 fs-s number">
                                        <p>Usar pontos</p>
                                        <p>({{ (calculateLoyalty(oItem, info.price_rule) * oItem.product_quantity).toFixed(0)}} pts)</p>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- fim [02] -->
                </div>

                <div :class="{'collapse': hasCollapse(oItem)}" :id="`id_${cart ? 'cart_' : ''}${indice}_${iItem}`">
                    <div :class="{'card card-body pt-0 collapse-content': hasCollapse(oItem)}">
                        <!-- [03] Ingredientes extras -->
                        <div v-if="oItem.ingredients_extra" class="fw-700 mt-20">
                            <p class="text-uppercase fs-s">ADICIONAIS:</p>
                            <p class="fw-300 fs-s mt-5" v-for="(oIngredient, iIngredient) in oItem.ingredients_extra"
                               :key="iIngredient">
                                <span v-if="oIngredient.ingredient_quantity && oIngredient.ingredient_quantity > 0"
                                      class="number">({{valideQty(oIngredient.ingredient_quantity)}}x)</span>
                                <span v-else>(1X)</span>
                                {{oIngredient.ingredient_name}} <span v-if="parseFloat(oIngredient.ingredient_amount) > 0">({{oIngredient.ingredient_amount | money}})</span>
                            </p>
                        </div>
                        <!-- fim [03] -->

                        <!-- [04] Remoção de ingredientes  -->
                        <div class="fw-700 fs-s mt-20" v-if="oItem.remove_ingredients">
                            <p class="text-uppercase">SEM:</p>
                            <p class="fw-300 mt-5" v-for="(oIngredient, iIngredient) in oItem.remove_ingredients"
                               :key="iIngredient">-
                                {{oIngredient.ingredient_name}}</p>
                        </div>
                        <!-- fim [04] -->

                        <!-- [05] Informação extra -->
                        <div v-if="oItem.info_extra || oItem.info_extra_option_id"
                             class="fw-700 mt-20 fs-s text-uppercase">
                            <p>{{infoExtraDisplay(oItem).question}}</p>
                            <p class="fw-300 mt-5">
                                {{infoExtraDisplay(oItem).answer}} <span v-if="infoExtraDisplay(oItem).amount > 0"> ({{infoExtraDisplay(oItem).amount | money}})</span>
                            </p>
                        </div>
                        <!-- fim [05] -->

                        <!-- [06] Meio a Meio -->
                        <vtto-products v-if="oItem.products" class="m-0 relative" :isChild="true"
                                       :items="oItem.products" :bPizza="true" :cart="cart"></vtto-products>
                        <!-- fim [06] -->

                        <!-- [07] Borda -->
                        <div class="fw-700 mt-20 fs-s text-uppercase mt-20" v-if="oItem.edge_id">
                            <p>Borda</p>
                            <p class="fw-300 mt-5">
                                {{oItem.edge_name}} <span
                                    v-if="oItem.edge_amount > 0">({{oItem.edge_amount | money}})</span>
                            </p>
                        </div>
                        <!-- fim [07] -->

                        <!-- [08] Etapas -->
                        <template v-if="oItem.product_steps">
                          <div v-for="(oStep, iSidx) in oItem.product_steps" :key="iSidx">
                              <div v-if="oStep.step_ingredients || oStep.step_products" class="fw-300 mt-20">
                                  <p class="fw-700 text-uppercase fs-s mb-5">{{getName(oStep)}}</p>

                                  <!-- [09] Combo -->
                                  <div v-if="oStep.step_products">
                                      <div v-for="(oProduct, iProduct) in oStep.step_products"
                                          :key="iProduct" class="">

                                          <!-- Se for uma pizza -->
                                          <vtto-products v-if="oProduct.products" class="m-0 relative" :isChild="true"
                                                        :items="oProduct.products" :isCombo="true"
                                                        :indice="`${iItem}_${iSidx}`" :cart="cart"></vtto-products>

                                          <!-- Se for um produto simples -->
                                          <vtto-products v-else class="m-0 relative" :isChild="true"
                                                        :items="[oProduct]" :cart="cart"></vtto-products>

                                      </div>

                                      <!-- [15] Borda de pizza em combo -->
                                      <div class="fw-700 mt-20 fs-s text-uppercase mt-20"
                                          v-if="oStep.step_products[0]">
                                          <div v-if="oStep.step_products[0].edge_id">
                                              <p>Borda</p>
                                              <p class="fw-300 mt-5">
                                                  {{oStep.step_products[0].edge_name}} <span
                                                      v-if="oStep.step_products[0].edge_amount > 0">({{oStep.step_products[0].edge_amount | money}})</span>
                                              </p>
                                          </div>
                                      </div>
                                      <!-- fim [15] -->
                                  </div>

                                  <!-- fim [09] -->

                                  <!-- [10] Montagem -->
                                  <div class="text-uppercase fs-s">

                                      <!-- [11] Ingredientes selecionados na montagem -->
                                      <template v-if="oStep.step_ingredients">
                                        <p v-for="(oIngredient, iIngredient) in oStep.step_ingredients" :key="iIngredient">
                                            <span class="text-lowercase">({{oIngredient.ingredient_quantity}}x) </span>
                                            {{oIngredient.ingredient_name}}
                                            <span v-if="hasIngredientPrice(oIngredient)"> ({{hasIngredientPrice(oIngredient) | money}})</span>
                                        </p>
                                      </template>
                                      <!-- fim [11] -->

                                      <!-- [05] Informação extra -->
                                      <div v-if="typeof oStep.info_extra_option_id != 'undefined'"
                                          class="fw-700 mt-20 text-uppercase">
                                          <p>{{infoExtraDisplay(oStep).question}}</p>
                                          <p class="fw-300 mt-5">
                                              <template v-if="typeof oStep.size_id !== 'undefined'">
                                                {{infoExtraDisplay(oStep, oStep.size_id).answer}} <span v-if="infoExtraDisplay(oStep, oStep.size_id).amount > 0"> ({{infoExtraDisplay(oStep, oStep.size_id).amount | money}})</span>
                                              </template>
                                              <template v-else-if="typeof oItem.product_size !== 'undefined' && typeof oItem.product_size.size_id !== 'undefined'">
                                                {{infoExtraDisplay(oStep, oItem.product_size.size_id).answer}} <span v-if="infoExtraDisplay(oStep, oItem.product_size.size_id).amount > 0"> ({{infoExtraDisplay(oStep, oItem.product_size.size_id).amount | money}})</span>
                                              </template>
                                          </p>
                                      </div>
                                      <!-- fim [05] -->

                                      <!-- [13] Ingredientes extras -->
                                      <div v-if="oStep.ingredients_extra && oStep.ingredients_extra[0]"
                                          class="fw-700 mt-20">
                                          <p class="text-uppercase">EXTRAS:</p>
                                          <p class="fw-300 mt-5"
                                            v-for="(oIngredient, iIngredient) in oStep.ingredients_extra"
                                            :key="iIngredient">
                                              <span v-if="oIngredient.ingredient_quantity && oIngredient.ingredient_quantity > 0"
                                                    class="text-lowercase">({{valideQty(oIngredient.ingredient_quantity)}}x)</span>
                                              <span v-else>(1x)</span>
                                              {{oIngredient.ingredient_name}} <span v-if="parseFloat(oIngredient.ingredient_amount) > 0">({{oIngredient.ingredient_amount | money}})</span>
                                          </p>
                                      </div>
                                      <!-- fim [13] -->
                                  </div>
                              </div>
                          </div>
                        </template>
                        <!-- fim [08] -->

                        <!-- [14] Observações -->
                        <div class="fw-300 fs-s mt-20 text-uppercase" v-if="oItem.product_comments">
                            <p class="fw-700">Observações:</p>
                            <p class="mt-5">{{oItem.product_comments}}</p>
                        </div>
                        <!-- fim [14] -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { setOrderInfo } from '@/plugins/orders'
import { showAlert } from '@/plugins/alerts'

export default {
  name: 'vtto-products',
  props: {
    oOrder: {},
    items: {},
    bPizza: {
      type: Boolean,
      default: false
    },
    isCombo: {
      type: Boolean,
      default: false
    },
    isChild: {
      type: Boolean,
      default: false
    },
    cart: {
      default: false
    },
    indice: {
      default: false
    },
    off: {
      default: false
    }
  },
  data: function () {
    return {
      bPoints: false,
      loyaltyBalance: 0
    }
  },
  created () {
    this.sumLoyalty()
  },
  computed: {
    ...mapGetters('cart', ['totalCart', 'itemPrice']),
    ...mapState('store', ['info']),
    ...mapState('account', ['getUser']),
    isMobile: function () {
      return this.$store.state.store.isMobile
    }
  },
  methods: {
    enableLoyalty (products) {
      let result = true

      for (let i in products) {
        if (!products[i].product_size.allow_loyalty_payment) {
          result = false
          return
        }
      }

      let storeInfo = this.$store.state.store.info

      if (storeInfo.loyalty && typeof storeInfo.loyalty.allow_use_today !== 'undefined') {
        result = storeInfo.loyalty.allow_use_today
      } else {
        result = true
      }

      return result
    },

    valideQty (qty) {
      if (!qty) {
        return 1
      }
      return qty
    },
    infoExtraDisplay (obj, sizeId) {
      let size = typeof sizeId !== 'undefined' ? sizeId : 0
      let res = {}
      if (!obj.info_extra) {
        obj.info_extra = obj.product_size.info_extra
      }

      if (obj.info_extra && !obj.info_extra.length) {
        const infoExtra = obj.info_extra
        obj.info_extra = []
        obj.info_extra.push(infoExtra)
      }

      for (let i in obj.info_extra) {
        res.question = obj.info_extra[i].info_extra_question
        for (let y in obj.info_extra[i].info_extra_options) {
          if (obj.info_extra[i].info_extra_options[y].option_id === obj.info_extra_option_id) {
            res.answer = obj.info_extra[i].info_extra_options[y].option_name
            res.amount = parseInt(obj.info_extra[i].info_extra_options[y].option_amount) > 0 ? obj.info_extra[i].info_extra_options[y].option_amount : obj.info_extra[i].info_extra_options[y].option_amount[size]
          }
        }
      }
      return res
    },
    getName (obj) {
      for (let i in obj) {
        if (i.indexOf('alias') >= 0) {
          if (obj[i] !== '') {
            return obj[i]
          }
        }
      }

      for (let i in obj) {
        if (i.indexOf('name') >= 0) {
          return obj[i]
        }
      }
    },
    sumExtra: function (products) {
      let value = 0
      for (let i in products) {
        if (products[i].infoExtra) {
          value += products[i].infoExtra
        }

        if (products[i].ingredientsExtra) {
          value += products[i].ingredientsExtra
        }
      }
      return value
    },

    testEmptyCart: function () {
      if (this.items.length === 0) {
        showAlert('error', 'Carrinho vazio!')
        this.$router.push({ name: 'homeCatalog' })
      }
      this.$emit('reload-price')
    },

    changePoints: function (iItemId, oItem) {
      let aLocalCart = this.items
      aLocalCart[iItemId].loyalty_payment = oItem.loyalty_payment
      aLocalCart[iItemId].id = iItemId
      /* Emite um sinal dizendo que o item agora deve ser usado para trocar por pontos ou não */
      this.$store.dispatch('cart/updateItemCart', aLocalCart[iItemId])
      setOrderInfo('items', aLocalCart)
      this.$emit('updateSubtotal', this.totalCart(this.info.price_rule))

      this.sumLoyalty()
    },

    /* Cancela um item do carrinho */
    cancelItem: function (iItemId) {
      const params = {
        type: 'confirm',
        title: '',
        text: 'Tem certeza que deseja</br><strong>cancelar este item?</strong>',
        onConfirm: () => {
          this.$store.dispatch('cart/removeItemCart', iItemId)
          setOrderInfo('items', this.items)
          this.$emit('updateSubtotal', this.totalCart(this.info.price_rule))
          this.testEmptyCart()
        }
      }
      this.$modal.show(params)
    },
    qty (qty, parts, type) {
      if (!parts && type !== 3) {
        if (qty > 0) {
          return `${qty}x`
        }
      }
      if (String(qty).search('/') > 0) {
        return `${qty}`
      }
      return `${qty}x `
    },
    sumLoyalty () {
      this.loyaltyBalance = parseInt(this.$store.state.account.oUser.loyaltybalance)

      for (let i in this.items) {
        let oItem = this.items[i]
        if (this.items[i].loyalty_payment) {
          this.loyaltyBalance -= (this.calculateLoyalty(oItem, this.info.price_rule) * oItem.product_quantity).toFixed(0)
        }
      }
    },
    ghostIphone () {
      // Não apagar, necessário para collapse no iPhone.
    },
    hasCollapse (product) {
      if (this.isCombo && (product.ingredients_extra || product.info_extra || product.remove_ingredients || product.edge_id || product.product_steps)) {
        return true
      }
      return false
    },
    /**
     * IMPORTANTE: Este método serve para calcular o valor de produtos dentro de uma montagem.
     * O problema é que quando a montagem está dentro de um combo, o valor total dos produtos
     * selecionados na montagem não é multiplicado pela quantidade selecionada, mas quando a
     * montagem está fora de um combo, a quantidade é multiplicada ao valor
     *
     * @TODO: Normalizar a funcionalidade de calcula o valor dos produtos na montagem, para
     * evitar que essa diferença aconteça
     */
    hasIngredientPrice (oIngredient) {
      if (typeof oIngredient.ingredient_amount !== 'undefined') {
        let quantity = typeof oIngredient.ingredient_quantity !== 'undefined' && parseFloat(oIngredient.ingredient_quantity) > 0 ? parseFloat(oIngredient.ingredient_quantity) : 1
        // Preço em produto de montagem (já está calculado quantidade x preço)
        if (parseFloat(oIngredient.ingredient_amount) > 0) {
          return oIngredient.ingredient_amount
        } else if (typeof oIngredient.ingredient_amount[Object.keys(oIngredient.ingredient_amount)] !== 'undefined' && parseFloat(oIngredient.ingredient_amount[Object.keys(oIngredient.ingredient_amount)]) > 0) {
          // Preço de produto de montagem dentro de um combo
          let amount = parseFloat(oIngredient.ingredient_amount[Object.keys(oIngredient.ingredient_amount)]) * quantity
          return amount.toFixed(2)
        } else {
          return false
        }
      } else {
        return false
      }
    },

    calculateLoyalty (oItem, rule) {
      let total = 0
      let max = 0

      // TIPO meio-a-meio
      if (typeof oItem.category_type !== 'undefined' && oItem.category_type === 1) {
        if (typeof oItem.products !== 'undefined') {
          for (let i in oItem.products) {
            let product = oItem.products[i]
            if (product.product_size.allow_loyalty_payment) {
              if (!rule || rule.type === 'W') {
                total += product.product_size.size_amount_loyalty / oItem.products.length
              } else {
                if (product.product_size.size_amount_loyalty > max) {
                  max = product.product_size.size_amount_loyalty
                }
              }
            } else {
              return 0
            }
          }
        } else {
          if (typeof oItem.size_amount_loyalty !== 'undefined') {
            total = oItem.size_amount_loyalty
          }
        }
      } else {
        if (typeof oItem.size_amount_loyalty !== 'undefined') {
          total = oItem.size_amount_loyalty
        }
      }
      total = max > 0 ? max : total
      return total
    },

    calculatePriceWithoutLoyalty (oItem, info) {
      return this.itemPrice(oItem.product_prices, info.price_rule, 1, 1)
    }
  }
}
</script>
