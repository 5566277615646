<template>
  <div id="combo">
    <div class="container main">
      <div class="row">
        <div class="col-lg-12">
          <div class="bg-white p-15 mt-15 bb-1-gray">
            <vtto-carousel-steps :current-step="currentStepIndex" :steps="steps" />
          </div>

          <div class="bg-white" v-if="currentItem.product_steps">
            <vtto-carousel-steps :current-step="currentStepIndex2" :steps="getSteps(currentItem.product_steps[currentStepIndex-1].step_products[0].product_steps)" />
          </div>

          <div class="bg-white p-20 bb-1-gray fs-s">
            <span class="text-uppercase fw-900">{{ steps[currentStepIndex-1].name }}</span>
          </div>
          <!-- step -->
          <div v-if="stage===1 || stage <= 0">
            <div class="bg-white p-15 mt-15 bb-1-gray">
              <span class="text-uppercase fs-s fw-900">Pizzas</span>
            </div>

            <ul class="list-unstyled bg-white">
                <div class="card card-body br-0 p-0" v-for="(product, index) in steps[currentStepIndex-1].combo.step_products" :key="index">
                  <vtto-simple-product :prices="false" :product="productFormated(product, index)" />
                </div>
            </ul>
          </div>
          <!-- step -->

          <!-- stage 2 -->
          <div v-if="stage===2">
            <vtto-catalog-detail-ingredients v-if="currentItem.product_steps[currentStepIndex-1].step_products[0].product_ingredients.length > 0" :current-item="currentItem" :combo="{step: currentStepIndex-1}" :ingredients="currentItem.product_steps[currentStepIndex-1].step_products[0].product_ingredients" />
              <vtto-steps-items :step="getSteps(currentItem.product_steps[currentStepIndex-1].step_products[0].product_steps)[currentStepIndex2-1].combo" />
          </div>
          <!-- stage 2 -->
        </div>
      </div>
    </div>
    <vtto-catalog-confirm-steps-button :active="buttonSteps" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VttoCatalogConfirmStepsButton from '@/components/Catalog/Steps/VttoConfirmStepsButton'
import VttoCarouselSteps from '@/components/VttoCarouselSteps'
import VttoSimpleProduct from '@/components/Catalog/new/VttoSimpleProduct'
import VttoCatalogDetailIngredients from '@/components/Catalog/Detail/VttoIngredients'
import VttoStepsItems from '@/components/Catalog/VttoStepsItems'
export default {
  components: {
    VttoCatalogConfirmStepsButton,
    VttoCarouselSteps,
    VttoSimpleProduct,
    VttoCatalogDetailIngredients,
    VttoStepsItems
  },
  data () {
    return {
      buttonSteps: true
    }
  },
  methods: {
    productFormated (product, index) {
      return {
        product_image: product.product_image,
        id: index,
        product_name: product.product_name,
        product_description: product.product_description,
        prices: {
          price: product.product_sizes[0].size_amount,
          promo: product.product_sizes[0].size_amount_promo,
          loyalty: product.product_sizes[0].size_amount_loyalty,
          minor: false
        }
      }
    },
    getSteps (productSteps) {
      let res = []
      Object.entries(productSteps).map(a => {
        res.push({
          name: a[1].step_name,
          combo: a[1]
        })
      })
      return res
    }
  },
  mounted () {
    this.$scrollTo('#combo')
  },
  computed: {
    steps: function () {
      let res = []
      if (this.params.product.product_steps) {
        Object.entries(this.params.product.product_steps).map(a => {
          res.push({
            name: a[1].step_name,
            combo: a[1]
          })
        })
      }
      return res
    },
    currentStepIndex: function () {
      if (this.$store.state.cart.steps[0]) {
        return this.$store.state.cart.steps[0].step
      }
      return 1
    },
    currentStepIndex2: function () {
      if (this.$store.state.cart.steps[1]) {
        return this.$store.state.cart.steps[1].step
      }
      return 1
    },
    ...mapState({
      currentItem: state => state.cart.currentItem,
      params: state => state.route.params,
      stage: state => state.cart.stage
    })
  }
}
</script>
