<template>
    <div>
        <div v-if="isOpened && !isMobile" class="bb-1-gray p-15 d-flex flex-center justify-between">
            <h3 class="fw-900 text-uppercase d-block fs-s">Alterar Senha</h3>
        </div>

        <div class="bg-white of-x-hide">
            <div class="slide-box bg-white" :class="[{ opened: isOpened }, { hidden: isHidden }]">
                <div class="px-xs-15">
                    <div class="edit-title-mobile">
                        <div class="bb-1-gray p-15 d-flex flex-center justify-between">
                            <h3 class="fw-900 text-uppercase d-block fs-s">Altere sua senha</h3>
                            <i @click="slide(false)" class="fas fa-times fs-1-6 pointer"></i>
                        </div>
                    </div>

                    <p class="fw-700 d-block text-uppercase mt-15">Senha atual</p>
                    <input @focus="scrollToMe('.opened')" v-model="oPassword.current_password" type="password"
                           class="form-control mt-10 br-0 fs-s" placeholder="preencha sua senha atual"/>
                    <p class="fw-700 d-block text-uppercase mt-15">Nova Senha (min. 6 caracteres)</p>
                    <input @focus="scrollToMe('.opened')" v-model="oPassword.password" type="password"
                           class="form-control mt-10 br-0 fs-s" placeholder="escolha uma senha"/>
                    <p class="fw-700 d-block text-uppercase mt-15">Repita a nova senha</p>
                    <input @focus="scrollToMe('.opened')" v-model="oPassword.password2" type="password"
                           class="form-control mt-10 br-0 fs-s" placeholder="confirme e repita a senha"/>
                    <div class="p-20 text-center d-block">
                        <button @click="updatePassword"
                                class="btn btn-primary text-uppercase text-center mx-auto fs-s mbslidexs">
                            Salvar senha
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-block  p-15 text-center d-block bt-1-gray">
            <button v-if="!isOpened" @click="slide(true)" class="btn btn-secondary text-uppercase text-center fs-s">
                Alterar
                senha
            </button>
            <button v-else @click="slide(false)" class="btn btn-secondary text-uppercase text-center fs-s">Cancelar
            </button>
        </div>
    </div>
</template>

<script>
import apiAccount from '@/api/account'
import { showAlert } from '@/plugins/alerts'
import { scrollToMe } from '@/plugins/scroll'
import router from '@/router'

export default {
  data: function () {
    return {
      isOpened: false,
      isHidden: true,
      oPassword: {
        current_password: '',
        password: '',
        password2: ''
      }
    }
  },

  computed: {
    isMobile () {
      return this.$store.state.store.isMobile
    }
  },

  methods: {
    updatePassword: function () {
      apiAccount.UpdatePassword(this.oPassword).then(function (response) {
        if (!response || !response.data || response.data.success === false) {
          showAlert('error', response.data.errors[0])
          return false
        } else {
          showAlert('success', 'Atualizado com sucesso!')
          router.push({ name: 'homeCatalog' })
        }
      })
    },

    slide: function (toggle) {
      if (toggle) {
        this.isHidden = !this.isHidden
        setTimeout(() => {
          this.isOpened = !this.isOpened
        }, 30)
      } else if (!toggle) {
        this.isOpened = !this.isOpened
        setTimeout(() => {
          this.isHidden = !this.isHidden
        }, 450)
      } else {
        console.log('Valor de slide inválido!')
      }
    },
    scrollToMe: function (box) {
      scrollToMe(box, -40)
    }
  }
}
</script>
