<template>
  <nav class="navbar navbar-expand-lg bg-main d-none d-lg-block col-12">
    <ul class="navbar-nav mr-auto text-uppercase">
      <!-- Link sem dropdown -->
      <li class="nav-item">
          <router-link :to="{name: 'homeCatalog'}" class="nav-link color-white" >Cardápio</router-link>
      </li>
      <!-- FIM Link sem dropdown -->
      <!-- Separador menu -->
      <li class="nav-item">
          <span class="nav-link color-white">|</span>
      </li>
      <!-- FIM separador -->
      <!-- Link com dropdown -->
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle color-white" href="index.html" id="navbarCategories" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Categorias
        </a>
        <!-- Submenu -->
        <ul class="dropdown-menu" aria-labelledby="navbarCategories">
          <!-- Categoria -->
          <div v-for="(aCategory, iCategory) in getCatalogFormated()" :key="iCategory">
            <li>
              <a class="pointer" v-scroll-to="`#category-${aCategory.category_id}`">
                <span class="bg-gray fw-900 p-10 d-block o-80 fs-0-7 text-uppercase">
                  {{aCategory.category_name}}
                </span>
              </a>
            </li>
            <!-- FIM Categoria -->

            <!-- Subcategoria -->
            <li v-for="(aSubcategory, iSubcategory) in aCategory.category_subcategories" :key="iSubcategory">
              <a class="pointer" v-scroll-to="`#category-${aSubcategory.category_id}`">
                <span class="title-subcategory d-block fs-0-7" href="#category-menu-6">
                  <i class="color-main fa fa-caret-right" aria-hidden="true"></i>
                  {{aSubcategory.category_name}}
                </span>
              </a>
            </li>
            <!-- FIM Subcategoria -->
          </div>
        </ul>
        <!-- FIM Submenu -->
      </li>
      <!-- FIM Link com dropdown -->
      <li class="nav-item">
          <span class="nav-link color-white">|</span>
      </li>
      <li class="nav-item">
          <router-link :to="{name: 'about-time'}" class="nav-link color-white">
            <span v-if="time_info">{{time_info}}</span>
            <span v-else>
              <span v-if="openNow()">Aberto agora</span>
              <span v-else>Fechado agora </span>
            </span>
          </router-link>
      </li>
      <li class="nav-item">
          <span class="nav-link color-white">|</span>
      </li>
      <li class="nav-item">
          <router-link :to="{name: 'about-delivery'}"  class="nav-link color-white" >Regiões de entrega</router-link>
      </li>
    </ul>
  </nav>
</template>
<script>
import { showAlert } from '@/plugins/alerts'

import { mapState } from 'vuex'
export default {
  data () {
    return {
      isOpen: false
    }
  },
  created () {
  },
  methods: {
    getCatalogFormated () {
      let res = []
      for (let i in this.catalog) {
        if (this.catalog[i].category_products) {
          res.push(this.catalog[i])
        }

        if (this.catalog[i].category_subcategories) {
          if (this.catalog[i].category_subcategories.length > 0) {
            res.push(this.catalog[i])
          }
        }
      }

      return res
    },
    openNow () {
      if (this.info && this.info.working_time.is_open) {
        var outdated = 0
        var addressID = 0
        var addsCheck = 0

        addsCheck = localStorage.getItem('addsCheck')

        if (this.$store.state.account.isLogged && addsCheck !== '1') {
          for (addressID in this.$store.state.account.oUserAddresses) {
            outdated += this.$store.state.account.oUserAddresses[addressID].outdated
          }
          if (outdated > 0) {
            setTimeout(() => {
              showAlert('error', 'Existe endereço para ser atualizado')
              localStorage.setItem('addsCheck', '1')
            }, 460)
            setTimeout(() => {
              location.href = '/front/meus-dados'
            }, 5000)
          }
        }
        return true
      }
      return false
    }
  },

  computed: {
    catalogFormated: function () {
      return this.$store.getters['catalog/getCatalogFormated']
    },
    ...mapState({
      info: state => state.store.info,
      time_info: state => state.store.info.working_time.description,
      catalog: state => state.catalog.catalog
    })
  }
}
</script>
