<template>
  <div>
    <div class="mb-15">
      <div @click="$emit('slide-item', 'obs')" class="bg-white p-15 bb-1-gray d-flex flex-center justify-between">
        <span class="text-uppercase fs-s fw-900">Observações</span>
        <div class="btn btn-secondary btn-s text-uppercase text-center fs-1-9 px-15 btn-s w-min-100">
          <i v-show="obs == false" class="fas fa-plus-circle mr-5"></i>
          <i v-show="obs != false" class="fas fa-edit mr-5"></i>

          <span v-if="!obs || obs.length < 1">Adicionar</span>
          <span v-else>Editar</span>
        </div>
      </div>

      <div v-if="obs" class="box-content p-15 py-25">
        <p class="fs-1-2">{{ obs }}</p>
      </div>
    </div>

    <div class="mb-15">
      <div @click="$emit('slide-item', 'gift')" class="bg-white p-15 bb-1-gray d-flex flex-center justify-between">
        <span class="text-uppercase fs-s fw-900">Cupom de desconto</span>
        <div class="btn btn-secondary btn-s text-uppercase text-center fs-1-9 px-15 btn-s w-min-100">
          <i v-show="gift == false" class="fas fa-plus-circle mr-5"></i>
          <i v-show="gift != false" class="fas fa-check-circle mr-5"></i>

          <span v-if="!gift || gift.length < 1">Adicionar</span>
          <span v-else>Inserido</span>
        </div>
      </div>

      <div v-if="gift" class="box-content p-15 py-25">
        <p class="fs-1-2 class-success">{{ gift }}</p>
      </div>
    </div>

    <div class="mb-15" v-if="withoutRegistration">
      <div @click="$emit('slide-item', 'customer')" class="bg-white p-15 bb-1-gray d-flex flex-center justify-between">
        <span class="text-uppercase fs-s fw-900">Usuário</span>
        <div class="btn btn-secondary btn-s text-uppercase text-center fs-1-9 px-15 btn-s w-min-100">
            <i class="fas fa-edit mr-5"></i>
            Adicionar
          </div>
      </div>

      <div v-if="customer" class="box-content p-15 py-25">
       <div class="box-content p-15 py-25">
        <div class="d-flex flex-center justify-between fs-1-2">
          <p v-if="customer.firtsname" class="text text-uppercase" >Nome: {{ customer.firtsname }}</p>
          <p v-if="customer.phone" class="text px-30 pr-0" >Telefone: {{ customer.phone }}</p>
          <p v-if="customer.email" class="text px-30 pr-0" >Email: {{ customer.email }}</p>
        </div>
      </div>
      </div>
    </div>

    <div class="mb-15">
      <div @click="$emit('slide-item', 'delivery')" class="bg-white p-15 bb-1-gray">
        <div class="d-flex flex-center justify-between">
          <span class="text-uppercase fs-s fw-900">Entrega</span>
          <div class="btn btn-secondary btn-s text-uppercase text-center fs-1-9 px-15 btn-s w-min-100">
            <i class="fas fa-edit mr-5"></i>
            Alterar
          </div>
        </div>
      </div>

      <div class="box-content p-15 py-25">
        <div class="d-flex flex-center justify-between fs-1-2">
          <p class="number text-uppercase">{{ addrText }}</p>
          <p class="number px-30 pr-0">{{ addrPrice | money }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obs: {},
    gift: {},
    addrText: {},
    addrPrice: {},
    customer: {}
  },
  computed: {
    isMobile () {
      return this.$store.state.store.isMobile
    },
    withoutRegistration () {
      return this.$store.state.order.oOrder.order_without_registration
    }
  }
}
</script>
