<template>
  <div class="bg-white d-block mb-20">
      <div class="bb-1-gray p-15 d-flex flex-center justify-between">
          <h3 class="fw-900 text-uppercase d-block fs-s">Cupom de Desconto</h3>
          <i @click="close()" class="fas fa-times fs-1-6 pointer d-block d-sm-none"></i>
      </div>

      <div class="p-15">
          <input name="input-cupom" type="text" class="form-control border-0 fs-s" v-on:blur="validateCoupon" placeholder="Tem um cupom de desconto? Insira aqui" v-model="sOrderCoupon" />
      </div>
      <p class="p-15 text-center">
        <button @click="validateCoupon" v-if="bMessageCoupon === false" class="btn btn-primary text-uppercase fs-1-2-xs">Validar Cupom de Desconto</button>
      </p>
      <p class="p-15 pb-30 fs-m fw-700 w-100 text-center" v-if="sMessageCoupon" :class="{'class-success': bMessageCoupon, 'class-error': !bMessageCoupon}">
        <span>{{sMessageCoupon}}</span>
      </p>
  </div>
</template>

<script>
import apiCoupon from '@/api/coupon'
import { setOrderInfo } from '@/plugins/orders'
import { sPrice } from '@/utils'
import { mapState } from 'vuex'
import { showAlert } from '@/plugins/alerts'

export default {
  name: 'vtto-checkout-obs',
  data: function () {
    return {
      sOrderCoupon: '',
      sMessageCoupon: '',
      bMessageCoupon: false,
      isSuccess: false,
      xCoupon: false
    }
  },
  props: {
    fSubtotal: {
      type: Number,
      default: 0
    },
    fTotalDelivery: {}
  },
  computed: {
    ...mapState({
      oOrder: state => state.order.oOrder
    })
  },
  methods: {
    calculateCoupon: function (oCoupon) {
      let fTotalDiscount = 0
      /* Desconto na entrega */
      if (oCoupon.discount_target === 'delivery') {
        if (oCoupon.discount_type === 'percent') {
          fTotalDiscount = parseFloat((this.fTotalDelivery * oCoupon.discount) / 100).toFixed(2)
        } else {
          fTotalDiscount = oCoupon.discount
        }
      /* Desconto no pedido */
      } else {
        /* Desconto em porcentagem */
        if (oCoupon.discount_type === 'percent') {
          fTotalDiscount = parseFloat((this.fSubtotal * oCoupon.discount) / 100).toFixed(2)
        /* Desconto em valor */
        } else {
          fTotalDiscount = oCoupon.discount
        }
      }

      setOrderInfo('total_discount', fTotalDiscount)
      this.$emit('updateTotalDiscount', fTotalDiscount)
    },
    showCoupon: function (oCoupon) {
      /*
      discount_type => percent / absolut
      discount_target => order / delivery
      */
      this.xCoupon = oCoupon
      let sMessageCoupon = 'Você ganhou '
      if (oCoupon.discount_type === 'percent') {
        sMessageCoupon += oCoupon.discount + ' % de desconto'
      } else {
        sMessageCoupon += sPrice(oCoupon.discount) + ' de desconto'
      }

      if (oCoupon.discount_target === 'order') {
        sMessageCoupon += ' no valor do pedido!'
      } else {
        sMessageCoupon += ' no valor da entrega!'
      }

      this.sMessageCoupon = sMessageCoupon
      this.bMessageCoupon = true
    },
    validateCoupon: function () {
      let self = this
      let oCoupon = {
        coupon: self.sOrderCoupon,
        without_registration: self.oOrder.order_without_registration
      }

      apiCoupon.validateCoupon(oCoupon).then(function (response) {
        if (!response || !response.data || response.data.success === false) {
          showAlert('error', response.data.errors)
          self.sMessageCoupon = response.data.errors
          self.sOrderCoupon = ''
        } else {
          let aData = response.data
          self.$emit('gift-payments', aData.data.payment_methods)
          self.showCoupon(aData.data)
          self.calculateCoupon(aData.data)
          self.isSuccess = true
          self.close()
        }
      })
    },
    close () {
      if (this.isSuccess) {
        this.$emit('have-gift', this.sMessageCoupon)
      } else {
        this.$emit('have-gift', false)
      }
    }
  },
  mounted () {
    window.addEventListener('popstate', this.close)
  },
  created () {
    window.history.pushState(null, null, window.location.href)
  },
  watch: {
    sOrderCoupon: function (value) {
      setOrderInfo('coupon', this.sOrderCoupon)
    },
    fSubtotal: function (value) {
      if (this.xCoupon) {
        this.calculateCoupon(this.xCoupon)
      }
    },
    fTotalDelivery: function (value) {
      if (this.xCoupon) {
        this.calculateCoupon(this.xCoupon)
      }
    }
  }
}
</script>
