import Vue from 'vue'
import GAuth from 'vue-google-oauth2'

export function ConfigGoogleAuth (sGoogleKey = '') {
  const oGoogleOPtionAuth = {
    clientId: sGoogleKey,
    scope: 'profile email',
    prompt: 'consent',
    fetch_basic_profile: true
  }
  Vue.use(GAuth, oGoogleOPtionAuth)
}
