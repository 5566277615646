import vueStore from '../store'
import vueRouter from '../router'

if (window.ReactNativeWebView) {
  const _getSessionStorage = () => {
    return Object.assign(
      {},
      ...(
        new Array(sessionStorage.length).fill('0').map(
          (item, index) => {
            let obj = {}
            obj[sessionStorage.key(index)] = sessionStorage.getItem(sessionStorage.key(index))
            return obj
          }
        )
      )
    )
  }

  window.addEventListener('storage', () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: 'setSessionStorage',
        data: _getSessionStorage()
      })
    )
  })

  const _getLocalStorage = () => {
    return Object.assign(
      {},
      ...(
        new Array(localStorage.length).fill('0').map(
          (item, index) => {
            let obj = {}
            obj[localStorage.key(index)] = localStorage.getItem(localStorage.key(index))
            return obj
          }
        )
      )
    )
  }

  window.addEventListener('storage', () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: 'setLocalStorage',
        data: _getLocalStorage()
      })
    )
  })

  document.addEventListener('message', function (e) {
    let message = JSON.parse(e.data)
    console.log(message)
    switch (message.type) {
      case 'loadSessionStorage':
        Object.entries(message.data).map((entry, index) => {
          console.log(entry[0], entry[1])
          sessionStorage.setItem(entry[0], entry[1])
        })
        break
      case 'loadLocalStorage':
        Object.entries(message.data).map((entry, index) => {
          console.log(entry[0], entry[1])
          localStorage.setItem(entry[0], entry[1])
        })
        break
      case 'appConnected':
        vueStore.dispatch('account/getUser')
        vueStore.dispatch('account/getUserAddresses')
        vueStore.dispatch('account/getIsLogged')
        vueRouter.push({ name: 'homeCatalog' })
        break
      case 'fbCreate':
        vueRouter.push({
          name: 'AccountCreate',
          params: {
            fbEmail: message.data.email
          }
        })
        break
      case 'mounted':
        vueStore.dispatch('account/getUser')
        vueStore.dispatch('account/getUserAddresses')
        vueStore.dispatch('account/getIsLogged')
        vueRouter.push({ name: 'homeCatalog' })
        break
    }
  })

  if (vueStore) {
    vueStore.subscribe((mutation, state) => {
      let user = mutation.payload
      let xKey = localStorage.getItem('vtto.account.x-user-key')
      switch (mutation.type) {
        case 'account/cleanUser':
          window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'appLogout' }))
          break
        case 'account/setUser':
          if (xKey && user) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                type: 'updateClient',
                data: { xKey, user }
              })
            )
          }
          break
      }
    })
  }
}

export default class AppBridge {
  static isApp () {
    return !!window.ReactNativeWebView
  }

  static emit (type, data) {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type, data }))
    }
  }

  static listen (event, callback) {
    if (window.ReactNativeWebView) {
      document.addEventListener('message', function (e) {
        let message = JSON.parse(e.data)
        if (message.type == event) {
          callback(message)
        }
      })
    }
  }
}
