<template>
    <div class="row">
        <div class="col-12">
            <ul class="nav-tabs list-table w-100 bg-white mb-15 text-center d-flex">
                <li class="list-table-item relative" :class="{'active' : $route.name == 'login'}">
                    <router-link :to="{ name: 'login'}" class="fw-900 fs-s text-uppercase p-15 d-block">Login
                    </router-link>
                </li>
                <li class="list-table-item relative" :class="{'active' : $route.name == 'AccountCreate' }">
                    <router-link :to="{ name: 'AccountCreate'}" class="fw-900 fs-s text-uppercase p-15 d-block">
                        Cadastre-se
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
  name: 'vtto-account-menu'
}
</script>
