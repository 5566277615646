<template>
    <div class="bg-white p-15 mt-15 bb-1-gray">
        <span class="text-uppercase fs-s fw-900">Observações</span>
        <input maxlength="250" @blur="addComments" @keyup.prevent=""  @keydown.enter.prevent="" v-model="comments" name="input-obs" type="text" class="form-control mt-10 br-0"
                  placeholder="Digite aqui suas observações" />
    </div>
</template>

<script>
import Bugsnag from '@bugsnag/js'

export default {
  data () {
    return {
      comments: ''
    }
  },
  methods: {
    addComments () {
      let currentItem = Object.assign({}, this.$store.state.cart.currentItem)
      currentItem.product_comments = this.comments
      try {
        Bugsnag.leaveBreadcrumb(this.comments)
        if (JSON.parse(sessionStorage.getItem('vtto.store.info'))['collect_memos'] == 1) {
          localStorage.setItem('has_memos', 1)
          throw new Error('memos aqui')
        }
      } catch (error) {
        Bugsnag.notify(error)
      }
      this.$store.dispatch('cart/setProduct', currentItem)
    }
  }
}
</script>
