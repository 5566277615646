 <template>
    <div class="bg-white d-block p-20 text-center relative">
        <div class="text-center col-md-4 mx-auto">
            <img loading="lazy" v-if="image" class="img-fluid" :src="image" alt="QR_CODE_PAYMENT" />
        </div>
        <div>
          <p class="p-15 fw-300 fs-s text-center link-copy" v-if="url">{{url}}</p>
          <p class="p-15 fw-300 mt-5" v-if="!isMobile()">Copie o link acima e cole no seu app de seu banco para fazer o pagamento</p>
            <p class="p-15 text-center" v-if="isMobile()">
              <button @click="copy" class="btn btn-primary text-uppercase fs-1-2-xs">Copiar</button>
            </p>
            <p class="p-5 text-center fw-300 fs-s" v-if="message">{{message}}</p>
        </div>
    </div>
</template>
<script>

export default {
  name: 'vtto-order-status-awaiting-payment',
  props: {
    image: {
      type: [String],
      default: ''
    },
    url: {
      type: [String],
      default: ''
    }
  },
  data () {
    return {
      message: ''
    }
  },
  methods: {
    copy: function () {
      try {
        navigator.clipboard.writeText(this.url)
          .then(() => {
            this.message = 'Link Copiado com sucesso.'
          })
          .catch(e => {
            console.error(e)
            this.message = 'Error ao copiar link'
          })
      } catch (err) {
        console.error(err)
      }
    },
    isMobile () {
      if (navigator.userAgent.includes('Vitto-app-android', 'Vitto-app-ios')) {
        return false
      }

      return true
    }
  }
}
</script>
