<template>
  <div class="d-flex w-100" :class="[level === 1 || level === 4 ? 'step-thin' : '', level === 2 || level === 5 ? 'step-superthin' : '']">
      <div class="step-bar w-100" v-for="(step, index) in steps" :key="index" :class="classActive(index)"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import 'owl.carousel'
export default {
  data () {
    return {
      level: ''
    }
  },
  props: {
    steps: {},
    currentStep: {
      default: 1
    }
  },
  methods: {
    classActive (index) {
      if (this.currentStep === index + 1) {
        return 'step-active'
      }

      if (this.currentStep > index + 1) {
        return 'step-complete'
      }
      return ''
    }
  },
  created () {
    this.$store.dispatch('store/setCarousel', true)
    this.$eventHub.$on('carousel', () => {
      window.$(this.$el).trigger('to.owl.carousel', this.currentStep)
    })
  },
  computed: {
    ...mapState({
      carousel: state => state.store.carousel
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../../node_modules/owl.carousel/dist/assets/owl.carousel.min.css';
@import '../../node_modules/owl.carousel/dist/assets/owl.theme.default.min.css';
</style>
