import Api from './api'

import { getXUserKey, getXUserKeyOrder } from '@/plugins/account'

export default {
  /* Lista extrato de pontos do cliente */
  ListOrderReport (oPageConfig) {
    return Api('order', 'report', 'get', oPageConfig, { 'x-user-key': getXUserKey() })
  },

  GetOrderDetail (oOrder) {
    return Api('order', 'details', 'get', oOrder, { 'x-user-key': getXUserKey() })
  },

  GetOrderStatus (oOrder) {
    return Api('order', 'status', 'get', oOrder, { 'x-user-key': (oOrder.order_without_registration ? getXUserKeyOrder() : getXUserKey()) }, false)
  },

  mktplcUpdateOrder (data) {
    return Api('mktplc', 'w-update-order', 'post', data, {})
  },

  getOrderStatusWithoutRegister (data) {
    return Api('order', 'get-order', 'post', data, {})
  }
}
