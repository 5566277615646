const state = {
  sSuccess: '',
  sError: '',
  sWarning: '',
  sFixed: '',
  iTimeout: 3000
}

const getters = {}

const actions = {
  setAlert ({ commit }, oMessage) {
    commit('setAlerts', oMessage)
    /* Depois de 3 segundos limpa a mensagem */
    setTimeout(function () {
      oMessage.sType = 'clean'
      commit('setAlerts', oMessage)
    }, oMessage.iTimeout)
  }
}

const mutations = {
  /* Atualiza Item do carrinho */
  setAlerts (state, oMessage) {
    if (oMessage.iTimeout) {
      state.iTimeout = oMessage.iTimeout
    }
    switch (oMessage.sType) {
      case 'success':
        state.sSuccess = oMessage.sInfo
        break
      case 'error':
        state.sError = oMessage.sInfo
        break
      case 'warning':
        state.sWarning = oMessage.sInfo
        break
      case 'fixed':
        state.sFixed = oMessage.sInfo
        break
      case 'clean':
        state.sSuccess = ''
        state.sError = ''
        state.sWarning = ''
        state.sFixed = ''
        state.iTimeout = 3000
        break
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
