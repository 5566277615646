<template>
    <div id="select">

      <!-- <pre>select: {{currentStage}} {{type}} {{currentItem}}</pre> -->

        <div class="row">
            <div class="col-lg-12">
              <!-- SE A CATEGORIA CONTIVER INGREDIENTES EXTRA (ingredients_extra_choice) -->
              <template v-if="currentStage === 0">
                <vtto-catalog-detail-info-extra @set-info-extra="setInfoExtra" v-if="currentItem.info_extra" :info-extra="currentItem.info_extra"/>
                <vtto-catalog-detail-ingredients-new v-if="currentItem.product_ingredients" :ingredients="currentItem.product_ingredients" @remove-ingredients="removeIngredients"/>

                <vtto-catalog-detail-ingredients-new v-if="currentItem.ingredients_extra_choice" :ingredients="currentItem.ingredients_extra_choice" :extra="true" @add-ingredients="addIngredientsExtra"/>
                <vtto-btn-steps @next="nextStep" :enable="'1'"/>
              </template>
              <template v-else>
                <div v-if="type === 1">
                  <!-- MONTAGEM -->
                  <vtto-catalog-mounting :size-position="currentItem.size_position" :parent="true" :current-item="currentItem"
                                          @extra-prices="extraPrices" @next="nextStep" @step-ingredients="resultSteps"
                                          :product-steps="cParam.product.product_steps" :size-id="currentItem.size_id"/>
                </div>
                <div v-if="type === 2">
                  <!-- PIZZA MEIO-A-MEIO -->
                  <vtto-step-parts @set-products="setParts" @set-edge="setEdge" @set-prices="setPrices"
                                    @next="nextStep" :edge="currentItem.size_edge_allowed"
                                    :qty-flavors="this.currentItem.qty_flavors" :flavors="cParam.product.products"
                                    :size-id="currentItem.size_id"/>
                </div>
                <div v-if="type === 3">
                  <!-- COMBO -->
                  <vtto-catalog-combo :product-steps="cParam.product.product_steps" @set-step="setStepCombo"
                                      @set-combo="setCombo"/>
                </div>
              </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import VttoStepParts from '@/components/Catalog/VttoStepParts'
import VttoCatalogMounting from '@/components/Catalog/VttoCatalogMounting'
import VttoCatalogCombo from '@/components/Catalog/VttoCombo'
import VttoCatalogDetailIngredientsNew from '@/components/Catalog/new/VttoIngredients'
import VttoCatalogDetailInfoExtra from '@/components/Catalog/Detail/VttoInfoExtra'
import VttoBtnSteps from '@/components/Catalog/new/VttoBtnSteps'

export default {
  components: {
    VttoStepParts,
    VttoCatalogMounting,
    VttoCatalogCombo,
    VttoCatalogDetailIngredientsNew,
    VttoBtnSteps,
    VttoCatalogDetailInfoExtra
  },
  data () {
    return {
      buttonSteps: true,
      qtySteps: 0,
      /**
       * type
       * 1 = montagem
       * 2 = meio a meio
       * 3 = combo
       */
      type: 0,
      cParam: {},
      currentStage: 1,
      btnEnable: true
    }
  },
  methods: {
    addIngredientsExtra (ingredientExtra) {
      this.currentItem.ingredients_extra = ingredientExtra
      this.$store.dispatch('cart/setProduct', this.currentItem)
    },
    setInfoExtra (option) {
      this.currentItem.info_extra_option_id = option.option_id
      this.currentItem.info_extra_option_name = option.option_name
      this.currentItem.info_extra_option_amount = option.option_amount
      this.$store.dispatch('cart/setProduct', this.currentItem)
    },
    removeIngredients (ingredients) {
      this.currentItem.remove_ingredients = ingredients
      this.$store.dispatch('cart/setProduct', this.currentItem)
    },

    setEdge (edge) {
      delete this.currentItem.edge_id
      delete this.currentItem.edge_name
      delete this.currentItem.edge_amount
      delete this.currentItem.size_edges

      if (edge !== 0) {
        this.currentItem.edge_id = edge.edge_id
        this.currentItem.edge_name = edge.edge_name
        this.currentItem.edge_amount = parseFloat(edge.edge_amount)
        this.currentItem.size_edges = this.currentItem.products[0].product_size.size_edges
      }
      this.$store.dispatch('cart/setProduct', this.currentItem)
    },
    extraPrices (extra) {
      let currentItemPrices = this.currentItemPrices
      currentItemPrices.products[0].extra = extra.extra
      this.$store.dispatch('cart/setPrices', currentItemPrices)
    },
    resultSteps (steps) {
      let amountExtra = 0
      for (let i in steps) {
        for (let k in steps[i].ingredients_extra) {
          amountExtra += steps[i].ingredients_extra[k].ingredient_amount * steps[i].ingredients_extra[k].ingredient_quantity
        }
        if (typeof steps[i].step_ingredients !== 'undefined') {
          for (let k in steps[i].step_ingredients) {
            amountExtra += steps[i].step_ingredients[k].ingredient_amount * steps[i].step_ingredients[k].ingredient_quantity
          }
        }
      }

      if (amountExtra > 0) {
        let currentItemPrices = this.currentItemPrices
        currentItemPrices.products[0].ingredientsExtra = amountExtra
        this.$store.dispatch('cart/setPrices', currentItemPrices)
      }
      this.currentItem.product_steps = steps
      this.$store.dispatch('cart/setProduct', this.currentItem)
    },
    nextStep (step) {
      if (this.currentStage === 0) {
        this.currentStage = 1
      } else {
        if (step === 'end') {
          return this.$router.push({ name: 'detail', params: this.cParam })
        }
      }
    },
    setParts (products) {
      this.currentItem.products = products.products
      this.currentItem.allow_loyalty_payment = products.allow_loyalty_payment
      this.currentItem.product_prices = products.product_prices
      this.$store.dispatch('cart/setProduct', this.currentItem)
    },
    setCombo (combo) {
      this.$forceUpdate()
      this.currentItem.product_steps = combo
      this.$store.dispatch('cart/setProduct', this.currentItem)
    },
    setStepCombo (step) {
      if (step === 'end') {
        return this.$router.push({ name: 'detail', params: this.cParam })
      }
    },

    setPrices (price) {
      this.$store.dispatch('cart/setPrices', price)
    }
  },
  mounted () {
    this.cParam = Object.assign({}, this.params)

    /** definir type */
    if (this.cParam.product) {
      if (this.cParam.product.product_steps && this.cParam.product.product_id && this.currentItem.type === 2) {
        /** montagem */
        this.type = 1
        this.qtySteps = this.cParam.product.product_steps.length
        return
      }
      /** definir quantidade de steps */
      if (this.cParam.product.product_steps) {
        this.$store.dispatch('cart/setSteps', [{ step: 1, end: this.cParam.product.product_steps.length }])
      }
      if (!this.cParam.product.product_id) {
        /** meio a meio */
        this.type = 2
        return
      }
      if (this.cParam.product.category_type) {
        this.type = 3
        return
      }
    }

    if (
      this.type &&
      this.type !== 2 && (
        (this.currentItem.info_extra && this.currentItem.info_extra.length > 0) ||
        (this.currentItem.product_ingredients && this.currentItem.product_ingredients.length > 0) ||
        (this.currentItem.ingredients_extra_choice && this.currentItem.ingredients_extra_choice.length > 0))
    ) {
      this.currentStage = 0
    }
  },
  beforeRouteEnter (to, from, next) {
    if (!to.params.product) {
      next({ path: '/front' })
    }
    next()
  },
  computed: mapState({
    info: state => state.store.info,
    params: state => state.route.params,
    currentItem: state => state.cart.currentItem,
    currentItemPrices: state => state.cart.currentItemPrices
  })
}
</script>
