<template>
    <div class="cart">
        <div class="row">
            <div class="col-12">
                <div class="bg-white d-block mb-20">
                    <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Seu Pedido</h3>
                    <div v-if="cart.length>0">
                      <!--  -->
                      <vtto-products :items="cart" :cart="true"></vtto-products>

                      <div class="text-right d-block bb-1-gray p-15 fs-m">
                          Subtotal: <span class="fs-m color-main price">{{ total() | money }}</span>
                      </div>

                      <div class="p-15 text-center d-block">
                          <a v-if="isOpen" active-class="active"
                                       class="btn btn-primary text-uppercase text-center mx-auto mb-2"
                                       @click="checkout()">{{getNameButton}}
                          </a>
                          <button v-else active-class="active"
                                  class="btn btn-primary text-uppercase text-center mx-auto">Loja Fechada
                          </button>
                          <a v-show="(isOpen && info.order_without_registration == 1 && this.$store.state.account.isLogged == false)" active-class="active"
                                       class="btn btn-primary text-uppercase text-center mx-auto"
                                       @click="checkout(1)">Fechar Pedido sem cadastro
                          </a>
                      </div>
                    </div>
                    <span v-if="cart.length===0" class="fw-400 text-uppercase p-15 d-block fs-s">Carrinho Vazio</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import VttoProducts from '@/components/VttoProducts'
import { mapState, mapGetters } from 'vuex'
import { showAlert } from '@/plugins/alerts'

export default {
  computed: {
    ...mapGetters('cart', ['totalCart', 'itemPrice']),
    ...mapGetters('store', ['isOpen']),
    ...mapState('cart', ['cart']),
    ...mapState('store', ['info']),
    getNameButton: function () {
      if (this.isOpen && this.info.order_without_registration == 1 && this.$store.state.account.isLogged == false) {
        return 'Fazer Login'
      }

      return 'Fechar pedido'
    }
  },
  components: {
    VttoProducts
  },
  methods: {
    checkout: function (oOrderType = 0) {
      this.$store.state.order.oOrder.order_without_registration = oOrderType
      if (oOrderType == 0) {
        this.$router.push({ name: 'checkout' })
        return
      }
      this.$router.push({ name: 'checkout-not-register' })
    },
    testEmptyCart: function () {
      if (this.cart.length === 0) {
        showAlert('error', 'Carrinho vazio!')
        if (this.$route.name !== 'homeCatalog') {
          this.$router.push({ name: 'homeCatalog' })
        }
      }
    },
    total () {
      let total = 0
      if (this.info.price_rule) {
        for (let i in this.cart) {
          total += this.cart[i].product_quantity * this.itemPrice(this.cart[i].product_prices, this.info.price_rule)
        }
      }
      return total
    }
  }
}
</script>
