<template>
    <div>
        <div class="bg-white p-15 mt-15 bb-1-gray">
            <span class="text-uppercase fs-s fw-900">{{ question }}</span>
        </div>

        <ul class="list-unstyled bg-white">
            <div class="card card-body br-0" v-for="(option, index) in options" :key="index">
                <li class="media m-0 relative">
                    <div class="media-body mr-10">
                        <label @click="setOption(option)" class="form-group-radio_group link-product" :for="`option-${index}`">
                            <input :id="`option-${index}`" type="radio" name="catalogFechado"
                                   :checked="index===0" class="form-group-radiobox" value="">
                            <span  class="form-group-check_radio"></span>
                            <span class="fw-700 fs-m">{{ option.option_name }}</span>
                        </label>
                    </div>
                    <div class="ml-10 text-right relative" v-if="option.option_amount > 0">
                        <p class="price fs-m color-main w-80">
                            <span class="color-dark-gray fs-0-8">{{ option.option_amount | money }}</span>
                        </p>
                    </div>
                </li>
            </div>
        </ul>
    </div>
</template>

<script>
export default {
  props: {
    infoExtra: {}
  },
  data () {
    return {
      question: '',
      options: []
    }
  },
  created () {
    if (typeof this.infoExtra !== 'undefined') {
      if (this.infoExtra[0]) {
        this.question = this.infoExtra[0].info_extra_question
        this.options = this.infoExtra[0].info_extra_options
        this.setOption(this.infoExtra[0].info_extra_options[0])
      } else {
        this.question = this.infoExtra.info_extra_question
        this.options = this.infoExtra.info_extra_options
        if (typeof this.infoExtra.info_extra_options !== 'undefined' && this.infoExtra.info_extra_options.length > 0) {
          this.setOption(this.infoExtra.info_extra_options[0])
        }
      }
    }
  },
  methods: {
    setOption (option) {
      this.$emit('set-info-extra', option)
    }
  }
}
</script>
