<template>
  <div v-if="show" class="loading bg-white fixed w-100 h-100-vh z-2000 d-flex flex-center center">
    <div>
        <div class="pulse"></div><br>
        <p class="center fs-m mt-5">Carregando</p>
    </div>
  </div>

  <!--  -->
</template>

<script>
export default {
  name: 'vitto-loading',
  props: {
    show: {
      default: true
    }
  }
}
</script>
