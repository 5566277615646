<template>
    <div class="bg-white d-block text-center relative p-20">
        <div class="loader">
            <div class="loader__figure"></div>
            <p class="loader__label">{{formatTime}}</p>
        </div>
        <div class="pt-200 mt-20">
            <p class="fw-900 fs-s text-uppercase">Isso poderá levar alguns minutos, por favor não feche a página</p>
            <p class="fw-400 fs-s ">Normalmente confirmamos os pedidos bem rápido<br>Mas fique tranquilo, caso o pedido
                não seja confirmado nos próximos minutos, você será avisado <b>por e-mail</b> ;)</p>
            <vtto-dead-line v-if="hasDeadline" :sOrderOrigin='origin' :aDeadlineDesk='deadlineDesk' :aDeadlineDelivery='deadlineDelivery' />
        </div>
    </div>
</template>

<script>
import VttoDeadLine from './VttoDeadLine.vue'
export default {
  name: 'vtto-waiting',
  components: {
    VttoDeadLine
  },
  data: function () {
    return {
      origin: '',
      oDeadLine: {}
    }
  },
  props: {
    formatTime: {}
  },

  computed: {
    hasDeadline () {
      return (this.oDeadLine && (this.oDeadLine.desk || this.oDeadLine.delivery))
    },
    deadlineDesk () {
      return this.oDeadLine.desk ? [...this.oDeadLine.desk] : null
    },
    deadlineDelivery () {
      return this.oDeadLine.delivery ? [...this.oDeadLine.delivery] : null
    }
  },
  mounted () {
    const sTypeDelivery = this.$store.state.order.oOrder.delivery_address.origin

    this.origin = sTypeDelivery
    this.oDeadLine = this.$store.state.store.info.deadline
  }
}
</script>
