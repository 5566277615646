var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"bg-white mb-20"},[_c('div',{staticClass:"d-lg-flex justify-content-lg-between bb-1-gray"},[_c('span',{staticClass:"fw-900 text-uppercase p-15 fs-s d-block"},[_vm._v("Seus pontos")]),_c('span',{staticClass:"fw-900 text-uppercase d-block p-15 fs-s color-main"},[_vm._v("Total: "+_vm._s(_vm.iPoints)+" ponto"),(_vm.iPoints != 1)?_c('span',[_vm._v("s")]):_vm._e()])]),_c('paginate',{staticClass:"paginate-list",attrs:{"name":"aPoints","list":_vm.aPoints,"per":10}},_vm._l((_vm.paginated('aPoints')),function(oLoyaltyPoint,iLoyaltyPointKey){return _c('div',{key:iLoyaltyPointKey,staticClass:"pedido bb-1-gray"},[_c('div',{staticClass:"d-lg-flex justify-content-lg-between"},[_c('div',{staticClass:"p-15 mt-10 fs-s d-block"},[(oLoyaltyPoint.order_id > 0)?[_vm._v(" Pedido "+_vm._s(oLoyaltyPoint.order_id)+" - "+_vm._s(oLoyaltyPoint.sCreated_at)+" "),_c('router-link',{staticClass:"color-main",attrs:{"to":{ name: 'order-detail', params: { iOrderId: oLoyaltyPoint.order_id}}}},[_vm._v("+ detalhes ")])]:_vm._e()],2),_c('div',{staticClass:"price p-15 pt-xs-0 text-lg-right"},[_c('span',{staticClass:"color-main fs-1-4"},[_vm._v(_vm._s(oLoyaltyPoint.points)+" pts")]),(oLoyaltyPoint.points_in_expiration !== null)?_c('span',[_vm._v(" (val. "+_vm._s(oLoyaltyPoint.loyalty.sExpirated_at)+")")]):_vm._e()])])])}),0),(_vm.aPoints.length > 10)?_c('paginate-links',{attrs:{"for":"aPoints","simple":{
                next: 'Próximo',
                prev: 'Anterior'
              },"classes":{
                'ul': ['d-flex', 'align-center','justify-center','list-unstyled'],
                'ul *': ['py-15','px-10'],
                '.next > a': ['btn', 'btn-secondary','fs-1-2'],
                '.prev > a': ['btn', 'btn-secondary','fs-1-2']
              }}}):_vm._e()],1)])]),(_vm.hasLoyalty)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"bg-white p-15 d-block text-center"},[_c('router-link',{staticClass:"btn btn-secondary text-uppercase text-center mx-auto",attrs:{"to":{ name: 'loyalty-rules'}}},[_vm._v(" Regulamento ")])],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }