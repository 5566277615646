import { empty } from '@/utils'
import store from '@/store'
import customStorage from '@/plugins/customStorage'

export function setXUserKey (xUserKey) {
  if (!empty(xUserKey)) {
    customStorage().set('vtto.account.x-user-key', xUserKey, true)
    store.dispatch('account/getUser')
    store.dispatch('account/getUserAddresses')
  } else {
    customStorage().remove('vtto.account.x-user-key')
    customStorage().remove('vtto.account.oUser')
    store.dispatch('account/getUser', null)
  }
  store.dispatch('account/getIsLogged')
}

export function setXUserKeyOrder (xUserKey) {
  if (!empty(xUserKey)) {
    customStorage().set('vtto.order_without_registration.x-user-key', xUserKey, true)
  }
}

export function getXUserKeyOrder () {
  return customStorage().get.item('vtto.order_without_registration.x-user-key')
}

export function getXUserKey (xUserKey) {
  return customStorage().get.item('vtto.account.x-user-key')
}
