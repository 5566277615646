<template>
    <div :class="{'box-content': !isChild}">
        <div v-for="(oItem, iItem) in items" :key="iItem" class="w-100 p-15"
             :class="[{'bb-fakespace': isMobile},{'bb-1-gray': !isMobile}]">
            <div class="media-body" :class="{'mb-20': isChild}">
                <div v-if="!isChild" class="fw-900 fs-m text-uppercase">
                    <p class="fs-1-2">
                        <span class="number mr-5" v-if="oItem.product_quantity">{{oItem.product_quantity}}x</span>
                        <span class="number mr-5" v-else>{{oItem.quantity}}x</span>
                        {{oItem.category_name !== false ? oItem.category_name : 'Item Removido'}}
                    </p>
                    <p v-if="oItem.subcategory_name">
                        {{oItem.subcategory_name !== false ? oItem.subcategory_name : 'Item Removido'}}
                    </p>
                </div>
                <div :class="[{'bt-1-darkgray': isChild},{'pt-15': isChild},{'w-100': isChild}]"
                     class="fw-500 fs-m text-uppercase">
                    <span v-if="isChild" class="number fw-700">{{oItem.product_quantity}}</span>
                    {{oItem.size_name}} <span v-if="!oItem.products && !oItem.combo">- {{oItem.product_name}}</span>
                </div>

                <!-- Adicionais -->
                <div v-if="oItem.add_ingredients" class="fw-300 fs-s mt-15">
                    <p class="fw-700">ADICIONAIS:</p>
                    <p v-for="(addIngredient, aiIndex) in oItem.add_ingredients" :key="aiIndex">
                        <span>{{ addIngredient.ingredient_quantity }}x</span> {{ addIngredient.ingredient_name }}
                        <span v-if="addIngredient.ingredient_amount > 0">({{ addIngredient.sPrice }})</span>
                    </p>
                </div>

                <div class="fw-300 fs-s mt-10" v-if="oItem.remove_ingredients">
                    <p class="fw-700">SEM:</p>
                    <p v-for="(sIngredient, iIngredient) in oItem.remove_ingredients" :key="iIngredient">-
                        {{sIngredient}}</p>
                </div>

                <!-- Informação extra -->
                <div v-if="oItem.info_extra" class="fw-300 fs-s mt-15">
                    <p class="fw-700 text-uppercase">{{ oItem.info_extra.info_extra_question }}</p>
                    <p>{{ oItem.info_extra.info_extra_options.option_name }}
                        <span v-if="oItem.info_extra.info_extra_options.option_amount"> aqui ({{ oItem.info_extra.info_extra_options.fPrice | money }})</span>
                    </p>
                </div>

                <vtto-order-item v-if="oItem.products" :isChild="true" :isHalf="true"
                                class="media m-0 relative bb-1-gray flex-column py-25"
                                :halves="oItem.products"></vtto-order-item>
                <vtto-order-item v-if="oItem.combo" :isChild="true"
                                class="media m-0 relative bb-1-gray flex-column py-25"
                                :items="oItem.combo"></vtto-order-item>

                <!-- Montagem -->
                <div v-if="oItem.product_steps" class="fw-300 fs-s mt-15">
                    <div v-for="(step, sIndex) in oItem.product_steps" :key="sIndex">
                        <p class="fw-700 text-uppercase">{{ step.step_name }}</p>
                        <div v-for="(stepIngredient, siIndex) in step.step_ingredients" :key="siIndex">
                            <p class="fw-500 text-uppercase mt-15">{{ stepIngredient.ingredient_quantity
                                }}x {{
                                stepIngredient.ingredient_name }}
                                <span v-if="stepIngredient.ingredient_amount && stepIngredient.sPrice"> ({{ stepIngredient.sPrice }})</span>
                            </p>
                        </div>
                        <!-- Informação extra -->
                        <div v-if="step.info_extra" class="fw-300 fs-s mt-15">
                            <p class="fw-700 text-uppercase">{{ step.info_extra.info_extra_question }}</p>
                            <p>{{ step.info_extra.info_extra_options.option_name }}
                                <span v-if="step.info_extra.info_extra_options.option_amount">aqui</span>
                            </p>
                        </div>
                    </div>
                </div>

                 <div v-if="isChild" class="fw-300 fs-s mt-15">
                     <div v-for="(items, sIndex) in oItem" :key="sIndex">
                         <div v-for="(item, sIndex) in items" :key="sIndex">
                            <p class="fw-700 text-uppercase">{{ item.step_name }}</p>
                             <div v-for="(stepIngredient, siIndex) in item.ingredients" :key="siIndex">
                                <p class="fw-500 text-uppercase mt-15" v-if="stepIngredient.ingredient_quantity"> {{ stepIngredient.ingredient_quantity
                                    }}x {{
                                    stepIngredient.ingredient_name }}
                                    <span v-if="stepIngredient.ingredient_amount">({{ stepIngredient.sPrice}})</span>
                                </p>
                            </div>
                        </div>
                     </div>
                </div>

                <!-- Borda -->
                <div v-if="oItem.edge_name " class="fw-300 fs-s mt-15">
                    <p><span class="fw-700">BORDA: </span> {{ oItem.edge_name }} <span v-if="oItem.edge_amount">({{ oItem.edge_amount | money }})</span>
                    </p>
                </div>

                <!-- Observações -->
                <div v-if="oItem.order_observation" class="fw-300 fs-s mt-15">
                    <p class="fw-700">OBS:</p>
                    <p>{{ oItem.order_observation }}</p>
                </div>

            </div>
        </div>
        <vtto-order-item v-for="(oItem, iItem) in halves" :key="iItem" :isChild="true" :isHalf="false"
            class="w-100 p-15"
            :class="[{'bb-fakespace': isMobile},{'bb-1-gray': !isMobile}]"
            :items="oItem"></vtto-order-item>
    </div>
</template>
<script>
export default {
  name: 'vtto-order-item',
  props: {
    items: {},
    halves: {},
    isChild: {
      type: Boolean,
      default: false
    },
    isHalf: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isMobile: function () {
      return this.$store.state.store.isMobile
    }
  }
}
</script>
