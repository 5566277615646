<!-- PRODUTO TIPO 2 = MEIO-A-MEIO -->
<template>
    <div>
        <div class="bg-white mt-15 m-xs-0">
            <vtto-carousel-steps :current-step="currentStep" :steps="getSteps()"/>
        </div>
        <div class="bg-white p-15 bb-1-gray fs-s">
            <span class="text-uppercase fw-900 number">{{ getSteps()[currentStep-1].name }}</span>
        </div>

        <div v-if="currentStage === 1 || currentStage === 0">
            <vtto-search v-if="!showEdges()" :catalog="newFlavors" @search="setFilteredFlavors" class="mb--15"></vtto-search>
            <div class="bg-white p-15 mt-15 bb-1-gray">
                <span class="text-uppercase fs-s fw-900">Escolha o Sabor</span>
            </div>

            <div v-if="filteredFlavors.length > 0">
              <ul class="list-unstyled bg-white" v-if="!showEdges()">
                  <div class="card card-body br-0 p-0" v-for="(product, index) in filteredFlavors" :key="index">
                      <vtto-simple-product @select-product="selectProduct" :end="qtyFlavors" :step="currentStep"
                                           :images-mobile="info.hidden_images_mobile" @stage="setStage" @step="setStep"
                                           :index="index" :product="formatProduct(product)" :is-combo="isCombo"/>
                  </div>
              </ul>
            </div>
            <div v-else>
              <div class="bg-white p-20 fs-1-2">
                <p>Nenhum produto foi encontrado!</p>
              </div>
            </div>

            <ul class="list-unstyled bg-white" v-if="showEdges()">
                <div class="card card-body br-0">
                    <li class="media m-0 relative">
                        <div class="media-body mr-10">
                            <label @click="setEdge(0)" class="form-group-radio_group link-product" for="edge-sem">
                                <input checked id="edge-sem" type="radio" name="edges" class="form-group-radiobox"
                                       value="">
                                <span class="form-group-check_radio"></span>
                                <span class="fw-700 fs-m">Sem Borda</span>
                            </label>
                        </div>
                    </li>
                </div>
                <div class="card card-body br-0" v-for="(edge, index) in sizeEdges" :key="index">
                    <li class="media m-0 relative">
                        <div class="media-body mr-10">
                            <label @click="setEdge(edge)" class="form-group-radio_group link-product" :for="`edge-${index}`">
                                <input :id="`edge-${index}`" type="radio" name="edges" class="form-group-radiobox"
                                       value="">
                                <span class="form-group-check_radio"></span>
                                <span class="fw-700 fs-m">{{ edge.edge_name }}</span>
                            </label>
                        </div>
                        <div class="ml-10 text-right relative">
                            <p class="price fs-m color-main w-80">
                                {{ edge.edge_amount | money }}
                            </p>
                        </div>
                    </li>
                </div>
            </ul>
        </div>

        <div v-else-if="currentStage === 2">
          <div v-show="inStage2 === 1">
            <vtto-catalog-detail-title :currentStep="currentStep"/>

            <vtto-catalog-detail-ingredients v-if="ingredientOnProdCombo() === 1" :ingredients="ingredients" :current-item="currentItem.products[0]"
                                            @remove-ingredients="removeIngredients" class="mb-15"/>

            <vtto-catalog-detail-ingredients v-if="ingredientOnProdCombo() === 2" :ingredients="ingredients" :current-item="currentProduct"
                                            @remove-ingredients="removeIngredients" class="mb-15"/>

            <vtto-catalog-detail-ingredients v-if="ingredientsExtra.length > 0" :extra="true"
                                             :ingredients="ingredientsExtra" @add-ingredients="addIngredients" class="mb-15"/>

            <vtto-catalog-step-info-extra v-if="ifInfoExtra(infoExtra)" :info-extra="infoExtra"
                                          @set-info-extra="setInfoExtra" class="mb-15"/>

            <div v-if="steps.length > 0" class="fixed bottom-0 w-100 d-block bg-white p-15 text-center" style="left: 0; z-index:1;">
              <button @click="inStage2 = 2" class="btn btn-primary text-uppercase text-center mx-auto" :disabled="!btn_enable_stage2">Próximo</button>
            </div>
          </div>

          <div v-show="inStage2 === 2">
            <vtto-catalog-mounting v-if="steps.length > 0" :parent="false" :product-steps="steps"
                                   @next="internalMouting" @step-ingredients="internalMoutingResult"
                                   :size-position="sizeId" :size-id="sizeId"/>
          </div>
        </div>

        <vtto-btn-steps
                  v-if="!hideButton && !(steps.length > 0 && inStage2 === 1 && currentStage === 2)"
                  @next="nextStep"
                  @next-stage="nextStage"
                  :stage="currentStage"
                  :current-step="currentStep"
                  :enable="btnEnable"
                  :closed="!info.working_time.is_open"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import VttoCarouselSteps from '@/components/VttoCarouselSteps'
import VttoSimpleProduct from '@/components/Catalog/new/VttoSimpleProduct'
import VttoBtnSteps from '@/components/Catalog/new/VttoBtnSteps'
import VttoCatalogDetailIngredients from '@/components/Catalog/new/VttoIngredients'
import VttoCatalogMounting from '@/components/Catalog/VttoCatalogMounting'
import VttoCatalogStepInfoExtra from '@/components/Catalog/new/VttoStepInfoExtra'
import VttoCatalogDetailTitle from '@/components/Catalog/Detail/VttoTitle'
import VttoSearch from '@/components/VttoSearch'

export default {
  props: {
    qtyFlavors: {},
    flavors: {},
    sizeId: {},
    edge: {},
    isCombo: {
      type: Boolean,
      default: false
    },
    isSpecialCombo: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentStep: 1,
      currentStage: 0,
      inStage2: 1,
      btnEnable: false,
      end: false,
      stepResult: [],
      ingredients: [],
      ingredientsExtra: [],
      steps: [],
      hideButton: false,
      removeFlavors: [],
      newFlavors: [],
      filteredFlavors: [],
      sizeEdges: [],
      tempRemoveFlavors: 0,
      infoExtra: {},
      edges: [],
      btn_enable_stage2: true
    }
  },
  components: {
    VttoCarouselSteps,
    VttoSimpleProduct,
    VttoBtnSteps,
    VttoCatalogDetailIngredients,
    VttoCatalogMounting,
    VttoCatalogStepInfoExtra,
    VttoCatalogDetailTitle,
    VttoSearch
  },
  created () {
    this.getFlavors()
    for (let i in this.flavors[0].product_sizes) {
      if (this.flavors[0].product_sizes[i].size_id === this.sizeId) {
        this.sizeEdges = this.flavors[0].product_sizes[i].size_edges
      }
    }
  },
  methods: {
    /**
     * verifica se possui ingredientes extras e retorna um dos segintes int:
     * 0 - não exibir ingredientes extras
     * 1 - produto com ingrediente extra
     * 2 - combo com ingrediente extra
     */
    ingredientOnProdCombo () {
      if (!this.ingredients.length > 0) return 0
      if (typeof (this.currentItem) === 'undefined') return 0
      if (this.currentItem.products === 'undefined') return 0
      if (this.isCombo === false) return 1
      return 2
    },
    /**
     * retorna os ingredientes do item no combo
     */
    currentProduct () {
      var productStep = this.currentItem.products_steps.slice(-1)[0]
      var stepProducts = productStep.step_products.slice(-1)[0]
      return stepProducts.products.slice(-1)[0]
    },
    ifInfoExtra (infoExtra) {
      if (!infoExtra) {
        return false
      }
      return Object.keys(infoExtra).length
    },
    setEdge (edge) {
      let newPrices = this.newPrices
      if (!edge) {
        newPrices.edge = 0
      } else {
        newPrices.edge = parseFloat(edge.edge_amount)
      }

      if (this.isCombo) {
        for (let i in this.$store.state.cart.currentItem.product_steps[0].step_products) {
          if (this.$store.state.cart.currentItem.product_steps[0].step_products[i].edge_amount) {
            newPrices.edge += parseFloat(this.$store.state.cart.currentItem.product_steps[0].step_products[i].edge_amount)
          }
        }
      }

      this.$emit('set-prices', newPrices)
      this.$emit('set-edge', edge)
    },
    showEdges () {
      if (this.currentStep === this.qtyFlavors + 1) {
        return true
      }
      return false
    },
    getFlavors () {
      let flavorsList = []
      for (let i in this.flavors) {
        if (this.flavors[i]) {
          flavorsList.push(this.flavors[i])
        }
      }
      this.newFlavors = flavorsList
    },
    internalMouting (step) {
      if (step === 'end') {
        const currentStep = this.currentStep
        this.hideButton = false
        this.currentStage = 0
        if (this.currentStep === this.qtyFlavors) {
          if (this.edge) {
            this.currentStep = currentStep + 1
            return
          }
          this.$emit('next', 'end')
          return
        }
        this.btnEnable = false
        this.currentStep = currentStep + 1
      }
    },
    internalMoutingResult (result) {
      this.stepResult[this.currentStep - 1].product_steps = result
      const res = {
        products: this.stepResult,
        allow_loyalty_payment: this.stepResult[this.currentStep - 1].product_size.allow_loyalty_payment,
        product_prices: {}
      }
      this.$emit('set-products', res)

      let newPrices = this.newPrices
      let saldo = 0
      for (let i in result) {
        for (let y in result[i].step_ingredients) {
          saldo += result[i].step_ingredients[y].ingredient_quantity * result[i].step_ingredients[y].ingredient_amount
        }
      }

      newPrices.products[this.currentStep - 1].mounting = saldo
      this.$emit('set-prices', newPrices)
    },
    removeIngredients (ingredients) {
      if (ingredients.length === 0) {
        if (this.stepResult[this.currentStep - 1].remove_ingredients) {
          delete this.stepResult[this.currentStep - 1].remove_ingredients
        }
      } else {
        this.stepResult[this.currentStep - 1].remove_ingredients = ingredients
      }
      // this.$emit('set-products', this.stepResult)
    },
    addIngredients (ingredients) {
      if (ingredients.length === 0) {
        if (this.stepResult[this.currentStep - 1].ingredients_extra) {
          delete this.stepResult[this.currentStep - 1].product_size.ingredients_extra
        }
      } else {
        this.stepResult[this.currentStep - 1].ingredients_extra = ingredients
      }
      const result = {
        products: this.stepResult,
        allow_loyalty_payment: this.stepResult[this.currentStep - 1].product_size.allow_loyalty_payment,
        product_prices: {}
      }

      let sumIngredients = 0
      for (let i in ingredients) {
        sumIngredients += parseFloat(parseFloat(ingredients[i].ingredient_amount) * parseInt(ingredients[i].ingredient_quantity))
      }
      let newPrices = this.newPrices
      if (this.$store.state.cart.currentItem.products) {
        this.$store.state.cart.currentItem.products[this.currentStep - 1].prices.ingredientsExtra = sumIngredients
      }
      this.$emit('set-prices', newPrices)
      this.$emit('set-products', result)
    },
    nextStep () {
      try {
        const currentStep = this.currentStep
        this.getFlavors()
        this.btnEnable = false
        /** se step for montagem */
        if (this.steps.length > 0) {
          this.hideButton = true
        }
        /** remover ingredientes */
        if (this.currentStage === 1) {
          this.btnEnable = true
          this.currentStage = 2
          return
        }

        /** volta de 'remover ingredientes' para o fluxo normal */
        if (this.currentStage === 2) {
          this.currentStage = 0
          if (this.currentStep < this.qtyFlavors) {
            this.btnEnable = false
            this.currentStep = currentStep + 1
            return
          }
        }

        /** borda ou final sem borda */
        if (this.currentStep === this.qtyFlavors) {
          if (this.edge) {
            this.btnEnable = true
            this.currentStep = currentStep + 1
            return
          }
          this.$emit('next', 'end')
          return
        }

        /** final com borda */
        if (this.currentStep > this.qtyFlavors) {
          this.$emit('next', 'end')
          return
        }
        this.currentStep += 1
      } catch (error) {
        console.log(error)
      }
    },
    nextStage (stage) {
      this.currentStage = 2
    },
    selectProduct (product) {
      this.inStage2 = 1
      this.infoExtra = {}
      if (product.product_size.info_extra) {
        this.infoExtra = { ...product.product_size.info_extra[0] }
        if (product.product_size.info_extra.info_extra_id) {
          this.infoExtra = { ...product.product_size.info_extra }
        }

        /* VERIFICA SE O PRODUTO TEM INFORMAÇÃO EXTRA E SE SETA O BOTÃO PRÓXIMO
        PARA DISABLED (VAI ESPERAR O CLIENTE SELECIONAR UMA OPÇÃO) */
        this.btn_enable_stage2 = false
      }

      this.tempRemoveFlavors = product.product_id
      this.removeFlavors[this.currentStep] = product.product_id
      this.ingredients = []
      this.ingredientsExtra = []
      this.steps = []
      this.stepResult[this.currentStep - 1] = product
      let qtyparts = 1

      if (this.qtyFlavors > 1) {
        qtyparts = `1/${this.qtyFlavors}`
      }

      if (this.isSpecialCombo == true) {
        qtyparts = 1
      }
      this.stepResult[this.currentStep - 1].product_quantity = qtyparts
      this.stepResult[this.currentStep - 1].category_id = product.category_id
      this.stepResult[this.currentStep - 1].category_parent_id = product.category_parent_id
      if (product.product_ingredients) {
        this.ingredients = product.product_ingredients
        this.currentStage = 1
      }

      if (product.product_size.ingredients_extra && product.product_size.ingredients_extra.length > 0) {
        this.ingredientsExtra = product.product_size.ingredients_extra
        product.product_size.ingredients_extra = []
        this.currentStage = 1
      }

      // INGREDIENTES EXTRA DA CATEGORIA
      if (typeof this.currentCategoryIngredientsExtras !== 'undefined' && typeof this.currentCategoryIngredientsExtras[product.category_id] !== 'undefined') {
        let extra = []
        for (let i in this.currentCategoryIngredientsExtras[product.category_id]) {
          let tmp = {
            ingredient_id: i,
            ingredient_name: this.currentCategoryIngredientsExtras[product.category_id][i].name,
            ingredient_type: 2,
            ingredient_min: 0,
            ingredient_max: 100,
            ingredient_amount: this.currentCategoryIngredientsExtras[product.category_id][i].price_by_size[product.product_size.size_id]
          }
          extra.push(tmp)
        }
        this.ingredientsExtra = extra
        this.currentStage = 1
      }

      if (product.product_size.info_extra) {
        this.info_extra = product.product_size.info_extra
        this.currentStage = 1
      }

      if (product.product_steps) {
        this.steps = product.product_steps
        this.currentStage = 1
      }

      let productPrices = {
        simple: {
          amount: 0,
          amount_loyalty: 0,
          amount_promo: 0
        }
      }
      /** allow loyalty payment */
      let allowLoyaltyPayment = false

      for (let i in this.stepResult) {
        if (typeof this.info.price_rule.type === 'undefined' || this.info.price_rule.type === 'W') {
          productPrices.simple.amount += this.stepResult[i].prices.price / this.qtyFlavors
          productPrices.simple.amount_loyalty += this.stepResult[i].prices.loyalty / this.qtyFlavors
          productPrices.simple.amount_promo += this.stepResult[i].prices.promo / this.qtyFlavors
        } else {
          if (this.stepResult[i].prices.price > productPrices.simple.amount) {
            productPrices.simple.amount = this.stepResult[i].prices.price
          }
          if (this.stepResult[i].prices.loyalty > productPrices.simple.amount_loyalty) {
            productPrices.simple.amount_loyalty = this.stepResult[i].prices.loyalty
          }
          if (this.stepResult[i].prices.promo > productPrices.simple.amount_promo) {
            productPrices.simple.amount_promo = this.stepResult[i].prices.promo
          }
        }

        if (allowLoyaltyPayment && !this.stepResult[i].product_size.allow_loyalty_payment) {
          allowLoyaltyPayment = false
        }
        allowLoyaltyPayment = this.stepResult[i].product_size.allow_loyalty_payment
      }

      if (this.isCombo) {
        this.stepResult[this.currentStep - 1].prices.price = this.$store.state.cart.currentItem.size_amount
        this.stepResult[this.currentStep - 1].prices.loyalty = this.$store.state.cart.currentItem.size_amount_loyalty
        this.stepResult[this.currentStep - 1].prices.promo = this.$store.state.cart.currentItem.size_amount_promo
      }

      this.btnEnable = true
      let newPrices = this.newPrices
      if (!newPrices.products) {
        newPrices = { products: [] }
      }

      newPrices.products[this.currentStep - 1] = this.stepResult[this.currentStep - 1].prices
      this.$emit('set-prices', newPrices)
      this.$emit('set-products', {
        products: this.stepResult,
        allow_loyalty_payment: allowLoyaltyPayment,
        product_prices: productPrices
      })
    },
    setStage () {

    },
    setStep () {

    },
    getSteps () {
      let res = []
      for (let i = 0; i < this.qtyFlavors; i++) {
        let flavor = {
          name: `${parseInt(i) + 1}º Sabor`,
          flavors: []
        }
        res.push(flavor)
      }
      /** edge */
      if (this.edge) {
        res.push({
          name: 'Borda',
          flavors: []
        })
      }
      return res
    },
    formatProduct (product) {
      const productSize = this.getPrices(product)
      let productIngredients = null
      let productSteps = null

      if (product.product_ingredients) {
        productIngredients = product.product_ingredients
      }

      if (product.product_steps) {
        productSteps = product.product_steps
      }
      return {
        category_id: product.category_id,
        category_parent_id: product.category_parent_id,
        product_image: product.product_image,
        product_id: product.product_id,
        product_name: product.product_name,
        product_description: product.product_description,
        product_size: productSize,
        product_ingredients: productIngredients,
        product_steps: productSteps,
        enabled_ingredient: product.enabled_ingredient,
        prices: {
          price: productSize.size_amount,
          promo: productSize.size_amount_promo,
          loyalty: productSize.size_amount_loyalty,
          minor: false
        }
      }
    },
    getPrices (product) {
      const amount = Object.entries(product.product_sizes).filter((e, i, a) => {
        return e[1].size_id === this.sizeId
      })
      if (amount[0]) {
        return amount[0][1]
      }
      return {
        size_amount: 0,
        size_amount_promo: 0,
        size_amount_loyalty: 0
      }
    },
    setInfoExtra (id) {
      this.btn_enable_stage2 = true
      this.stepResult[this.currentStep - 1].info_extra_id = this.infoExtra.info_extra_id
      this.stepResult[this.currentStep - 1].info_extra_option_id = id
      this.stepResult[this.currentStep - 1].info_extra_option_amount = 0

      for (let i in this.infoExtra.info_extra_options) {
        if (this.infoExtra.info_extra_options[i].option_id === id) {
          this.stepResult[this.currentStep - 1].info_extra_option_amount = parseFloat(this.infoExtra.info_extra_options[i].option_amount)
        }
      }
      const result = {
        products: this.stepResult,
        allow_loyalty_payment: this.stepResult[this.currentStep - 1].product_size.allow_loyalty_payment, // Verificar por que o allow loyalty está em info extra
        product_prices: {}
      }
      this.$emit('set-products', result)
    },
    setFilteredFlavors (flavors) {
      this.filteredFlavors = flavors
    }

  },
  computed: mapState({
    info: state => state.store.info,
    newPrices: state => state.cart.currentItemPrices,
    currentItem: state => state.cart.currentItem,
    currentCategoryIngredientsExtras: state => state.catalog.ingredientsextra
  })
}
</script>
