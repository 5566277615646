<!-- MONTAGEM -->
<template>
    <div>
      <div v-if="currentStage === 0">
        <vtto-catalog-detail-info-extra v-if="infoExtra.length" :info-extra="infoExtra" @set-info-extra="setInfoExtra"/>
        <vtto-catalog-detail-ingredients-new v-if="ingredients" :ingredients="ingredients" @remove-ingredients="removeIngredients"/>
        <vtto-catalog-detail-ingredients-new v-if="ingredientsExtras" :ingredients="ingredientsExtras" :extra="true" @add-ingredients="addIngredientsExtra"/>
      </div>
      <div v-else>
        <div class="bg-white mt-15 m-xs-0">
            <vtto-carousel-steps :current-step="currentStep" :steps="steps"/>
        </div>
        <div class="bg-white p-15 bb-1-gray fs-s">
           <span v-if="isCombo  && currentItem.product_steps && currentItem.product_steps[stepCombo -1] && currentItem.product_steps[stepCombo -1].step_products && currentItem.product_steps[stepCombo -1].step_products[currentStep - 1]" class="text-ppercase fw-900">{{currentItem.product_steps[stepCombo-1].step_products[currentStep - 1].product_name+" "+steps[currentStep-1].name}}</span>
           <span v-else-if="!isCombo  && currentItem.product_steps && currentItem.product_steps[stepCombo -1] && currentItem.product_steps[stepCombo -1].step_products && currentItem.product_steps[stepCombo -1].step_products[currentStep - 1]" class="text-ppercase fw-900">{{currentItem.product_steps[stepCombo-1].step_products[currentStep - 1].product_name+" "+steps[currentStep-1].name}}</span>
           <span v-else class="text-uppercase fw-900">{{steps[currentStep-1].name}}</span>
            <vtto-mounting-items
                type="2"
                @enable-btn="enableBtn"
                @ingredient-click="addIngredients"
                :size-position="sizePosition"
                :id="currentStep"
                :step="steps[currentStep-1].mounting"
                :product-ingredients="productIngredients"
                :size-id="sizeId" />
        </div>
      </div>
        <vtto-btn-steps v-if="!end" @next="nextStep" :enable="btnEnable"/>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import VttoCarouselSteps from '@/components/VttoCarouselSteps'
import VttoMountingItems from '@/components/Catalog/new/VttoMountingItems'
import VttoBtnSteps from '@/components/Catalog/new/VttoBtnSteps'
import VttoCatalogDetailIngredientsNew from '@/components/Catalog/new/VttoIngredients'
import VttoCatalogDetailInfoExtra from '@/components/Catalog/Detail/VttoInfoExtra'

export default {
  components: {
    VttoCarouselSteps,
    VttoMountingItems,
    VttoBtnSteps,
    VttoCatalogDetailIngredientsNew,
    VttoCatalogDetailInfoExtra
  },
  props: {
    productSteps: {},
    productIngredients: {},
    parent: {},
    sizePosition: {},
    sizeId: {},
    comboStep: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      resultSteps: [],
      btnEnable: false,
      qtyIngredients: 0,
      minIngredients: 0,
      end: false,
      currentStep: 1,
      currentStage: 1,
      isCombo: false,
      ingredientsExtras: [],
      ingredients: [],
      infoExtra: [],
      stepCombo: 1
    }
  },
  created () {
    if (this.steps[this.currentStep - 1].mounting.step_min_items === 0) {
      this.btnEnable = true
    }
  },
  updated () {
    this.stepCombo = typeof this.comboStep === 'undefined' ? this.stepCombo : this.comboStep
  },
  mounted () {
    // VERIFICA SE É UM COMBO, E SE HÁ INGREDIENTES EXTRA PARA A CATEGORIA SELECIONADA
    if (typeof this.currentItem !== 'undefined') {
      if (parseInt(this.currentItem.type) === 3 && typeof this.currentItem.product_steps !== 'undefined' && this.currentItem.product_steps.length > 0) {
        this.isCombo = true
        this.stepCombo = typeof this.comboStep === 'undefined' ? 1 : this.comboStep
        if (this.currentItem.product_steps[this.stepCombo - 1]) {
          let size = this.currentItem.product_steps[this.stepCombo - 1].step_size_id
          this.findIngredientsExtrasByProductSize(this.currentItem.product_steps[this.stepCombo - 1].step_products[this.currentStep - 1], size)
        }
        if ((this.infoExtra && this.infoExtra.length > 0) ||
            (this.ingredients && this.ingredients.length > 0) ||
            (this.ingredientsExtras && (Object.keys(this.ingredientsExtras).length > 0 || this.ingredientsExtras.length > 0))) {
          this.currentStage = 0
          this.btnEnable = true
        }
      }
    }
  },
  methods: {
    findIngredientsExtrasByProductSize (productInfo, size) {
      if (typeof productInfo.product_ingredients !== 'undefined') {
        this.ingredients = productInfo.product_ingredients
      }

      let productSizes = productInfo.product_sizes

      for (let i in productSizes) {
        if (productSizes[i].size_id === size) {
          // INGREDIENTES EXTRA
          if (typeof productSizes[i].ingredients_extra !== 'undefined' && productSizes[i].ingredients_extra.length > 0) {
            this.ingredientsExtras = productSizes[i].ingredients_extra
          }
          // INGREDIENTES
          if (typeof productSizes[i].ingredients !== 'undefined') {
            this.ingredients = productSizes[i].ingredients
          }
          // INFORMAÇÃO EXTRA
          if (typeof productSizes[i].info_extra !== 'undefined') {
            this.infoExtra = productSizes[i].info_extra
          }
        }
      }
    },

    addIngredientsExtra (ingredientExtra) {
      if (this.isCombo) {
        this.currentItem.product_steps[this.stepCombo - 1].step_products[this.currentStep - 1].ingredients_extra = ingredientExtra
      } else {
        this.currentItem.product_steps[this.currentStep - 1].ingredients_extra = ingredientExtra
      }
      this.$store.dispatch('cart/setProduct', this.currentItem)
    },
    setInfoExtra (option) {
      if (this.isCombo) {
        this.currentItem.product_steps[this.stepCombo - 1].step_products[this.currentStep - 1].info_extra = this.infoExtra
        this.currentItem.product_steps[this.stepCombo - 1].step_products[this.currentStep - 1].info_extra_option_id = option.option_id
        this.currentItem.product_steps[this.stepCombo - 1].step_products[this.currentStep - 1].info_extra_option_name = option.option_name
        this.currentItem.product_steps[this.stepCombo - 1].step_products[this.currentStep - 1].info_extra_option_amount = option.option_amount
      } else {
        this.currentItem.product_steps[this.currentStep - 1].info_extra = this.infoExtra
        this.currentItem.product_steps[this.currentStep - 1].info_extra_option_id = option.option_id
        this.currentItem.product_steps[this.currentStep - 1].info_extra_option_name = option.option_name
        this.currentItem.product_steps[this.currentStep - 1].info_extra_option_amount = option.option_amount
      }

      this.$store.dispatch('cart/setProduct', this.currentItem)
    },
    setInfoExtraMounting (info) {
      if (!this.resultSteps[this.currentStep - 1]) {
        this.resultSteps[this.currentStep - 1] = {
          info_extra_option_id: info,
          step_id: this.steps[this.currentStep - 1].mounting.step_id
        }
        if (!this.resultSteps[this.currentStep - 1].step_ingredients) {
          this.resultSteps[this.currentStep - 1].step_ingredients = []
        }
      }

      this.resultSteps[this.currentStep - 1].info_extra_option_id = info

      this.$emit('step-ingredients', this.resultSteps)
    },
    removeIngredients (ingredients) {
      if (this.isCombo) {
        this.currentItem.product_steps[this.stepCombo - 1].step_products[this.currentStep - 1].remove_ingredients = ingredients
      } else {
        this.currentItem.product_steps[this.currentStep - 1].remove_ingredients = ingredients
      }

      this.$store.dispatch('cart/setProduct', this.currentItem)
    },
    addIngredients (stepIngredients) {
      let infoExtra = {}
      if (this.resultSteps[this.currentStep - 1]) {
        if (this.resultSteps[this.currentStep - 1].info_extra_option_id) {
          infoExtra = {
            info_extra_option_id: this.resultSteps[this.currentStep - 1].info_extra_option_id,
            info_extra_option_amount: this.resultSteps[this.currentStep - 1].info_extra_option_amount
          }
        }
      }
      this.resultSteps[this.currentStep - 1] = { ...stepIngredients, ...infoExtra }
      let qtyIngredients = 0
      this.minIngredients = stepIngredients.step_min_items
      for (let i in stepIngredients.step_ingredients) {
        if (typeof stepIngredients.step_ingredients[i] !== 'undefined') {
          qtyIngredients += parseInt(stepIngredients.step_ingredients[i].ingredient_quantity)
        }
      }
      this.qtyIngredients = qtyIngredients
      this.$emit('step-ingredients', this.resultSteps)
    },
    nextStep () {
      if (this.currentStage === 0) {
        this.currentStage = 1
        // VERIFICAR SE O BOTÃO DE PRÓXIMO DEVE ESTAR LIBERADO OU NÃO
        this.btnEnable = true
        console.log(this.steps, this.currentStep, this.currentStep - 1)
        if (this.steps[this.currentStep - 1].mounting.step_min_items > 0) {
          this.btnEnable = false
        }
      } else {
        let currentStep = this.currentStep
        this.setExtraPrices()
        if (currentStep === this.productSteps.length) {
          /** fim dos steps */
          this.end = true
          this.$emit('next', 'end')
          return
        }
        this.currentStep = currentStep + 1
      }
    },
    setExtraPrices () {
      let amount = 0
      for (let i in this.resultSteps) {
        if (this.resultSteps[i].step_ingredients) {
          for (let y in this.resultSteps[i].step_ingredients) {
            amount += this.resultSteps[i].step_ingredients[y].ingredient_amount
          }
        }
      }
      this.$emit('extra-prices', { extra: amount })
    },
    enableBtn (value) {
      this.btnEnable = value
    }
  },
  watch: {
    currentStep: function () {
      this.btnEnable = false
      if (this.steps[this.currentStep - 1].mounting.step_min_items === 0) {
        this.btnEnable = true
      }
    }
  },
  computed: {
    steps: function () {
      const productSteps = { ...this.productSteps }
      let res = []
      for (let i in productSteps) {
        let name = productSteps[i].step_alias === '' ? productSteps[i].step_name : productSteps[i].step_alias
        res.push({
          name: name,
          mounting: productSteps[i]
        })
      }
      return res
    },
    ...mapState({
      currentItem: state => state.cart.currentItem
    })
  }
}
</script>
