<template>
    <div>
        <div class="bg-white p-15 mt-15 m-xs-0 bb-1-gray" v-if="title">
          <p class="text-uppercase fs-s fw-900">
            <span>{{ title.category }}</span>
            <span v-if="title.subcategory"> // {{ title.subcategory }}</span>
            <span> - {{ title.product_name }}</span>
            <span> - {{ title.size }}</span>
          </p>
        </div>

        <div v-if="titleHidden()" class="bg-white p-20 bb-1-gray fs-s">{{ title.product_name }}</div>
        <div class="bg-white p-15" v-if="image">
            <div class="d-lg-block text-center mb-20">
                <img loading="lazy" class="img-fluid" :src="image">
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    title: {
      default: null
    },
    currentStep: {}
  },
  filters: {
    ingredients (values) {
      let names = []
      Object.entries(values).map(a => {
        names.push(a[1].ingredient_name)
      })
      if (names.length === 0) {
        return ''
      }
      return names.join(', ')
    }
  },
  data () {
    return {
      image: ''
    }
  },
  methods: {
    titleHidden () {
      if (this.title && this.title.product_image) {
        return true
      }
      return false
    }
  },
  created () {
    if (this.currentItem.product_image) {
      this.image = this.currentItem.product_image
    }

    if (this.currentItem.products && this.currentItem.products[this.currentStep - 1] && this.currentItem.products[this.currentStep - 1].product_image) {
      this.image = this.currentItem.products[this.currentStep - 1].product_image
    }
  },
  computed: {
    currentItem: function () {
      return this.$store.state.cart.currentItem
    }
  }
}
</script>
