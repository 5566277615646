<template>
  <header id="header" class="mb-15 mb-xs-10" v-if="!mktplc">
    <!-- Header desktop -->
    <div class="container d-flex justify-content-between">
      <vtto-header-brand :name="{name: 'homeCatalog'}" :image="brand" />
      <vtto-menu-top :isOpen="isOpen" :isLogged="isLogged" :oUser="oUser" :items="menuData" :deadlineDelivery="deadlineDelivery" :deadlineDesk="deadlineDesk" :withoutRegistration="withoutRegistration"/>
    </div>
    <!-- FIM Header desktop -->

    <!-- Header fixed mobile -->
    <div class="fixed top-0 w-100 z-1000 text-center text-uppercase color-white bg-main d-block d-lg-none p-10 h-40">

      <router-link :to="{ name: 'homeCatalog' }" class="color-white fw-900 fs-m limit-name d-block">{{ title }}</router-link>

      <!-- APENAS CHECKOUT -->
      <router-link :to="{ name: 'homeCatalog' }" v-if="!footerVisible" role="button" class="action-header color-white absolute top-0 right-0 mt-10">
        <i class="fas fa-times fs-1-8"></i>
      </router-link>
      <!--  -->

    </div>

    <!-- FIM Header fixed mobile -->

    <!-- Infos Loja mobile -->
    <div class="bg-white w-100 text-center p-15 mt-40 d-block d-lg-none mb--10" v-if="this.$store.state.route.name==='homeCatalog'">
        <p v-if="isOpen" class="color-open text-uppercase fw-700 fs-m">Estamos Abertos</p>
        <p v-else class="color-close text-uppercase fw-700 fs-m">Fechado Agora</p>
        <div v-if="deadlineDelivery || deadlineDesk">
          <i class="far fa-clock"></i>
          <div v-if="deadlineDelivery"> Entrega: {{ deadlineDelivery }}</div><div v-if="deadlineDesk"> Balcão: {{ deadlineDesk }}</div>
        </div>
    </div>
    <div class="p-15" v-else></div>
    <!-- FIM infos loja mobile -->
  </header>
  <header id="js-header" class="bg-restaurant py_5" v-else>
    <div class="color-white  ml_5 mt_5 fw-100 text-uppercase d-flex justify-between">
      <div><i class="fas fa-arrow-left"></i> Voltar</div>
      <div><h5 class="fw_900 fs_1_3 mb_5 header-title mr_10">Ken Taki Batel</h5></div>
    </div>
    <a class="media pb_15  pt_50 bb-gray px_15 header-info">
      <img class="mr-3 brand-list" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRj3HCHHNI8cWHAZyxHKNszPdidkaGXLn4BnlLxoKftDcECF8P0" alt="" />
      <div class="media-body">
        <h5 class="mt_45 fw_900 fs_1_3 mb_5 color-black">Ken Taki Batel</h5>
        <p class="color-gray fs_1_2 pb_10">Japones <i class="fas fa-star color-yellow mr_2"></i> 4.2</p>
      </div>
    </a>
  </header>
</template>

<script>
import VttoMenuTop from '@/components/VttoMenuTop'
import VttoHeaderBrand from '@/components/VttoHeaderBrand'
import { empty } from '@/utils'
export default {
  components: {
    VttoMenuTop,
    VttoHeaderBrand
  },
  props: {
    mktplc: {
      default: function () {
        return false
      }
    },
    menuData: {},
    title: {},
    brand: {},
    isLogged: {},
    oUser: {},
    deadline: {
      default: function () {
        return {
          delivery: '',
          desk: ''
        }
      }
    }
  },
  methods: {
    goToBack: function () {
      this.$router.go('-1')
    },
    formatDeadline (deadline) {
      if ((!deadline[0] || deadline[0] === '0') && (!deadline[1] || deadline[1] === '0')) {
        return ''
      }

      if (!deadline[1] || deadline[1] === '0') {
        return `em média ${deadline[0]}min`
      }
      return `${deadline[0]}min - ${deadline[1]}min`
    }
  },
  computed: {
    deadlineDelivery: function () {
      return (this.deadline.delivery) ? this.formatDeadline(this.deadline.delivery) : null
    },
    deadlineDesk: function () {
      return (this.deadline.desk) ? this.formatDeadline(this.deadline.desk) : null
    },
    isOpen: function () {
      if (!empty(this.$store.state.store)) {
        return this.$store.state.store.info.working_time.is_open
      }
      return false
    },
    footerVisible: function () {
      return this.$store.state.store.footerVisible
    },
    withoutRegistration: function () {
      return this.$store.state.store.info.order_without_registration && !this.$store.state.account.isLogged
    }
  }
}
</script>

<style>
.bg-restaurant {
  max-height: 100px;
  background-size: cover;
  padding-bottom: 40px;
}
.py_5 {
  padding-top: 5px!important;
  padding-bottom: 5px!important;
}
.pb_15 {
  padding-bottom: 15px;
}
.pt_50 {
  padding-bottom: 50px;
}
.px_15 {
  padding-left: 15px;
  padding-right: 15px;
}
.brand-list {
  width: 64px;
  height: 64px;
  border: 1px solid #eee;
}
.color-black, a.color-black {
  color: #000;
}
.mb_5 {
  margin-bottom: 5px;
}
.mt_45 {
  margin-top: 45px;
}
.fw_900 {
  font-weight: 900;
}
.fs_1_3 {
  font-size: 1.3em;
}
.ml_5 {
  margin-left: 5px !important;
}
.mt_5 {
  margin-top: 5px !important;
}
.bb-gray {
  border-bottom: 1px solid #eee;
}
.pt_50 {
  padding-top: 50px;
}
</style>
