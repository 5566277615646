<template>
  <div class="container main">
    <div class="row">
      <div class="col-12">
        <div class="bg-white d-block mb-20">
            <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Nova Senha</h3>
            <div class="p-15">
              <p class="fw-700 d-block text-uppercase">Email</p>
              <input disabled v-model="oAccount.email" type="text" class="form-control mt-10 br-0 fs-s" />
              <p class="fw-700 d-block text-uppercase mt-15">Senha</p>
              <input @focus="scrollToMe()" v-model="oAccount.password" type="password" class="form-control mt-10 br-0 fs-s" placeholder="escolha uma senha" />
              <p class="fw-700 d-block text-uppercase mt-15">Repita a senha</p>
              <input @focus="scrollToMe()" v-model="oAccount.password2" type="password" class="form-control mt-10 br-0 fs-s" placeholder="confirme e repita a senha" />
              <div class="p-15 text-center d-block">
                  <button @click="sendPassword" class="btn btn-primary text-uppercase text-center mx-auto">Enviar</button>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- FIM Conteúdo -->
</template>
<script>
import apiAccount from '@/api/account'
import router from '@/router'
import { empty } from '@/utils'
import { showAlert } from '@/plugins/alerts'
import { scrollToMe } from '@/plugins/scroll'
export default {
  data () {
    return {
      oAccount: {
        email: '',
        password: '',
        password2: '',
        token: ''
      }
    }
  },

  mounted () {
    let oQueryString = this.$route.query
    if (empty(oQueryString) || empty(oQueryString.email) || empty(oQueryString.token)) {
      showAlert('warning', 'Dados inválidos')
      router.push({ name: 'homeCatalog' })
    }
    this.oAccount.email = oQueryString.email
    this.oAccount.token = oQueryString.token
  },
  methods: {
    sendPassword: function () {
      if (this.oAccount.password !== this.oAccount.password2) {
        showAlert('warning', 'As senhas devem ser iguais!')
        return false
      }
      if (this.oAccount.email === '') {
        showAlert('warning', 'Preencha o email!')
        return false
      }
      apiAccount.SendPasswordPasswordForgotten(this.oAccount).then(function (response) {
        let aData = response.data
        let bSuccess = aData.success
        if (!bSuccess) {
          showAlert('error', aData.errors[0])
        } else {
          showAlert('success', 'Sucesso!')
          router.push({ name: 'login' })
        }
      })
    },
    scrollToMe: function () {
      scrollToMe('body', -80)
    }
  }
}
</script>
