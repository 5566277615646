<template>
    <div class="bg-white d-block mb-20">
        <div class="bb-1-gray p-15 d-flex flex-center justify-between">
            <h3 class="fw-900 text-uppercase d-block fs-s">Entrega</h3>
            <i @click="closeSlide" class="fas fa-times fs-1-6 pointer d-block d-sm-none"></i>
        </div>

        <ul class="d-block p-15 list-unstyled fs-s">
            <template v-if="!hideDelivery">
                <li class="mb-15" v-for="(oAddress, iAddress) in aAddresses" :key="iAddress">
                    <label
                           class="flex-start form-group-radio_group text-uppercase fw-500" :for="iAddress">
                        <input :id="iAddress" type="radio" class="delivery-type form-group-radiobox"
                               v-model="delivery_address.address_id" :value="iAddress"/>
                        <span class="form-group-check_radio"></span>
                        <div v-if="oAddress">
                            <p>{{makeAddress(oAddress)}}</p>
                            <template v-if="parseFloat(oAddress.delivery_price) != 0">
                              <p class="fw-700 number">{{oAddress.delivery_price | money}}</p>
                            </template>
                            <template v-else>
                              <p class="fw-700 number">Entrega Grátis</p>
                            </template>
                        </div>
                    </label>
                </li>
            </template>

            <li class="mb-15" v-if="!hideStore && !isWithoutRegistration()">
                <label
                       class="flex-start form-group-radio_group text-uppercase fw-500" for="address-type-desk">
                    <input id="address-type-desk" type="radio" class="delivery-type form-group-radiobox"
                           v-model="delivery_address.address_id" value="0"/>
                    <span class="form-group-check_radio"></span>
                    <div>
                        <p>Retirada no balcão</p>
                    </div>
                </label>
            </li>

            <li class="mb-15" v-if="isWithoutRegistration()">
              <label
                        class="flex-start form-group-radio_group text-uppercase fw-500" for="new_address">
                    <input id="new_address" type="radio" class="delivery-type form-group-radiobox"
                            v-model="iAddress" value="-1" @change="setAddressRegistration('delivery')"/>
                    <span class="form-group-check_radio"></span>
                    <div>
                      <p>Novo endereço</p>
                    </div>
                </label>
            </li>

            <li class="mb-15" v-if="!hideStore && isWithoutRegistration()">
                <label
                       class="flex-start form-group-radio_group text-uppercase fw-500" for="address-type-desk">
                    <input id="address-type-desk" type="radio" class="delivery-type form-group-radiobox"
                           v-model="iAddress" value="0" @change="setAddressRegistration('desk')" />
                    <span class="form-group-check_radio"></span>
                    <div>
                        <p>Retirada no balcão</p>
                    </div>
                </label>
            </li>
        </ul>

        <div v-if="oOrder.order_without_registration && iAddress == -1">
          <form @change="calcalculateDeliveryPrice">
            <div class="row">
              <div class="col-12">
                  <div class="bg-white d-block mb-20">
                      <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Endereço de Entrega</h3>
                      <ul class="nav-tabs list-table w-100 bg-white my-15 text-center border-0">
                          <li class="list-table-item relative">
                              <label class="form-group-radio_group link-product" for="checkHome"
                                    data-url="https://homol.vtto.com.br/front/outro/1">
                                  <input id="checkHome" type="radio" name="deliveryDestiny" class="form-group-radiobox"
                                        value="Casa" v-model="oAddress.title">
                                  <span class="form-group-check_radio"></span>
                                  <span class="fw-700 fs-m">Casa</span>
                              </label>
                          </li>
                          <li class="list-table-item relative">
                              <label class="form-group-radio_group link-product" for="checkWork"
                                    data-url="https://homol.vtto.com.br/front/outro/1">
                                  <input id="checkWork" type="radio" name="deliveryDestiny" class="form-group-radiobox"
                                        value="Trabalho" v-model="oAddress.title">
                                  <span class="form-group-check_radio"></span>
                                  <span class="fw-700 fs-m">Trabalho</span>
                              </label>
                          </li>
                          <li class="list-table-item relative">
                              <label class="form-group-radio_group link-product" for="/front/outro/2"
                                    data-url="https://homol.vtto.com.br/front/outro/1">
                                  <input id="/front/outro/2" type="radio" name="deliveryDestiny"
                                        class="form-group-radiobox" value="Outros" v-model="oAddress.title">
                                  <span class="form-group-check_radio"></span>
                                  <span class="fw-700 fs-m">Outros</span>
                              </label>
                          </li>
                      </ul>
                      <div action="" class="p-15 pt-0">
                          <p class="fw-700 d-block text-uppercase">Apelido (opcional)</p>
                          <input type="text" class="form-control mt-10 br-0 fs-s"
                                placeholder="Ex: (Minha Casa)" v-model="oAddress.title" @focus="scrollToMe('.opened')"/>
                          <template v-if="!hideZipcode">
                              <p class="fw-700 d-block text-uppercase mt-15">CEP</p>
                              <input type="tel" class="form-control mt-10 br-0 fs-s"
                                  placeholder="digite o CEP" v-mask="'#####-###'" v-model="oAddress.zipcode" @focus="scrollToMe('.opened')"/>
                          </template>
                          <p class="fw-700 d-block text-uppercase mt-15">Estado</p>
                          <div class="form-group select-default">
                              <select id="uf" name="uf" class="text-uppercase relative pr-30" v-model="oAddress.state" @focus="scrollToMe()">
                                  <option value=""> Selecione</option>
                                  <option v-for="(uf, stateKey) in states" :key="stateKey" :value="uf">{{uf}}</option>
                              </select>
                          </div>
                           <div v-if="cities && cities[oAddress.state]" class="form-group select-default">
                            <p class="fw-700 d-block text-uppercase mt-15">Cidade</p>
                            <select id="cidade" name="cidade" @focus="scrollToMe('.opened')"
                                    class="text-uppercase relative pr-30"
                                    v-model="oAddress.city_id">
                                <option value="0"> Selecione</option>
                                <option v-for="(city, cityKey) in cities[oAddress.state]" :key="cityKey"
                                        :value="cityKey">{{city}}
                                </option>
                            </select>
                        </div>

                        <div v-if="districts && districts[oAddress.city_id]" class="form-group select-default">
                          <p class="fw-700 d-block text-uppercase mt-15">Bairro</p>
                            <select id="bairro" name="bairro" @focus="scrollToMe('.opened')"
                                    class="text-uppercase relative pr-30"
                                    v-model="oAddress.district_id">
                                <option value="0"> Selecione</option>
                                <option v-for="(district) in districts[oAddress.city_id]"
                                        :key="district.district_id"
                                        :value="district.district_id">{{district.district_name}}
                                </option>
                            </select>
                        </div>
                           <p class="fw-700 d-block text-uppercase mt-15">Endereço</p>
                        <input type="text" class="form-control mt-10 br-0 fs-s" placeholder="digite o endereço" v-model="oAddress.address" @focus="scrollToMe('.opened')"/>
                        <!-- <span class="color-red ml-5" v-if="errorMsg.address">{{ errorMsg.address }}</span> -->
                        <div class="row">
                            <div class="col-md-3">
                                <p class="fw-700 d-block text-uppercase mt-15">Número</p>
                                <input type="text" class="form-control mt-10 br-0 fs-s" placeholder="digite o número" v-model="oAddress.number" @focus="scrollToMe()"/>
                                <!-- <span class="color-red ml-5" v-if="errorMsg.number">{{ errorMsg.number }}</span> -->
                            </div>
                            <div class="col-md-9">
                                <p class="fw-700 d-block text-uppercase mt-15">Complemento (opcional)</p>
                                <input type="text" class="form-control mt-10 br-0 fs-s" placeholder="digite o complemento" v-model="oAddress.extra" @focus="scrollToMe('.opened')"/>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            </form>

            <p class="p-15 text-center" v-if="isMobile" >
              <button @click="closeSlide" class="btn btn-primary text-uppercase fs-1-2-xs">Salvar</button>
            </p>
        </div>

        <div v-if="aAddresses[delivery_address.address_id] && aAddresses[delivery_address.address_id].outside_accept === false"
             lass="outer-region text-center text-uppercase color-close my-10 fw-700">
            Fora da área de entrega. Endereço sob análise.
        </div>
        <!-- <div v-if="isMobile" class="p-10 text-center d-block d-sm-none">
            <button class="btn btn-primary text-uppercase fs-1-2-xs">Voltar</button>
            <p class="mt-10">ou</p>
        </div> -->

        <p class="text-center py-15 p-xs-0" v-if="oOrder.order_without_registration != 1">
            <router-link :to="{name: 'account-edit'}" class="btn btn-secondary text-uppercase fs-1-xs">Editar
                endereços
            </router-link>
        </p>
    </div>
</template>
<script>
import { setOrderInfo } from '@/plugins/orders'
import { mapState } from 'vuex'
import { showAlert } from '@/plugins/alerts'
import { scrollToMe } from '@/plugins/scroll'
import apiCheckout from '@/api/checkout'
import { validateAddress } from '../../utils'
export default {
  name: 'vtto-checkout-delivery',
  data: function () {
    return {
      delivery_address: {
        origin: 'delivery',
        address_id: -1,
        total_delivery: 0,
        min_order_price: 0
      },
      aAddresses: {},
      iAddress: -1,
      oAddress: {
        title: 'Casa',
        zipcode: '',
        address: '',
        state: '',
        city_id: '',
        district_id: '',
        number: '',
        extra: ''
      }
    }
  },

  mounted: function () {
    let self = this
    this.initAddresses()

    if (self.oOrder.order_without_registration) {
      self.changeDelivery('desk')
    }

    self.$store.dispatch('account/getListAddressInfo')
  },
  methods: {
    initAddresses: function () {
      let self = this
      var outdated = 0
      var addressID = 0

      self.aAddresses = {}
      if (self.aAddresses_ && !self.hideDelivery) {
        for (let i = 0; i < self.aAddresses_.length; i++) {
          if (i == 0) {
            self.delivery_address.address_id = self.aAddresses_[i].address_id
            self.delivery_address.origin = 'delivery'
            self.delivery_address.total_delivery = self.aAddresses_[i].delivery_price
            self.delivery_address.address = self.makeAddress(self.aAddresses_[i])
            self.delivery_address.min_order_price = self.aAddresses_[i].min_order_price
            self.setDeliveryAddress(self.delivery_address, false)
          }
          self.aAddresses[self.aAddresses_[i].address_id] = self.aAddresses_[i]
        }
      }

      for (addressID in this.$store.state.account.oUserAddresses) {
        outdated += this.$store.state.account.oUserAddresses[addressID].outdated
      }

      if (outdated > 0) {
        setTimeout(() => {
          showAlert('error', 'Existe endereço para ser atualizado')
          localStorage.setItem('addsCheck', '1')
        }, 460)
        setTimeout(() => {
          location.href = '/front/meus-dados'
        }, 5000)
      }
    },
    changeDelivery: function (origin) {
      this.delivery_address.origin = origin
      this.setDeliveryAddress(this.delivery_address, false)
    },
    closeSlide: function () {
      this.setDeliveryAddress(this.delivery_address, true)
    },
    makeAddress: function (oAddress) {
      return (oAddress) ? oAddress.address + ', ' + oAddress.number + ' - ' + oAddress.district_name + ' - ' + oAddress.city_name : 'Retirada no balcão'
    },
    isWithoutRegistration: function () {
      let self = this
      return self.oOrder.order_without_registration && !self.$store.state.account.isLogged
    },
    emptyAddress: function () {

    },
    setAddressRegistration: function (origin = 'desk') {
      let self = this
      let address = JSON.parse(JSON.stringify(self.oAddress))
      if (origin != 'desk' && validateAddress(address, self.hideZipcode)) {
        self.calcalculateDeliveryPrice()
      } else {
        this.delivery_address.address_id = (origin == 'desk' ? 0 : -1)
        this.delivery_address.origin = origin
        this.delivery_address.min_order_price = 0
        this.setDeliveryAddress(this.delivery_address, false)
      }
    },
    mountAddress () {
      let self = this

      let cityName = this.$store.state.account.addressInfoList.cities[self.oAddress.state][self.oAddress.city_id]

      let districtName = this.$store.state.account.addressInfoList.districts[self.oAddress.city_id].filter(e => {
        return e.district_id == self.oAddress.district_id
      })

      let oAddress = {
        address: self.oAddress.address,
        number: self.oAddress.number,
        district_name: districtName[0].district_name,
        city_name: cityName
      }

      return self.makeAddress(oAddress)
    },
    calcalculateDeliveryPrice: function () {
      let self = this
      let address = JSON.parse(JSON.stringify(self.oAddress))
      if (validateAddress(address, self.hideZipcode)) {
        apiCheckout.calcalculateDeliveryPrice(self.oAddress).then(function (response) {
          if (!response || !response.data || response.data.success === false) {
            showAlert('error', response.data.errors[0])
            return false
          } else {
            let oData = response.data
            self.delivery_address.origin = 'delivery'
            self.delivery_address.address_id = -1
            self.delivery_address.total_delivery = oData.data.price
            self.delivery_address.min_order_price = oData.data.min_order_price
            self.delivery_address.address = self.mountAddress()
            self.setDeliveryAddress(self.delivery_address, true)
            setOrderInfo('address', self.oAddress)
          }
        })
      }
    },
    scrollToMe: function () {
      if (scrollToMe) {
        scrollToMe('body', -80)
      }
    },
    setDeliveryAddress: function (deliveryAddress, slide) {
      setOrderInfo('delivery_address', deliveryAddress)
      this.$emit('delivery_address', deliveryAddress, slide)
    }
  },
  computed: {
    ...mapState({
      oOrder: state => state.order.oOrder,
      aAddresses_: state => state.account.oUserAddresses,
      aPaymentTypes: state => state.store.info.payments,
      aInfo: state => state.store.info
    }),

    hideZipcode: function () {
      return this.$store.state.store.info.hide_zipcode
    },

    isMobile: function () {
      return this.$store.state.store.isMobile
    },

    states () {
      return this.$store.state.account.addressInfoList.uf
    },

    cities () {
      return this.$store.state.account.addressInfoList.cities
    },

    districts () {
      return this.$store.state.account.addressInfoList.districts
    },
    hideDelivery: function () {
      let hD = this.$store.state.store.info.hidden_delivery
      if (hD) {
        this.delivery_address.address_id = 0
        this.delivery_address.origin = 'desk'
        this.delivery_address.total_delivery = 0
        this.delivery_address.address = this.makeAddress(false)
        this.delivery_address.min_order_price = 0
        this.setDeliveryAddress(this.delivery_address, false)
      }

      return hD
    },
    hideStore: function () {
      return this.$store.state.store.info.hidden_store
    },
    address_id: function () {
      return this.delivery_address.address_id
    }
  },

  watch: {
    address_id: function () {
      let fprice = 0.00
      if (this.delivery_address.address_id != 0 && this.delivery_address.address_id > 1) {
        fprice = this.aAddresses[this.delivery_address.address_id].delivery_price
        this.delivery_address.origin = 'delivery'
        this.delivery_address.min_order_price = this.aAddresses[this.delivery_address.address_id].min_order_price
        this.delivery_address.address = this.makeAddress(this.aAddresses[this.delivery_address.address_id])
      } else if (this.delivery_address.address_id == 0) {
        this.delivery_address.origin = 'desk'
        this.delivery_address.min_order_price = 0
        this.delivery_address.address = this.makeAddress(false)
      }

      if (!this.isWithoutRegistration()) {
        this.delivery_address.total_delivery = fprice
        this.setDeliveryAddress(this.delivery_address, true)
      }
    },
    aAddresses_: function () {
      this.initAddresses()
    }
  }
}
</script>
