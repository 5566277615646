/*
sType => success / error / warning / fixed
sInfo => Mensagem
*/
import store from '@/store'
export function showAlert (sType, sInfo, iTimeout = 3000) {
  let oMessage = {
    sType: sType,
    sInfo: sInfo,
    iTimeout: iTimeout
  }
  store.dispatch('messaging/setAlert', oMessage)
}
