import { sync } from 'vuex-router-sync'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import AsyncComputed from 'vue-async-computed'
import VueScrollTo from 'vue-scrollto'
import store from './store'
import * as filters from './filters'
import VueMask from 'v-mask'
import money from 'v-money'
import { empty } from './utils'
import { getXUserKey } from './plugins/account'
import config from './config'
import Modal from './plugins/modal'
import VuePaginate from 'vue-paginate'
import Vue2Filters from 'vue2-filters'
import VueTimers from 'vue-timers'

const $ = require('jquery')
window.$ = $
window.jQuery = $
require('bootstrap')
var Bugsnag = require('@bugsnag/js')
var BugsnagPluginVue = require('@bugsnag/plugin-vue')
Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG_KEY,
  plugins: [new BugsnagPluginVue()]
})
Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)

Vue.prototype.$config = config
Vue.prototype.$eventHub = new Vue()

window.AppEvents = new Vue()
window.dataLayer = window.dataLayer || []

Vue.use(AsyncComputed)
Vue.use(VueScrollTo)
Vue.use(Modal)
Vue.use(VuePaginate)
Vue.use(Vue2Filters)
Vue.use(VueTimers)
Vue.use(VueMask)
Vue.config.productionTip = false

if (!navigator.cookieEnabled) {
  alert('Habilite os cookies para continuar')
}

try {
  sessionStorage.clear()

  /* eslint-disable */
  let apiEnv = ''
  if (typeof envName !== 'undefined') {
    apiEnv = envName
  }
  
  store.commit('store/setEnvName', apiEnv)
  
  /* eslint-enable */
  store.dispatch('cart/setCart')
  store.dispatch('store/getIsOpen')
  /* FIM v-money */
  router.beforeEach((to, from, next) => {
    VueScrollTo.scrollTo('#app')
    // GTM TRACER PAGE VIEW
    window.dataLayer.push({
      event: 'pageview',
      pagePath: to.fullPath,
      pageTitle: to.name
    })
    if (to.name !== 'login' && to.name !== 'AccountCreate') {
      if (to.meta.needsAuth && empty(getXUserKey())) {
        router.push({ name: 'login' })
        return
      }
    } else {
      if (!empty(getXUserKey())) {
        router.push({ name: 'homeCatalog' })
      }
    }
    store.commit('store/setLoading', true)
    store.dispatch('store/getInfo')
    store.dispatch('catalog/getCatalog')
    store.dispatch('account/getIsLogged')
    store.dispatch('account/getUser')
    store.dispatch('account/getUserAddresses')
    next()
  })

  sync(store, router)
  // set global filters
  Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
  })

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')

  /* ============================================================= */
  /* Atalhos                                                      */
  /* ============================================================= */
  Vue.config.keyCodes.f2 = 113

  /* ============================================================= */
  /* Diretivas                                                     */
  /* ============================================================= */

  /* Registra a diretiva personalizada global chamada `v-focus` */
  Vue.directive('focus', {
    /* Quando o elemento vinculado é inserido no DOM... */
    inserted: function (el) {
      if (window.innerWidth >= 768) {
        /* Coloque o foco no elemento */
        el.focus()
      }
    }
  })

  /* v-money */
  Vue.use(VueMask)
  let moneyConfig = {
    decimal: ',',
    thousands: '.',
    prefix: 'R$ ',
    suffix: '',
    precision: 2,
    masked: false
  }
  Vue.use(money, moneyConfig)
} catch (error) {
  alert('Sem acesso ao storage')
  Bugsnag.leaveBreadcrumb(JSON.stringify(error))
  Bugsnag.notify(error)
}
