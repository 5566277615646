<template>
  <div class="fixed bottom-0 w-100 d-block bg-white" style="left: 0; z-index:1;">
    <div class="p-15 text-center d-block">
        <button v-if="!closed" class="btn btn-primary text-uppercase text-center mx-auto" :disabled="!buttonStepsActive" @click="step">{{ label }}</button>
        <button v-else class="btn btn-primary text-uppercase text-center mx-auto">Loja Fechada</button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import checkout from '@/api/checkout'
import { showAlert } from '@/plugins/alerts'
export default {
  props: {
    active: {
      default: false
    },
    closed: {}
  },
  data () {
    return {
      cParams: {}
    }
  },
  created () {
    this.cParams = { ...this.params }
  },
  methods: {
    step () {
      /**
       * currentItem.type
       * 1 = simples
       * 2 = Montagem
       * 3 = combo
       */

      /** adicionar carrinho */
      if (this.$store.state.catalog.buttonStepsMode === 2) {
        this.$store.state.cart.currentItem.added = (new Date())
        checkout.ValidateCart({ items: [this.$store.state.cart.currentItem] }).then(res => {
          if (res?.data?.data) {
            this.$store.dispatch('cart/addItemCart', res.data.data)
            this.$router.push({ name: 'homeCatalog' })
          } else {
            showAlert('error', 'falha ao colocar produto no carrinho')
          }
        })
        return
      }

      if (this.currentItem.type === 2) {
        if (!this.currentItem.product_id) {
          if (!this.steps || this.steps.length === 0) {
            return this.$router.push({ name: 'select', params: { ...this.params } })
          }

          if (this.stage === 1) {
            this.$store.dispatch('cart/setStage', 2)
            return
          }

          if (this.steps[0].step >= this.steps[0].end) {
            return this.$router.push({ name: 'detail', params: { ...this.params } })
          }

          this.$store.dispatch('cart/nextStep', 0)
          this.$store.dispatch('cart/setStage', 0)
          return
        }

        if (this.steps.length === 0) {
          return this.$router.push({ name: 'select', params: { ...this.params } })
        }

        if (this.steps[0].step >= this.steps[0].end) {
          return this.$router.push({ name: 'detail', params: { ...this.params } })
        }
        this.$store.dispatch('cart/nextStep', 0)
        return
      }

      if (this.currentItem.type === 3) {
        /** seta tamnho */
        if (this.stage === 1) {
          this.$store.dispatch('cart/setStage', 2)
          return
        }

        if (this.stage === 2 || this.stage < 0) {
          this.$store.dispatch('cart/nextStep', 0)
          this.$store.dispatch('cart/setStage', 0)
          return
        }
        if (this.steps[0].step >= this.steps[0].end) {
        /** envia pra detalhes */
          return this.$router.push({ name: 'detail', params: { ...this.params } })
        }
        /** vai pra rota do combo */
        return this.$router.push({ name: 'catalogCombo', params: { ...this.params } })
      }

      /** simples */
      if (!this.currentItem.size_id) {
        return
      }
      return this.$router.push({ name: 'detail', params: { ...this.params } })
    }
  },
  computed: {
    label: function () {
      return this.$store.state.catalog.buttonStepsLabel
    },
    mode: function () {
      return this.$store.state.catalog.buttonStepsMode
    },
    ...mapState({
      steps: state => state.cart.steps,
      stage: state => state.cart.stage,
      params: state => state.route.params,
      currentItem: state => state.cart.currentItem,
      buttonStepsActive: state => state.catalog.buttonSteps,
      info: state => state.store.info
    })
  }
}
</script>
