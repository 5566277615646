<template>
  <div v-if="loyalty && loyalty.allow_loyalty">
    <div class="row">
        <div class="col-12">
            <div class="bg-white d-block mb-20">
                <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Fidelidade</h3>
                <div class="bb-1-gray d-block p-15 fs-s">Ganhe 1 ponto a cada {{ loyalty.money_to_points | money }} em compras! <br/>(exceto taxas de entrega)</div>
                <div class="p-15 text-center d-block">
                    <router-link class="btn btn-secondary text-uppercase text-center mx-auto" :to="{name: 'loyalty-rules'}">regulamento</router-link>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'vtto-loyalty',
  props: {
    loyalty: {
      default: function () {
        return {
          money_to_points: 0
        }
      }
    }
  }
}
</script>
