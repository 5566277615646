<template>
    <div>
        <div class="bg-white p-15 mt-15 bb-1-gray">
            <span class="text-uppercase fs-s fw-900">{{ infoExtra.info_extra_question }}</span>
        </div>
        <ul class="list-unstyled bg-white">
            <li class="media m-0 p-15 bb-1-gray relative" v-for="(option, index) in infoExtra.info_extra_options"
                :key="index">
                <div class="media-body mr-10">
                    <label @click="setInfoExtra(index)" class="form-group-radio_group link-product" :for="`${uuid}-${index}`">
                        <input :id="`${uuid}-${index}`" type="radio" :name="`catalogAberto-${uuid}`"
                               class="form-group-radiobox" value="" >
                        <span class="form-group-check_radio"></span>
                        <span class="fw-700 fs-m">{{ option.option_name }}</span>
                    </label>
                </div>
                <div class="ml-10 text-right relative">
                  <template v-if="parseFloat(option.option_amount) > 0">
                    <p class="price fs-m color-main w-80">
                        <span class="color-dark-gray fs-0-8">{{ option.option_amount | money }}</span>
                    </p>
                  </template>
                  <template v-else-if="sizeId > 0 && parseFloat(option.option_amount[sizeId]) > 0">
                    <p class="price fs-m color-main w-80">
                        <span class="color-dark-gray fs-0-8">{{ option.option_amount[sizeId] | money }}</span>
                    </p>
                  </template>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import uuid from '@/plugins/uuid'

export default {
  props: {
    infoExtra: {},
    sizeId: {}
  },
  mounted () {
    this.$emit('enable-btn', false)
  },
  updated () {
    for (let i in this.infoExtra.info_extra_options) {
      document.getElementById(this.uuid + '-' + i).checked = false
    }
  },
  methods: {
    setInfoExtra (id) {
      this.$emit('enable-btn', true)
      this.$emit('set-info-extra', this.infoExtra.info_extra_options[id].option_id)
    }
  },
  computed: {
    uuid: function () {
      return uuid()
    }
  }
}
</script>
