<template>
    <div>
        <div class="bb-1-gray p-15 d-flex flex-center justify-between">
            <h3 class="fw-900 text-uppercase d-block fs-s">Dados Pessoais</h3>

            <button v-if="!isOpened" @click="slide(true)"
                    class="btn btn-secondary btn-s text-uppercase text-center fs-1-9 px-15 btn-s">
                <i class="fas fa-edit mr-5"></i>
                Editar
            </button>
            <i v-else @click="slide(false)" class="fas fa-times fs-1-6 pointer"></i>
        </div>

        <div v-if="(!isOpened && isHidden) || isMobile" class="editable p-15 px-30 fs-m  bg-gray color-dark-gray o-60">
            <p class="fw-700 color-black">
                {{ nameInLowerCase }}
            </p>
            <p class="fw-700">
                <i class="fas fa-envelope-open mr-10"></i>
                {{ oAccount.email }}
            </p>
            <p class="fw-500 number">
                <i class="fas fa-phone mr-10"></i>
                {{ oAccount.phone }}
            </p>
            <p class="fw-500 number mb-5">
                <i class="fas fa-birthday-cake mr-10"></i>
                {{ oAccount.sBirthdate }}
            </p>
        </div>

        <div class="bg-white of-x-hide">
            <div class="slide-box bg-white" :class="[{ opened: isOpened }, { hidden: isHidden }]">
                <div class="px-xs-15">
                    <div class="edit-title-mobile">
                        <div class="bb-1-gray p-15 d-flex flex-center justify-between">
                            <h3 class="fw-900 text-uppercase d-block fs-s">Edite seus dados</h3>
                            <i @click="slide(false)" class="fas fa-times fs-1-6 pointer"></i>
                        </div>
                    </div>

                    <p class="fw-700 d-block text-uppercase mt-15">Nome</p>
                    <input @focus="scrollToMe('.opened')" v-model="oAccount.firstname" type="text"
                           class="form-control mt-10 br-0 fs-s" placeholder="digite o nome"/>
                    <p class="fw-700 d-block text-uppercase mt-15">Sobrenome</p>
                    <input @focus="scrollToMe('.opened')" v-model="oAccount.lastname" type="text"
                           class="form-control mt-10 br-0 fs-s" placeholder="digite o sobrenome"/>
                    <p class="fw-700 d-block text-uppercase mt-15">Email</p>
                    <input @focus="scrollToMe('.opened')" v-bind:value="oAccount.email" type="text"
                           class="form-control mt-10 br-0 fs-s" placeholder="digite seu email" disabled/>
                    <p class="fw-700 d-block text-uppercase mt-15">Telefone</p>
                    <input @focus="scrollToMe('.opened')" v-model="oAccount.phone" type="text"
                           class="form-control mt-10 br-0 fs-s" placeholder="(__) ____-____"
                           v-mask="'(##) #####-####'"/>
                    <p class="fw-700 d-block text-uppercase mt-15">Data de Nascimento (opcional)</p>
                    <input @focus="scrollToMe('.opened')" v-model="oAccount.sBirthdate" type="text"
                           class="form-control mt-10 br-0 fs-s" placeholder="__/__/____" v-mask="'##/##/####'"/>

                    <div class="fw-700 d-block mt-15 mb-15">
                        <input type="checkbox" class="form-group-sm-checkbox mr-10" id="sConsentOffers" v-model="checkSmsEmail" @click="toggleSmsEmailMarketing" />
                        <label class="form-group-sm-label_checkbox mt-10 br-0 fs-s" for="sConsentOffers">
                            Aceito receber promoções e novidades por e-mail e SMS
                        </label>
                    </div>

                    <div class="col-12">
                        <div class="bg-white p-25 text-center d-block bt-1-gray">
                            <button @click="localUpdateAccount"
                                    class="btn btn-primary text-uppercase text-center fs-s mbslidexs">Salvar Dados
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import apiAccount from '@/api/account'
import store from '@/store'
import { showAlert } from '@/plugins/alerts'
import { scrollToMe } from '@/plugins/scroll'
import ipAddress from '@/plugins/ip-address'

export default {
  data: function () {
    return {
      isOpened: false,
      isHidden: true,
      checkSmsEmail: false,
      oAccount: {
        firstname: '',
        lastname: '',
        phone: '',
        birthdate: '',
        consents: [],
        requestOrigin: null
      }
    }
  },
  mounted () {
    this.populateAccount()
  },

  methods: {
    populateAccount: function () {
      let oUser = this.oUser
      oUser.requestOrigin = this.getRequestOrigin()
      this.oAccount = oUser
      this.checkSmsEmail = this.isChecked()
    },
    getRequestOrigin: function () {
      let requestOrigin = {}
      requestOrigin.userAgent = navigator.userAgent
      ipAddress().then(data => {
        requestOrigin.ip = data.ip
      }).catch(function (error) {
        console.error(error.message)
        requestOrigin.ip = null
      })
      return requestOrigin
    },

    isChecked: function () {
      return this.oAccount.consents.some(function (consent) { return (consent.granted == true) })
    },
    localUpdateAccount: function () {
      const that = this
      this.oAccount.birthdate = this.oAccount.sBirthdate
      apiAccount.UpdateAccount(this.oAccount).then(function (response) {
        let bReset = true
        store.dispatch('account/getUser', bReset)
        if (!response || !response.data || response.data.success === false) {
          showAlert('error', response.data.errors[0])
          return false
        } else {
          showAlert('success', 'Atualizado com sucesso!')
          that.slide(false)
        }
      })
    },
    slide: function (toggle) {
      if (toggle) {
        this.isHidden = !this.isHidden
        setTimeout(() => {
          this.isOpened = !this.isOpened
        }, 30)
      } else if (!toggle) {
        this.isOpened = !this.isOpened
        setTimeout(() => {
          this.isHidden = !this.isHidden
        }, 450)
      } else {
        console.log('Valor de slide inválido!')
      }
    },
    scrollToMe: function (box) {
      scrollToMe(box, -40)
    },
    toggleSmsEmailMarketing: function () {
      let self = this
      for (let key in self.oAccount.consents) {
        self.oAccount.consents[key].granted = !self.oAccount.consents[key].granted
      }
    }
  },

  computed: mapState({
    oUser: (state) => {
      return state.account.oUser
    },
    isMobile: state => state.store.isMobile,

    nameInLowerCase: function () {
      let name = this.oAccount.firstname + ' ' + this.oAccount.lastname

      return name.toLowerCase().trim()
    }
  })
}
</script>
