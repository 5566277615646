<template>
    <div>
        <div class="bg-white p-15 mt-15 bb-1-gray">
            <div class="d-none d-lg-block text-center mb-20">
                <img loading="lazy" class="img-fluid" :src="product_image">
            </div>
            <span class="text-uppercase fs-s fw-900">Ingredientes</span>
        </div>
        <ul class="list-unstyled bg-white">
            <li class="p-15 bb-1-gray" v-for="(ingredient, index) in ingredients" :key="index">
                <div class="form-group-md-checkbox_group">
                    <input ref="item" @click="removeIngredients()" type="checkbox"
                           :id="`option-${ingredient.ingredient_id}`"
                           name="aOptions[]" class="form-group-md-checkbox" checked
                           :value="`${ingredient.ingredient_id}`"/>
                    <label class="form-group-md-label_checkbox fw-700 fs-m" :for="`option-${ingredient.ingredient_id}`">{{
                        ingredient.ingredient_name }}</label>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
  data () {
    return {
      optionIngredients: []
    }
  },
  props: {
    ingredients: {},
    image: {},
    element: {},
    currentItem: {},
    combo: {}
  },
  methods: {
    removeIngredients () {
      let removeIngredients = []
      for (let i in this.$refs.item) {
        if (!this.$refs.item[i].checked) {
          removeIngredients.push(this.$refs.item[i].value)
        }
      }
      if (this.element) {
        this.currentItem[this.element.name][this.element.index].removeIngredients = removeIngredients
        return this.$store.dispatch('cart/setProduct', this.currentItem)
      }

      if (this.combo) {
        this.currentItem.product_steps[this.combo.step].step_products[0].removeIngredients = removeIngredients
        return this.$store.dispatch('cart/setProduct', this.currentItem)
      }

      this.currentItem.removeIngredients = removeIngredients
      this.$store.dispatch('cart/setProduct', this.currentItem)
    }
  },
  computed: {
    product_image () {
      if (this.image) {
        return this.$config.url_images + this.image
      }
      return ''
    }
  }
}
</script>
