import Api from './api'

import { getXUserKey } from '@/plugins/account'

export default {
  /* Valida o carrinho */
  ValidateCart (oCart) {
    return Api('checkout', 'cart', 'post', oCart, { 'x-user-key': getXUserKey() })
  },
  /* Fehca o pedido */
  SendOrder (oOrder) {
    return Api('checkout', 'checkout', 'post', oOrder, { 'x-user-key': getXUserKey() })
  },
  /** pega cartões armazenados */
  getCustomerCards () {
    return Api('checkout', 'customer-cards', 'post', {}, { 'x-user-key': getXUserKey() })
  },
  /** envia erros para o slack */
  sendErrors (data) {
    Api('checkout', 'send-to-slack', 'post', data, { 'x-user-key': getXUserKey() })
  },
  calcalculateDeliveryPrice (data) {
    return Api('address', 'calculate-delivery-price', 'post', data)
  }
}
