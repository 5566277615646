import Qs from 'qs'
import store from '@/store'

const axios = require('axios')
let apiKey = process.env.VUE_APP_API_KEY
let apiEnv = process.env.VUE_APP_API_ENV

if (typeof token !== 'undefined') {
/* eslint-disable */
  apiKey = token
/* eslint-enable */
}

if (typeof envName !== 'undefined') {
/* eslint-disable */
  apiEnv = envName
/* eslint-enable */
}

let url = window.location.search.substr(1).split('&')
for (let i in url) {
  if (url[i].split('=')[0] === 'client') {
    apiKey = url[i].split('=')[1]
  }

  if (url[i].split('=')[0] === 'schema') {
    localStorage.setItem('schema', url[i].split('=')[1])
  }
}

axios.defaults.headers.common['x-api-key'] = apiKey
axios.defaults.headers.common['x-api-env-name'] = apiEnv
axios.defaults.headers.common['content-type'] = 'application/json'

export default async (resource, method, httpMethod = 'get', data = {}, headers = {}, showLoading = true) => {
  let sUrl = `${process.env.VUE_APP_API}/${resource}/${method}`
  /* No caso de método get converte o onjeto para query string */
  if (httpMethod === 'get') {
    sUrl += '?' + Qs.stringify(data)
  }
  if (showLoading) {
    store.commit('store/setLoading', true)
  }
/* eslint-disable */
  return await axios({
    url: sUrl,
    method: httpMethod,
    data: data,
    headers: headers
  }).then((response) => {
    if (showLoading) {
      store.commit('store/setLoading', false)
    }
    return response
  }).catch(error => {
    store.commit('store/setLoading', false)
  })
}
/* eslint-enable */
