<template>
    <div class="container main">
        <div class="row">
            <div class="col-12">
                <div class="bg-white d-block mb-20">
                    <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Pedido <span class="number">{{iOrderId}}</span>
                        - {{oOrder.sStatus}}</h3>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="bg-white d-block mb-xs-5 mb-20">
                    <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Seu pedido </h3>
                    <!-- Item do pedido -->
                    <vtto-order-item class="media m-0 relative bb-1-gray flex-column" :items="oOrder.items"></vtto-order-item>
                    <!-- FIM Item do pedido -->
                </div>
                <div class="bg-white d-block mb-20" v-if="oOrder.delivery">
                    <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Entrega</h3>
                    <div class="media m-0 relative bb-1-gray p-15 box-content">
                        <div class="media-body mr-10">
                            <p class="fw-500 fs-m">{{oOrder.delivery.method}}</p>
                            <p class="fw-300 fs-s mt-10">{{oOrder.delivery.address}}
                                <span v-if="oOrder.delivery.number">, nº {{oOrder.delivery.number}}</span>
                                <span v-if="oOrder.delivery.extra"> - {{oOrder.delivery.extra}}</span>
                                <span v-if="oOrder.delivery.district"> - {{oOrder.delivery.district}}</span>
                                <span v-if="oOrder.delivery.city"> - {{oOrder.delivery.city}}</span>
                            </p>
                        </div>
                        <div class="ml-10 text-right relative">
                            taxa de entrega
                            <p class="price fs-m color-main w-80">
                                {{oOrder.delivery.fPrice}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="bg-white d-block mb-20" v-if="oOrder.gift && oOrder.gift.fValue">
                    <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Descontos</h3>
                    <div class="media m-0 relative bb-1-gray p-15 box-content">
                        <div class="ml-10 text-right relative">
                            <p class="fw-500 fs-m">Cupom de desconto</p>
                            <div class="ml-10 text-right relative">
                                <p class="price fs-m color-main w-80">
                                    {{oOrder.gift.fValue}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white d-block mb-20" v-if="oOrder.payments">
                    <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Forma de Pagamento</h3>
                    <!-- Pagante -->
                    <li class="media m-0 relative bb-1-gray p-15 box-content"
                        v-for="(oPayment, iPayment) in oOrder.payments" :key="iPayment">
                        <div class="media-body mr-10">
                            <p class="fw-500 fs-m">Pagante {{iPayment+1}}</p>
                            <p class="fw-300 fs-s mt-10">
                                {{oPayment.type}}
                                <span v-if="oPayment.method">- {{oPayment.method}}</span>
                                <span v-if="oPayment.change > 0">Troco para: {{oPayment.fChange}}</span>
                                <img loading="lazy" v-if="false && oPayment.image" :src="oPayment.image">
                            </p>
                        </div>
                        <div class="ml-10 text-right relative">
                            <p class="price fs-m color-main w-80">
                                {{oPayment.fPrice}}
                            </p>
                        </div>
                    </li>

                    <li class="media m-0 relative bb-1-gray p-15 box-content" v-if="oOrder.sStatus == 'AGUARDANDO PAGAMENTO'">
                        <find-order :order_id="iOrderId" :ignore="true"></find-order>
                    </li>
                    <!-- FIM Pagante -->
                </div>
                <div class="bg-white d-block mb-20" v-if="oOrder.observation">
                    <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Observação</h3>
                    <div class="text-lg-right relative p-15 box-content">
                        {{oOrder.observation}}
                    </div>
                </div>
                <div class="bg-white d-block mb-20">
                    <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Valores</h3>
                    <div class="text-lg-right relative p-15 box-content">
                        Total:
                        <p class="price fs-m color-main w-80" v-if="oOrder.delivery">
                            {{ parseFloat(oOrder.order_total_amount) + parseFloat(oOrder.delivery.price) | money }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="bg-white d-block mb-20 p-15 text-center d-block">
                    <router-link :to="{name: 'account-orders'}"
                                 class="btn btn-secondary text-uppercase text-center mr-10">Voltar para os pedidos
                    </router-link>
                    <a v-if="false" @click="localRepeatOrder(oOrder)"
                       class="pointer btn btn-primary text-uppercase text-center mt-xs-10">Repetir Pedido</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import apiOrder from '@/api/order'
import { repeatOrder } from '@/plugins/orders'
import VttoOrderItem from '@/components/Order/VttoProduct'
import { showAlert } from '@/plugins/alerts'
import FindOrder from './FindOrder.vue'

export default {
  components: {
    VttoOrderItem,
    FindOrder
  },
  data: function () {
    return {
      oOrder: {}
    }
  },
  mounted () {
    this.getOrderDetail()
    this.$scrollTo('.main')
  },
  computed: mapState({
    iOrderId: state => state.route.params.iOrderId
  }),
  methods: {
    localRepeatOrder (oOrder) {
      return repeatOrder(oOrder)
    },
    getOrderDetail () {
      let self = this
      var aReturn = []
      let oOrder = {
        order_id: this.iOrderId
      }
      apiOrder.GetOrderDetail(oOrder).then(function (response) {
        if (!response || !response.data || response.data.success === false) {
          if (!response) {
            showAlert('error', 'Descrição não encontrada')
          } else {
            showAlert('error', JSON.stringify(response.data))
          }
          return aReturn
        } else {
          let aData = response.data
          self.oOrder = aData.data
        }
      })
    }
  }
}
</script>
