<template>
  <div class="container main p-xs-0">

    <!-- Dados pessoais -->
    <div class="row">
        <div class="col-12 bg-white">
            <vtto-account-data></vtto-account-data>
        </div>
    </div>
    <!--  -->

    <!-- Alterar senha -->
    <div class="row">
        <div class="col-12 bg-white mb-15">
            <vtto-account-password></vtto-account-password>
        </div>
    </div>
    <!--  -->
    <div class="row" v-for="(oAddress_, iAddress) in oUserAddresses" :key="iAddress" >
      <vtto-account-address :hideZipcode="hideZipcode" :bNew="false" :iAddressId="iAddress" :iCountAddresses="Object.keys(aAddresses).length" :oAddress="oAddress_"></vtto-account-address>
    </div>

    <div class="row">
      <vtto-account-address :hideZipcode="hideZipcode" :bNew="true" v-if="showNewAddress" :oAddress="oAddressLocal" iCountAddresses="0"></vtto-account-address>
    </div>

    <div class="row">
        <div class="col-12 bg-white btn-bnew mb-20">
            <div class="d-block  p-15 text-center d-block bt-1-gray">
                <button @click="showNewAddress=!showNewAddress" class="btn btn-secondary text-uppercase text-center fs-s">
                  <span v-if="showNewAddress">Ocultar Novo</span>
                  <span v-else>Novo</span>
                  Endereço
                </button>
            </div>
        </div>
    </div>

    <div class="row d-block d-lg-none">
      <div @click="logout" class="col-12 bg-white p-20 fs-m mb-20">
        <div>
          <a class="pointer ml-15"><i class="fas fa-sign-out-alt" aria-hidden="true"></i> Sair</a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import apiAccount from '@/api/account'
import VttoAccountAddress from '@/components/Account/VttoAddress'
import VttoAccountData from '@/components/Account/VttoAccount'
import VttoAccountPassword from '@/components/Account/VttoPassword'
import { showAlert } from '@/plugins/alerts'
import { setXUserKey } from '@/plugins/account'
export default {
  components: {
    VttoAccountAddress,
    VttoAccountData,
    VttoAccountPassword
  },
  name: 'vtto-account',
  data: function () {
    return {
      showNewAddress: false,
      oAddress: {
        title: '',
        address: '',
        number: '',
        city_id: 0,
        district_id: 0,
        state: 0,
        extra: '',
        zipcode: ''
      },
      aAddresses: []
    }
  },
  mounted () {
    this.getAddresses()
  },

  created () {
    this.$store.dispatch('account/getListAddressInfo')
  },

  methods: {
    logout () {
      setXUserKey()
      this.$store.dispatch('account/cleanUser')
      this.$router.push({ name: 'login' })
    },
    getAddresses: function () {
      let self = this
      apiAccount.ListAddresses().then(function (response) {
        if (!response || !response.data || response.data.success === false) {
          showAlert('error', response.data.errors[0])
          return false
        } else {
          let aData = response.data
          self.aAddresses = aData.data
        }
      })
    }
  },
  computed: mapState({
    hideZipcode: state => state.store.info.hide_zipcode,
    oUserAddresses: state => state.account.oUserAddresses,
    oAddressLocal: function () {
      return this.oAddress
    }
  })
}
</script>
