<template>
    <div>
      <vtto-catalog />
    </div>
</template>

<script>
import VttoCatalog from '@/components/VttoCatalog'
export default {
  components: {
    VttoCatalog
  },
  beforeCreate () {
    this.$store.dispatch('cart/cleanCurrentItem')
    this.$store.dispatch('catalog/setBtnMode', 1)
    this.$store.dispatch('cart/setSteps', [])
    this.$store.dispatch('store/setCarousel', false)
  }
}
</script>
