<template>
  <div>
    <div v-if="getCatalog !== ''">
      <vtto-search :catalog="getCatalog" @search="setFilteredCatalog" class="mb-xs--15"></vtto-search>
      <div v-if="filteredCatalog.length > 0" class="catalog-list">
        <div v-for="(item, index) in filteredCatalog" :key="index" class="catalog-item" v-if="item.enabledDeliveryConsumer">
          <vtto-catalog-category :item="item" :collapse="info.front_collapse_menu"
                                 :images-mobile="info.hidden_images_mobile"/>
        </div>
      </div>
      <div v-else>
        <div class="bg-white p-20 fs-1-2 mt-15">
          <p>Nenhum produto foi encontrado!</p>
        </div>
      </div>
    </div>
    <div v-else>
      <vtto-loading-content></vtto-loading-content>
    </div>
  </div>
</template>

<script>
import VttoCatalogCategory from '@/components/Catalog/VttoCategory'
import VttoLoadingContent from '@/components/VttoLoadingContent'
import VttoSearch from '@/components/VttoSearch'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'vtto-catalog',
  components: {
    VttoCatalogCategory,
    VttoLoadingContent,
    VttoSearch
  },
  data () {
    return {
      term: '',
      getCatalog: '',
      filteredCatalog: '',
      loading: ''
    }
  },
  methods: {
    setFilteredCatalog (catalog) {
      this.filteredCatalog = catalog
    }
  },
  mounted () {
    if (this.getCatalogFormated.length > 0) {
      this.getCatalog = this.getCatalogFormated
    }
  },
  watch: {
    getCatalogFormated: function () {
      this.getCatalog = this.getCatalogFormated
    }
  },
  computed: {
    ...mapState({
      info: state => state.store.info
    }),
    ...mapGetters('catalog', [
      'getCatalogFormated'
    ])
  }
}
</script>
