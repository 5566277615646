<template>
    <div id="detail">
        <div ref="detail">
            <div class="row">
                <div class="col-lg-12">
                  <div v-if="hasStage() && stage === 0">
                    <vtto-catalog-detail-edge v-if="currentItem.size_edge_allowed" :current-item="currentItem" @set-edge="setEdge" />
                    <vtto-catalog-detail-info-extra @set-info-extra="setInfoExtra" v-if="currentItem.info_extra" :info-extra="currentItem.info_extra"/>
                    <vtto-catalog-detail-ingredients-new v-if="currentItem.product_ingredients" :ingredients="currentItem.product_ingredients" :current-item="currentItem" @remove-ingredients="removeIngredients"/>
                    <vtto-catalog-detail-ingredients-new v-if="currentItem.ingredients_extra_choice" :ingredients="currentItem.ingredients_extra_choice" :extra="true" @add-ingredients="addIngredients"/>
                    <div class="fixed bottom-0 w-100 d-block bg-white p-15 text-center" style="left: 0; z-index:1;">
                      <button @click="stage = 1" class="btn btn-primary text-uppercase text-center mx-auto">Próximo</button>
                    </div>
                  </div>
                  <div v-if="!hasStage() || (hasStage() && stage === 1)">
                    <!-- VttoProducts  -->
                    <vtto-products class="bg-white mt-15" :items="getCurrentItem()"></vtto-products>

                    <vtto-catalog-detail-price :prices.sync="productPrices" :item="currentItem"/>
                    <vtto-catalog-detail-observation/>
                  </div>
                </div>
            </div>
        </div>
        <vtto-catalog-confirm-steps-button v-if="!(hasStage() && stage === 0)" :active="buttonSteps" :closed="!open"/>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import VttoProducts from '@/components/VttoProducts'
import VttoCatalogConfirmStepsButton from '@/components/Catalog/Steps/VttoConfirmStepsButton'
import VttoCatalogDetailPrice from '@/components/Catalog/Detail/VttoPrices'
import VttoCatalogDetailObservation from '@/components/Catalog/Detail/VttoObservation'
import VttoCatalogDetailInfoExtra from '@/components/Catalog/Detail/VttoInfoExtra'
import VttoCatalogDetailEdge from '@/components/Catalog/Detail/VttoEdge'
import VttoCatalogDetailIngredientsNew from '@/components/Catalog/new/VttoIngredients'

export default {
  components: {
    VttoProducts,
    VttoCatalogConfirmStepsButton,
    VttoCatalogDetailPrice,
    VttoCatalogDetailObservation,
    VttoCatalogDetailInfoExtra,
    VttoCatalogDetailEdge,
    VttoCatalogDetailIngredientsNew
  },
  data () {
    return {
      productPrices: {},
      stage: 0
    }
  },
  methods: {
    hasStage () {
      // VERIFICAR SE AS 'informações extras' ESTÃO DENTRO OU FORA DE UM ARRAY, SE ESTIVEREM FORA, COLOCÁ-LAS EM UM ARRAY
      if (typeof this.currentItem.info_extra !== 'undefined' && typeof this.currentItem.info_extra.info_extra_id !== 'undefined') {
        let infoExtra = this.currentItem.info_extra
        delete this.currentItem.info_extra
        this.currentItem.info_extra = []
        this.currentItem.info_extra[0] = infoExtra
      }

      if ((this.currentItem.info_extra && this.currentItem.info_extra.length > 0) || (this.currentItem.product_ingredients && this.currentItem.product_ingredients.length > 0 && this.verifyEnabledIngredient(this.currentItem)) ||
          (this.currentItem.ingredients_extra_choice && this.currentItem.ingredients_extra_choice.length > 0)) {
        return true
      }
      return false
    },
    verifyEnabledIngredient (currentItem) {
      if (!('enabled_ingredient' in currentItem)) {
        return false
      }

      if (currentItem.enabled_ingredient == 1) {
        return true
      }

      return false
    },
    getCurrentItem () {
      let product = []
      product.push(this.currentItem)
      return product
    },
    setInfoExtra (option) {
      this.currentItem.info_extra_option_id = option.option_id
      this.currentItem.info_extra_option_name = option.option_name
      this.currentItem.info_extra_option_amount = option.option_amount
      this.$store.dispatch('cart/setProduct', this.currentItem)
      this.processPrices()
    },
    removeIngredients (ingredients) {
      this.currentItem.remove_ingredients = ingredients
      this.$store.dispatch('cart/setProduct', this.currentItem)
    },
    addIngredients (ingredients) {
      if (!this.currentItem.product_prices.ingredients_extra) {
        this.currentItem.product_prices.ingredients_extra = {}
      }
      this.currentItem.ingredients_extra = ingredients
      this.$store.dispatch('cart/setProduct', this.currentItem)
      this.processPrices()
    },
    setEdge (currentItem) {
      this.$store.dispatch('cart/setProduct', currentItem)
      this.processPrices()
    },
    showDisplay () {
      return false
    },
    formatCompositionSummary () {
      let res = {
        product_name: this.currentItem.product_name,
        product_ingredients: this.currentItem.product_ingredients,
        steps: []
      }

      Object.entries(this.currentItem.product_steps).map(a => {
        let ingredients = []
        for (let i in a[1].step_ingredients) {
          let label = ''
          label += `(${a[1].step_ingredients[i].ingredient_quantity}x) ${a[1].step_ingredients[i].ingredient_name}`
          if (a[1].step_ingredients[i].ingredient_amount) {
            let fPrice = a[1].step_ingredients[i].ingredient_amount
            label += ` (+ ${this.money(fPrice)})`
          }
          ingredients.push(label)
        }

        if (a[1].step_ingredients_extra) {
          for (let i in a[1].step_ingredients_extra) {
            let label = ''
            label += `(${a[1].step_ingredients_extra[i].ingredient_quantity}x) ${a[1].step_ingredients_extra[i].ingredient_name}`

            if (a[1].step_ingredients_extra[i].ingredient_amount > 0) {
              label += ` (+ ${this.money(a[1].step_ingredients_extra[i].ingredient_quantity * a[1].step_ingredients_extra[i].ingredient_amount)})`
            }
            ingredients.push(label)
          }
        }

        if (a[1].info_extra) {
          for (let i in a[1].info_extra[0].info_extra_options) {
            if (a[1].info_extra[0].info_extra_options[i].option_id === a[1].info_extra_option_id) {
              let label = ''
              label += `${a[1].info_extra[0].info_extra_options[i].option_name}`
              ingredients.push(label)
            }
          }
        }

        res.steps.push({
          step_name: a[1].step_name,
          step_choice: ingredients
        })
      })
      return res
    },

    money (value, prefix = 'R$') {
      value = parseFloat(value).toFixed(2).replace('.', ',')
      return `${prefix}${value}`
    },
    calculeExtras (product) {
      let extras = 0
      /** step ingredients */
      extras += product.step_ingredients[0].ingredient_quantity * product.step_ingredients[0].ingredient_amount

      return extras
    },
    extSteps (item, s = false) {
      let extra = 0

      for (let i in item) {
        for (let y in item[i].step_ingredients) {
          extra += item[i].step_ingredients[y].ingredient_amount
        }
        for (let y in item[i].step_products) {
          if (item[i].step_products[y].product_steps) {
            extra += this.extSteps(item[i].step_products[y].product_steps, true)
          }
        }
      }

      return extra
    },
    stepsExtra (item, s = false) {
      let extra = 0

      for (let i in item) {
        for (let y in item[i].ingredients_extra) {
          let quantity = parseInt(item[i].ingredients_extra[y].ingredient_quantity) || 1
          let amount = parseFloat(item[i].ingredients_extra[y].ingredient_amount)
          extra += parseFloat(amount * quantity)
        }
        for (let y in item[i].step_products) {
          if (item[i].step_products[y].products) {
            extra += this.stepsExtra(item[i].step_products[y].products, true)
          }
        }
      }

      return extra
    },
    enableDisableEdge (edge) {
    },
    processPrices () {
      let newPrices = this.$store.state.cart.currentItemPrices
      let products = []

      /** PRODUTO SIMPLES, MONTAGEM OU COMBO */
      if (!this.currentItem.products) {
        let calculatedPrices = typeof newPrices.products !== 'undefined' ? newPrices.products[0] : []
        calculatedPrices.ingredients = 0
        calculatedPrices.ingredientsExtra = 0
        calculatedPrices.infoExtra = 0
        calculatedPrices.edge = 0
        calculatedPrices.extra = 0

        /** ingredients extra geral */
        if (this.currentItem.ingredients_extra) {
          for (let i in this.currentItem.ingredients_extra) {
            let quantity = typeof this.currentItem.ingredients_extra[i].ingredient_quantity !== 'undefined' ? parseInt(this.currentItem.ingredients_extra[i].ingredient_quantity) : 1
            calculatedPrices.ingredientsExtra += parseFloat(this.currentItem.ingredients_extra[i].ingredient_amount) * quantity
          }
        }

        /** info extra geral */
        if (this.currentItem.info_extra) {
          if (typeof this.currentItem.info_extra.info_extra_id === 'undefined' && this.currentItem.info_extra.length > 0) {
            let infoExtra = this.currentItem.info_extra[0]
            delete this.currentItem.info_extra
            this.currentItem.info_extra = infoExtra
          }

          for (let i in this.currentItem.info_extra.info_extra_options) {
            if (this.currentItem.info_extra.info_extra_options[i].option_id === this.currentItem.info_extra_option_id) {
              calculatedPrices.infoExtra += this.currentItem.info_extra.info_extra_options[i].option_amount
            }
          }
        }

        /** COMBO */
        if (this.isCombo()) {
          for (let i in this.currentItem.product_steps) {
            calculatedPrices = this.processComboStepProductPrice(this.currentItem.product_steps[i], calculatedPrices)
          }
        }

        /** MONTAGEM */
        calculatedPrices = this.processProductMountingPrice(this.currentItem, calculatedPrices)

        if (typeof calculatedPrices.edge !== 'undefined' && parseFloat(calculatedPrices.edge) > 0) {
          newPrices.edge = calculatedPrices.edge
          delete calculatedPrices.edge
        }

        /** EDGE */
        if (this.currentItem.edge_amount > 0) {
          calculatedPrices.edge = this.currentItem.edge_amount
        }

        products.push(calculatedPrices)
      } else {
        /** PIZZA MEIO-A-MEIO */

        for (let i in this.currentItem.products) {
          let product = this.currentItem.products[i]

          let calculatedPrices = {
            extra: 0,
            ingredients: 0,
            ingredientsExtra: 0,
            infoExtra: 0,
            price: product.prices.price,
            promo: product.prices.promo,
            loyalty: product.prices.loyalty,
            minor: product.prices.minor,
            edge: 0
          }

          /** INFORMAÇÃO EXTRA */
          calculatedPrices = this.processProductInfoExtraPrice(product, calculatedPrices)

          /** COMBO */
          if (this.isCombo()) {
            for (let i in product.product_steps) {
              calculatedPrices = this.processComboStepProductPrice(product.product_steps[i], calculatedPrices)
            }
          }

          /** MONTAGEM */
          if (this.isMounting(product)) {
            calculatedPrices = this.processProductMountingPrice(product, calculatedPrices)
          }

          /** INGREDIENTES EXTRA */
          if (this.hasExtraIngredient(product)) {
            calculatedPrices = this.processIngredientExtra(product, calculatedPrices)
          }

          products.push(calculatedPrices)
        }
      }

      newPrices.products = products
      this.$store.dispatch('cart/setPrices', newPrices)
      this.$eventHub.$emit('prices', 0)
    },
    /**
     * MÉTODO DE VALIDAÇÃO DE PRODUTO COMBO
     * TYPE = 3
     */
    isCombo () {
      let currentItem = this.currentItem
      if (typeof currentItem.category_type !== 'undefined' &&
      parseInt(currentItem.category_type) === 3 &&
      typeof currentItem.product_steps !== 'undefined' &&
      currentItem.product_steps.length > 0) {
        return true
      }
      return false
    },
    isMounting (product) {
      if (typeof product.product_steps !== 'undefined' && product.product_steps !== null) {
        let isEmpty = Object.keys(product.product_steps).length === 0 && product.product_steps.constructor === Object
        if (!isEmpty) {
          return true
        }
      }
      return false
    },

    hasExtraIngredient (product) {
      return (typeof product.ingredients_extra !== 'undefined' && product.ingredients_extra.length > 0)
    },
    /** PROCESSA OS PREÇOS DOS INGREDIENTES EXTRAS */
    processIngredientExtra (product, finalPrices) {
      if (this.hasExtraIngredient(product)) {
        for (let j in product.ingredients_extra) {
          let ingredient = product.ingredients_extra[j]
          if (typeof ingredient.ingredient_quantity !== 'undefined' &&
          parseInt(ingredient.ingredient_quantity) > 0 &&
          typeof ingredient.ingredient_amount !== 'undefined' &&
          parseFloat(ingredient.ingredient_amount) > 0) {
            finalPrices.ingredientsExtra += parseInt(ingredient.ingredient_quantity) * parseFloat(ingredient.ingredient_amount)
          }
        }
      }
      return finalPrices
    },
    /**
     * PROCESSA OS PREÇOS DOS ITEMS DENTRO DE ETAPAS DE COMBO
     */
    processComboStepProductPrice (productStep, finalPrices) {
      if (typeof productStep.step_products !== 'undefined' && productStep.step_products.length > 0) {
        for (let i in productStep.step_products) {
          let stepProduct = productStep.step_products[i]

          /** BORDA */
          if (typeof stepProduct.edge_amount !== 'undefined') {
            finalPrices.edge += parseFloat(stepProduct.edge_amount)
          }

          /** PRODUTOS */
          if (typeof stepProduct.products !== 'undefined' && stepProduct.products.length > 0) {
            for (let j in stepProduct.products) {
              let product = stepProduct.products[j]
              finalPrices = this.processProductPrice(product, finalPrices)
            }
          }

          /** ingredients extra da categoria */
          if (stepProduct.ingredients_extra) {
            finalPrices = this.processIngredientExtra(stepProduct, finalPrices)
          }

          /** MONTAGEM */
          if (typeof stepProduct.product_steps !== 'undefined' && stepProduct.product_steps.length > 0) {
            finalPrices = this.processProductMountingPrice(stepProduct, finalPrices)
          }
        }
      }
      return finalPrices
    },
    /**
     * PROCESSA OS PREÇOS DO PRODUTO ENVIADO
     */
    processProductPrice (product, finalPrices) {
      /** INFORMAÇÃO EXTRA DO PRODUTO */
      finalPrices = this.processProductInfoExtraPrice(product, finalPrices)
      /** MONTAGEM */
      finalPrices = this.processProductMountingPrice(product, finalPrices)
      /** PRODUTO SIMPLES */
      finalPrices = this.processSimpleProductPrice(product, finalPrices)

      return finalPrices
    },
    /**
     * INFORMAÇÃO EXTRA DO PRODUTO
     */
    processProductInfoExtraPrice (product, finalPrices) {
      if (
        typeof product.info_extra_option_id !== 'undefined' &&
        parseInt(product.info_extra_option_id) > 0 &&
        typeof product.info_extra_option_amount !== 'undefined' &&
        parseFloat(product.info_extra_option_amount) > 0
      ) {
        finalPrices.infoExtra += parseFloat(product.info_extra_option_amount)
      }
      return finalPrices
    },
    /**
      * PROCESSAMENTO DE PREÇOS DE PRODUTO TIPO MONTAGEM
      */
    processProductMountingPrice (product, finalPrices) {
      if (this.isMounting(product)) {
        /** INGREDIENTES EXTRA DO PRODUTO */
        for (let i in product.product_steps) {
          let productStep = product.product_steps[i]
          /** INFORMAÇÃO EXTRA DA ETAPA DO PRODUTO */
          if (typeof productStep.info_extra !== 'undefined') {
            /** NORMALIZAÇAO DA INFORMAÇÃO EXTRA NA ETAPA, PARA FICAR IGUAL A INFO EXTRA DO COMBO */
            productStep.product_size = { info_extra: productStep.info_extra[0] }
            finalPrices = this.processProductInfoExtraPrice(productStep, finalPrices)
          }
          /** INGREDIENTES DA ETAPA */
          if ((typeof productStep.step_ingredients !== 'undefined' && (productStep.step_ingredients.length > 0)) || (typeof productStep.step_ingredients === 'object' && Object.keys(productStep.step_ingredients).length)) {
            for (let j in productStep.step_ingredients) {
              let ingredient = productStep.step_ingredients[j]
              if (typeof ingredient.ingredient_quantity !== 'undefined' &&
              parseInt(ingredient.ingredient_quantity) > 0 &&
              typeof ingredient.ingredient_amount !== 'undefined' &&
              parseFloat(ingredient.ingredient_amount) > 0) {
                let qty = parseInt(ingredient.ingredient_quantity)
                let amt = parseFloat(ingredient.ingredient_amount)
                finalPrices.ingredients += qty * amt
              }
            }
          }
          /** INGREDIENTES EXTRA DA ETAPA */
          finalPrices = this.processIngredientExtra(productStep, finalPrices)
        }
      }

      return finalPrices
    },
    processSimpleProductPrice (product, finalPrices) {
      if (!this.isMounting(product)) {
        /** INGREDIENTES EXTRA */
        finalPrices = this.processIngredientExtra(product, finalPrices)
      }
      return finalPrices
    }
  },
  created () {
    this.processPrices()
    let edge = 0
    if (this.currentItem.edge_amount && this.currentItem.edge_amount > 0) {
      edge = this.currentItem.edge_amount
    }
    if (!this.currentItem.product_prices) {
      this.currentItem.product_prices = {}
    }
    this.currentItem.product_prices.edge = {
      amount: edge
    }
    let extra = 0
    extra += this.extSteps(this.currentItem.product_steps)
    this.currentItem.product_prices.extra = {
      amount: extra
    }

    if (this.currentItem.product_steps) {
      if (this.currentItem.product_steps[0].step_products) {
        if (this.currentItem.product_steps[0].step_products[0].products) {
          extra += this.stepsExtra(this.currentItem.product_steps)
          this.newPrices.products[0].ingredientsExtra = extra
        }
      }
    }
    this.productPrices = this.currentItem.product_prices
    this.$store.dispatch('cart/setProduct', this.currentItem)

    this.$store.state.catalog.buttonStepsLabel = 'Adicionar ao Pedido'
    this.$store.state.catalog.buttonSteps = true
    this.$store.dispatch('catalog/setBtnMode', 2)
  },
  beforeRouteEnter (to, from, next) {
    if (!to.params.product) {
      next({ path: '/front' })
    }
    next()
  },
  computed: {
    title: function () {
      return {
        category: this.currentItem.category_name,
        subcategory: this.currentItem.subcategory_name,
        size: this.currentItem.size_name,
        product_name: this.currentItem.product_name
      }
    },
    ...mapState({
      open: state => state.store.info.working_time.is_open,
      info: state => state.store.info,
      isLogged: state => state.store.isLogged,
      catalog: state => state.catalog.catalog,
      params: state => state.route.params,
      buttonSteps: state => state.catalog.buttonSteps,
      currentItem: state => state.cart.currentItem,
      currentItemPrices: state => state.cart.currentItemPrices,
      steps: state => state.cart.steps,
      mode: state => state.catalog.buttonStepsMode,
      newPrices: state => state.cart.currentItemPrices
    })
  }
}
</script>
