/* global window, FB, document */

import Bugsnag from '@bugsnag/js'

export function loadFbSdk (appId, version) {
  return new Promise(resolve => {
    window.fbAsyncInit = function () { // eslint-disable-line func-names
      FB.init({
        appId: appId,
        cookie: true,
        xfbml: false,
        status: false,
        version: version
      })
      resolve('SDK Loaded!')
    };
    (function (d, s, id) { // eslint-disable-line func-names
      const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) { return }
      const js = d.createElement(s); js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      js.onerror = error => { Bugsnag.notify(new Error('O SDK do Facebook não foi carregado')); reject(error) }
      fjs.parentNode.insertBefore(js, fjs)
    }(document, 'script', 'facebook-jssdk'))
  })
}

export function getLoginStatus (serverRequest = false) {
  return new Promise(resolve => {
    if (serverRequest) {
      window.FB.getLoginStatus(responseStatus => {
        resolve(responseStatus)
      }, serverRequest)
    } else {
      window.FB.getLoginStatus(responseStatus => {
        resolve(responseStatus)
      })
    }
  })
}

export function fbLogin (options) {
  return new Promise(resolve => {
    window.FB.login(response => resolve(response), options)
  })
}
export function fbLogout () {
  return new Promise(resolve => {
    window.FB.logout(response => resolve(response))
  })
}
