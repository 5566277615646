<template>
  <div class="bg-white d-block p-20 text-center relative">
    <div class="pb-30">
      <span class="color-red fs-14"><i class="fas fa-ban"></i></span>
      <p class="fw-900 fs-m text-uppercase color-red mt-20">Infelizmente seu pedido foi cancelado. Entre em contato conosco para mais informações: {{sPhone}}</p>
      <p class="fw-400 fs-m">E informe o código do seu pedido: {{iOrderId}}</p>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'vtto-order-status-canceled',
  props: {
    iOrderId: {
      type: [Number, String],
      default: 0
    }
  },
  computed: mapState({
    sPhone: state => state.store.info.phones.phone_1
  })
}
</script>
