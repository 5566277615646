<template>
    <div class="container main">
        <vtto-account-menu></vtto-account-menu>
        <div class="row">
            <div class="col-12">
                <div class="bg-white d-block mb-20">
                    <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Dados Pessoais</h3>
                    <div class="p-15">
                        <p class="fw-700 d-block text-uppercase">Email</p>
                        <input :disabled="(fbemail ? fbemail : (googleEmail ? googleEmail : false))"  @focus="scrollToMe()" type="email" name="email" id="email" @blur="verifyEmail" class="form-control mt-10 br-0 fs-s" placeholder="digite seu email" v-model="oCustomer.email"/>
                        <span class="color-red" v-if="emailError"><span>{{ emailErrorMsg }}</span> <router-link v-if="emailErrorType===0" class="color-red" :to="{name: 'forgot-password'}">ou clique aqui e recupere a senha</router-link></span>

                        <p class="fw-700 d-block text-uppercase mt-15">Nome</p>
                        <input @focus="scrollToMe()" type="text" class="form-control mt-10 br-0 fs-s" placeholder="digite o nome" v-model="oCustomer.firstname"/>
                        <span class="color-red ml-5" v-if="errorMsg.firstname">{{ errorMsg.firstname }}</span>

                        <p class="fw-700 d-block text-uppercase mt-15">Sobrenome</p>
                        <input @focus="scrollToMe()" type="text" class="form-control mt-10 br-0 fs-s" placeholder="digite o sobrenome" v-model="oCustomer.lastname"/>
                        <span class="color-red ml-5" v-if="errorMsg.lastname">{{ errorMsg.lastname }}</span>

                        <p class="fw-700 d-block text-uppercase mt-15">Telefone</p>
                        <input @focus="scrollToMe()" type="tel" class="form-control mt-10 br-0 fs-s" v-mask="'(##) #####-####'" placeholder="(__) ____-____" v-model="oCustomer.phone"/>
                        <span class="color-red ml-5" v-if="errorMsg.phone">{{ errorMsg.phone }}</span>

                        <p class="fw-700 d-block text-uppercase mt-15">Data de Nascimento (opcional)</p>
                        <input @focus="scrollToMe()" type="tel" class="form-control mt-10 br-0 fs-s" v-mask="'##/##/####'" placeholder="__/__/____" v-model="oCustomer.birthdate"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="bg-white d-block mb-20">
                    <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Endereço de Entrega</h3>
                    <ul class="nav-tabs list-table w-100 bg-white my-15 text-center border-0">
                        <li class="list-table-item relative">
                            <label class="form-group-radio_group link-product" for="checkHome"
                                   data-url="https://homol.vtto.com.br/front/outro/1">
                                <input id="checkHome" type="radio" name="deliveryDestiny" class="form-group-radiobox"
                                       value="Casa" v-model="oCustomer.address.title">
                                <span class="form-group-check_radio"></span>
                                <span class="fw-700 fs-m">Casa</span>
                            </label>
                        </li>
                        <li class="list-table-item relative">
                            <label class="form-group-radio_group link-product" for="checkWork"
                                   data-url="https://homol.vtto.com.br/front/outro/1">
                                <input id="checkWork" type="radio" name="deliveryDestiny" class="form-group-radiobox"
                                       value="Trabalho" v-model="oCustomer.address.title">
                                <span class="form-group-check_radio"></span>
                                <span class="fw-700 fs-m">Trabalho</span>
                            </label>
                        </li>
                        <li class="list-table-item relative">
                            <label class="form-group-radio_group link-product" for="/front/outro/2"
                                   data-url="https://homol.vtto.com.br/front/outro/1">
                                <input id="/front/outro/2" type="radio" name="deliveryDestiny"
                                       class="form-group-radiobox" value="" v-model="oCustomer.address.title">
                                <span class="form-group-check_radio"></span>
                                <span class="fw-700 fs-m">Outros</span>
                            </label>
                        </li>
                    </ul>
                    <div action="" class="p-15 pt-0">
                        <p class="fw-700 d-block text-uppercase">Apelido (opcional)</p>
                        <input @focus="scrollToMe()" type="text" class="form-control mt-10 br-0 fs-s"
                               placeholder="Ex: (Minha Casa)" :value="oCustomer.address.title"/>
                        <template  v-if="!hideZipcode">
                            <p class="fw-700 d-block text-uppercase mt-15">CEP</p>
                            <input @focus="scrollToMe()" type="tel" class="form-control mt-10 br-0 fs-s"
                                placeholder="digite o CEP" v-mask="'#####-###'" v-model="oCustomer.address.zipcode"/>
                        </template>
                        <p class="fw-700 d-block text-uppercase mt-15">Estado</p>
                        <div class="form-group select-default">
                            <select @focus="scrollToMe()" id="uf" name="uf" class="text-uppercase relative pr-30"
                                    v-model="oCustomer.address.state">
                                <option value=""> Selecione</option>
                                <option v-for="(uf, stateKey) in states" :key="stateKey" :value="uf">{{uf}}</option>
                            </select>
                            <span class="color-red ml-5" v-if="errorMsg.state">{{ errorMsg.state }}</span>
                        </div>
                        <p class="fw-700 d-block text-uppercase mt-15" v-if="oCustomer.address.state" >Cidade</p>
                        <div class="form-group select-default" v-if="oCustomer.address.state" >
                            <select @focus="scrollToMe()" id="cidade" name="cidade"
                                    class="text-uppercase relative pr-30" v-model="oCustomer.address.city_id">
                                <option value=""> Selecione</option>
                                <option v-for="(city, cityKey) in cities[oCustomer.address.state]" :key="cityKey"
                                        :value="cityKey">{{city}}
                                </option>
                            </select>
                            <span class="color-red ml-5" v-if="errorMsg.city_id">{{ errorMsg.city_id }}</span>
                        </div>
                        <p class="fw-700 d-block text-uppercase mt-15" v-if="oCustomer.address.city_id">Bairro</p>
                        <div class="form-group select-default" v-if="oCustomer.address.city_id">
                            <select @focus="scrollToMe()" id="bairro" name="bairro"
                                    class="text-uppercase relative pr-30" v-model="oCustomer.address.district_id">
                                <option value=""> Selecione</option>
                                <option v-for="(district) in districts[oCustomer.address.city_id]"
                                        :key="district.district_id" :value="district.district_id">{{district.district_name}}
                                </option>
                            </select>
                            <span class="color-red ml-5" v-if="errorMsg.district_id">{{ errorMsg.district_id }}</span>
                        </div>
                        <p class="fw-700 d-block text-uppercase mt-15">Endereço</p>
                        <input @focus="scrollToMe()" type="text" class="form-control mt-10 br-0 fs-s" placeholder="digite o endereço" v-model="oCustomer.address.address"/>
                        <span class="color-red ml-5" v-if="errorMsg.address">{{ errorMsg.address }}</span>
                        <div class="row">
                            <div class="col-md-3">
                                <p class="fw-700 d-block text-uppercase mt-15">Número</p>
                                <input @focus="scrollToMe()" type="text" class="form-control mt-10 br-0 fs-s" placeholder="digite o número" v-model="oCustomer.address.number"/>
                                <span class="color-red ml-5" v-if="errorMsg.number">{{ errorMsg.number }}</span>
                            </div>
                            <div class="col-md-9">
                                <p class="fw-700 d-block text-uppercase mt-15">Complemento (opcional)</p>
                                <input @focus="scrollToMe()" type="text" class="form-control mt-10 br-0 fs-s" placeholder="digite o complemento" v-model="oCustomer.address.extra"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">

                <div class="bg-white d-block mb-20">
                    <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Dados de Acesso</h3>
                    <form action="" class="p-15">
                        <p class="fw-700 d-block text-uppercase">Senha (min. 6 caracteres)</p>
                        <input type="password" class="form-control mt-10 br-0 fs-s" placeholder="escolha uma senha"
                               v-model="oCustomer.password"/>
                        <p class="fw-700 d-block text-uppercase mt-15">Repita a senha</p>
                        <input @focus="scrollToMe()" type="password" class="form-control mt-10 br-0 fs-s"
                               placeholder="confirme e repita a senha" v-model="oCustomer.password2"/>
                        <span class="color-red ml-5 mt-10" v-if="errorMsg.password">{{ errorMsg.password }}</span>

                        <div class="form-group-sm-checkbox_group mt-10">
                            <input type="checkbox" id="sEula" name="checkEula" class="form-group-sm-checkbox mr-10"
                                   v-model="bEula"/>
                            <label class="form-group-sm-label_checkbox fs-s" for="sEula">
                                Concordo com os
                                <u class="color-dark-gray">
                                  <a href="https://sistemavitto.com.br/privacidade_sistema.html" target="_blank"> termos de uso e politica de privacidade</a>
                                </u>
                            </label>
                            <span class="color-red mt-5 ml-5" v-if="errorMsg.eula">{{ errorMsg.eula }}</span>
                        </div>

                        <div class="form-group-sm-checkbox_group mt-10">
                            <input type="checkbox" class="form-group-sm-checkbox mr-10" id="sConsentOffers" @click="toggleSmsEmailMarketing"/>
                            <label class="form-group-sm-label_checkbox fs-s" for="sConsentOffers">
                                Aceito receber promoções e novidades por e-mail e SMS
                            </label>
                        </div>
                    </form>
                    <div class="p-15 text-center d-block">
                      <button v-if="!loading" @click="checkForm" class="btn btn-primary text-uppercase text-center mx-auto mb-15">Concluir Cadastro</button>
                      <button v-else class="btn btn-primary text-uppercase text-center mx-auto mb-15">Aguarde...</button>
                      <div v-if="errors > 0" class="alert alert-danger mb-0 fs-1-2" style="top: 0!important;" role="alert">
                        <p v-if="errors == 1">Existe {{ errors }} campo preenchido errado</p>
                        <p v-else>Existem {{ errors }} campos preenchidos errado</p>
                        <p>Corrija-o<span v-if="errors != 1">s</span> para concluir o cadastro</p>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import apiAccount from '@/api/account'
import VttoAccountMenu from '@/components/Account/VttoMenu'
import { setXUserKey } from '@/plugins/account'
import { showAlert } from '@/plugins/alerts'
import { scrollToMe } from '@/plugins/scroll'
import ipAddress from '@/plugins/ip-address'
import { verifyPhone } from '@/utils'
import router from '@/router'

const axios = require('axios')
export default {
  data () {
    return {
      loading: false,
      errors: 0,
      emailError: false,
      emailErrorMsg: 'E-mail já cadastrado',
      emailErrorType: 0,
      fbemail: false,
      googleEmail: false,
      /* Termos de uso já vem selecionados */
      bEula: false,
      /* Dados do cliente */
      oCustomer: {
        email: '',
        firstname: '',
        lastname: '',
        phone: '',
        birthdate: '',
        password: '',
        password2: '',
        /* Dados do endereço */
        address: {
          title: 'Casa',
          zipcode: '',
          address: '',
          state: '',
          city_id: '',
          district_id: '',
          number: '',
          extra: ''
        },
        consents: [
          { type: 1, granted: false },
          { type: 2, granted: false }
        ],
        requestOrigin: {
          userAgent: '',
          ip: ''
        }
      },
      /* Mensagens de erro */
      errorMsg: {
        eula: '',
        firstname: '',
        lastname: '',
        phone: '',
        password: '',
        address: '',
        state: '',
        city_id: '',
        district_id: '',
        number: ''
      },
      /* Erros */
      validator: [
        {
          field: 'firstname',
          verify: 'this.oCustomer.firstname == false',
          msg: 'Preencha um nome.'
        },
        {
          field: 'lastname',
          verify: 'this.oCustomer.lastname == false',
          msg: 'Preencha um sobrenome.'
        },
        {
          field: 'phone',
          verify: 'this.verifyPhone(this.oCustomer.phone) == false',
          msg: 'Informe um telefone válido.'
        },
        {
          field: 'password',
          verify: 'this.oCustomer.password < 5 || (this.oCustomer.password != this.oCustomer.password2)',
          msg: 'As senhas preenchidas não coincidem ou possuem menos que 6 caracteres.'
        },
        {
          field: 'address',
          verify: 'this.oCustomer.address.address  == false',
          msg: 'Informe um endereço.'
        },
        {
          field: 'state',
          verify: 'this.oCustomer.address.state  == false',
          msg: 'Informe um estado.'
        },
        {
          field: 'city_id',
          verify: 'this.oCustomer.address.city_id  == false',
          msg: 'Informe uma cidade.'
        },
        {
          field: 'district_id',
          verify: 'this.oCustomer.address.district_id  == false',
          msg: 'Informe um bairro.'
        },
        {
          field: 'number',
          verify: 'this.oCustomer.address.number  == false',
          msg: 'Informe o número do endereço.'
        },
        {
          field: 'eula',
          verify: 'this.bEula == false',
          msg: 'É necessário aceitar os termos de uso.'
        }
      ]
    }
  },
  created () {
    let self = this

    if (self.$route.params.fbEmail) {
      self.oCustomer.email = self.$route.params.fbEmail
      self.fbemail = true
    }

    if (self.$route.params.googleEmail) {
      self.oCustomer.email = self.$route.params.googleEmail
      self.googleEmail = true
    }

    self.oCustomer.requestOrigin.userAgent = navigator.userAgent

    ipAddress().then(data => {
      self.oCustomer.requestOrigin.ip = data.ip
    }).catch(function (error) {
      console.error(error.message)
      self.oCustomer.requestOrigin.ip = null
    })

    self.$store.dispatch('account/getListAddressInfo')
  },
  methods: {
    verifyPhone (phone) {
      return verifyPhone(phone)
    },
    verifyEmail () {
      let self = this
      apiAccount.VerifyEmail({ email: this.oCustomer.email }).then(res => {
        if (!res || !res.data || res.data.errors) {
          this.emailError = true
          this.emailErrorMsg = res.data.errors[0]
          if (res.data.errors[0].search('inválido') >= 0) {
            this.emailErrorType = 1
          }
          self.loading = false
          return
        }

        this.emailError = false
      })
    },
    createCustomer () {
      let self = this
      apiAccount.CreateCustomer(self.oCustomer).then(function (response) {
        if (!response || !response.data || !response.data.success) {
          showAlert('error', response.data.errors[0])
          self.loading = false
          return false
        }

        self.loading = false
        if (self.hasConfirmationFlow()) {
          showAlert('success', 'Seu cadastro foi realizado com sucesso! Para realizar o primeiro acesso, acesse o seu e-mail e realize a confirmação de e-mail.', 10000)
          router.push({ name: 'login' })
        } else {
          let aData = response.data
          setXUserKey(aData.data['x-user-key'])
          showAlert('success', 'Sucesso!')
          router.push({ name: 'homeCatalog' })
        }
      })
    },
    scrollToMe: function () {
      if (scrollToMe) {
        scrollToMe('body', -80)
      }
    },
    verifyField (verification, msg) {
      if (verification) {
        this.errors++
        return msg
      } else {
        return ''
      }
    },
    checkForm () {
      this.loading = true
      this.verifyEmail()
      this.errors = 0

      if (this.emailError === true) {
        this.errors++
      }

      for (let i in this.validator) {
        /* eslint-disable */
        this.errorMsg[this.validator[i].field] = this.verifyField(eval(this.validator[i].verify), this.validator[i].msg)
        /* eslint-enable */
      }

      if (this.errors === 0) {
        this.createCustomer()
      } else {
        this.loading = false
      }
    },
    toggleSmsEmailMarketing: function () {
      let self = this
      for (let key in self.oCustomer.consents) {
        self.oCustomer.consents[key].granted = !self.oCustomer.consents[key].granted
      }
    },
    hasConfirmationFlow: function () {
      return this.$store.state.store.info.enabled_email_confirmation_flow
    }
  },
  name: 'vtto-create-user',
  computed: {
    hideZipcode () {
      return this.$store.state.store.info.hide_zipcode
    },

    states () {
      return this.$store.state.account.addressInfoList.uf
    },

    cities () {
      return this.$store.state.account.addressInfoList.cities
    },

    districts () {
      return this.$store.state.account.addressInfoList.districts
    }
  },
  watch: {
    'oCustomer.address.zipcode': function (value) {
      let self = this
      if (value.length === 9) {
        let sUrl = `${process.env.VUE_APP_API}/location/find-by-zipcode?zipcode=` + value.replace('-', '')

        return axios({
          url: sUrl,
          method: 'get'
        }).then(function (response) {
          if (response && response.data && response.data.data && response.data.data.address) {
            let address = response.data.data.address
            self.oCustomer.address.address = address.type + ' ' + address.name_min
            self.oCustomer.address.state = address.uf
            self.oCustomer.address.city_id = address.city_id
            self.oCustomer.address.district_id = address.district_id
          }
        })
      }
    }
  },
  components: {
    VttoAccountMenu
  }

}
</script>
