<template>
    <div class="bg-white d-block p-20 pb-30 text-center relative">
        <div class="">
            <span class="color-green fs-14"><i class="fas fa-utensils"></i></span>
            <p class="fw-900 fs-m text-uppercase color-green mt-20">Já vamos começar a preparar tudo para você.</p>
            <p class="fw-400 fs-m" v-if="aDeadlineDelivery || aDeadlineDesk">
                A previsão de
                <span v-if="sOrderOrigin == 'delivery'">entrega</span>
                <span v-if="sOrderOrigin == 'desk'">retirada</span>
                é de
                <span v-if="aDeadlineDelivery && sOrderOrigin == 'delivery'">{{aDeadlineDelivery[0]}}</span>
                <span v-if="aDeadlineDesk && sOrderOrigin == 'desk'">{{aDeadlineDesk[0]}}</span>
                <span v-if="aDeadlineDelivery && sOrderOrigin == 'delivery' && aDeadlineDelivery[1]"> a {{aDeadlineDelivery[1]}}</span>
                <span v-if="aDeadlineDesk && sOrderOrigin == 'desk' && aDeadlineDesk[1]"> a {{aDeadlineDesk[1]}}</span>
                min
            </p>
            <div class="mt-20 p-20" style="background: #f2f2f2">
  <p class="fw-900 fs-m color-blue ">Não esqueça de lavar corretamente as mãos antes e depois do recebimento do seu pedido</p>
  <p class="fw-400 fs-m"> Juntos podemos evitar que você e outras pessoas contraiam o covid-19</p>
</div>
            <router-link v-if="hideButton" :to="{ name: 'order-detail', params: { iOrderId: iOrderId}}"
                         class="btn btn-secondary text-uppercase text-center mt-20 mx-auto fs-s">
                Acompanhar pedido
            </router-link>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'vtto-order-status-confirmed',
  props: {
    iOrderId: {
      type: [Number, String],
      default: 0
    },
    sOrderOrigin: {
      default: ''
    },
    hideButton: {
      default: 1
    }
  },
  created: function () {
    var payments = this.$store.state.order.oOrder.payments
    var total = 0
    payments.forEach(element => {
      total += element.payment_amount
    })
    total = parseFloat(total).toFixed(2)
    if (window.fbq) {
      window.fbq('track', 'Purchase', {
        value: total, // trocar quando pronto
        currency: 'BRL'
      })
    }
  },
  computed: mapState({
    sPhone: state => state.store.info.phones.phone_1,
    aDeadlineDesk: state => state.store.info.deadline && state.store.info.deadline.desk,
    aDeadlineDelivery: state => state.store.info.deadline && state.store.info.deadline.delivery
  })
}
</script>
