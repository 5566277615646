import Api from './api'

import { getXUserKey } from '@/plugins/account'

export default {
  /* Efetua login */
  Login (oLogin) {
    return Api('account', 'login', 'post', oLogin, { 'x-user-key': getXUserKey() })
  },

  /* Cria cliente */
  CreateCustomer (oCustomer) {
    return Api('account', 'create', 'post', oCustomer, { 'x-user-key': getXUserKey() })
  },

  /* Cria endereço */
  CreateAddress (oAddress) {
    return Api('address', 'create', 'post', oAddress, { 'x-user-key': getXUserKey() })
  },

  /* Lista todos os estados, cidades e bairros do estabelecimento */
  ListAddressInfo () {
    return Api('address', 'list', 'get', {}, { 'x-user-key': getXUserKey() })
  },

  /* Busca cliente */
  GetCustomer () {
    return Api('account', 'account', 'get', {}, { 'x-user-key': getXUserKey() })
  },

  /* Lista extrato de pontos do cliente */
  ListLoyaltyReport (oPageConfig) {
    return Api('account', 'loyalty-report', 'get', oPageConfig, { 'x-user-key': getXUserKey() })
  },

  /* Login via Facebook */
  FbLogin (oLogin) {
    return Api('account', 'loginfb', 'post', oLogin, { 'x-user-key': getXUserKey() })
  },

  /* Login via Google  */
  GoLogin (oLogin) {
    return Api('account', 'login-google', 'post', oLogin, { 'x-user-key': getXUserKey() })
  },
  /* Envia email para recuperar senha */
  SendEmailPasswordForgotten (oAccount) {
    return Api('account', 'forgot-password', 'post', oAccount, { 'x-user-key': getXUserKey() })
  },

  /* Envia nova senha */
  SendPasswordPasswordForgotten (oAccount) {
    return Api('account', 'retrieve-password', 'post', oAccount, { 'x-user-key': getXUserKey() })
  },

  /* Atualiza a senha */
  UpdatePassword (oAccount) {
    return Api('account', 'update-password', 'put', oAccount, { 'x-user-key': getXUserKey() })
  },

  /* Atualiza dados do usuário */
  UpdateAccount (oAccount) {
    return Api('account', 'update', 'put', oAccount, { 'x-user-key': getXUserKey() })
  },

  CreateAddresses (oAddress) {
    return Api('address', 'create', 'post', { addresses: [oAddress] }, { 'x-user-key': getXUserKey() })
  },

  ListAddresses (origin) {
    return Api('address', 'address-list', 'get', { origin: origin }, { 'x-user-key': getXUserKey() })
  },

  UpdateAddresses (oAddress) {
    return Api('address', 'update', 'put', oAddress, { 'x-user-key': getXUserKey() })
  },

  RemoveAddresses (iAddressId) {
    return Api('address', 'delete', 'delete', { address_id: iAddressId }, { 'x-user-key': getXUserKey() })
  },

  VerifyEmail (email) {
    return Api('account', 'email-validate', 'post', email)
  },

  ConfirmEmail (email) {
    return Api('account', 'email-confirmation', 'post', email)
  }

}
