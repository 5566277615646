import Api from './api'

export default {
  GetSurvey (hash) {
    return Api('survey', 'survey', 'post', hash)
  },
  PostSurvey (data) {
    return Api('survey', 'notes', 'post', data)
  }
}
