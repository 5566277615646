<template>
  <div v-if="social">
    <div class="row">
        <div class="col-12">
            <div class="bg-white d-block mb-20">
                <h3 class="fw-900 text-uppercase bb-1-gray p-15 d-block fs-s">Redes Sociais</h3>
                <a :href="'https://'+s" v-if="s!==''" target="_blank" class="media p-15 bb-1-gray" v-for="(s, i) in social" :key="i">
                    <i :class="`${getClass(i)} fs-3 mr-10`"></i>
                    <div class="media-body fs-s text-uppercase mt-5">
                        {{i}}
                    </div>
                </a>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'vtto-social',
  data () {
    return {
      classes: {
        website: 'fas fa-globe',
        facebook: 'fab fa-facebook-square',
        reservas: 'fas fa-calendar-alt',
        instagram: 'fab fa-instagram',
        youtube: 'fab fa-youtube-square',
        pinterest: 'fab fa-pinterest-square',
        twitter: 'fab fa-twitter-square'
      }
    }
  },
  props: {
    social: {}
  },
  methods: {
    getClass (indice) {
      if (this.classes[indice]) {
        return this.classes[indice]
      }
      return 'fas fa-stop'
    }
  }
}
</script>
